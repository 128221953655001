import CardItem from "components/alove/CardItem";
import { ActionSetting } from "layouts/screens/screenView/components/action";
import React, { useEffect, useState } from "react";

import { Comm } from "models/content/Comm";
import { ScreenModel } from "models/content/Screen";

const MDInput = require("components/MDInput/index.js").default;

export const SMSContainer = ({
  comm,
  setComm,
  screens,
}: {
  comm: Comm;
  setComm: any;
  screens: ScreenModel[];
}) => {
  return (
    <React.Fragment>
      <CardItem title="Edit SMS" id="comm" halfWidth>
        <MDInput
          label="SMS Content"
          value={comm.sms?.body || ""}
          fullWidth
          multiline
          rows={4}
          onChange={(e) => setComm({ ...comm, sms: { ...comm.sms, body: e.target.value } })}
        />
      </CardItem>
      <CardItem title="SMS Link" id="comm" halfWidth>
        <ActionSetting
          action={comm.sms || {}}
          setAction={(a) => setComm({ ...comm, sms: a })}
          screens={screens}
        />
      </CardItem>
    </React.Fragment>
  );
};
