import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { capitalize, Icon } from "@mui/material";
import { T } from "components/alove/Translator";
import { getMMList } from "utils/matchmakerUtils";
import { TablePage } from "components/alove/TablePage";
import { getSMEStats } from "utils/matchmakerUtils";
import { TwoSizeChip } from "components/alove/Chips";
import { getStatusColor, MMStatuses } from "models/BO/Matchmaker";
import { GridColDef } from "@mui/x-data-grid";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";

const MDAvatar = require("components/MDAvatar").default;
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

function SMEProfiles() {
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const [stats, setStats] = useState({
    mmTotal: 0,
    mmActive: 0,
    mmPending: 0,
    mmWarning: 0,
  });
  const columns: GridColDef[] = [
    {
      headerName: "",
      field: "avatar",

      align: "center",
      renderCell: (params) => (
        <Grid container alignItems="center" height="100%">
          <MDAvatar src={params?.row?.avatar} />
        </Grid>
      ),
    },
    { headerName: "firstNameHeader", field: "first_name", flex: 1 },
    { headerName: "lasNameHeader", field: "last_name", flex: 1 },
    { headerName: "emailHeader", field: "email", flex: 1 },
    { headerName: "phoneHeader", field: "phone", flex: 1 },
    {
      headerName: "status",
      field: "status",
      flex: 1,
      align: "center",

      renderCell: (params) => (
        <TwoSizeChip
          sizeoption={params.row.profileStatus == MMStatuses.pending ? "wide" : "narrow"}
          label={<T>smeStatus{params.row.profileStatus}</T>}
          color={getStatusColor(params.row.profileStatus) as any}
        />
      ),
    },
    { headerName: "requests", field: "requests", align: "center" },
    {
      headerName: "",

      align: "center",
      field: "actions",
      renderCell: (params) => <Icon>edit</Icon>,
    },
  ];
  const navigate = useNavigate();

  const getSMEUsers = async (host, filters, page) => {
    const SMEUsers = await getMMList({
      statusFilter: filters["status"],
      user: filters["search"],
      page,
      withDeleted: currentRole.hasAccess("7.4", PermissionType.Manager),
    });
    console.log("SMEUser: ", SMEUsers);
    return SMEUsers.map((item, index) => ({
      ...item,
      profileStatus: item.status,
      requests: `${item.activeRequests || 0}/${item.inactiveRequests || 0}`,
    }));
  };

  useEffect(() => {
    getSMEStats().then((data) => {
      setStats(data);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="SME Profiles" />
      <TablePage
        title="SME List"
        table={{
          columns: columns,
          loadNext: getSMEUsers,
          rowCount: stats.mmTotal,
          lineClick: (row) => navigate(row.id.toString()),
        }}
        filters={[
          {
            label: "search",
            type: "search",
            placeholder:"Name, Email, Phone",
          },
          {
            label: "status",
            options: Object.keys(MMStatuses)
              .filter((key) => !isNaN(Number(key)))
              .map((key) => ({
                key,
                label: capitalize(MMStatuses[key]),
              })),
          }
        ]}
        stats={{
          title: "SMEStats",
          stats: stats,
          fields: [
            {
              name: "mmTotal",
              title: "totalSME",
              color: "primary",
              filterLabel: "status",
              filterValue: "",
            },
            {
              name: "mmActive",
              title: "activeSME",
              filterLabel: "status",
              filterValue: MMStatuses.available.toString(),
            },
            {
              name: "mmPending",
              title: "pendingSME",
              filterLabel: "status",
              filterValue: MMStatuses.pending.toString(),
            },
            {
              name: "mmWarning",
              title: "warning",
              color: "error",
            },
          ],
        }}
      />
    </DashboardLayout>
  );
}

export default SMEProfiles;
