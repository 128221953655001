import { useState, useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import { getSettingsDO, updateSettingsDO, deploySettingsDO } from "utils/settingsUtils";
import { getPositionsDO } from "utils/translateUtils";
import { postLogDO } from "utils/logsUtils";
import { postNewAdminTaskDO } from "utils/adminUtils";
import { addAlert, useMaterialUIController } from "context";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { GridEditBooleanCell, GridEditInputCell, GridRenderCellParams } from "@mui/x-data-grid";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { PermissionType } from "models/BO/BoRole";
import { networkGlobals } from "utils/networkUtils";
import { Setting } from "models/BO/Setting";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const positionsChanges = {};
let changes = [] as any[];

function AppSettings() {
  const pageNumber = "9.1";
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    auth,
    currentUser,
    currentRole,
  } = controller;

  const [settings, setSettings] = useState([] as any[]);
  const [refresh, setrefresh] = useState(false);
  const [type, setType] = useState({ key: "mobile_app", label: "Mobile app" });

  const [types, setTypes] = useState([
    { key: "mobile_app", label: "Mobile app" },
    { key: "brands_assets", label: "Brand assets" },
    { key: "brands_colors", label: "Brands colors" },
    { key: "algorithm", label: "Algorithm" },
    { key: "payments", label: "Payments" },
    { key: "app_features", label: "App Features" },
    { key: "server", label: "Server" },
  ]);

  const [brand, setBrand] = useState({ id: 100, label: "a-love" });
  const [loaded, setLoader] = useState(false);
  const [newSettings, setNewSettings] = useState([] as any[]);
  const [editMode, setEditMode] = useState(false);
  const [positions, setPositions] = useState([] as any[]);
  const [publishLoader, setPublishLoader] = useState(false);

  useEffect(() => {
    changes = [];
    getPositions();
  }, []);

  useEffect(() => {
    getSettings();
  }, [type, brand]);

  const getPositions = async () => {
    const positions = await getPositionsDO(null,"content");
    setPositions(positions);
  };

  const getSettings = async (host?, filters?) => {
    setSettings([]);
    setNewSettings([]);
    const typeFilter = filters?.Type;
    console.log('type: ', typeFilter);
    if (typeFilter) {
      const settings = await getSettingsDO( typeFilter);
      settings.sort((a, b) =>
        a.description > b.description ? 1 : b.description > a.description ? -1 : 0
      );
      settings.forEach((element) => (element.id = element.paramName));
      setSettings(settings);
      setNewSettings(settings);
    setType(types.find((t) => t.key === typeFilter)!);
      return settings;
    }
    return [];
  };

  const buildPositions = (positionsValue) => {
    let returnedPositions = [] as any[];
    if (positionsValue?.length && positionsValue[0] !== null) {
      for (let position of positionsValue) {
        const pos = positions.find((p) => p?.id == position);
        if (pos !== undefined) {
          returnedPositions.push({ position_name: pos?.position_name });
        }
      }
      return returnedPositions;
    }
  };

  const updatePosition = (index, value) => {
    let positionIndexes = [] as any[];
    for (let val of value) {
      if (val?.id) {
        positionIndexes.push(val?.id);
      } else {
        positionIndexes.push(positions[index]?.id);
      }
    }
    positionsChanges[newSettings[index]?.paramName] = positionIndexes;
    const newArray = newSettings.map((item, i) => {
      if (index == i) {
        return { ...item, position: positionIndexes };
      } else {
        return item;
      }
    });
    setNewSettings(newArray);
  };

  const askToPublish = async () => {
    const postNewAdminTaskDOStatus = await postNewAdminTaskDO(
      "dev",
      "Deploy Settings",
      "in settings",
      currentUser?.id ? currentUser?.id : 0,
      "/dashboards/settings/appSettings"
    );
  };

  const publish = async () => {
    setPublishLoader(true);

    const publishStatus = await deploySettingsDO(type?.key);
      addAlert(dispatch, "Settings published successfully");
      setrefresh(!refresh);
      await postLogDO(
        "dev",
        networkGlobals.host,
        "publish in settings",
        "settings",
        changes,
        currentUser?.id ? currentUser?.id : 0
      );
      addAlert(dispatch, "Settings published successfully");
      setTimeout(() => {
        setPublishLoader(false);
      }, 1000);
    
  };

  const updateValue = (newRow, oldRow) => {
    console.log("newRow", newRow);  
    // const newArray = newSettings.map((item, i) => {
    //     if (index == i) {
    //         if (newSettings[index]?.prevalidated_param_value?.includes('true')) {
    //             // newChanges.push({paramName:item.paramName,})
    //             newChanges.push({ paramName: item.paramName, prevalidated_param_value: false, description: item?.description })
    //             return { ...item, prevalidated_param_value: 'false' };
    //         } else if (newSettings[index].prevalidated_param_value.includes('false')) {
    //             newChanges.push({ paramName: item.paramName, prevalidated_param_value: true, description: item?.description })
    //             return { ...item, prevalidated_param_value: 'true' };
    //         } else {
    //             newChanges.push({ paramName: item.paramName, prevalidated_param_value: e.target.value, description: item?.description })
    //             return { ...item, prevalidated_param_value: e.target.value };
    //         }
    //     } else {
    //         return item;
    //     }
    // });
    updateSettingsDO( {
      paramName: oldRow.paramName,
      prevalidatedParamValue: newRow.prevalidatedParamValue,
      description: newRow?.description,
    });
    return newRow;
  };

  const updateDescription = (index) => (e) => {
    let newChanges = [...changes];
    const newArray = newSettings.map((item, i) => {
      if (index == i) {
        newChanges.push({
          paramName: item?.paramName,
          prevalidated_param_value: item?.param_value,
          description: e.target.value,
        });
        return { ...item, description: e.target.value };
      } else {
        return item;
      }
    });
    setNewSettings(newArray);
  };

  const saveUpdatedValue = async () => {
    setLoader(true);
    const changesList = Object.values(changes);
    const updateStatus = await updateSettingsDO( {
      changes: changesList,
      positionsChanges,
    });
    if (updateStatus === 201) {
      await postLogDO(
        "dev",
        networkGlobals.host,
        "cahnges in settings",
        "settings",
        changesList,
        currentUser?.id ? currentUser?.id : 0
      );
      setLoader(false);
      changes = [];
      addAlert(dispatch, "Settings saved successfully");
    } else {
      setLoader(false);
      addAlert(dispatch, `Something happened on save settings`, true);
    }
  };

  const renderPreview = (params:GridRenderCellParams<Setting>) => {
    switch (params.row.belongs) {
      case "brands_assets":
        return (
          <img
            src={params.row?.paramValue}
            // alt={'Settings Image'}
            // borderRadius="lg"
            width="100%"
            height="100%"
            style={{ backgroundColor: "#c6c6c6", objectFit: "contain" }}
          />
        );
      case "brands_colors":
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: `#${params.row?.prevalidatedParamValue?.slice(4)}`,
            }}
          />
        );
      default:
        return "";
    }
  };

  const renderCell = (params:GridRenderCellParams) =>
    params.row?.paramType === "bool" ? (
      params.value == "false" || !params.value ? (
        <ClearIcon />
      ) : (
        <DoneIcon />
      )
    ) : (
      params.value
    );

  const columns: TableColumnProps<Setting>[] = [
    {
      field: "paramName",
      headerName: "Settings",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 4,
      editable: true,
    },
    {
      field: "paramValue",
      headerName: "Current value",
      flex: 1,
      align: "center",
      renderCell: renderCell,
    },
    {
      field: "preview",
      headerName: "Preview",
      width: 65,
      renderCell: renderPreview,
    },
    {
      field: "prevalidatedParamValue",
      headerName: "New value",
      flex: 1,
      align: "center",
      editable: currentRole.hasAccess(pageNumber, PermissionType.Edit),
      renderCell: renderCell,

      renderEditCell: (params) =>
        params.row?.paramType === "bool" ? (
          <GridEditBooleanCell {...params} />
        ) : (
          <GridEditInputCell {...params} />
        ),
    },
  ];

  return (
    <>
      {currentUser?.id === 15 ? (
        <Fab
          color="info"
          size="small"
          aria-label="add"
          sx={{ position: "fixed", top: "40vh", right: 1 }}
        >
          <SaveIcon onClick={() => saveUpdatedValue()} />
        </Fab>
      ) : null}

      <DashboardLayout>
        <DashboardNavbar />
        <TablePage
          title="Settings"
          table={{ columns, loadNext: getSettings, processRowUpdate: updateValue }}
          refresh={refresh}
          actions={{
            title: "Actions",
            page: pageNumber,
            saveActions: [
              {
                label: "Publish",
                role: PermissionType.Publish,
                onClick: publish,
                variant: "contained",
              },
              {
                label: "Ask to publish",
                onClick: askToPublish,
                variant: "contained",
                role: PermissionType.AskToPublish,
              },
            ],
          }}
          filters={[
            // {
            //   label: "Brand",
            //   options: brands?.map((b) => ({key: b.id, label: b.label})),
            //   defaultValue: brands[0],
            // },
            {
              label: "Type",
              options: types,
              defaultValue: types[0],
            },
          ]}
        />

        {/* {false && (
          <Autocomplete
            size="small"
            isOptionEqualToValue={(positions, value) =>
              positions.position_name === value.position_name
            }
            multiple
            defaultValue={buildPositions(s?.position)}
            options={positions}
            // onClick={() => {
            // }}
            disableCloseOnSelect
            onChange={(e, value) => updatePosition(index, value)}
            getOptionLabel={(positions) => positions.position_name}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.position_name} sx={{ height: 20 }}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                {option.position_name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                // variant="standard"
                label="Positions"
                placeholder="More..."
                size="small"
              />
            )}
          />
        )} */}
      </DashboardLayout>
    </>
  );
}

export default AppSettings;
