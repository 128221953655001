import { Divider, Grid } from "@mui/material";
import { ImageUploader } from "components/alove/ImageUploader";
import { ScreenCard, ScreenModel } from "models/content/Screen";
import { useEffect, useState } from "react";
const MDButton = require("components/MDButton/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
import { Autocomplete, Card, List, ListItem, ListItemIcon, ListItemSecondaryAction, TextField } from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { RootRef } from "@material-ui/core";

export const CardsForm = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  const [cards, setCards] = useState([] as ScreenCard[]);
  useEffect(() => {
    setCards(screen.cards)
  });

  const updateCard = (index: number, field: string, value: string) => {
    cards[index][field] = value;
    setScreen({ ...screen, cards });
  };

  
  const onDragEnd = (result) => {
    let itemsNew = cards.filter((field, index) => index !== result.source.index);
    itemsNew.splice(result.destination.index, 0, cards[result.source.index]);
    setScreen({ ...screen, cards: itemsNew });
  };

  const cardElement = (card: ScreenCard, index: number) => {

    return <Grid item xs={12} key={index} container spacing={2}>
    <Grid item xs={8}>
      <MDInput
        label="Title"
        value={card.title}
        fullWidth
        onChange={(e: any) => {
          updateCard(index, "title", e.target.value);
        }}
      />
    </Grid>
    <Grid item xs={3}>
      <MDButton
        variant="contained"
        color="secondary"
        onClick={() => {
          setScreen({
            ...screen,
            cards: cards.filter((_, i) => i !== index),
          });
        }}
      >
        Remove Card
      </MDButton>
    </Grid>
    <Grid item xs={12}>
      <MDInput
        label="Body"
        value={card.body}
        multiline
        rows={4}
        fullWidth
        onChange={(e: any) => {
          updateCard(index, "body", e.target.value);
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <ImageUploader
        initImage={card.image || ""}
        onUploaded={(image: string) => updateCard(index, "image", image)}
      />
    </Grid>
    <Divider style={{width: "100%"}} />
  </Grid>
  }


  const item = (card: ScreenCard, index: number) => {
    return (
      <Draggable key={card.title} draggableId={card.title} index={index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <ListItem>
              <Grid
                container
                my={1}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                  <Grid item xs={1} xl={1}>
                    <ListItemIcon>
                      <MenuIcon />
                    </ListItemIcon>
                  </Grid>
                
                <Grid item xs={11}>
                  {cardElement(card, index)}
                </Grid>
              </Grid>
            </ListItem>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <Grid container flexDirection="column" p={4} gap={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <RootRef rootRef={provided.innerRef}>
                <List sx={{}}>
                  {cards.map(item)}
                  {provided.placeholder}
                </List>
              </RootRef>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Grid>
  );

  return (
    <Grid container spacing={2} pl={2}>
       <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided1, snapshot) => {
              return (
                <RootRef rootRef={provided1.innerRef}>
                  <List sx={{}}>
                    {cards.map((card, index) => {
                        return (
                          <Draggable key={card} draggableId={card} index={index}>
                            {(provided, snapshot) => (
                              <>
                                <ListItem
                                  ContainerComponent="li"
                                  ContainerProps={{ ref: provided.innerRef }}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                
                                  <ListItemSecondaryAction />
                                </ListItem>
                              </>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided1.placeholder}
                  </List>
                </RootRef>
              );
            }}
          </Droppable>
        </DragDropContext>
      <MDButton
        variant="contained"
        color="primary"
        sx={{ m: 2 }}
        onClick={() => {
          setScreen({ ...screen, cards: [...cards, { title: "", body: "", image: "" }] });
        }}
      >
        Add Card
      </MDButton>
    </Grid>
  );
};
