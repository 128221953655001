import { StatsPanelProps } from "components/alove/StatsPanel";

export type ReportList = {
  title: string;
  dataset: {
    title: [string, string];
    count: number | string;
    trail: string;
  }[];
};

export type HorizontalBarChart = {
  title: string;
  labels: string[];
  datasets: {
    label: string;
    color: string;
    data: number[];
  }[];
};

export type LineChart = {
  title: string;
  labels: string[];
  datasets: {
    label: string;
    backgroundColor: string;
    color: string;
    fill: boolean;
    data: number[];
  }[];
};

export type PieChartReport = {
  title: string;
  note: string;
  labels: string[];
  datasets: {
    label: string;
    backgroundColors: string[];
    data: number[];
  };
};
export type StatisticsCard = {
  title: string;
  count: string;
};

export class Report {
  list?: ReportList;
  horizontalBarChart?: HorizontalBarChart;
  lineChart?: LineChart;
  pieChart?: PieChartReport;
}
