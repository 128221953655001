import axios from "axios";
import { makeRequest, networkGlobals } from "./networkUtils";
import { Attribute, AttrResponseType } from "models/profile/Attribute";
import { getBucket } from "./networkUtils"
import { groupBy } from "./globalsUtils";
import { HIDDEN_ATTRIBUTES } from "./matchmakerUtils";
import { Introduction } from "models/introduction/Introduction";
import { AttributePolicy } from "models/profile/Profile";



let profileAttributes: {[key:string]: Attribute[]} = {}
export const loadAttributes = async () : Promise<Attribute[]> => {
  const brand = networkGlobals.brand.name.toLocaleLowerCase();
  const key = `${brand}-${getBucket()}`
  if (!profileAttributes[key]?.length) {
    profileAttributes[key] = await axios.get(
      `https://${getBucket()}.s3.amazonaws.com/public/generated/${brand}/1.0.0/profile_attr_en.json`
      // `https://mujual.s3.amazonaws.com/public/generated/${brand}/1.0.0/profile_attr_en.json`
    ).then((res) => res.data);
    
  }
  return profileAttributes[key].map((attr) => Object.assign(new Attribute(), attr));
};

export const getAttributes = (host, brandID) => {
  return makeRequest(`attributes?brandID=${brandID}&host=${host}`)
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export const updatePrevalidation = (preValidation, bcid) => {
  return makeRequest(`attributes/batchContents/${bcid}/preValidation`, {
    method: "POST",
    body: preValidation,
  })
};

export const getBioPrefRelations = (attrName) =>  makeRequest(`attributes/${attrName}/relations`)


export const updateBioPrefRelations = (attrName, relations) => {
  return makeRequest(`attributes/${attrName}/relations`, {
    method: "POST",
    body: relations,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
}


const findPrefFromBio = (attrs:Attribute[], bio:string) : Attribute[] => {
  const attr = attrs.filter((a) =>
    a.responses?.find((r) => r.bios?.find((b) => b?.bio_attr_id == bio))
  );
  if (!attr) return [];
  return attr;
};

export const findBioFromPref = (attrs:Attribute[], pref:string) : Attribute[] => {
  const prefAttributes = attrs.find((a) => a.attr_name == pref);
  const attr = attrs.filter((a) =>
    prefAttributes?.responses?.find((r) => r.bios?.find((b) => b?.bio_attr_id == a.attr_name))
  );
  if (!attr) return [];
  return attr;
}

const isAttributesMatch = (bio: Attribute, pref: Attribute, bioValue, prefValue) => {
  if (!bioValue || !prefValue) return null;

  if (bio.response_type_id == 1){
    return null
  }

  const bioResponses = bio.responses?.filter((r) => bioValue.includes(r.k));
  const prefResponses = pref.responses?.filter((r) => prefValue.includes(r.k));
  
  if (bioResponses?.some((bioResponse) => bioResponse.type == AttrResponseType.RatherNotSay)) {
    return null
  }

  return prefResponses.some((prefResponse) =>
    prefResponse.type == AttrResponseType.OpenToAll ||
    prefResponse.bios?.some((prefBio) =>
      bioResponses.some((bioResponse) => bioResponse.k === prefBio.bio_response_id)
    )
  );
};

export const visibiltyOptions = {
  PUBLIC: {
    label: "Visible For Partners & SME",
    value: 1,
  },
  SME_VISIBLE: {
    label: "Visible For SME",
    value: 5,
  },
  PRIVATE: {
    label: "Visible Only For Me",
    value: 3,
  },
  HIDDEN: {
    label: "Hidden From All (Techincal attribute)",
    value: 4,
  },
};

export const getAttrArray = (attributes:Attribute[], request: Introduction, isShidduch:boolean) => {
  const groupped = groupBy(attributes, (x) => x.attr_group);
  return (groupped["bio"] || [])
    .filter((attr) => !HIDDEN_ATTRIBUTES.includes(attr.attr_name))
    .filter((attr) => !(request.initiatorProfile?.attributesPolicy?.[attr.attr_name] == AttributePolicy.autofilled ||
                        request.responderProfile?.attributesPolicy?.[attr.attr_name] == AttributePolicy.autofilled))
    .filter(
      (attr) =>
        !isShidduch ||
        [visibiltyOptions.PUBLIC.value, visibiltyOptions.SME_VISIBLE.value].includes(
          attr.default_policy
        )
    )
    .map((attr: Attribute) => {
      if (!attr?.attr_name) return null;
      const initiatorBio = request.initiatorProfile?.attributesValues[attr.attr_name]?.join(",");
      const responderBio = request.responderProfile?.attributesValues[attr.attr_name]?.join(",");
      if (!initiatorBio && !responderBio) return null;

      const initiatorBioTxt = attr.responses
        ?.filter(
          (r) =>
            request.initiatorProfile?.attributesValues[attr.attr_name]?.includes(r.k) ||
            request.initiatorProfile?.attributesValues[attr.attr_name]?.includes(r.id) ||
            request.initiatorProfile?.attributesValues[attr.attr_name]?.includes(r.id.toString())
        )
        ?.map((r) => r.txt)
        .join(", ");

      const responderBioTxt = attr.responses
        ?.filter(
          (r) =>
            request.responderProfile?.attributesValues[attr.attr_name]?.includes(r.k) ||
            request.responderProfile?.attributesValues[attr.attr_name]?.includes(r.id) ||
            request.responderProfile?.attributesValues[attr.attr_name]?.includes(r.id.toString())
        )
        ?.map((r) => r.txt)
        .join(", ");

      const prefAttributes = findPrefFromBio(attributes, attr.attr_name);
      const prefAttribute = prefAttributes[0];
      const initiatorPref =
        request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.join(", ");
      const responderPref =
        request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.join(", ");

      let initiatorPrefTxt = prefAttributes.map((prefAttribute) => 
       prefAttribute?.responses
        ?.filter(
          (r) =>
            request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.includes(r.k) ||
            request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
              r.id
            ) ||
            request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
              r.id.toString()
            )
        )
        ?.map((r) => ["Yes", "No"].includes(r.txt) ? "<strong>" + prefAttribute?.editing_question_txt + ":</strong> "+ r.txt + "</br>" : r.txt)
        .join(", ")
      ).join("\n");


      let responderPrefTxt = prefAttributes.map((prefAttribute) =>
       prefAttribute?.responses
        ?.filter(
          (r) =>
            request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.includes(r.k) ||
            request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
              r.id
            ) ||
            request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
              r.id.toString()
            )
        )
        ?.map((r) => ["Yes", "No"].includes(r.txt) ? "<strong>" + prefAttribute?.editing_question_txt + ":</strong> "+ r.txt + "</br>" : r.txt)
        .join(", ")
      ).join("\n ");


      const initiatorMatch = isAttributesMatch(
        attr,
        prefAttribute,
        request.initiatorProfile?.attributesValues[attr.attr_name],
        request.responderProfile?.attributesValues[prefAttribute?.attr_name]
      );

      const responderMatch = isAttributesMatch(
        attr,
        prefAttribute,
        request.responderProfile?.attributesValues[attr.attr_name],
        request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]
      );

      return {
        id: attr.editing_question_id || initiatorBio?.toString() + responderBio?.toString(),
        bioName: attr.editing_question_txt,
        initiatorBio: initiatorBioTxt || initiatorBio?.toString(),
        responderBio: responderBioTxt || responderBio?.toString(),
        initiatorPrefernce: initiatorPrefTxt || initiatorPref?.toString(),
        responderPrefernce: responderPrefTxt || responderPref?.toString(),
        initiatorMatch,
        responderMatch,
        responseType: attr.response_type_id,
        rangeMax: attr.range_max,
        rangeMin: attr.range_min,
        responseSettings: attr.response_settings,
        batchId: attr.related_batch_id,
      };
    })
    .filter((r) => r)
    .sort((a, b) => a.bioName.localeCompare(b.bioName));
};