import colors from "assets/theme/base/colors";
export default {
  styleOverrides: {
    outlined:{
      maxHeight: "30px",
    },
    deletable: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      maxHeight: "22px",
      backgroundColor: `${colors.primary.main} !important`,
      color: 'white',
      margin: "0 2px !important",
      
      "& .MuiChip-deleteIcon": {
        color: `${colors.primary.main} !important`,
        height: "25px",
        width: "25px",
        background: 'radial-gradient(white 0%, white 50%, transparent 30%)',
      },
    },
  },
};
