import { Autocomplete } from "@mui/material";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";

import { Predictor, PredictorRelation, PredictorRelationLevel } from "models/batch/PredictorRelation";
import React, { useEffect, useState } from "react";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { networkGlobals } from "utils/networkUtils";
import { createPredictorRelationDO } from "utils/predictorRelationUtils";
import { getQuestionsCategoryDO } from "utils/questionsUtils";

const MDInput = require("components/MDInput/index.js").default;

export const AddRelationDialog = ({onRefresh} : {onRefresh: () => void}) => {
  const [relation, setRelation] = useState<PredictorRelation>({
    predictor1: Predictor.anxietyDimension,
    level1: PredictorRelationLevel.Low,
    predictor2: Predictor.avoidanceDimension,
    level2: PredictorRelationLevel.Low,
    grade: 10,
  } as PredictorRelation);
  

  return (
    <AloveDialog
      dialogTitle="Add Relation"
      dialogType={DialogType.AddPredictorRelation}
      handleConfirm={() => createPredictorRelationDO(relation).then(onRefresh)}
      valid
      dialogConfirm="Add"
    >
      <Autocomplete
        options={Object.values(Predictor).filter((predictor) => !isNaN(Number(predictor))) as number[]}
        getOptionLabel={(option) => camelCaseToCapitalizedWords(Predictor[option])}
        style={{ width: 300 }}
        defaultValue={0}
        disableClearable
        renderInput={(params) => <MDInput {...params} label="Predictor 1" />}
        onChange={(e, value) => setRelation({ ...relation, predictor1: value as Predictor })}
      />

      <Autocomplete
        options={Object.values(PredictorRelationLevel).filter((level) => !isNaN(Number(level))) as number[]}
        getOptionLabel={(option) => PredictorRelationLevel[option]}
        style={{ width: 300 }}
        defaultValue={0}
        disableClearable
        renderInput={(params) => <MDInput {...params} label="Level 1" />}
        onChange={(e, value) =>
          setRelation({ ...relation, level1: value as PredictorRelationLevel })
        }
      />
      <Autocomplete
        options={Object.values(Predictor).filter((predictor) => !isNaN(Number(predictor))) as number[]}
        getOptionLabel={(option) => camelCaseToCapitalizedWords(Predictor[option])}
        style={{ width: 300 }}
        defaultValue={0}
        disableClearable
        renderInput={(params) => <MDInput {...params} label="Predictor 2" />}
        onChange={(e, value) => setRelation({ ...relation, predictor2: value as Predictor })}
      />
      <Autocomplete
        options={Object.values(PredictorRelationLevel).filter((level) => !isNaN(Number(level))) as number[]}
        getOptionLabel={(option) => PredictorRelationLevel[option]}
        style={{ width: 300 }}
        defaultValue={0}
        disableClearable
        renderInput={(params) => <MDInput {...params} label="Level 2" />}
        onChange={(e, value) =>
          setRelation({ ...relation, level2: value as PredictorRelationLevel })
        }
      />
      <MDInput
        label="Grade"
        value={relation.grade || 10}
        inputProps={{ type: "number" }}
        onChange={(e) => setRelation({ ...relation, grade: parseInt(e.target.value) })}
      />
    </AloveDialog>
  );
};
