import { Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import { getStatistics } from "utils/enduserUtils";
import { setCurrentDialog, useMaterialUIController } from "context";
import { DialogType } from "components/alove/AloveDialog";
import {
  HeaderIndication,
  HeaderIndicationProps,
} from "layouts/itemPageContainer/components/headerIndication";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { useNavigate } from "react-router-dom";
import { Profile } from "models/profile/Profile";
import { camelCaseToCapitalizedWords, formtHeight } from "utils/globalsUtils";
import { T } from "components/alove/Translator";
import { EndUser } from "models/profile/EndUser";
import { loadAttributes } from "utils/attributesUtil";
import { Attribute } from "models/profile/Attribute";

const MDTypography = require("components/MDTypography/index.js").default;

export const Bio = ({ profile, user }: { profile: Profile; user: EndUser }) => {
  const [attributes, setAttributes] = useState([] as Attribute[]);
  const [controller] = useMaterialUIController();
  const { currentBrand, currentHost } = controller;
  useEffect(() => {
    loadAttributes().then((attrs) => {
        setAttributes(attrs);
    });
  }, []);

  profile.attributesValues ||= {}
  profile.attributesValues["age"] = [user.age]

  profile.attributesValues["city"] = [profile.location]

  const formatValue = (attribute: Attribute, value) => {
    switch (attribute?.attr_name) {
      case "height":
      case "heightPref":
        return formtHeight(value);
      default:
        return attribute?.responses?.find((r) => r.id == value)?.txt || value;
    }
  };

  const field = (key: string) => {
    const attribute = attributes.find((a) => a.attr_name == key);
    let title = attribute?.editing_question_txt
    if(key == "city") {
      title = "Location"
    }
    if(key == "age") {
      title = "Age"
    }

    return [
      <VerticalDivider height="40px" key={key} />,
      <Grid item xs key={key + "1"}>
        <MDTypography variant="h5" style={{ textTransform: "capitalize" }}>
          {title?.replace("Preferred", "Pref")}
        </MDTypography>
        {["heightPref", "prefAge"].includes(key) ? (
          <MDTypography variant="body2" style={{}}>
            {profile.attributesValues[key]?.map((v) => formatValue(attribute!, v)).join(" - ")}
          </MDTypography>
        ) : (
          profile.attributesValues[key]?.map((v) => (
            <MDTypography variant="body2" style={{}} key={v}>
              {formatValue(attribute!, v)}
            </MDTypography>
          ))
        )}
      
      </Grid>,
    ];
  };

  return (
    <Grid container display="flex" justifyContent="start" flexDirection="column" gap={2}>
      <Grid container display="flex" justifyContent="start" flexDirection="row" item>
        <Grid item xs={1}>
          <MDTypography variant="h5" style={{ textTransform: "capitalize" }}>
            Bio
          </MDTypography>
        </Grid>
        {["age", "gender", "city", "marital", "bodyType", "height", "identifyAsJew"].map(field).flat()}
      </Grid>

      <Grid container display="flex" justifyContent="start" flexDirection="row" item>
        <Grid item xs={1}>
          <MDTypography variant="h5" style={{ textTransform: "capitalize" }}>
            Pref
          </MDTypography>
        </Grid>
        {["prefAge", "prefGender", "prefHowFar", "prefMarital", "bodyTypePref", "heightPref", ""]
          .map(field)
          .flat()}
      </Grid>
    </Grid>
  );
};
