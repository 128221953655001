import React from "react";
import { Delete, DeleteOutline } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { updateContactDO, deleteRoleDO } from "utils/brandsUtils";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import DeleteContactModal from "./deleteContactModal";

// const MDTypography = require("components/MDTypography/index.js").default;

export const ContactsTable = (props) => {
  const { contacts, snackBar, setSnackbar, getData } = props;

  const handleRowEditCommit = React.useCallback(
    async (newRow, oldRow) => {
      if ((newRow.function !== oldRow.function || newRow.first_name !== oldRow.first_name || newRow.last_name !== oldRow.last_name || newRow.phone !== oldRow.phone || newRow.email !== oldRow.email || newRow.position !== oldRow.position) && !newRow?.is_new) {
        const updateStatus = await updateContactDO('dev', newRow);
        getData();
        setSnackbar({
          status: true,
          color: updateStatus === 200 ? "success" : "error",
          icon: updateStatus === 200 ? "check" : "error",
          title: updateStatus === 200 ? "Contact was updated" : "Something was wrong",
        });
      }
    },
    []
  );

  const deleteContact = async (id) => {
    const deleteStatus = await deleteRoleDO('dev', id);
    setSnackbar({
      status: true,
      color: deleteStatus === 200 ? "success" : "error",
      icon: deleteStatus === 200 ? "check" : "error",
      title: deleteStatus === 200 ? "Contact was deleted" : "Something was wrong",
    });
    getData();
  }

  const columns: GridColDef[] = [
    {
      headerName: "Function",
      field: "function",
      flex: 1,
      "editable": true
    },
    {
      headerName: "First Name",
      field: "first_name",
      flex: 1,
      "editable": true
    },
    {
      headerName: "Last Name",
      field: "last_name",
      flex: 1,
      "editable": true
    },
    {
      headerName: "Phone",
      field: "phone",
      flex: 1,
      "editable": true
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      "editable": true
    },
    {
      headerName: "Position",
      field: "position",
      flex: 1,
      "editable": true
    },
    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: (params: GridRowParams) => [
        // <GridActionsCellItem onClick={() => deleteContact(params)}
        //   icon={<DeleteOutline />}
        //   label="Delete"
        // />,
        <DeleteContactModal
                    row={params}
                    snackbar={snackBar}
                    setSnackbar={setSnackbar}
                    deleteContact={deleteContact}
                    // getRoles={() => setRefresh(!refresh)}
                  />
      ],
    },
  ];

  return (
    <>
      <DataGrid
        rows={contacts}
        columns={columns}
        hideFooter
        processRowUpdate={handleRowEditCommit}
      />
    </>
  )
};