import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { ReactComponent as UrgentSvg } from "assets/images/icons/urgent.svg";
import MDButton from "components/MDButton";
import { Avatar, Chip, Divider, ListItem, Tooltip, Autocomplete } from "@mui/material";
import { shortName } from "utils/matchmakerUtils";
import { formatDate } from "@fullcalendar/react";
// import { avatarUrl, formatDate, formatDateTime } from "utils/globalsUtils";
import { ResumeIcon } from "components/alove/ResumeIcon";
import { isUrget } from "utils/matchmakerUtils";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import MDTypography from "components/MDTypography";
import { fullName } from "utils/matchmakerUtils";
import { render } from "@testing-library/react";
import { TwoSizeChip } from "components/alove/Chips";
import { statusColors } from "utils/matchmakerUtils";
import { EngagedChip } from "components/alove/Chips";
const MDInput = require("components/MDInput").default;

const name = (user, request, isAdmin) => {
  return fullName(user, request.status, isAdmin);
};

const urgentBuilder = (request) =>
  isUrget(request) ? (
    <Tooltip title={<T>urgentTooltip</T>}>
      <UrgentSvg style={{ width: "15px", height: "15px" }} />
    </Tooltip>
  ) : (
    <span style={{ width: "15px", height: "15px" }}></span>
  );

const coloredText = (text, request) => (
  <MDTypography
    variant="body2"
    style={{
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color:
        request.status == STATUSES.ENDED
          ? "grey"
          : [STATUSES.DATING, STATUSES.ENGAGED].includes(request.status)
            ? "#E44E9C"
            : isUrget(request)
              ? "red"
              : "black",
    }}
  >
    <T>{text}</T>
  </MDTypography>
);
const busyText = {
  "status-busy-first": "User marked themselves busy",
  "status-busy-second": "User marked themselves busy",
  "status-unmatch-second": "User requested to unmatch",
  "action-busy-first": "Auto cancelled (?)",
  "action-busy-second": "Contact user",
  "action-unmatch-second": "Contact user",
};

const busyStatus = (request, column) => {
  const userStatus = request.statusReason?.initiatorStatus || request.statusReason?.responderStatus;
  const introStatus = request?.status <= 9 ? "first" : "second";
  return userStatus && request.status != 15
    ? busyText[`${column}-${userStatus}-${introStatus}`]
    : null;
};

const shouldColorName = (request, intiator) =>
  (intiator && ["busy", "unmatch"].includes(request.statusReason?.initiatorStatus)) ||
  (!intiator && ["busy", "unmatch"].includes(request.statusReason?.responderStatus));

const dueClass = (request) =>
  [12, 13, 14, 8].includes(request.status) ? "hidden" : isUrget(request) ? "urgent" : "";

const shouldColorBackground = (request) =>
  request.status <= 9 &&
  (request.statusReason?.initiatorStatus || request.statusReason?.responderStatus);

const statusText = (request) => (
  <span>
    <T>statusLabelTable{request.status}</T>{" "}
    <T>
      {request.status == STATUSES.DATING && request.statusReason?.dateNumber
        ? ` (${request.statusReason?.dateNumber}# date)`
        : ""}
    </T>
  </span>
);

const requestID = (request, isAdmin) =>
  isAdmin ? (
    request.mmBoUser?.first_name ? (
      <Grid
        style={{
          alignItems: "center",
          display: "flex",
          backgroundColor: "#f5f5f5",
          borderRadius: "15px",
          justifyContent: "left",
          marginTop: "5px",
        }}
        sx={{ p: 1, mr: 2 }}
        gap={1}
      >
        <Avatar src={request.mmBoUser?.avatar} sx={{ width: 24, height: 24 }} />
        <MDTypography
          variant="body2"
          style={{
            fontSize: "12px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {request.mmBoUser?.first_name}
        </MDTypography>
      </Grid>
    ) : (
      "No tickets"
    )
  ) : (
    <MDTypography variant="body2" fontSize="12px" color="grey">
      #{request.introductionId}
    </MDTypography>
  );

export const ticketsColumns = (isAdmin, action, allUsers) => [
  {
    headerName: '#',
    field: "id",
    width: 10
  },
  {
    headerName: "Brand",
    field: "brand",
    width: 65,
  },
  {
    headerName: "Assigned to",
    field: "assignedTo",
    width: 240,
    renderCell: (params) =>
      params?.row?.assigned_to?.map((p, i) => {
        const user = allUsers?.find((u) => u.id === p);
        return (user?.first_name + ' ' + user?.last_name + ', ')
      })
  },
  {
    headerName: "Created",
    field: "created",
    width: 90,
    renderCell: (params) => formatDate(params.row.created),
  },
  {
    headerName: "User ID",
    field: "profileId",
    width: 140,
    renderCell: (params) => params?.row?.profileId?.slice(-16)
    // renderCell: (params) => console.log('params: ', params)
  },
  {
    headerName: "Status",
    field: "status",
    width: 70,
  },
  {
    headerName: "Topic",
    field: "topic",
    flex: 1,
  },
  {
    headerName: "Sub-topic",
    field: "sub_topic",
    flex: 1,
    // align: "center",
  },
  {
    headerName: "SLA Left",
    field: "time_left",
    width: 90,
    // renderCell: (params) => console.log('aprams: ', params)
    
  },
  {
    headerName: "Last Update",
    field: "last_update",
    width: 100,
    renderCell: (params) => formatDate(params.row.created),
    // align: "center",
  },
];