/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import SalesTable from "examples/Tables/SalesTable";
import DataTable from "examples/Tables/DataTable";

// Sales dashboard components

// Data
import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
import salesTableData from "layouts/dashboards/sales/data/salesTableData";
import dataTableData from "layouts/dashboards/sales/data/dataTableData";
import { Report } from "models/BO/Report";
import { getReport, getTicketsReport } from "utils/reportsUtils";
import PieChartContainer from "./components/PieChartContainer";
import { StatsPanel } from "components/alove/StatsPanel";
import { useMaterialUIController } from "context";
import { ActionsCard, ActionsRow } from "components/alove/ActionsRow";
import { Autocomplete, capitalize } from "@mui/material";
import { DropdownButton } from "components/alove/DropDownButton";
import { camelCaseToCapitalizedWords, snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import colors, { colorsArray } from "assets/theme/base/colors";

const MDBox = require("components/MDBox").default;
const MDTypography = require("components/MDTypography").default;
const MDBadgeDot = require("components/MDBadgeDot").default;
const MDButton = require("components/MDButton").default;
const MDInput = require("components/MDInput").default;

const MONTH = 30.44;
const timeFilters = {
  "24 Hours": 1,
  "1 Week": 7,
  "1 Month": MONTH,
  "3 Months": MONTH * 3,
  "6 Months": MONTH * 6,
  "1 Year": MONTH * 12,
  "3 Years": MONTH * 36,
  Lifetime: (new Date().getFullYear() - 2022) * 12 * MONTH,
};

const pieVariants = ["gender", "ethnicity", "state", "user_type"]
const listVariants = ["state","israel_yeshiva","us_yeshiva","seminary","high_school","post_israel"]
const typesVariants = {
};

export default function ReportPage({ type }: { type: string }) {
  const [report, setReport] = useState(new Report());
  const [tickets, setTickets] = useState({});
  const [controller] = useMaterialUIController();
  const { currentBrand, currentHost } = controller;
  const [dateFilter, setDateFilter] = useState("1 Year" as keyof typeof timeFilters);
  const [seriesVariant, setSeriesVariant] = useState(
    typesVariants[type]?.seriresVariants?.[0] || ""
  );
  const [pie, setPie] = useState(pieVariants[0] as keyof typeof pieVariants);
  const [list, setList] = useState(listVariants[0] as keyof typeof listVariants);
  useEffect(() => {
    setSeriesVariant("");
    typesVariants[type] ||= {};
    typesVariants[type].seriresVariants = [];
    loadReport(type, dateFilter, "", pie,list);
  }, [type])
  useEffect(() => {
    loadReport(type, dateFilter, seriesVariant, pie,list);
  }, [currentBrand, currentHost, dateFilter, pie, seriesVariant, list]);
  useEffect(() => {
    getTicketsReport(type,Math.ceil(timeFilters[dateFilter])).then((data) => setTickets(data));
  }, [type, currentBrand, currentHost, dateFilter]);

  const loadReport = (type, dateFilter, seriesVariant, pie,list) => {
    getReport(type, Math.ceil(timeFilters[dateFilter]), seriesVariant, pie,list).then((data) => {
      if (!typesVariants[type]?.seriresVariants?.length) {
        typesVariants[type].seriresVariants =
          data.lineChart?.datasets || [];
      }
      setReport(data);
    });
  }


  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Reports" />
      <MDBox>
        <MDBox mb={1}>
          <ActionsCard
            title="Filters"
            filters={[
              <DropdownButton
                label="1 Year"
                options={Object.keys(timeFilters).map((key) => ({ label: key, value: key }))}
                onClick={(option) => setDateFilter(option.value)}
              />,
            ]}
          />
        </MDBox>
        <StatsPanel
          title="Statistics"
          stats={tickets || {}}
          fields={Object.entries(tickets).map(([key, value], i) => ({
            name: key,
            title: key,
            value: value,
            color: colorsArray[i % colorsArray.length],
          }))}
        />
        <Grid container spacing={1}>
          {report.lineChart && (
            <Grid item xs={12} sm={6} lg={8}>
              <DefaultLineChart
                title={report.lineChart.title}
                variant={
                  typesVariants[type]?.seriresVariants?.length > 1 && (
                    <DropdownButton
                      label="Status"
                      options={typesVariants[type].seriresVariants.map(({label,values}) => ({
                        label: capitalize(label),
                        value: values,
                      }))}
                      onClick={(option) => setSeriesVariant(option.value)}
                    />
                  )
                }
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1} flexWrap="wrap">
                      {typesVariants[type]?.seriresVariants.map((data, index) => (
                        <MDBadgeDot
                          key={index}
                          color={colorsArray[index % colorsArray.length]}
                          size="sm"
                          badgeContent={data.label}
                        />
                      ))}
                    </MDBox>
                  </MDBox>
                }
                chart={report.lineChart}
              />
            </Grid>
          )}
          {report.pieChart && (
            <Grid item xs={12} sm={6} lg={4}>
              <PieChartContainer pieData={report.pieChart} setPie={setPie} />
            </Grid>
          )}
          {report.horizontalBarChart && (
            <Grid item xs={12} lg={8}>
              <HorizontalBarChart
                title={report.horizontalBarChart.title}
                chart={report.horizontalBarChart}
              />
            </Grid>
          )}
          {report.list && (
            <Grid item xs={12} lg={4} height="30rem">
              <SalesTable title={report.list.title} rows={report.list.dataset} variants={listVariants.map((key) => ({
                key,
                label: snakeCaseToCapitalizedWords(key),
              }))} 
              setVariant={(variant) => setList(variant.key)}
              />

            </Grid>
          )}
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Top Selling Products
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid> */}
      </MDBox>
    </DashboardLayout>
  );
}
