import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { postRoleDO, getRoleDO } from 'utils/rolesUtils';
import { getSystemUsersNamesDO } from 'utils/systemUsersUtils';
import { Card, Grid, Autocomplete, Divider, TextField, CircularProgress, IconButton } from "@mui/material";
import { updateQuestionarieDO } from '../../../utils/questionsUtils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function EditQuestionnaireModal(props) {
    const { data, getAndPrepareTableData, snackbar, setSnackbar } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState(data?.display_name);
    const [nameError, setNameError] = useState(false)
    const [description, setDescription] = useState(data?.description);
    const [descriptionError, setDescriptionError] = useState(false);
    const [version, setVersion] = useState(data?.version);
    const [versionError, setVersionError] = useState(false);

    const handleClickOpen = (e) => {
        e.stopPropagation();
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const updateQuestionarieName = async () => {
        if (!name) {
            setNameError(true);
        } else {
            setNameError(false);
        }

        // if (!description) {
        //     setDescriptionError(true);
        // } else {
        //     setDescriptionError(false);
        // }

        if (!version) {
            setVersionError(true);
        } else {
            setVersionError(false);
        }

        if (name && description && version) {
            const updateStatus = await updateQuestionarieDO(name, description, version, 'dev', data?.id);
            console.log('updateStatus: ', updateStatus);
            if (updateStatus === 200) {
                handleClose();
                getAndPrepareTableData();
                setSnackbar({ ...snackbar, status: true, title: 'Questionniare  was updated', color: 'success', icon: 'check' });
            }
        } else {
            // Display an error message or handle validation feedback
            console.error('Please fill in all required fields and select at least one user.');
            setSnackbar({ ...snackbar, status: true, title: 'something happened on update questionniare', color: 'error', icon: 'check' });
        }
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}>
                < EditIcon />
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Edit Questionnairer - {data?.id}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >

                    <MDInput
                        placeholder="Display name"
                        label="Display name"
                        value={name}
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        // fullWidth
                        onChange={(e) => setName(e.target.value)}
                        error={nameError}
                    />

                    <MDInput
                        value={description}
                        placeholder="Description"
                        label="Description"
                        // rows="3"
                        size="small"
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        sx={{ width: 300, mb: 2 }}
                        error={descriptionError}
                    />
                    <MDInput
                        value={version}
                        placeholder="Version"
                        label="Version"
                        // rows="3"
                        size="small"
                        onChange={(e) => setVersion(e.target.value)}
                        fullWidth
                        sx={{ width: 300, mb: 2 }}
                        error={versionError}
                    />

                    {/* <Autocomplete
                        // id="asynchronous-demo"
                        multiple
                        size="small"
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        onChange={(e, value) => setUsers(value)}
                        isOptionEqualToValue={(option, value) => option.first_name === value.first_name}
                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                        options={options}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="New Users"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    /> */}
                </DialogContent>
                <DialogActions><Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            // color={'info'}
                            fullWidth
                            onClick={() => handleClose()}
                        >
                            cancel
                        </MDButton>
                        {/* <MDTypography variant="button" color="text" fontWeight="regular">
                                Pending
                            </MDTypography> */}
                    </Grid>
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            color={'info'}
                            fullWidth
                            onClick={() => updateQuestionarieName()}
                        >
                            Update
                        </MDButton>
                    </Grid>
                </Grid>
                    {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
