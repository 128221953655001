import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { T } from "./Translator";

const MDButton = require("components/MDButton").default;

type DropdownButtonProps = {
  label?: string;
  value?: string;
  onClick: (option: any) => void;
  options: { label: string; value: string }[];
  white?: boolean;
};

export const DropdownButton = (props: DropdownButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    null as { label: string; value: string } | null
  );
  useEffect(() => {
    console.log("props.value", props.value);
    setSelectedOption(
      props.options.find((option) => option.value === props.value) ||
      null as { label: string; value: string } | null
    );
  }, [props.value, props.options]);
  const open = Boolean(anchorEl);
  const close = (option?) => {
    setAnchorEl(null);
    if (option) {
        setSelectedOption(option);
      props.onClick(option);
    }
  };
  const renderMenu = () => (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={() => close()}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
      disableAutoFocusItem
    >
      {props.options.map((option) => (
        <MenuItem key={option.value} onClick={() => close(option)}>
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <React.Fragment>
      <MDButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="outlined"
        color={props.white ? "white" : "dark"}
        style={{ borderRadius: "20px" }}
      >
        <T>{selectedOption ? selectedOption!.label : props.label}</T>
      </MDButton>
      {renderMenu()}
    </React.Fragment>
  );
};
