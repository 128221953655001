import React, { useState, useEffect } from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { postLogDO } from 'utils/logsUtils';
import { Grid, Autocomplete, TextField, IconButton, Dialog } from "@mui/material";
import { useMaterialUIController } from "context";
import { getCustomerSupportTopicsDO, getSLASettingsDO, updateStatusDO,  updateAssigneeDO, getAllUSersDO, updaeTicketAsiigneDO } from 'utils/customerSupportUtils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ReAssignModal(props) {

    const { reAssignModalStatus, setSnackbar, snackbar, setReAssignModalStatus, ticket, host, brandUsers, ticketID, getData } = props;
    // console.log('tti: ', ticket);
    // console.log('brandUsers from tti: ', brandUsers);

    const [controller, dispatch] = useMaterialUIController();
    const { currentBrand } = controller;

    const [modalOpen, setModalOpen] = useState(false);
    const [users, setUsers] = useState();

    const handleClose = () => {
        setReAssignModalStatus(false);
    };

    const updateAssignee = async (newVal) => {

        console.log('newVal: ', newVal);
        const data = [];
        console.log('ticketa: ', ticketID);
        
        newVal?.map((a, i) => data?.push(a?.id));
        const updateRes = await updaeTicketAsiigneDO(host, data, ticketID);
        setSnackbar({
            status: true,
            color: updateRes === 200 ? 'success' : 'error',
            icon: updateRes === 200 ? 'check' : 'error',
            title: updateRes === 200 ? 'Assignee was updated' : 'Something happened!',
        });
        getData();
    }

    // useEffect(() => {
    //     getData();

    // }, []);


    // const getData = async () => {
    //     if (ticket?.brand) {
    //         const users = await getUsersWithBrandsDO(host, ticket?.brand);
    //         console.log('user from tti: ', users);
    //     }

    //     setUsers(users);
    // }



    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={reAssignModalStatus}
                sx={{ maxWidth: 600, width: 600, height: 600, maxHeight: 600, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Re Assign
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <Autocomplete
                        sx={{ mt: 1, minWidth: 500 }}
                        size="small"
                        multiple
                        // limitTags={4}
                        // getOptionDisabled={(option) => !currentRole.hasAccess(params.row.id, Number(option))}
                        // defaultValue={role?.permissions?.controls
                        //   ?.find((control) => control.page === params.row.id)
                        //   ?.access.filter((a) => isExtra(a))
                        //   .map((a) => a.toString())}
                        isOptionEqualToValue={(positions, value) => positions.first_name === value.first_name}
                        onChange={(e, v) => updateAssignee(v)}
                        defaultValue={brandUsers
                            .filter(user => ticket?.assigned_to?.includes(user.id))
                            .map(user => ({
                                id: user.id,
                                first_name: user.first_name,
                                last_name: user.last_name
                            }))}
                        options={brandUsers ? brandUsers : []}
                        // options={brandUsers || {id:'', first_name:'', last_name:''}}
                        getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
                        renderInput={(params) => <MDInput {...params} variant="outlined" />}
                    />

                </DialogContent>
                <DialogActions><Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            // color={'info'}
                            size="small"
                            fullWidth
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </MDButton>
                    </Grid>
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            color={'info'}
                            size="small"
                            fullWidth
                            onClick={() => handleClose()}
                        >
                            Re Assign
                        </MDButton>
                    </Grid>
                </Grid>

                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
