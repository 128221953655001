import { Grid } from "@mui/material";

import React from "react";
import { getStatistics, getUsageStats } from "utils/enduserUtils";
import { setCurrentDialog, useMaterialUIController } from "context";
import { DialogType } from "components/alove/AloveDialog";
import {
  HeaderIndication,
  HeaderIndicationProps,
} from "layouts/itemPageContainer/components/headerIndication";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { UsageEventType } from "models/profile/Profile";

const MDTypography = require("components/MDTypography/index.js").default;

export const UsageStats = ({ profileId }: { profileId: string }) => {
  const [stats, setStats] = React.useState([] as HeaderIndicationProps[]);
  React.useEffect(() => {
    if (!profileId) return;
    getUsageStats(profileId).then((res) => {
      setStats(
        Object.values(UsageEventType).map((type) => ({
          title: camelCaseToCapitalizedWords(type),
          value: res.find((stat) => stat.type === type)?.count || 0,
        }))
      );
    });
  }, [profileId]);
  return (
    <Grid container display="flex" justifyContent="start" flexDirection="row">
      {stats
        .map((indication: HeaderIndicationProps, index) => [
          <HeaderIndication {...indication} index={index} key={indication.title} />,
          index != stats.length - 1 && <VerticalDivider height="40px" key={index} />,
        ])
        .flat()}
    </Grid>
  );
};
