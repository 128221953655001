import { Chip, ChipProps, Grid, Icon } from "@mui/material";
import RingSvg from "assets/images/icons/rings.svg";

interface TwoSizeChipProps extends ChipProps {
  sizeoption?: "wide" | "narrow";
}

export const TwoSizeChip = (props: TwoSizeChipProps) => {
  const { sizeoption, label } = props;
  return (
    <Chip
      sx={{
        width: sizeoption == "wide" ? "140px" : "92px",
        "& .MuiChip-label": {
          padding: "0px",
        },
      }}
      variant="outlined"
      label={label}
      {...props}
    />
  );
};

export const EngagedChip = (props) => {
  return (
    <Chip
      label={
        <Grid container alignItems="center" justifyContent="center" gap={2}>
          Engaged
          <img height="20px" src={RingSvg} />
        </Grid>
      }
      style={{
        color: "white",
        width: "140px",
        backgroundColor: "#E82BBE",
      }}
      {...props}
    />
  );
};
