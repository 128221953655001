import { Report } from "models/BO/Report";
import { makeRequest } from "./networkUtils";
import { colorsArray } from "assets/theme/base/colors";

const accumulateData = (datasets) => {
  const accumulated: any = [];

  datasets.forEach((dataset, index) => {
    const newData = dataset.data.map((value, i) => {
      const previousSum = index > 0 ? accumulated[index - 1].data[i] : 0;
      return value + previousSum;
    });

    accumulated.push({
      ...dataset,
      data: newData, // Replace the data with the cumulative data
    });
  });

  return accumulated;
};

export const getReport = async (
  reportType: string,
  dateFilter: number,
  variant: string,
  subVariant: string,
  listVariant: string
): Promise<Report> => {
  const report: Report = await makeRequest(
    `reports/${reportType}?dateFilter=${dateFilter}&variant=${variant}&subVariant=${subVariant}&listVariant=${listVariant}`
  );

  if (report.pieChart) {
    report.pieChart!.title = "Profiles";
    report.pieChart!.datasets.label = "Profiles";

    report.pieChart!.datasets.data = report.pieChart!.datasets.data.map((value:any, i) => parseInt(value));
    report.pieChart!.datasets.backgroundColors = report.pieChart!.labels.map(
      (data, i) => colorsArray[i % colorsArray.length]
    );

  }
  if (report.lineChart) {
    report.lineChart?.datasets.forEach((dataset, i) => {
      dataset.color = colorsArray[i % colorsArray.length];
    });

    // report.lineChart!.datasets = accumulateData(report.lineChart!.datasets);
  }

  if (report.horizontalBarChart) {
    report.horizontalBarChart?.datasets.forEach((dataset, i) => {
      dataset.color = colorsArray[i % colorsArray.length];
    });
  }

  // Array(monthes)
  // .fill(0)
  // .map((_, i) =>
  //   new Date(_).toLocaleString('default', { month: 'short', year: "2-digit" }),
  // ),
  return report;
};

export const getTicketsReport = async (
  name: string,
  dateFilter: number
): Promise<Report> => {

  return await makeRequest(`reports/${name}/tickets?dateFilter=${dateFilter}`);
}