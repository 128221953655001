import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { addAlert, useMaterialUIController } from "context";
import { GeneralCodeType } from "models/content/GeneralCode";
import React from "react";
import { addGeneralCodeDO } from "utils/generalCodesUtils";

const MDInput = require("components/MDInput").default;

export type GeneralCodeDialogProps = {
  entity: string;
  type: GeneralCodeType;
};

export const AddGeneralCodeDialog = ({  type, entity }: GeneralCodeDialogProps) => {
  const [controller, dispatch] = useMaterialUIController();
  const [name, setName] = React.useState("");
  return (
    <AloveDialog
      dialogType={DialogType.AddGeneralCode}
      dialogTitle={`Add ${entity}`}
      dialogBody={`Enter the name of the ${entity} you want to add`}
      dialogConfirm="Add"
      valid={name.length > 0}
      handleConfirm={async () => {
        addGeneralCodeDO(type, name).then(() => addAlert(dispatch, `${entity} added`));
      }}
    >
      <MDInput
        label={`${entity} Name`}
        placeholder={`Enter ${entity} name`}
        required
        value={name}
        onChange={(e: any) => setName(e.target.value)}
      />
    </AloveDialog>
  );
};
