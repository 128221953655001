import { Delete } from "@mui/icons-material";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { DataGridPro, GridEventListener } from "@mui/x-data-grid-pro";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { DropdownButton } from "components/alove/DropDownButton";
import { TemplateCategory, TwilioTemplate } from "models/content/TwilioTemplate";
import UrgentSvg from "assets/images/icons/urgent.svg";
import { useEffect, useState } from "react";
import {
  deleteWATemplate,
  getWATemplates,
  saveWATemplate,
  submitWATemplate,
} from "utils/commUtils";
import { snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";
import { setCurrentDialog, useMaterialUIController } from "context";

const MDButton = require("components/MDButton/index.js").default;

export const WATemplatesList = ({
  templates,
  setTemplates,
}: {
  templates: TwilioTemplate[];
  setTemplates: any;
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const [deleteTemplate, setDeleteTemplate] = useState<TwilioTemplate>();
  const refreshTemplates = () => {
    getWATemplates().then(setTemplates);
  };

  return (
    <Box
      sx={{
        "& .marketing": {
          color: "#F4357A",
        },
        "& .MuiDataGrid-cell": {
          whiteSpace: "pre-line !important",
        },
      }}
    >
      <DataGridPro
        rows={templates}
        getRowId={(row) => row.sid} 
        getRowHeight={() => 'auto'}

        getRowClassName={(params) =>
          params.row.approvalRequests?.category === TemplateCategory.MARKETING ? "marketing" : ""
        }
        columns={[
          {
            field: "friendlyName",
            headerName: "Name",
            flex: 1,
            valueFormatter: (v) => snakeCaseToCapitalizedWords(v),
          },
          {
            field: "body",
            headerName: "Body",
            flex: 5,

          },
          {
            field: "status",
            headerName: "Status",
            width: 100,
            renderCell: (params) => params.row.approvalRequests?.status,
          },
          {
            field: "action",
            headerName: "Action",
          },
          {
            field: "category",
            headerName: "",
            width: 30,
            renderCell: (params) =>
              params.row.approvalRequests?.category === TemplateCategory.MARKETING && (
                <Tooltip title="This is classified as a marketing template, Extra fees will apply, And Whatsapp could limit sendings this message">
                  <img style={{ width: "15px", height: "15px" }} src={UrgentSvg} />
                </Tooltip>
              ),
          },
          {
            field: "delete",
            headerName: "Delete",
            renderCell: (params) => (
              <IconButton
                onClick={() => {
                  setDeleteTemplate(params.row);
                  setCurrentDialog(dispatch, DialogType.DeactivateEntitiy);
                }}
              >
                <Delete />
              </IconButton>
            ),
          },
        ]}
      />
      <DeactivateDialog
        title="Delete WhatsApp Template"
        content="Are you sure you want to delete this template?"
        confirm="Delete"
        onSubmit={async () => {
          await deleteWATemplate(deleteTemplate!);
          refreshTemplates();
        }}
      />
    </Box>
  );
};
