import { Dialog } from "@mui/material";
import React from "react";
import { avatarUrl } from "utils/globalsUtils";
import { STATUSES } from "utils/matchmakerUtils";

export const MMAvatar = ({ request, isFirst, size, isAdmin } : { request: any, isFirst?: boolean, size?: string, isAdmin?: boolean }) => {
  
  const [showImageDialog, setShowImageDialog] = React.useState("");

  let imageUri = isFirst ? request.initiatorUser?.image?.uri : request.responderUser?.image?.uri;
  let profileId = isFirst ? request.initiatorProfileId : request.responderProfileId;
  let profile = isFirst ? request.initiatorProfile : request.responderProfile;
  if (!imageUri) {
    imageUri = isFirst 
      ? request.initiatorImage?.uri
      : request.responderImage?.uri;

  }

  if (!imageUri) {
    imageUri = isFirst
      ? request.initiatorProfile?.images?.at(0)?.uri
      : request.responderProfile?.images?.at(0)?.uri;
    profileId = isFirst ? request.initiatorProfileId : request.responderProfileId;
  }


  let url;
  if ((!isAdmin && request.status == STATUSES.NEW) || !imageUri) {
    const isMale  = profile?.attributesValues?.gender?.[0] == "male";
    const isFemale = profile?.attributesValues?.gender?.[0] == "female";
    url = `https://alove-dev-assets.s3.amazonaws.com/public/uploaded/zuug/png/no_pic_place_holder_${
      isMale ? "m" : isFemale ? "w" : "o"
    }.png`;
  } else {
    url = avatarUrl(profileId, imageUri);

  }

  return (
    <React.Fragment>
      <img
        src={url}
        onClick={() => setShowImageDialog(url)}

        style={{
          fit: "cover",
          borderRadius: "50%",
          crop: "fill",
          objectFit: "cover",
          width: size || "30px",
          height: size || "30px",
        } as any}
      />
      <Dialog
        open={!!showImageDialog}
        onClose={() => setShowImageDialog("")}
        fullWidth
        maxWidth="md"
      >
        <img
          src={url}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Dialog>
    </React.Fragment>
  );
};
