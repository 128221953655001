

export type TemplateType = {
    actions: {
        title: string;
        type: string;
        url: string;
    }[];
    body: string;
}

export enum TemplateCategory {
    MARKETING = "MARKETING",
    UTILITY = "UTILITY",
}

export type TwilioApproval = {
    allow_category_change: boolean;
    category: TemplateCategory;
    content_type: string;
    flows: any;
    name: string;
    rejection_reason: string;
    status: string;

}

export class TwilioTemplate {
    dateCreated: string;
    dateUpdated: string;
    sid: string;
    accountSid: string;
    friendlyName: string;
    language: string;
    variables: any;
    types: {[key: string]: TemplateType};
    url: string;
    links: any;
    approvalRequests: TwilioApproval;

    get body(){
       return this.types?.["twilio/call-to-action"]?.body
    }

    get action() {
        return  this.types?.["twilio/call-to-action"]?.actions[0].title
    }
}
