import {
  Box,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { uniqueBy } from "utils/globalsUtils";
import { getAttrArray, loadAttributes } from "utils/attributesUtil";
import { Attribute } from "models/profile/Attribute";
import { Introduction } from "models/introduction/Introduction";
import { questionnarieListDo } from "utils/questionsUtils";
import colors from "assets/theme/base/colors";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getProfileResponses } from "utils/enduserUtils";
import { ProfileResponse } from "models/profile/ProfileResponse";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ResponseCell } from "./cell";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";

export const AttributesTable = ({
  request
}: {
  request: Introduction;
}) => {
  const pageNumber = "2.8"
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const [attributes, setAttributes] = useState([] as Attribute[]);
  const [questionnaires, setQuestionnaires] = useState([] as any);
  const [initiatorProfileResponses, setInitiatorProfileResponses] = useState([] as ProfileResponse[]);
  const [responderProfileResponses, setResponderProfileResponses] = useState([] as ProfileResponse[]);

  useEffect(() => {
    loadAttributes().then((attrs) => {
      setAttributes(attrs);
    });

    if (currentRole.hasAccess(pageNumber,PermissionType.ViewAllOptions)){
      questionnarieListDo().then((questionnaires) => {
        setQuestionnaires(questionnaires);
      });

      getProfileResponses(request.initiatorProfileId).then((responses) => {
        setInitiatorProfileResponses(responses);
      });
      getProfileResponses(request.responderProfileId).then((responses) => {
        setResponderProfileResponses(responses);
      });
    }
  }, []);

  const initiatorName = request.initiatorUser?.firstName;
  const responderName = request.responderUser?.firstName;

  const tableCellCss = (match) => {
    if (match === null) return "";
    return match ? "match" : "unmatch";
  };

  const addResponses = (attrs) => {
      console.log("attrs", attrs);
    if (initiatorProfileResponses.length == 0 || responderProfileResponses.length == 0) {
      return uniqueBy(attrs, "bioName");;
    }

    for (const response of initiatorProfileResponses) {
      if (!response.profileAttr && response.batchId != "12") {
        const responderResponse = responderProfileResponses.find((r) => r.batchContentId == response.batchContentId);
        const initiatorBio = response.response.join ? response.response.join(", ") : response.response;
        const responderBio = responderResponse?.response.join ? responderResponse?.response.join(", ") : responderResponse?.response;
        attrs.push({
          id: Number(response.batchContentId) + 99999,
          bioName: response.question,
          initiatorBio,
          responderBio,
          initiatorMatch: initiatorBio == responderBio,
          responderMatch: responderBio == initiatorBio,
          
          ...response,
        });
      }
    }
    return uniqueBy(attrs, "bioName");
  };

  return (
    <Grid>
      <Grid
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        sx={{ width: "100%" }}
        style={{ zIndex: 2, position: "relative" }}
      >
        
          <Box
            sx={{
              "& .unmatch": {
                color: colors.error.main,
              },
              "& .match": {
                color: colors.success.main,
              },
            }}
          >
            <DataGridPro
              rows={addResponses(getAttrArray(attributes, request, false))}
              getTreeDataPath={(row) => [
                questionnaires.find((q) => q.id == row.batchId)?.display_name || "Onboarding",
                row.bioName,
              ]}
              treeData
              columns={[
                {
                  field: "initiatorBio",
                  headerName: initiatorName + "'s Bio",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) => <ResponseCell {...params} /> ,
                  cellClassName: (params) => tableCellCss(params.row.initiatorMatch),
                },
                {
                  field: "responderPrefernce",
                  headerName: responderName + "'s Preferences",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) => <ResponseCell {...params} /> ,
                  cellClassName: (params) => tableCellCss(params.row.initiatorMatch),
                },
                {
                  field: "responderBio",
                  headerName: responderName + "'s Bio",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) => <ResponseCell {...params} /> ,
                  cellClassName: (params) => tableCellCss(params.row.responderMatch),
                },
                {
                  field: "initiatorPrefernce",
                  headerName: initiatorName + "'s Preferences",
                  flex: 1,
                  renderCell: (params: GridRenderCellParams) => <ResponseCell {...params} /> ,
                  cellClassName: (params) => tableCellCss(params.row.responderMatch),
                },
              ]}
              hideFooter
              autoHeight
              groupingColDef={{
                flex: 1,
              }}
            />
          </Box>
          </Grid>
       
    </Grid>
  );
};
