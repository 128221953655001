import { EndUser } from "models/profile/EndUser";
import { Profile, ProfileAsset } from "models/profile/Profile";

export enum MatchScoreDB {
    No = 0,
    Initiator = 1,
    Responder = 2,
    Error = 3,
}

export class MatchScore {
    initiatorProfileId: string;
  
    responderProfileId: string;
  
    createdAt: Date;
  
    initiatorBioScore: number;
  
    responderBioScore: number;
  
    predictorScore: number;
  
    isDealBreaker: MatchScoreDB;
  
    dealBreakerReason: string;
  
    error: string;

    profile: Profile;

    user: EndUser;

    profileImage: ProfileAsset;
  }