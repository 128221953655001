import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Autocomplete, Collapse, Icon } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { TableFilterProps } from "./TablePage";
import { T } from "./Translator";
import { useMaterialUIController } from "context";
const MDTypography = require("components/MDTypography/index.js").default;
const MDBox = require("components/MDBox/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const MDInput = require("components/MDInput/index.js").default;

export interface CardItemFilterProps {
  options: any[];
  currentValue: string;
  onChange: (value: any) => void;
  getLabel?: (value: any) => string;
}

export interface CardItemProps {
  title: string;
  children: React.ReactNode;
  id: string;
  defaultOpen?: boolean;
  addButton?: boolean | string;
  filters?: CardItemFilterProps[];
  halfWidth?: boolean;
  buttons?: React.ReactNode[];
  addNewContact?: () => void;
}

function CardItem(props: CardItemProps) {
  const [controller] = useMaterialUIController();
  const { mobileView } = controller;
  const ref: any = React.useRef();
  const {
    title,
    children,
    id,
    defaultOpen = true,
    addButton = false,
    addNewContact,
    filters,
    halfWidth
  } = props;
  const [open, setOpen] = React.useState(defaultOpen);
  const isFirst = React.useRef(true);
  useEffect(() => {
    ref.current?.addEventListener("open", () => {
      console.log("open");
      setOpen(true);
      ref.current?.scrollIntoView();
    });
  }, []);

  useEffect(() => {
    console.log("isFirst", isFirst.current);
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }
    if (open) setTimeout(() => ref.current?.scrollIntoView(false), 300);
  }, [open]);

  const cardProps = {
    id: id + "-card",
    ref: ref,
    onOpen: () => setOpen(true),
  };

  return (
    <Card {...cardProps} style={{ width: (halfWidth && !mobileView) ? "49.5%" : "100%" }} >
      <Grid px={4} container gap={2} alignItems="center" onClick={() => setOpen(!open)} >
        <Grid my={4} item>
          <MDTypography variant="h5"><T>{title}</T></MDTypography>
        </Grid>
        <Grid item xs={true} />
        <Grid item sx={{ cursor: "pointer", alignItems: "center", display: "flex" }}>
          {open && addButton && (
            <MDButton
              variant="outlined"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                addNewContact && addNewContact();
              }}
            >
             {addButton === true ? "Add" : addButton}
            
            </MDButton>
          )}
          {open && props.buttons}
          {open && filters?.map((f) => (
            <div onClick={(e) => e.stopPropagation()} key={f.currentValue}>
              <Autocomplete
                options={f.options}
                getOptionLabel={f.getLabel || ((o) => o.label || o)}
                value={f.currentValue}
                onChange={(e, o) => f.onChange(o)}
                style={{ width: 150 }}
                renderInput={(params) => <MDInput {...params} label="Filter" />}
              />
            </div>
          ))}
        </Grid>
        <Icon sx={{ fontSize: "30px !important" }}>{open ? "expand_less" : "expand_more"}</Icon>
      </Grid>
      <MDBox component="form" pb={open ? 3 : 0} px={4}>
        <Collapse in={open} timeout="auto">{children}</Collapse>
      </MDBox>
    </Card>
  );
}

export default CardItem;
