import axios from "axios";
import { makeRequest, networkGlobals } from "./networkUtils";
import { Setting } from "models/BO/Setting";
const activeFeatures = {}
const settings: {[key:string]:Setting[]} = {}

export enum SettingName {
    SystemShutdown = "systemShutdown",
}

export enum AppFeature {
    premium = "premium",
    pendingProfiles = "pendingProfiles",
    chat = "chat",
}


export const hasFeature = (featureKey:AppFeature, host?:string, brandID?:number):boolean => {
    host ||= networkGlobals.host
    brandID ||= networkGlobals.brand.id
    const key = `${host}-${brandID}`
    return activeFeatures[key]?.find((f) => f.paramName === featureKey)?.paramValue === "true"
}

export const loadSettings = async () => {
    const key = `${networkGlobals.host}-${networkGlobals.brand.id}`
    if (settings[key] === undefined) {
        settings[key] = []
        settings[key] = await getSettingsDO()
        activeFeatures[key] = settings[key].filter((f) => f.belongs === 'app_features')
        return true
    }
}

export const getSetting = (settingKey:SettingName, defaultValue?:any):any => {
    const key = `${networkGlobals.host}-${networkGlobals.brand.id}`
    const valStr:string = settings[key]?.find((f) => f.paramName === settingKey)?.paramValue
    if (!valStr) {
        return defaultValue
    }
    switch (typeof defaultValue) {
        case 'boolean':
            return valStr === 'true'
        case 'number':
            return parseInt(valStr)
    }
    return valStr
}


/** get list of settings */
export const getSettingsDO = async (type?): Promise<Setting[]> => {
    return makeRequest('settings?type=' + (type || ''))
}

/** put settings */
export const updateSettingsDO = async (data) => {
    return makeRequest('settings', { method: 'PUT', body: data })
}

export const deploySettingsDO = async (type) : Promise<any> => {
    return makeRequest('settings/deploy', { method: 'POST', body: { type } })
} 