/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Grid, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import ArrowBack from "assets/images/icons/back.svg";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { useMaterialUIController } from "context";
import { BrandLogo } from "components/alove/BrandLogo";

const MDBox = require("components/MDBox").default;
const MDTypography = require("components/MDTypography").default;

function Breadcrumbs({ icon, title, route, light }) {
  const [controller, dispatch] = useMaterialUIController();
  const {mobileView} = controller;
  const routes = route.slice(0, -1);
  return (
    <MDBox style={{ width: mobileView && "90%" }}>
      {!mobileView && <Grid container gap={1} alignItems="center">
        <Icon style={{ opacity: 0.5 }}>{icon}</Icon>

        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
          >
            Home &gt;
          </MDTypography>
        </Link>
        {routes.map((el, i) => (
          <Link key={i} to={`/${routes.slice(0, i + 1).join("/")}`}>
            <MDTypography
              component="span"
              variant="body2"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              textTransform="capitalize"
            >
              {camelCaseToCapitalizedWords(el.replaceAll("-", " "))} &gt;
            </MDTypography>
          </Link>
        ))}
        <Link to="">
          <MDTypography
            component="span"
            
            variant="body2"
            color={light ? "white" : "dark"}
            textTransform="capitalize"
          >
            {camelCaseToCapitalizedWords(title.replaceAll("-", " "))}
          </MDTypography>
        </Link>
      </Grid>}
      <Grid container gap={2} alignItems="center" style={{ marginLeft: "3px" }}>
        {title != "dashboard" && (
          <Link to={`/${routes.join("/")}`}>
            <img src={ArrowBack} height="15px" />
          </Link>
        )}
        <MDTypography
        variant="h3"
          fontWeight="bold"
          component="span"
          color={light ? "white" : "dark"}
          textTransform="capitalize"
        >
          {title.replaceAll("-", " ")}
        </MDTypography>
        <Grid item xs={true} />
        {mobileView && <BrandLogo />}
      </Grid>
    </MDBox>
  );
}
export default Breadcrumbs;
