import { GridActionsCellItem, GridColDef, ValueOptions } from "@mui/x-data-grid";
import { AddGeneralCodeDialog } from "components/alove/AddGeneralCodeDialog";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { TwoSizeChip } from "components/alove/Chips";
import { TablePage } from "components/alove/TablePage";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";
import { Comm, CommEvent, CommEventsParams } from "models/content/Comm";
import { useNavigate } from "react-router-dom";
import { camelCaseToCapitalizedWords, snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import {
  createCommDO,
  deleteCommDO,
  getComms,
  getWATemplates,
  publishComms,
  saveCommDO,
} from "utils/commUtils";
import { useEffect, useState } from "react";
import { ContentStatus } from "models/content/Content";
import { DeleteForever, DeleteOutline, DeleteOutlineRounded } from "@mui/icons-material";
import { GeneralCodeType } from "models/content/GeneralCode";
import CardItem from "components/alove/CardItem";
import { WATemplatesList } from "./waTemplatesList";
import { TwilioTemplate } from "models/content/TwilioTemplate";
import { WAAddNewTemplate } from "../viewComm/wa/waAddNewTemplate";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export const CommsList = () => {
  const [controller, dispatch] = useMaterialUIController();
  const [pushRefresher, setPushRefresher] = useState(false);
  const [smsRefresher, setSmsRefresher] = useState(false);
  const navigate = useNavigate();

  const [templates, setTemplates] = useState<TwilioTemplate[]>([]);
  useEffect(() => {
    getWATemplates().then(setTemplates);
  }, []);

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      editable: true,
    },
    {
      headerName: "Event",
      field: "event",
      flex: 1,
      valueGetter: (v) => camelCaseToCapitalizedWords(v),
    },
    {
      headerName: "Param",
      field: "param",
      flex: 1,
      renderCell: (params) => {
        return (
          CommEventsParams[params.row.event || ""]?.find((p) => p.value == params.value)?.label ||
          params.value
        );
      },
    },
  ];

  const loadComms = (host: string, filters: any, page: number): Promise<Comm[]> => {
    return getComms();
  };

  const proccessRowUpdate = (newComm: Comm, oldComm: Comm) => {
    saveCommDO({ name: newComm.name, id: newComm.id }).then(() => addAlert(dispatch, "Name saved"));
    return newComm;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Communications" />
      <TablePage
        actionsInFiltersRow
        actions={{
          saveActions: [
            {
              label: "Publish",
              onClick: () =>
                publishComms().then(() => addAlert(dispatch, "Push notifications published")),
              variant: "contained",
              role: PermissionType.Publish,
            },
          ],
          addActions: [
            {
              label: "Email",
              onClick: () => navigate("/communications/newEmail"),
              variant: "contained",
              color: "secondary",
              role: PermissionType.AddNew,
            },
            {
              label: "Category",
              onClick: () => setCurrentDialog(dispatch, DialogType.AddGeneralCode),
              variant: "contained",
              color: "secondary",
              role: PermissionType.AddNew,
            },
          ],
        }}
        table={{
          loadNext: loadComms,
          columns: columns,
          minHeight: 50,
          pagination: false,

          processRowUpdate: proccessRowUpdate,
          getTreeDataPath: (row) => [row.category?.name || "No category", row.id],
          lineClick: (comm) => !isNaN(comm.id) && navigate(`/communications/${comm.id}`),
        }}
        title="Communications"
      />

      <CardItem
        title="WhatsApp Templates"
        id="wa"
        defaultOpen={false}
        buttons={[
          <MDButton
            variant="contained"
            color="primary"
            sx={{ mr: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentDialog(dispatch, DialogType.AddWATemplate);
            }}
          >
            {" "}
            Add Template{" "}
          </MDButton>,

          <MDButton
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              getWATemplates().then(setTemplates);
            }}
          >
            Refresh
          </MDButton>,
        ]}
      >
        <WATemplatesList templates={templates} setTemplates={setTemplates} />
      </CardItem>
      <AddGeneralCodeDialog type={GeneralCodeType.EmailCategory} entity="Category" />
      <WAAddNewTemplate refresh={() => getWATemplates().then(setTemplates)} />
    </DashboardLayout>
  );
};
