import axios from "axios";
import { networkGlobals } from "./networkUtils";

/** get list of settings */
export const getLogsDO = async (host, brandID) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/logs/?host=${host}`)
            .then(res => {
                // console.log('res: ',res);
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get logs:', error)
                reject(error);
            })
    })
}

/** get list of settings */
export const getLogForDeployDO = async (host) => {
    return new Promise((resolve, reject) => {
        axios.get(`${networkGlobals.serverHost}/api/logs/active/?host=${host}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get logs for deploy:', error)
                reject(error);
            })
    })
}

/** update publish status */
export const updateStatusDO = async (host) => {
    return new Promise((resolve, reject) => {
        axios.put(`${networkGlobals.serverHost}/api/logs/updateStatus`, { host })
            .then(res => {
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from update status log:', error)
                reject(error);
            })
    })
}

/** post settings */
export const postLogDO = async (host, changesHost, what, where, changes, by_user_id,brandID) => {
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/logs/`, { changes, changesHost, host, by_user_id, where, what,brandID})
            .then(res => {
                console.log('res post log DO; ', res?.status);
                resolve(res?.status);
            })
            .catch(error => {
                console.error('error from post logs:', error)
                reject(error);
            })
    })
}