import axios from "axios";

export const dbCopyDO = async (table, source, target) => {
    console.log('table: ', table);
    console.log('source: ', source);
    console.log('target: ', target);
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/dbCopy/`,{table,source, target})
            .then(res => {
                console.log('res from db copy: ',res);
                resolve(res);
            })
            .catch(error => {
                console.error('error from db copy:', error)
                reject(error);
            })
    })
} 