import axios from "axios";
import { formatDate } from "./globalsUtils";
import { EndUser } from "models/profile/EndUser";
import { AssetStatus, Profile, ProfileAsset } from "models/profile/Profile";
import { useMaterialUIController } from "context";
import { makeRequest, networkGlobals } from "./networkUtils";
import { mmAction } from "./matchmakerUtils";
import { ProfileResponse } from "models/profile/ProfileResponse";
import { MatchScore } from "models/introduction/matchScore";

export const getByEmailPhone = async (
  brandID: string,
  opt: { search: string }
): Promise<EndUser> => {
  let searchBY,
    search = opt.search;
  let phone = search
    .replace(" ", "")
    .replace("-", "")
    .replace("(", "")
    .replace(")", "")
    .replace("+1", "")
    .replace("+", "");

  if (/^\d{3}$/.test(search)) {
    search = "+15555555" + search;
    searchBY = "phone_number";
  } else if (/^\+?\d+$/.test(phone)) {
    search = `+1${phone}`;
    searchBY = "phone_number";
  } else if (/^.+@.+\..+$/.test(opt.search)) {
    searchBY = "email";
  } else {
    searchBY = "id";
  }
  return makeRequest(
    `profiles/cognito?searchBY=${searchBY}&userID=${encodeURIComponent(search)}&brandID=${
      brandID || ""
    }`
  );
};

export const getPendingProfiles = async (brandID: string) => {
  return makeRequest(`profiles/adminList?brandID=${brandID || ""}&status=6`);
};

export const getProfileStats = async (brandID: string) => {
  return makeRequest(`profiles/stats?brandID=${brandID}`);
};

let profilesCache = {};
export const getEndusersList = async (
  brandID: string,
  status: string[],
  showInternal: boolean,
  page: number
) => {
  const key = `${brandID}-${page}-${networkGlobals.host}-${status.join(",")} - ${showInternal}`;
  if (!profilesCache[key]) {
    profilesCache[key] = await makeRequest(
      `profiles?page=${page}&status=${status.join(",")}&showInternal=${showInternal}`
    );
  }
  return profilesCache[key];
};

export const getByUserId = async (
  userID: string
): Promise<{ user: EndUser; profile: Profile; profileAssets: ProfileAsset[] }> => {
  return makeRequest(`profiles/${userID}`);
};

export const getQuestionnaireStats = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/questionnaires-stats`);
};

export const getStatistics = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/statistics`);
};

export const getIntrosByFilter = async (profileId: string, filter: string) => {
  return makeRequest(`profiles/${profileId}/interactions?filter=${filter}`);
};

export const getImages = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/images`);
};

export const getUsageStats = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/usage-statistics`);
};

export const getTickets = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/tickets`);
};

export const updateStatus = async (profileId: string, statusId: number, statusReason: string) => {
  const res = makeRequest(`profiles/${profileId}/status`, {
    method: "PUT",
    body: { statusId, statusReason },
  }).then(() => (profilesCache = {}));
  await mmAction("", "approveEndUser", "", "", profileId);
  return res;
};

export const updateEndUser = async (profileId: string, data: any) => {
  const res = await makeRequest(`profiles/${profileId}`, { method: "PUT", body: data }).then(
    (r) => {
      profilesCache = {};
      return r;
    }
  );
  await mmAction("", "approveEndUser", "", "", profileId);
  return res;
};

export const getNotifcationsSettings = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}/notifications`);
};

export const updateNotificationSetting = async (
  profileId: string,
  channel_id: number,
  category_id: number,
  state: boolean
) => {
  return makeRequest(`profiles/${profileId}/notifications`, {
    method: "PUT",
    body: { channel_id, category_id, state },
  });
};

export const updateImageStatus = async (profileId: string, imageId: string, status) => {
  return makeRequest(`profiles/${profileId}/images/${imageId}`, {
    method: "PUT",
    body: { status },
  });
};

export const deleteProfile = async (profileId: string) => {
  return makeRequest(`profiles/${profileId}`, { method: "DELETE" }).then(
    () => (profilesCache = {})
  );
};

export const getProfileResponses = async (profileId: string): Promise<ProfileResponse[]> => {
  return makeRequest(`profiles/${profileId}/responses`);
};

export const getProfileMatchScores = async (
  profileId: string,
  filter,
  page
): Promise<MatchScore[]> => {
  return makeRequest(
    `profiles/${profileId}/match-scores?page=${page}&Attribute=${
      filter?.Attribute || ""
    }&hideDealBreakers=${filter?.hideDealBreakers || ""}`
  );
};
