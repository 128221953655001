import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VerticalDivider } from "./VerticalDivider";
import { snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import { useMaterialUIController } from "context";
import { Collapse } from "@mui/material";
import { get } from "http";

const { T } = require("components/alove/Translator");

const { Grid, Icon, Divider, Card } = require("@mui/material");
const MDTypography = require("components/MDTypography/index.js").default;

export interface StatsPanelProps {
  fields: {
    name: string;
    title: string;
    color?: string;
    filterLabel?: string;
    filterValue?: string;
    onClick?: () => void;
  }[];

  title: string;
  stats: any;
  mb?: number;
  filterClicked?: (label: string, value: string) => void;
}

export const StatsPanel = (props: StatsPanelProps): JSX.Element => {
  const { fields, title, stats, filterClicked, mb = 1 } = props;

  const [controller] = useMaterialUIController();
  const { mobileView } = controller;

  const [open, setOpen] = useState(!mobileView);

  const getFields = () =>
    fields
      .map((field) => [
        mobileView ? null : <VerticalDivider key={field.name + "d"} />,
        <Grid
          key={field.name}
          item
          flexDirection="row"
          alignItems="center"
          display="flex"
          gap={1}
          onClick={() =>
            filterClicked ? filterClicked(field.filterLabel!, field.filterValue!) : null
          }
          style={{ cursor: field.onClick ? "pointer" : "default" }}
        >
          <MDTypography
            color={field.color}
            fontWeight="bold"
            style={{ lineHeight: "normal" }}
            variant="h1"
          >
            {isNaN(stats[field.name])
              ? stats[field.name]?.toString()
              : parseFloat(parseFloat(stats[field.name]).toFixed(1)) || 0}
          </MDTypography>
          <MDTypography
            color={field.color}
            width="150px"
            style={{ lineHeight: "normal" }}
            variant="h6"
          >
            <T>{field.title}</T>
          </MDTypography>
        </Grid>,
      ])
      .concat(
        new Array(5 - fields.length)
          .fill(0)
          .map((a, i) => [
            <VerticalDivider hidden key={"d" + i} />,
            <Grid item width="150px" key={i + "1"} />,
          ])
          .flat()
      );

  return (
    <Card sx={{ px: 4, mb: mb }} style={{ width: "100%" }} onClick={() => setOpen(!open)}>
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Grid item sx={{ my: 4 }}>
          <MDTypography variant="h5" style={{ width: "250px" }}>
            <T>{title}</T>
          </MDTypography>
        </Grid>
        {mobileView && (
          <Icon sx={{ fontSize: "24px !important", margin: "8px" }}  >{open ? "expand_less" : "expand_more"}</Icon>)}
        {mobileView ? <Collapse in={open}>{getFields()}</Collapse> : getFields()}
      </Grid>
    </Card>
  );
};
