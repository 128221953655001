import { Grid, ListItem, Typography } from "@mui/material";
import { IntroductionLog } from "models/introduction/IntroLog";
import { useState, useEffect } from "react";
import { camelCaseToCapitalizedWords, formatDateTime, snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import { getIntroductionLogs } from "utils/introductionUtils";


export const IntroductionLogs = ({ id }) => {
    const [logs, setLogs] = useState([] as IntroductionLog[]);
    useEffect(() => {
        getIntroductionLogs(id).then((res) => setLogs(res));
    }, [id]);
    return (
        <Grid>
            {logs.map((log, index) => (
                <ListItem key={index}>
                    <Typography variant="body2">
                        {formatDateTime(log.date)} - {camelCaseToCapitalizedWords(log.type)} - {snakeCaseToCapitalizedWords(log.message)}
                    </Typography>
                </ListItem>
            ))}
        </Grid>
    );
}