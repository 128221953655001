import { Grid, IconButton, Slider, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useState } from "react";

/** add more responses for scale range */
async function addMoreResponsesScaleRange() {
  const id = Math.floor(1000 + Math.random() * 15000);
  const cloneResponse = [...responsesInputsScaleRange];
  const newResponse = { id, name: "Value", value: "0", label: "" };
  const maxValObject = responsesInputsScaleRange[responsesInputsScaleRange.length - 1];
  cloneResponse.pop();
  cloneResponse.push(newResponse);
  cloneResponse.push(maxValObject);
  setResponsesInputsScaleRange(cloneResponse);
}

/** remove response from scale range */
async function removeMoreResponsesScaleRange(id) {
  const newArray = [...responsesInputsScaleRange];
  const place = responsesInputsScaleRange.findIndex((i) => i.id === id);
  newArray.splice(place, 1);
  setResponsesInputsScaleRange(newArray);
}

/**update min max values */
async function updateMinMaxValuesScaleRange(id, value) {
  const index = responsesInputsScaleRange.findIndex((obj) => obj.id === id);
  const updatedItems = [...responsesInputsScaleRange];
  updatedItems[index] = {
    ...updatedItems[index],
    ...value,
  };
  setResponsesInputsScaleRange(updatedItems);
}

const cell = (name, label, value, onChange, onChangeLabel) => {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={3} xl={2}>
        <MDTypography variant="caption" fontWeight="bold" color="text">
          {name}
        </MDTypography>
      </Grid>
      <Grid item xs={1} xl={2}>
        <MDInput
          placeholder="Value"
          value={value}
          size="small"
          type="number"
          fullWidth
          onChange={onChange}
        />
      </Grid>

      {onChangeLabel && (
        <Grid item xs={4}>
          <MDInput
            placeholder="Label"
            value={label}
            size="small"
            fullWidth
            onChange={onChangeLabel}
          />
        </Grid>
      )}
      {/* <Grid item xs={3} xl={1}>
        {question.isMinValueError ? (
          <MDTypography variant="caption" fontWeight="bold" color="text">
            {minValueErrorMessage}
          </MDTypography>
        ) : null}
      </Grid> */}
    </Grid>
  );
};

export const RangeScalResponse = (props) => {
  const { question, setQuestion } = { ...props };
  //   const [responsesInputsScaleRange, setResponsesInputsScaleRange] = useState(
  //     [
  //         { id: 1, name: "Minimum Value", value: 0, label: '' },
  //         { id: 2, name: "Maximum Value", value: 9, label: '' }
  //     ]
  // );
  const isRange = question.responseType.name === "Range";
  return (
    <MDBox p={0} lineHeight={1}>
      {/* {responsesInputsScaleRange.map((r) => 
        cell(r.name, r.label, r.value, ""))} */}

      {cell(
        "Minimum Value",
        question.questionTexts["response_settings.txt_min"],
        question.range_min,
        (e) => setQuestion({ ...question, range_min: e?.target?.value }),
        (e) => {
          question.questionTexts["response_settings.txt_min"] = e?.target?.value;
          setQuestion({ ...question });
        }
      )}
      {cell(
        "Maximum Value",
        question.questionTexts["response_settings.txt_max"],
        question.range_max,
        (e) => setQuestion({ ...question, range_max: e?.target?.value }),
        (e) => {
          question.questionTexts["response_settings.txt_max"] = e?.target?.value;
          setQuestion({ ...question });
        }
      )}
      {cell("Steps", "", question.range_inc, (e) =>
        setQuestion({ ...question, range_inc: e?.target?.value })
      )}

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} xl={2}>
          <MDTypography variant="caption" fontWeight="bold" color="text">
            Default {isRange ? "values" : "value"}
          </MDTypography>
        </Grid>
        <Grid item xs={12} xl={4}>
          <Slider
            aria-label="Value"
            value={
              isRange
                ? (question.default_response
                  ? question.default_response.split(",").map(Number)
                  : [0, 100])
                : Number(question.default_response)
            }
            valueLabelDisplay="auto"
            onChange={(e) => setQuestion({ ...question, default_response: isRange ? e?.target?.value.join(",") : e?.target?.value })}
            step={question.range_inc || 1}
            marks={[
              { value: question.range_min, label: question.range_min },
              { value: question.range_max, label: question.range_max },
            ]}
            min={Number(question.range_min)}
            max={Number(question.range_max)}
          />
        </Grid>
      </Grid>

      {/* {r.name === 'Minimum Value' && responsesInputsScaleRange.length < 3 ? (
                    <Grid item xs={3} xl={3} >
                        <Tooltip title="Add">
                            <IconButton onClick={() => addMoreResponsesScaleRange()}>
                                <AddCircleIcon />
                                <MDTypography variant="caption" fontWeight="bold" color="text" >
                                    Add a scale mark
                                </MDTypography>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ) : null}
                {r.name === 'Value' ? (
                    <Grid item xs={3} xl={3} >
                        <Tooltip title="Add">
                            <IconButton onClick={() => removeMoreResponsesScaleRange(r?.id)}>
                                <RemoveCircleIcon />
                                <MDTypography variant="caption" fontWeight="bold" color="text" >
                                    Remove scale mark
                                </MDTypography>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ) : null}  */}
    </MDBox>
  );
  // <Grid container spacing={3} alignItems="center" >
  //                         <Grid item xs={12} xl={2} >
  //                             <MDTypography variant="caption" fontWeight="bold" color="text"  >
  //                                 Default {question.responseType === 'Range' ? 'values' : 'value'}
  //                             </MDTypography>
  //                         </Grid>

  //                         <Grid item xs={12} xl={4} >
  //                             {question.responseType?.name === 'Range' ? (
  //                                 <Slider
  //                                     // defaultValue={[2,5]}
  //                                     // value={question?.sliderRangeValue}
  //                                     valueLabelDisplay="auto"
  //                                     onChange={(e) => setQuestion({ ...question, sliderRangeValue: e?.target?.value })}
  //                                     step={1}
  //                                     marks
  //                                     // min={Number(responsesInputsScaleRange[0].value)}
  //                                     // max={Number(responsesInputsScaleRange[responsesInputsScaleRange.length - 1].value)}
  //                                 />
  //                             ) : (
  //                                 <Slider
  //                                     aria-label="Value"
  //                                     // value={question?.sliderScaleValue}
  //                                     valueLabelDisplay="auto"
  //                                     onChange={(e) => setQuestion({ ...question, sliderScaleValue: e?.target?.value })}
  //                                     step={1}
  //                                     marks
  //                                     // min={Number(responsesInputsScaleRange[0].value)}
  //                                     // max={Number(responsesInputsScaleRange[responsesInputsScaleRange.length - 1].value)}
  //                                 />
  //                             )}
  //                         </Grid>
  //                     </Grid>
};
