import colors from "assets/theme/base/colors";

export enum BoRole {
  SuperAdmin = 1,
  BrandAdmin = 2,
  Matchmaker = 53,
}

export interface Statusable {
  status: string | number | any;
  statuses: { key:string; value:string }[];
  statusColor?: string;
  statusLabel?: string;
  statusReason?: string;
}

export class BoUser implements Statusable {
  id: number;
  email: string;
  password: string;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  phone: string;
  lang: string;
  role: {
    [key: string]: BoUserRole;
  };
  avatar?: string;
  isActive: boolean;
  brands: any;
  isOnline: boolean;
  lastActive: Date;
  hiddenFromCs: boolean;
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get statusLabel(): string {
    return this.isActive ? "Active" : "Inactive";
  }

  get statusColor(): string {
    return this.isActive ? "success" : "error";
  }

  get statuses(): { key:string; value:string }[] {
    return [{ key: "Active", value: "Active" }, { key: "Inactive", value: "Inactive" }];
  }

  get statusReason(): string {
    return ""
  }

  get status(): string {
    return this.isActive ? "Active" : "Inactive";
  }

  get first_name(): string {
    return this.firstName;
  }

  get last_name(): string {
    return this.lastName;
  }

  set first_name(value: string) {
    this.firstName = value;
  }

  set last_name(value: string) {
    this.lastName = value;
  }

}

export class BoUserRole {
  roles: number[];
}