import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { ReactComponent as UrgentSvg } from "assets/images/icons/urgent.svg";
import MDButton from "components/MDButton";
import { Avatar, Chip, Divider, ListItem, Tooltip, Autocomplete } from "@mui/material";
import { shortName } from "utils/matchmakerUtils";
import { formatDate } from "@fullcalendar/react";
// import { avatarUrl, formatDate, formatDateTime } from "utils/globalsUtils";
import { ResumeIcon } from "components/alove/ResumeIcon";
import { isUrget } from "utils/matchmakerUtils";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import MDTypography from "components/MDTypography";
import { fullName } from "utils/matchmakerUtils";
import { statusWithAction } from "utils/matchmakerUtils";
import { render } from "@testing-library/react";
import { TwoSizeChip } from "components/alove/Chips";
import { statusColors } from "utils/matchmakerUtils";
import { EngagedChip } from "components/alove/Chips";
const MDInput = require("components/MDInput").default;

const name = (user, request, isAdmin) => {
  return fullName(user, request.status, isAdmin);
};

const urgentBuilder = (request) =>
  isUrget(request) ? (
    <Tooltip title={<T>urgentTooltip</T>}>
      <UrgentSvg style={{ width: "15px", height: "15px" }} />
    </Tooltip>
  ) : (
    <span style={{ width: "15px", height: "15px" }}></span>
  );

const coloredText = (text, request) => (
  <MDTypography
    variant="body2"
    style={{
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color:
        request.status == STATUSES.ENDED
          ? "grey"
          : [STATUSES.DATING, STATUSES.ENGAGED].includes(request.status)
            ? "#E44E9C"
            : isUrget(request)
              ? "red"
              : "black",
    }}
  >
    <T>{text}</T>
  </MDTypography>
);

export const ticketsColumns = (isAdmin, action, allUsers) => [
  {
    headerName: '#',
    field: "id",
    width: 50,
  },
  {
    headerName: "Disp",
    field: "disp",
    // width: 600,
    width: 60,
  },
  {
    headerName: "Question",
    field: "txt",
    // width: 600,
    flex: 1
  },
  {
    headerName: "Type",
    field: "type",
    width: 60,
    // renderCell: (params) =>
    //   params?.row?.assigned_to?.map((p, i) => {
    //     const user = allUsers?.find((u) => u.id === p);
    //     return (user?.first_name + ' ' + user?.last_name + ', ')
    //   })
  },
  {
    headerName: "Core",
    field: "is_core",
    width: 60,
    // renderCell: (params) => formatDate(params.row.created),
  },
  {
    headerName: "Updated",
    field: "updated",
    width: 90,
    renderCell: (params) => formatDate(params.row.created),
  },
  {
    headerName: "Status",
    field: "status",
    width: 60,
  }
];