import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { postRoleDO } from "utils/rolesUtils";
import { Grid, Autocomplete, TextField, IconButton } from "@mui/material";
import { useMaterialUIController } from "context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddNewRole(props) {
  const { setSnackbar, prepareRows, rolesTypes, currentUser, brandID } = props;
  const [controller] = useMaterialUIController();
  const { currentBrand } = controller;

  const [modalOpen, setModalOpen] = useState(false);
  const [role, setRole] = useState({
    name: "",
    description: "",
    type: {
      name: "",
    },
  });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    type: false,
  });

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setRole({ ...role, name: "", description: "", type: { name: "" } });
    setModalOpen(false);
  };

  const createNewRoleHandle = async () => {
    let errors = {};
    errors.name = role.name === "" ? true : false;
    errors.description = role.description === "" ? true : false;
    errors.type = !role.type?.id;

    setErrors({ ...errors });

    if (!errors.name && !errors.description && !errors.type) {
      const data = {
        role_name: role?.name,
        description: role?.description,
        type: role?.type?.name,
        created_by: currentUser?.id,
        brand_id: brandID,
      };
      const status = await postRoleDO("dev", currentBrand.id, data);
      if (status === 201) {
        setSnackbar({
          status: true,
          title: "New role was added successfully",
          color: "success",
        });
        prepareRows();
        handleClose();
      }
    } else {
      console.error("Please fill in all required fields.");
    }
  };

  return (
    <React.Fragment>
      <MDButton variant="contained" color="secondary" onClick={handleClickOpen}>
        Role
      </MDButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalOpen}
        sx={{
          maxWidth: 400,
          width: 400,
          height: 500,
          maxHeight: 500,
          position: "absolute",
          top: "10vh",
          left: "40vw",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="add-new-role-dialog-title">
          Add new role
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: "#000000" }} />
        </IconButton>
        <DialogContent>
          <MDInput
            placeholder="Role name"
            value={role?.name}
            size="small"
            sx={{ mb: 2 }}
            fullWidth
            onChange={(e) => setRole({ ...role, name: e.target.value })}
            error={errors.name}
          />
          <MDInput
            value={role?.description}
            placeholder="Description"
            rows="3"
            onChange={(e) => setRole({ ...role, description: e.target.value })}
            multiline
            fullWidth
            error={errors.description}
          />
          <Autocomplete
            disableClearable
            sx={{ mt: 2 }}
            value={role?.type}
            onChange={(event, newValue) => {
              setRole({ ...role, type: newValue });
            }}
            size="small"
            options={rolesTypes || []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} error={errors?.type} label="Type" placeholder="Choose type" />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ margin: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5.9} xl={5.9}>
              <MDButton
                variant="outlined"
                color={"info"}
                fullWidth
                size="small"
                onClick={handleClose}
              >
                cancel
              </MDButton>
            </Grid>
            <Grid item xs={6} xl={6}>
              <MDButton
                variant="gradient"
                color={"info"}
                size="small"
                fullWidth
                onClick={createNewRoleHandle}
              >
                Create role
              </MDButton>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
