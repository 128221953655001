import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { postLogDO } from 'utils/logsUtils';
import { updatePositionDO } from 'utils/positionsUtils';
import { Grid, IconButton } from "@mui/material";
import { useMaterialUIController } from "context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function EditPosition(props) {

    const [controller, dispatch] = useMaterialUIController();
    const { currentUser } = controller;
    const [modalOpen, setModalOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [newNameError, setNewNameError] = useState(false)

    const { position, setSnackbar, snackbar, getPosiions } = props;

    const handleClickOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const handleUpdatePosition = async (oldName) => {
        console.log('newName: ', newName);
        if (!newName) {
            setNewNameError(true);
        } else {
            setNewNameError(false);
        }

        if (newName) {
            const status = await updatePositionDO('dev', oldName, newName, props?.position?.id, currentUser?.id ? currentUser?.id : 0);
            if (status === 201) {
                const logData = [{
                    type: 'new position',
                    oldName,
                    newName
                }];
                await postLogDO('dev', 'dev', 'update position value', 'positions', logData, currentUser?.id ? currentUser?.id : 0);
                setNewName('');
                setModalOpen(false);
                setSnackbar({ ...snackbar, status: true, color: "success", icon: "check", title: 'Position was updated' });
                getPosiions();
            }
            handleClose();
        } else {
            // Display an error message or handle validation feedback
            console.error('Please fill in all required fields and select at least one user.');
        }
    }

    return (
        <React.Fragment>
            <IconButton size="small" disableRipple onClick={handleClickOpen}>
                <Tooltip title={'Edit'}>
                    <EditIcon />
                </Tooltip>
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Rename
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <MDInput
                        placeholder="Position name"
                        value={position.position_name}
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        disabled
                    />
                    <MDInput
                        placeholder="New value"
                        value={newName}
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        onChange={(e) => setNewName(e.target.value)}
                        error={newNameError}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={2} xl={2}></Grid>
                        <Grid item xs={4} xl={4}>
                            <MDButton
                                variant="gradient"
                                fullWidth
                                size="small"
                                onClick={() => handleClose()}
                            >
                                cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={4} xl={4}>
                            <MDButton
                                variant="gradient"
                                color={'info'}
                                fullWidth
                                size="small"
                                onClick={() => handleUpdatePosition(position.position_name)}
                            >
                                Rename
                            </MDButton>
                        </Grid>
                        <Grid item xs={2} xl={2}></Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}