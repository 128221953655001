import { Autocomplete, Button, Chip, Grid, Slider, Switch, TextField, Typography } from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Setting } from "models/BO/Setting";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";

const MDButton = require("components/MDButton/index.js").default;

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const SettingField = ({
  setting,
  updateSetting,
  disabled,
  timezone
}: {
  setting: Setting;
  updateSetting: any;
  disabled: boolean;
  timezone: string;
}) => {
  const [value, setValue] = useState("0");

  useEffect(() => {
    setValue(setting.paramValue);
  }, [setting]);

  const dateTimeField = (setting: Setting, child: any) => {
    return setting.paramValue ? (
      <Grid container alignContent="center">
        <Grid item sm={10}>
          <LocalizationProvider dateAdapter={AdapterDayjs} key="en" >
            {child}
          </LocalizationProvider>
        </Grid>
        <Grid item sm={2}>
          <MDButton onClick={() => updateSetting(setting, "")} color="error" variant="text" disabled={disabled}>
            <Delete />
          </MDButton>
        </Grid>
      </Grid>
    ) : (
      <Grid container alignItems="center" gap={3}>
        <Typography variant="body2">Not set</Typography>
        <MDButton
          onClick={() => updateSetting(setting, dayjs().toISOString())}
          variant="outlined"
          size="small"
          color="primary"
            disabled={disabled}
        >
          Set now
        </MDButton>
      </Grid>
    );
  };

  const inputCell = (setting: Setting) => {
    switch (setting.paramType) {
      case "boolinfo":
        return (
          <Chip
            label={setting.paramValue === "true" ? "Yes" : "No"}
            color={setting.paramValue === "true" ? "primary" : "error"}
            
          />
        );
      case "bool":
        return (
          <Switch
            checked={setting.paramValue === "true"}
            onChange={(e) => updateSetting(setting, e.target.checked.toString())}
            disabled={disabled}
          />
        );
      case "hours":
        return (
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={10}>
              <Slider
                // defaultValue={parseInt(setting.paramValue) / 60}
                value={parseInt(value) / 60}
                valueLabelDisplay="auto"
                min={0}
                max={72}
                step={6}
                onChange={(e, v) => setValue((Number(v) * 60).toString())}
                onChangeCommitted={(e, v) => updateSetting(setting, (Number(v) * 60).toString())}
                disabled={disabled}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2">{parseInt(setting.paramValue) / 60}</Typography>
            </Grid>
          </Grid>
        );
      case "date":
        return dateTimeField(
          setting,
          <DateTimePicker
            timezone={timezone}
            value={dayjs(setting.paramValue?.toString())}
            onChange={(v) => updateSetting(setting, v?.toISOString())}
            disabled={disabled}
          />
        );

      case "time":
        return dateTimeField(
          setting,
          <TimePicker
            value={dayjs(setting.paramValue?.toString())}
            onChange={(v) => updateSetting(setting, v?.toISOString())}
            timezone={timezone}
            disabled={disabled}
          />
        );
      default:
        return setting.paramValue;
    }
  };

  return (
    <Grid container item sm={6} alignItems={"center"}>
      <Grid item sm={true}>
        <Typography variant="body2">{setting.description}</Typography>
      </Grid>
      <Grid item sm={6}>
        {inputCell(setting)}
      </Grid>
    </Grid>
  );
};
