import { Comm } from "models/content/Comm"
import { makeRequest } from "./networkUtils"
import { TwilioTemplate } from "models/content/TwilioTemplate"

export const getComms = (): Promise<Comm[]> => {
    return makeRequest("comms")
}

export const getComm = (id: string): Promise<Comm> => {
    return makeRequest(`comms/${id}`)
}

export const createCommDO = (comm: Comm): Promise<Comm> => {
    return makeRequest("comms", { method: "POST", body: comm })
}

export const saveCommDO = (comm: Partial<Comm>): Promise<Comm> => {
    return makeRequest(`comms/${comm.id}`, { method: "PUT", body: comm })
}

export const deleteCommDO = (comm: Comm): Promise<void> => {
    return makeRequest(`comms/${comm.id}`, { method: "DELETE" })
}

export const publishComms = (): Promise<void> => {
    return makeRequest("comms/publish", { method: "POST" })
}

export const getWATemplates = (): Promise<TwilioTemplate[]> => {
    return makeRequest("twilio/templates").then((templates) => templates.map((t) => Object.assign(new TwilioTemplate(), t)))
}

export const  saveWATemplate = (template): Promise<void> => {
    return makeRequest(`twilio/templates`, { method: "POST", body: template })
}

export const submitWATemplate = (template: TwilioTemplate): Promise<void> => {
    return makeRequest(`twilio/templates/${template.sid}/submit`, { method: "PUT" })
}

export const deleteWATemplate = (template: TwilioTemplate): Promise<void> => {
    return makeRequest(`twilio/templates/${template.sid}`, { method: "DELETE" })
}
