import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode"
import { makeRequest } from "./networkUtils"



export const addGeneralCodeDO = (type: GeneralCodeType, name: string, extra?:any): Promise<void> => {
    return makeRequest("general-codes", { method: "POST", body: { type, name,extra } })
}

export const getGeneralCodes = (type: GeneralCodeType): Promise<GeneralCode[]> => {
    return makeRequest("general-codes?type=" + type)
}
