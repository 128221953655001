import { Autocomplete, Card, Divider, Grid } from "@mui/material";
import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode";
import { Comm, CommEvent, CommEventsParams, EmailTemplate } from "models/content/Comm";
import React, { useEffect, useState } from "react";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { camelCaseToCapitalizedWords, snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import { ActionSetting } from "layouts/screens/screenView/components/action";
import { ScreenModel } from "models/content/Screen";
const MDInput = require("components/MDInput/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const EmailWorkflow = ({
  comm,
  setComm,
  screens,
}: {
  comm: Comm;
  setComm: any;
  screens: ScreenModel[];
}) => {
  return (
    <Grid container flexDirection="row" alignItems="center" spacing={1}>
    <Grid item xs={2}>
      <MDTypography variant="body2">Template</MDTypography>
    </Grid>
      <Grid item xs={5}>
        <Autocomplete
          fullWidth
          options={Object.values(EmailTemplate)}
          getOptionLabel={(option: EmailTemplate) => snakeCaseToCapitalizedWords(option)}
          value={(comm.email?.template as EmailTemplate) || EmailTemplate.Login}
          onChange={(e, value) => setComm({ ...comm, email: { ...comm.email, template: value } })}
          renderInput={(params) => <MDInput {...params} label="Email template" />}
        />
      </Grid>

      <Divider style={{ width: "100%" }} />
      <Grid item xs={2}>
        <MDTypography variant="body2">Action Urls</MDTypography>
      </Grid>
      <Grid item xs={10}>
        <ActionSetting
          action={comm.email || {}}
          setAction={(a) => setComm({ ...comm, email: a })}
          screens={screens}
        />
      </Grid>
    </Grid>
  );
};
