import React, { useState, useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/MDTable";
import { getLogsDO } from "utils/logsUtils";

function Logs() {

    const [tableData, setTableData] = useState(
        {
            columns: [],
            rows: []
        });

    useEffect(() => {
        getLogs();
    }, []);

    const getLogs = async () => {
        const logs = await getLogsDO('dev');
        let rows = [];
        logs.reverse().map((item, index) => {
            const d = new Date(item.date);
            rows.push({
                where: item.where,
                what: item.what,
                date: item.date ? d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) : '',
                data: JSON.stringify(item.changes?.changes),
                by: item.first_name + ' ' + item.last_name,
            })
        });
        setTableData({
            columns: [
                { Header: "where", accessor: "where", width: "15%" },
                { Header: "what", accessor: "what", width: "20%" },
                { Header: "date", accessor: "date", width: "15%" },
                { Header: "data", accessor: "data", width: "30" },
                { Header: "by", accessor: "by", width: "15%" },
            ],
            rows: rows,
        });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} xl={6}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Logs
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox lineHeight={1}>
                        <DataTable table={tableData} canSearch />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default Logs;