import { Introduction, IntroductionStatus } from "models/introduction/Introduction";
import { makeRequest, networkGlobals } from "./networkUtils";
import { IntroductionLog } from "models/introduction/IntroLog";

const requestCache = {};
export const getIntroductions = async (
  page,
  status,
  from,
  to,
  matchmakerId,
  shidduchId,
  refresh = false
) => {
  const url =
    `introductions?brandId=${networkGlobals.brand.id}&page=${page}&status=${status}&` +
    `before=${to}&matchmakerId=${matchmakerId}&after=${from}&introId=${shidduchId}`;

  if (!requestCache[url + networkGlobals.host] || refresh) {
    requestCache[url + networkGlobals.host] = makeRequest(url);
  }

  return requestCache[url + networkGlobals.host];
};

export const getIntroductionDO = async (id) => {
  return makeRequest(`introductions/${id}`);
};

export const compareIntroduction = async (initiatorProfileId, responderProfileId) => {
  return makeRequest(
    `introductions/compare?initiatorProfileId=${initiatorProfileId}&responderProfileId=${responderProfileId}`
  );
}

export const updateStatusIntroduction = async (id, status:IntroductionStatus, adminStatusReason?:string) => {
  return makeRequest(`introductions/${id}/status`, { method: "PUT", body: { status,adminStatusReason } });
};

export const createNewIntroduction = async (introduction: Partial<Introduction>): Promise<{ introduction: Introduction }> => {
  return makeRequest("introductions", { method: "POST", body: introduction });
};

export const getIntroductionLogs = async (id): Promise<IntroductionLog[]> => {
  return makeRequest(`introductions/${id}/logs`);
};

export const swapIntroductionDO = async (id) => {
  return makeRequest(`introductions/${id}/swap`, { method: "PUT" });
};

enum AttachmentResult {
  ERROR = "attachmentResultError",
  SECURE = "attachmentResSecure",
  AVOIDANT = "attachmentResAvoidant",
  ANXIOUS = "attachmentResAnoxious",
  ANXIOUS_AVOIDANT = "attachmentResAnoxiousAvoidant",
}

export async function getAttachmentMatchScore(intoduction: Introduction): Promise<number> {
  const results = await makeRequest(
    `introductions/${intoduction}/attachments?initiatorProfileId=${intoduction.initiatorProfileId}&responderProfileId=${intoduction.responderProfileId}`
  );

  let matchScore = -1;
  const attachmentRes1 = results?.[0]?.attributeValue;
  const attachmentRes2 = results?.[1]?.attributeValue;

  // Error
  if (!attachmentRes1 || !attachmentRes2) {
    matchScore = -1;
  }
  // Secure secure
  else if (
    attachmentRes1 === AttachmentResult.SECURE &&
    attachmentRes2 === AttachmentResult.SECURE
  ) {
    matchScore = 100;
  }
  // Secure avoidant
  else if (
    (attachmentRes1 === AttachmentResult.SECURE && attachmentRes2 === AttachmentResult.AVOIDANT) ||
    (attachmentRes1 === AttachmentResult.AVOIDANT && attachmentRes2 === AttachmentResult.SECURE)
  ) {
    matchScore = 25;
  }
  // Secure anxious
  else if (
    (attachmentRes1 === AttachmentResult.SECURE && attachmentRes2 === AttachmentResult.ANXIOUS) ||
    (attachmentRes1 === AttachmentResult.ANXIOUS && attachmentRes2 === AttachmentResult.SECURE)
  ) {
    matchScore = 75;
  }
  // Secure anxious-avoidant
  else if (
    (attachmentRes1 === AttachmentResult.SECURE &&
      attachmentRes2 === AttachmentResult.ANXIOUS_AVOIDANT) ||
    (attachmentRes1 === AttachmentResult.ANXIOUS_AVOIDANT &&
      attachmentRes2 === AttachmentResult.SECURE)
  ) {
    matchScore = 75;
  }
  // Avoidant avoidant
  else if (
    attachmentRes1 === AttachmentResult.AVOIDANT &&
    attachmentRes2 === AttachmentResult.AVOIDANT
  ) {
    matchScore = 50;
  }
  // Avoidant anxious
  else if (
    (attachmentRes1 === AttachmentResult.AVOIDANT && attachmentRes2 === AttachmentResult.ANXIOUS) ||
    (attachmentRes1 === AttachmentResult.ANXIOUS && attachmentRes2 === AttachmentResult.AVOIDANT)
  ) {
    matchScore = 25;
  }
  // Avoidant anxious-avoidant
  else if (
    (attachmentRes1 === AttachmentResult.AVOIDANT &&
      attachmentRes2 === AttachmentResult.ANXIOUS_AVOIDANT) ||
    (attachmentRes1 === AttachmentResult.ANXIOUS_AVOIDANT &&
      attachmentRes2 === AttachmentResult.AVOIDANT)
  ) {
    matchScore = 50;
  }
  // Anxious anxious
  else if (
    attachmentRes1 === AttachmentResult.ANXIOUS &&
    attachmentRes2 === AttachmentResult.ANXIOUS
  ) {
    matchScore = 50;
  }
  // Anxious anxious-avoidant
  else if (
    (attachmentRes1 === AttachmentResult.ANXIOUS &&
      attachmentRes2 === AttachmentResult.ANXIOUS_AVOIDANT) ||
    (attachmentRes1 === AttachmentResult.ANXIOUS_AVOIDANT &&
      attachmentRes2 === AttachmentResult.ANXIOUS)
  ) {
    matchScore = 50;
  }
  // Anxious-avoidant Anxious-avoidant
  else if (
    attachmentRes1 === AttachmentResult.ANXIOUS_AVOIDANT &&
    attachmentRes2 === AttachmentResult.ANXIOUS_AVOIDANT
  ) {
    matchScore = 50;
  }

  return matchScore;
}
