import React, { useState, useEffect } from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MDTypography from "components/MDTypography";
// import Divider from '@mui/material/Divider';
import { postLogDO } from 'utils/logsUtils';
import { Grid, Autocomplete, TextField, IconButton } from "@mui/material";
import { addAlert, useMaterialUIController } from "context";
import { questionnarieListDo, saveTextQuestionDO, updateTextQuestionDO, getBatchQuestionDO, getAttributesDO } from 'utils/questionsUtils';
import { PrevalidateModal } from '../question/components/prevalidateModal';
import { networkGlobals } from 'utils/networkUtils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddEditQuestionText(props) {


    const {
        addEditModalOpenStatus,
        setAddEditModalOpenStatus,
        updateData,
        questionaireID,
        brandID,
        userID,
        lastOrdinal,
        getAndPrepareTableData,
        addEditModalOpenType
    } = props;
    const [controller, dispatch] = useMaterialUIController();
    const { currentBrand, currentUser } = controller;

    // for content_type_id = 4
    const [questionData, setQuestionData] = useState([]);

    // const [text, setText] = useState('');
    const [questionnarie, setQuestionnarie] = useState();

    const [inputEerrors, setInputErrors] = useState({
        name: false,
    });
    const [modalData, setModalData] = useState();
    const [questionnaireList, setQuestionnaireList] = useState([]);

    useEffect(() => {
        getQuestionnarieList();
        if (updateData?.content_type_id === 4) {
            getQuestoinData();
        }
    }, [addEditModalOpenStatus,currentBrand])

    const getQuestionnarieList = async () => {
        if (!currentBrand?.id)  return
        const questionnarieList = await questionnarieListDo(networkGlobals.host, currentBrand?.id);
        setQuestionnaireList(questionnarieList);
        setModalData(updateData?.id ? { ...updateData } : null);
        const questionarieValue = questionnarieList.find((q) => q?.id == updateData?.batch_id);
        setQuestionnarie(questionarieValue)
    }

    const handleClose = () => {
        // setModalOpen(false);
        setAddEditModalOpenStatus(false);
    };

    const getQuestoinData = async () => {
        console.log('aaaaaaaa');

        const questionData = await getBatchQuestionDO(networkGlobals.host, updateData?.id);
        console.log('questionData: ', questionData);
        setQuestionData(questionData);

        const attributes = await getAttributesDO(networkGlobals.host);
        console.log('attributes: ', attributes);
    }

    const setQuestion = async (method) => {
        if (method === 'new' && questionnarie?.id) {
            const addNewResponse = await saveTextQuestionDO(networkGlobals.host, modalData, questionnarie?.id, brandID, userID, lastOrdinal? lastOrdinal:0);
            if (addNewResponse === 201) {
                addAlert(dispatch, 'New text question were added')
                
                setModalData({ ...modalData, text: '' })
                getAndPrepareTableData();
                setAddEditModalOpenStatus(false);
            } else {
                addAlert(dispatch, 'Something goes wrong')
            }
        } else if (method === 'update') {
            console.log('modalData: ', modalData);
            const updateStatus = await updateTextQuestionDO(networkGlobals.host, modalData, brandID, userID);
            console.log('updateStatus: ', updateStatus);
            if (updateStatus === 200) {
                addAlert(dispatch, 'Text question were updated')
                setModalData({ ...modalData, text: '' })
                getAndPrepareTableData();
                setAddEditModalOpenStatus(false);
            } else {
                addAlert(dispatch, 'Something goes wrong')
                setModalData({ ...modalData, text: '' })
            }
        }
    }

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={addEditModalOpenStatus}
                maxWidth='sm'
                fullWidth
            // sx={{ maxWidth: 600, width: 600, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {updateData ? 'Edit question # ' + updateData?.id : 'Add new'}
                    {userID === 15 ? ` brandID: ${brandID}, last ordinal: ${lastOrdinal}` : null}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >

                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center" >
                        {updateData?.content_type_id === 1 || addEditModalOpenType === 'text' ? (
                            <>
                                <Grid item xs={12} xl={12}>
                                    <MDInput
                                        // label="Text"
                                        placeholder="Text"
                                        fullWidth
                                        value={modalData?.text}
                                        size="small"
                                        sx={{ mb: 2 }}
                                        // fullWidth
                                        onChange={(e) => setModalData({ ...modalData, text: e.target.value })}
                                    // error={nameError}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        size="small"
                                        isOptionEqualToValue={(positions, value) => positions?.display_name === value?.display_name}
                                        // multiple
                                        disabled={updateData ? true : false}
                                        value={questionnarie}
                                        id="edit-qiestion-modal"
                                        options={questionnaireList}
                                        disableCloseOnSelect
                                        onChange={(e, value) => setQuestionnarie(value)}
                                        getOptionLabel={(positions) => positions.display_name}
                                        renderOption={(props, option, { selected }) =>
                                        (
                                            <li {...props} key={option.display_name}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.display_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                label="Questionnarie" />
                                        )}
                                    />
                                </Grid>
                                {updateData && <Grid item xs={4}>
                                    <PrevalidateModal q={updateData} bcid={updateData.bcid} />
                                </Grid>}
                            </>
                        ) : null}

                        {updateData?.content_type_id === 4 || addEditModalOpenType === 'link' ? (
                            <>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        size="small"
                                        isOptionEqualToValue={(positions, value) => positions?.display_name === value?.display_name}
                                        // multiple
                                        // disabled={updateData ? true : false}
                                        value={questionnarie}
                                        id="edit-qiestion-modal"
                                        options={questionnaireList}
                                        disableCloseOnSelect
                                        onChange={(e, value) => setQuestionnarie(value)}
                                        getOptionLabel={(positions) => positions.display_name}
                                        renderOption={(props, option, { selected }) =>
                                        (
                                            <li {...props} key={option.display_name}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.display_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Questionnarie" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        id="condition"
                                        disableClearable
                                        // value={questionData[0]?.pre_validation[0]?.content[0]?.condition}
                                        // getOptionLabel={(option) => option?.name}
                                        // isOptionEqualToValue={(positions, value) => positions?.name === value?.name}
                                        // onChange={(event, newValue) => {
                                        // setQuestion({ ...question, questionType: newValue });
                                        // }}
                                        size="small"
                                        // id="controllable-states-demo"
                                        // options={selectData.questionType}
                                        options={[]}
                                        renderInput={(params) => <TextField {...params} label="Attribute" />}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        id="condition"
                                        disableClearable
                                        // value={questionData[0]?.pre_validation[0]?.content[0]?.condition}
                                        // getOptionLabel={(option) => option?.name}
                                        // isOptionEqualToValue={(positions, value) => positions?.name === value?.name}
                                        // onChange={(event, newValue) => {
                                        // setQuestion({ ...question, questionType: newValue });
                                        // }}
                                        size="small"
                                        // id="controllable-states-demo"
                                        // options={selectData.questionType}
                                        options={[]}
                                        renderInput={(params) => <TextField {...params} label="Value" />}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        id="condition"
                                        disableClearable
                                        value={questionData[0]?.pre_validation[0]?.content[0]?.condition}
                                        // getOptionLabel={(option) => option?.name}
                                        // isOptionEqualToValue={(positions, value) => positions?.name === value?.name}
                                        // onChange={(event, newValue) => {
                                        // setQuestion({ ...question, questionType: newValue });
                                        // }}
                                        size="small"
                                        // id="controllable-states-demo"
                                        // options={selectData.questionType}
                                        options={['bigger', 'equals', 'lower']}
                                        renderInput={(params) => <TextField {...params} label="Condition" />}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        id="operand"
                                        disableClearable
                                        value={questionData[0]?.pre_validation[0]?.operand}
                                        // getOptionLabel={(option) => option?.name}
                                        // isOptionEqualToValue={(positions, value) => positions?.name === value?.name}
                                        // onChange={(event, newValue) => {
                                        // setQuestion({ ...question, questionType: newValue });
                                        // }}
                                        size="small"
                                        // id="controllable-states-demo"
                                        // options={selectData.questionType}
                                        options={['OR', 'AND']}
                                        renderInput={(params) => <TextField {...params} label="Operand" />}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={12}>
                                    <Autocomplete
                                        size="small"
                                        isOptionEqualToValue={(positions, value) => positions?.display_name === value?.display_name}
                                        // multiple
                                        // disabled={updateData ? true : false}
                                        value={questionnarie}
                                        id="edit-qiestion-modal"
                                        options={questionnaireList}
                                        disableCloseOnSelect
                                        onChange={(e, value) => setQuestionnarie(value)}
                                        getOptionLabel={(positions) => positions.display_name}
                                        renderOption={(props, option, { selected }) =>
                                        (
                                            <li {...props} key={option.display_name}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                />
                                                {option.display_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                label="Questionnarie start" />
                                        )}
                                    />
                                </Grid>
                            </>
                        ) : null}

                        <Grid item xs={12} xl={12}>
                            <MDTypography variant="h5" fontWeight="medium">
                                <FormControlLabel control={
                                    <Switch
                                        checked={modalData?.content_state_id === 2 ? true : false}
                                        size="small"
                                        onChange={() => { setModalData({ ...modalData, content_state_id: modalData?.content_state_id === 2 ? 1 : 2 }) }}
                                    />}
                                    label="Enabled"
                                />
                            </MDTypography>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                // color={'info'}
                                fullWidth
                                size="small"
                                onClick={() => handleClose()}
                            >
                                cancel
                            </MDButton>
                            {/* <MDTypography variant="button" color="text" fontWeight="regular">
                                Pending
                            </MDTypography> */}
                        </Grid>
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                color={'info'}
                                size="small"
                                fullWidth
                                onClick={() => setQuestion(updateData ? 'update' : 'new')}
                                disabled={modalData?.text ? false : true}
                            >
                                {updateData  ? 'Update' : 'Create new'}
                            </MDButton>
                        </Grid>
                    </Grid>
                    {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}