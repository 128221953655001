import axios from "axios";
import { makeRequest, networkGlobals } from "./networkUtils";
import { Ticket } from "models/customer-supports/Ticket";


function getHost() {
    return networkGlobals.serverHost;
}

/** get list of customer support topic */
export const getCustomerSupportTopicsDO = async (host?) => {
    let cHost = host || networkGlobals.host;
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.get(`${currentHost}/api/customerSupport/topics?host=${cHost}`)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get customer support topic:', error)
                reject(error);
            })
    })
}

/** get list of customer support statuses */
export const getCustomerSupportStatusesDO = async (host) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.get(`${currentHost}/api/customerSupport/statuses?host=${host}`)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get customer support topic:', error)
                reject(error);
            })
    })
}

export const getTicketsDO = async (host, filters) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.get(`${currentHost}/api/customerSupport/tickets?host=${host}&filters=${filters}`)
            .then(res => {
                console.log('res: ', res);
                
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get customer support topic:', error)
                reject(error);
            })
    })
}

export const getUserIDDO = async (host, profileID) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.get(`${currentHost}/api/customerSupport/userID?host=${host}&profileID=${profileID}`)
            .then(res => {
                resolve(res?.data?.[0]?.user_id);
            })
            .catch(error => {
                console.error('error from get user id:', error)
                reject(error);
            })
    })
}

export const getSLASettingsDO = async (host, brandID) => {
    brandID ||= networkGlobals.brand.id
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.get(`${currentHost}/api/customerSupport/SLAsettings?host=${host}&brandID=${brandID}`)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get customer support topic:', error)
                reject(error);
            })
    })
}

/** get not excluded users */
export const getUsersWithBrandsDO = async (host, brands) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.get(`${currentHost}/api/customerSupport/users/details?host=${host}&brands=${brands}`)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get user with brands:', error)
                reject(error);
            })
    })
}

/** get all users */
export const getAllUSersDO = async (host) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.get(`${currentHost}/api/customerSupport/allUsers/details?host=${host}`)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get all system users:', error)
                reject(error);
            })
    })
}

/** get all users */
export const getTicketDO = async (host, ticketID) : Promise<Ticket> => {
    return makeRequest(`customerSupport/tickets/${ticketID}`);
    // return new Promise((resolve, reject) => {
    //     const currentHost = getHost();
    //     axios.get(`${currentHost}/api/customerSupport/ticket?host=${host}&ticketID=${ticketID}`)
    //         .then(res => {
    //             resolve(res?.data[0]);
    //         })
    //         .catch(error => {
    //             console.error('error from ticket by id:', error)
    //             reject(error);
    //         })
    // })
}

export const getTicketResponses = (ticketId: string) => {
    return makeRequest(`customerSupport/responses?ticketId=${ticketId}`);
}

// /** get all users */
// export const getTicketLogDO = async (host, ticketID) => {
//     return new Promise((resolve, reject) => {
//         const currentHost = getHost();
//         axios.get(`${currentHost}/api/customerSupport/ticketLog?host=${host}&ticketID=${ticketID}`)
//             .then(res => {
//                 resolve(res?.data);
//             })
//             .catch(error => {
//                 console.error('error from ticket by id:', error)
//                 reject(error);
//             })
//     })
// }


export const addResponse = async (content: string, recipient: string,ticketId: string) => {
    return makeRequest('customerSupport/responses', {method: 'POST', body: {content, recipient, ticketId}});
}
// export const sendLogDO = async (host, log, ticketID) => {
//     return new Promise((resolve, reject) => {
//         const currentHost = getHost();
//         axios.post(`${currentHost}/api/customerSupport/log`,{host,log, ticketID})
//             .then(res => {
//                 console.log('res from add new log: ',res);
//                 resolve(res);
//             })
//             .catch(error => {
//                 console.error('error on add new log:', error)
//                 reject(error);
//             })
//     })
// } 

/** update SLA row */
export const updateStatusDO = async (host, data) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.put(`${currentHost}/api/customerSupport/updatSLASettings`, { host, data })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from get profile:', error)
                reject(error);
            })
    })
}

/** update Assignee row */
export const updateAssigneeDO = async (host, data, id) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.put(`${currentHost}/api/customerSupport/updateAssignee`, { host, data, id })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from get profile:', error)
                reject(error);
            })
    })
}

/** update status row */
export const updateticketStatusDO = async (data, id) => {
    return makeRequest(`customerSupport/${id}/status`, {method: 'PUT', body: data});
    // return new Promise((resolve, reject) => {
    //     const currentHost = getHost();
    //     axios.put(`${currentHost}/api/customerSupport/updateStatus`, { host, data, id })
    //         .then(res => {
    //             resolve(res.status);
    //         })
    //         .catch(error => {
    //             console.error('error from get profile:', error)
    //             reject(error);
    //         })
    // })
}

/** update assigne row */
export const updaeTicketAsiigneDO = async (host, data, id) => {
    return new Promise((resolve, reject) => {
        const currentHost = getHost();
        axios.put(`${currentHost}/api/customerSupport/updateTicketAssignee`, { host, data, id })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from get profile:', error)
                reject(error);
            })
    })
}




