import React, { useState, useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/MDTable";
import { getAdminTasksDO } from 'utils/adminUtils'

function AdminTasks() {

    const [tableData, setTableData] = useState(
        {
            columns: [],
            rows: []
        });


    useEffect(() => {
        getAdminTasts();
    }, []);

    const getAdminTasts = async () => {
        const tasks = await getAdminTasksDO('dev');
        let rows = [];
        tasks.reverse().map((item, index) => {

            rows.push({
                // id:item.id,
                task: item.task,
                where: item.where,
                by: item.first_name + ' ' + item.last_name,
                link: ['link', item.link],
                action: ['done']
            })
        });
        setTableData({
            columns: [
                // { Header: "id", accessor: "id", width: "10%" },
                { Header: "task", accessor: "task", width: "20%" },
                { Header: "where", accessor: "where", width: "20%" },
                { Header: "by", accessor: "by", width: "20%" },
                { Header: "link", accessor: "link", width: "20%" },
                { Header: "action", accessor: "action", width: "10%" }
            ],
            rows: rows,
        });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} xl={6}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Admin tasks
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox lineHeight={1}>
                        <DataTable table={tableData} canSearch />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default AdminTasks;