import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { T } from "components/alove/Translator";
import { useEffect, useState } from "react";
import { loadAttributes } from "utils/attributesUtil";
import { updatePrevalidation } from "utils/attributesUtil";
import MDInput from "components/MDInput";
import { getBioPrefRelations } from "utils/attributesUtil";
import { updateBioPrefRelations } from "utils/attributesUtil";
export const RelatedQuestionModal = (props) => {
  const { responses, attrName } = props;
  const [open, setOpen] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [relatedQuestions, setRelatedQuestions] = useState([]);

  useEffect(() => {
    if (!attrName) return;
    loadAttributes().then((attr) => {
      console.log("attr: ",  attr);
        setAttributes(attr);
    });
    getBioPrefRelations(attrName).then((relations) => {
      if (relations?.length) {
        setRelatedQuestions(relations);
      } else {
        setRelatedQuestions(
          responses.map((r) => ({ pref_response_id: r.id, score: 10, pref_attr_id: attrName }))
        );
      }
    });
  }, [attrName]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    updateBioPrefRelations(attrName, relatedQuestions);
    setOpen(false);
  };

  return [
    <MDButton
      variant="outlined"
      sx={{ mt: 2 }}
      color="primary"
      onClick={() => setOpen(true)}
      fullWidth
    >
      Related questions
    </MDButton>,
    <Dialog onClose={handleClose} open={open} maxWidth="xl" fullWidth>
      <DialogTitle>Related questions</DialogTitle>
      <Grid gap={2} container>
        {relatedQuestions?.map((prc, prci, arr) => {
          const attr = attributes.find((a) => a.attr_name === prc.bio_attr_id);
          return (
            <ListItem key={prci}>
              <Grid container px={2} gap={2} key={prci}>
                <Grid item xs={2}>
                  <Autocomplete
                    options={responses || []}
                    getOptionLabel={(a) => a.response || ""}
                    freeSolo
                    onChange={(e, v) => {
                      arr[prci].pref_response_id = v.txtID;
                      setRelatedQuestions([...relatedQuestions]);
                    }}
                    value={responses?.find((r) => r.txtID == prc.pref_response_id) || ""}
                    renderInput={(params) => <TextField {...params} label="Current responses" />}
                  />
                </Grid>
                <Grid item xs={1} alignItems="center" justifyContent="center" container>
                  <MDTypography variant="h6" fontscore="medium">
                    Related to:
                  </MDTypography>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    options={attributes}
                    getOptionLabel={(a) => a.editing_question_txt || a.attr_name || ""}
                    getOptionKey={(a) => a.attr_name}
                    value={attr || ""}
                    onChange={(e, v) => {
                      arr[prci].bio_attr_id = v?.attr_name;
                      setRelatedQuestions([...relatedQuestions]);
                    }}
                    renderInput={(params) => <TextField {...params} label="Attribute" />}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    options={attr?.responses || []}
                    getOptionLabel={(a) => a.txt || a.k || ""}
                    freeSolo
                    onChange={(e, v) => {
                      arr[prci].bio_response_id = v.k;
                      setRelatedQuestions([...relatedQuestions]);
                    }}
                    value={attr?.responses?.find((r) => r.k == prc.bio_response_id) || ""}
                    renderInput={(params) => <TextField {...params} label="Realated responses" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <MDInput
                    value={prc.score}
                    label="score"
                    placeholder="score"
                    type="number"
                    onChange={(e) => {
                      arr[prci].score = e.target.value;
                      setRelatedQuestions([...relatedQuestions]);
                    }}
                  />
                </Grid>

                <Tooltip title="Remove condition">
                  <IconButton
                    onClick={() => {
                      setRelatedQuestions(relatedQuestions.filter((_, i) => i !== prci));
                    }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </ListItem>
          );
        })}
      </Grid>
      <DialogActions>
        <MDButton
          onClick={() => {
            relatedQuestions.push({
              bio_attr_id: relatedQuestions[0]?.bio_attr_id,
              score: 10,
              pref_attr_id: attrName,
            });
            setRelatedQuestions([...relatedQuestions]);
          }}
          variant="outlined"
          color="primary"
        >
          Add new related response
        </MDButton>
        <Grid item xs={true} />
        <MDButton onClick={handleClose}>Close</MDButton>
        <MDButton onClick={handleSave} variant="contained" color="primary">
          Save
        </MDButton>
      </DialogActions>
    </Dialog>,
  ];
};
