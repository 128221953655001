// import { CircularProgress, Divider, Grid, List } from "@mui/material";
// import MDTypography from "components/MDTypography";
import { ReactElement, useEffect, useImperativeHandle, useRef, useState } from "react";
import { T } from "./Translator";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete, Box, capitalize, Card, Chip, Grid, Switch } from "@mui/material";
import { StatsPanel, StatsPanelProps } from "./StatsPanel";
import {
  DataGridPro,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  GridListColDef,
} from "@mui/x-data-grid-pro";
import CardItem from "./CardItem";
import { ActionsCard, ActionsRow, ActionsRowProps } from "./ActionsRow";
import { Search } from "@mui/icons-material";
import { VerticalDivider } from "./VerticalDivider";
import { Stats } from "fs";
import { GridBaseColDef } from "@mui/x-data-grid/internals";
import { useMaterialUIController } from "context";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { MobileListItem } from "./MobileListItem";
import { MobileDotMenu } from "./MobileDotMenu";

const MDTypography = require("components/MDTypography/index.js").default;
const MDInput = require("components/MDInput/index.js").default;

export interface TableFilterProps {
  label: string;
  type?: string;
  options?: { key: any; label?: string; getLabel?: (o) => string }[];
  onInputChange?: (e: any) => void;
  defaultValue?: any;
  multiple?: boolean;
  placeholder?: string;
  clearOtherFilters?: boolean;
  hidden?: boolean;
  disableClearable?: boolean;
  featured?: boolean;
  renderFilter?: (onChange: (value: any) => void) => JSX.Element;
}

export interface TableColumnProps<R extends GridValidRowModel> extends GridBaseColDef<R> {
  hidden?: (column: GridColDef) => boolean;
  editableCB?: (row: R) => boolean;
  mobileHidden?: boolean;
  featured?: boolean;
}

export interface TablePageProps<R extends GridValidRowModel> {
  title: string;
  table: {
    columns: TableColumnProps<R>[];
    loadNext: (host: string, filters: any, page: number) => Promise<any>;
    lineClick?: (row: R) => void;
    pagination?: boolean;
    rowCount?: number;
    getTreeDataPath?: (row: R) => string[];
    processRowUpdate?: (newRow: R, oldRow: R) => Promise<R> | R;
    onRowOrderChange?: GridEventListener<"rowOrderChange">;
    groupingColDef?: GridGroupingColDefOverride;
    minHeight?: number;
    getRowId?: (row: R) => string;
  };
  filters?: TableFilterProps[];
  stats?: StatsPanelProps;
  actions?: ActionsRowProps;
  refresh?: any;
  actionsInFiltersRow?: boolean;
}
interface PresavePage {
  page: number;
  filters: any;
}

let preservePages: { [key: string]: PresavePage } = {};

export const TablePage = (props: TablePageProps<any>) => {
  const { title, table, filters = [], stats, actions, refresh, actionsInFiltersRow } = props;
  let searchKey: any = useRef("");
  let count = useRef(0);

  let preservePage = preservePages[title]?.page || 0;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 30,
    page: preservePage,
  });

  let endLoading = false;
  const [loader, setLoader] = useState(true);
  const [rows, setRows] = useState([] as any[]);
  const [openRowId, setOpenRowId] = useState("");

  const [controller] = useMaterialUIController();
  const { currentUser, currentBrand, currentHost, mobileView } = controller;

  if (!preservePages[title]) {
    preservePages[title] = {
      filters: {},
      page: 0,
    };
  }

  table.columns.forEach((col) => {
    col.renderHeader = (params) => (
      <MDTypography variant="h6">
        <T>{params.colDef?.headerName}</T>
      </MDTypography>
    );
    if (col.align == "center") {
      col.headerAlign = "center";
    }
    if (col.editableCB) {
      col.editable = col.editableCB(col);
    }
  });

  useEffect(() => {
    filters
      .filter((f) => f.defaultValue)
      .forEach((f) => (preservePages[title].filters[f.label] = f.defaultValue.key));
  }, []);

  useEffect(() => {
    setPaginationModel({ ...paginationModel, page: preservePage });
  }, [currentHost, currentBrand]);

  useEffect(() => {
    loadMore(paginationModel.page);
  }, [paginationModel]);

  useEffect(() => {
    loadMore(paginationModel.page);
  }, [refresh]);

  const loadMore = (page) => {
    if (!filters.find((f) => f.label == "brand") || currentBrand.id != 100) {
      preservePages[title].filters["brand"] = currentBrand.id;
    }

    const key = `${currentHost}${JSON.stringify(preservePages[title].filters)}${page}${
      currentBrand.id
    }${refresh}`;
    console.log("key", key);
    if (key == searchKey.current) {
      return;
    }

    searchKey.current = key;
    console.log("loadMore", page);

    preservePages[title].page = page;
    setLoader(true);
    table.loadNext(currentHost, preservePages[title].filters, page).then((data) => {
      setLoader(false);
      setRows(data || []);
    });
  };

  const changeFilter = (filter, value) => {
    if (filter.clearOtherFilters) {
      preservePages[title].filters = {};
    }
    console.log("filter", filter, value);
    console.log("preservePages", preservePages);
    preservePages[title].filters[filter.label] = value;
    setPaginationModel({
      pageSize: 30,
      page: 0,
    });
  };

  const clearFilters = () => {
    preservePages[title].filters = {};
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const changeDate = (filter, value) => {
    if (value[1]) {
      preservePages[title].filters[filter.label] = {
        before: value[0].toISOString().split("T")[0],
        after: value[1].toISOString().split("T")[0],
      };
      setPaginationModel({ ...paginationModel, page: 0 });
    }
  };

  const search = (filter, value) => {
    preservePages[title].filters = {};
    preservePages[title].filters[filter.label] = value;
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const renderFilter = (filter) => {
    if (filter.renderFilter) {
      return filter.renderFilter((value) => changeFilter(filter, value));
    }
    switch (filter.type) {
      case "date":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} key={filter.label}>
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              name="allowedRange"
              onChange={(e) => changeDate(filter, e)}
            />
          </LocalizationProvider>
        );
      case "search":
        return [
          <MDTypography variant="body2" key={filter.label + "l"}>
            Search
          </MDTypography>,

          <MDInput
            key={filter.label}
            placeholder={filter.placeholder}
            onKeyPress={(e) => e.key === "Enter" && search(filter, e.target.value)}
            sx={{ ml: mobileView ? 0 : 1 }}
            InputProps={{
              endAdornment: (
                <Search
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  onClick={(e) =>
                    search(filter, (e.target as any)?.parentElement.children[0].value)
                  }
                />
              ),
            }}
          />,
          <VerticalDivider key={filter.label + "d"} />,
        ];
      case "switch":
        return (
          <Grid item key={filter.label} container alignItems="center" xs={1}>
            <MDTypography variant="body2">{camelCaseToCapitalizedWords(filter.label)}</MDTypography>
            <Switch sx={{ ml: 1 }} onChange={(e) => changeFilter(filter, e.target.checked)} />
          </Grid>
        );
      default:
        const values = filter.options?.filter((o) =>
          filter.multiple
            ? preservePages[title].filters[filter.label]?.includes(o.key)
            : o.key == preservePages[title].filters[filter.label]
        );
        return (
          <Autocomplete
            key={filter.label}
            sx={{ width: 150 }}
            defaultValue={filter.defaultValue}
            options={filter.options}
            limitTags={1}
            value={filter.multiple ? values : values[0]}
            multiple={filter.multiple}
            disableClearable={filter?.disableClearable}
            onInputChange={filter?.onInputChange}
            noOptionsText={filter?.onInputChange ? "Type to search" : null}
            // onChange={(e, o) => changeFilter(filter, o?.key || "")}
            isOptionEqualToValue={(positions, value) => positions.label === value.label}
            onChange={(e, o) =>
              changeFilter(filter, filter.multiple ? o?.map((o) => o.key) : o?.key)
            }
            getOptionLabel={(o) => (o.getLabel ? o.getLabel(o) : o.label)}
            renderInput={(params) => <MDInput {...params} label={capitalize(filter.label)} />}
          />
        );
    }
  };

  const rowCount = Number(
    table.rowCount || rows[0]?.full_count || rows[0]?.fullCount || rows.length
  );

  const listColDef: GridListColDef = {
    field: "listColumn",
    renderCell: (params: GridRenderCellParams) => {
      return (
        <MobileListItem
          item={params.row}
          columns={table.columns}
          onRowOpen={() =>
            params.row.id == openRowId ? setOpenRowId("") :
             setOpenRowId(params.row.id)}
          currentRowId={openRowId}
        />
      );
    },
  };

  return (
    <Grid sx={{ width: "100%" }}>
      {actions && !actionsInFiltersRow && <ActionsCard {...actions} />}
      {stats && (
        <StatsPanel
          {...stats}
          filterClicked={(k, v) =>
            changeFilter(
              filters.find((f) => f.label == k),
              v
            )
          }
        />
      )}

      <Card sx={{ px: mobileView ? 0 : 4, pb: 4, mb: 1 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: mobileView ? 4 : 0 }}
        >
          <Grid item sx={{ my: 4 }}>
            <MDTypography variant="h5" fontWeight="medium">
              <T>{title}</T> ({rowCount})
            </MDTypography>
          </Grid>

          {filters.filter((f) => f.featured).map(renderFilter)}
          <MobileDotMenu>
            {actions && actionsInFiltersRow && <ActionsRow {...actions} />}
            {actions && actionsInFiltersRow && !!filters?.length && <VerticalDivider />}
            {filters.filter((f) => !f.hidden && !f.featured).map(renderFilter)}

            {!!filters?.length && (
              <MDTypography
                variant="body2"
                fontWeight="medium"
                onClick={clearFilters}
                color="error"
                style={{ cursor: "pointer", textDecoration: "underline" }}
                sx={{ ml: 1 }}
              >
                <T>Clear All</T>
              </MDTypography>
            )}
          </MobileDotMenu>
        </Grid>
        <DataGridPro
          unstable_listColumn={listColDef}
          unstable_listView={mobileView}
          style={{
            cursor: table.lineClick ? "pointer" : "default",
            minHeight: (table.minHeight || 400) + "px",
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 30, page: preservePage },

              rowCount: -1,
            },
          }}
          pageSizeOptions={[30]}
          localeText={{ noRowsLabel: "No result found : )" }}
          paginationModel={paginationModel}
          onPaginationModelChange={(p) => {
            console.log("onPaginationModelChange", p);
            if (count.current < 2 && preservePages[title].page) {
              count.current += 1;
              return;
            }
            setPaginationModel(p);
          }}
          treeData={!!table.getTreeDataPath}
          getTreeDataPath={table.getTreeDataPath}
          rows={rows}
          rowCount={rowCount}
          pagination={
            !!table.pagination || !!table.rowCount || !!rows[0]?.full_count || !!rows[0]?.fullCount
          }
          columns={table.columns.filter((c) => !c.hidden?.(c))}
          rowReordering={!!table.onRowOrderChange}
          paginationMeta={{
            hasNextPage: true || rows?.length > 0,
          }}
          onCellClick={(params, events) => {
            if (params.isEditable) {
              events.stopPropagation();
            }
          }}
          loading={loader}
          processRowUpdate={table.processRowUpdate}
          onRowOrderChange={table.onRowOrderChange}
          onProcessRowUpdateError={console.error}
          paginationMode="server"
          onRowClick={(row) => table.lineClick?.(row)}
          groupingColDef={table.groupingColDef}
          getRowId={table.getRowId}
          getRowHeight={mobileView ? (params) => (params.id == openRowId ? 250 : 70) : undefined}
        />
      </Card>
    </Grid>
  );
};
