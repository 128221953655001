import PieChart from "examples/Charts/PieChart";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import channelChartData from "layouts/dashboards/sales/components/ChannelsChart/data";

export default function Chart() {
    return (
        <MDBox
            // pt={1} 
            // pb={3}
            // pt={4}
            pb={0}
            // px={}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            // mt="auto"
            mt={-4}
            // mr={1}
            mb={0}
            width='100%'
        // height='100%'
        >
            <Card>
                <MDBox p={3} lineHeight={1}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} xl={12}>
                            <MDTypography variant="h6" fontWeight="medium">
                                Stats(TBD)
                            </MDTypography>
                        </Grid>

                        <Grid item xs={12} xl={12}>
                            <PieChart chart={channelChartData} height="12.5rem" />
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        </MDBox>
    )
}