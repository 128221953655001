import { Card, Grid } from "@mui/material";
import { ActionsList, ItemActiosProps } from "./itemActions";
import { ItemStatus, ItemStatusProps } from "./itemStatus";
import { MobileDotMenu } from "components/alove/MobileDotMenu";

const MDTypography = require("components/MDTypography/index.js").default;

export const MobileHeader = ({
  actions,
  itemStatus,
}: {
  actions?: ItemActiosProps;
  itemStatus?: ItemStatusProps;
}): JSX.Element => {
  return (
    <Card sx={{ pt: 3, px: 4}}>
      <Grid container spacing={1} alignItems="stretch" justifyContent="space-between">
        <Grid item xs={3}>
            <MDTypography variant="h6" fontWeight="medium">
            {actions?.itemNumber}
            </MDTypography>
        </Grid>
        <Grid item xs={5}>
          {itemStatus && <ItemStatus {...itemStatus} />}
        </Grid>
        {!!actions?.actions?.length && (
          <MobileDotMenu>
            <ActionsList {...actions} />
          </MobileDotMenu>
        )}
      </Grid>
    </Card>
  );
};
