import CardItem from "components/alove/CardItem";
import ItemPageContainer from "layouts/itemPageContainer";
import { Setting } from "models/BO/Setting";
import { useEffect, useState } from "react";
import { deploySettingsDO, getSettingsDO, updateSettingsDO } from "utils/settingsUtils";

import * as  timezones  from "./timezones.json";
import settingsTemplate from "./settingsTemplate.json";
import { SettingField } from "./components/field";
import { addAlert, useMaterialUIController } from "context";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { PermissionType } from "models/BO/BoRole";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const SystemSettings = () => {
  const pageNumber = "9.13"
  const [controller,dispatch] = useMaterialUIController();
  const {currentBrand,currentHost,currentRole} = controller;
  const [settings, setSettings] = useState([] as Setting[]);
  const [timezone, setTimezone] = useState("America/New_York");
  

  useEffect(() => {
    getSettingsDO().then((settings) => {
      setSettings(settings);
    });
  }, [currentBrand,currentHost]);

  const updateSetting = (setting: Setting, newValue: string) => {
    setting.paramValue = newValue;
    setting.prevalidatedParamValue = newValue;
    setSettings([...settings.filter((s) => s.paramName !== setting.paramName), setting]);
    updateSettingsDO(setting);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="System" />
      <ItemPageContainer
        navItems={[{ label: "System Pause", ref: "systemShutdown" }]}
        actions={{
            itemName: ``,
          itemNumber: ``,
          actionsTitle: "Settings",
          actions: [
            {
              title: "Timezone",
              onClick: () => {},
              element:  (<Autocomplete
                id="combo-box-demo"
                options={Array.from(timezones)}
                getOptionLabel={(option) => option.replace("_"," ")}
                style={{ width: 200 }}
                disableClearable
                defaultValue={timezone}
                onChange={(e, v) => setTimezone(v!.toString())}
                renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
              />)
            }
          ],
        }}
      >
        {settingsTemplate.map((template) => {
          return (
            <CardItem id={template.id} title={template.title} key={template.id}>
              <Grid container columnSpacing={10} rowSpacing={2}>
              {template.settings.map((sName: string) => {
                const setting = settings.find((s) => s.paramName == sName);
                return setting ? (
                  <SettingField
                    setting={setting}
                    updateSetting={updateSetting}
                    key={setting.paramName}
                    timezone={timezone}
                    disabled={!currentRole.hasAccess(pageNumber,PermissionType.Edit)}
                  />
                ) : <Grid item sm={6}/>
              })}
              </Grid>
            </CardItem>
          );
        })}
      </ItemPageContainer>
    </DashboardLayout>
  );
};
