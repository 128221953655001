import { Grid } from "@mui/material"
import { VerticalDivider } from "components/alove/VerticalDivider"
import { HeaderIndication } from "layouts/itemPageContainer/components/headerIndication"
import { Ticket } from "models/customer-supports/Ticket"

export const ReporterDetails = ({ticket}: {ticket: Ticket}) => {

    return ticket.userAgent && (
        <Grid container gap={2} >
            <HeaderIndication title="System" value={ticket.userAgent.system} />
            <VerticalDivider />
            <HeaderIndication title="OS Version" value={ticket.userAgent.OSVersion} />
            <VerticalDivider />
            <HeaderIndication title="App Version" value={ticket.userAgent.appVersion} />
            <VerticalDivider />
            <HeaderIndication title="Build" value={ticket.userAgent.build} />
            <VerticalDivider />
            <HeaderIndication title="Model" value={ticket.userAgent.manufacturer + " " + ticket.userAgent.model} />

        </Grid>
        
    )
}