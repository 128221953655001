import axios from "axios";
import { networkGlobals } from "./networkUtils";


/** put position */
export const deletePositionDO = async (host, name, ID, userID) => {
    return new Promise((resolve, reject) => {
        axios.put(`${networkGlobals.serverHost}/api/positions/delete/`, { host, name, ID, userID })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from delete positions:', error)
                reject(error);
            })
    })
}

/** put position */
export const updatePositionDO = async (host, oldName, newName, ID, userID) => {
    return new Promise((resolve, reject) => {
        axios.put(`${networkGlobals.serverHost}/api/positions/`, { host, oldName, newName, ID, userID })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from update positions:', error)
                reject(error);
            })
    })
}

/** post new position */
export const addNewPositionDO = async (host, name, createdBY, where) => {
    console.log('where from position utils: ', where);
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/positions/`, { host, name, createdBY, where })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from post positions:', error)
                // reject(error);
                resolve(error);
            })
    })
}

/** upload image */
export const uploadImageDO = async (base64, position, type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${networkGlobals.serverHost}/api/positions/file/`, { base64, position, type })
            .then(res => {
                resolve(res.status);
            })
            .catch(error => {
                console.error('error from post positions:', error)
                // reject(error);
                resolve(error);
            })
    })
}
