import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { deactivateQuesionDO } from 'utils/questionsUtils';

export default function DeActivQuesion(props) {

    const { questionForEdit, getQuestion } = { ...props };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeQuestionStatus = async() => {
        let value = 2;
        if (questionForEdit[0]?.state_status === 2) {
            value = 1
        }

        const deactivateStatus = await deactivateQuesionDO('dev', questionForEdit[0]?.id, value);
        if(deactivateStatus === 200){
            getQuestion();
            handleClose();
        }
    }

    return (
        <React.Fragment>
            {questionForEdit[0]?.question_state_id !== 3 ? (
                <MDButton mr={1} pr={1} size={'small'} variant="outlined" color="dark" onClick={handleClickOpen}>
                    {questionForEdit[0]?.state_status === 2 ? 'Deactivate ' : 'Activate '}question
                </MDButton>
            ) : null}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deactivate question?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure {questionForEdit[0]?.state_status === 2 ? 'deactivate ' : 'activate '} question?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} xl={6}>
                            <Button onClick={handleClose}>No</Button>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <Button onClick={() => changeQuestionStatus()} autoFocus>{questionForEdit[0]?.state_status === 2 ? 'Deactivate ' : 'Activate '}</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}