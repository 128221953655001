import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// import PositionImageViewModal from './viewImageModal';

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StatsPanel } from "components/alove/StatsPanel";
import ItemPageContainer from "layouts/itemPageContainer";

import CardItem from "components/alove/CardItem";
import { SMEBasic } from "layouts/itemPageContainer/components/basic";
import { deleteEndUserDO, getSystemUserByIDDO, postNewUserDO, updateUserDO } from "utils/systemUsersUtils";
import { title } from "process";
import { create } from "domain";
import { formatDate, formatDateTime } from "utils/globalsUtils";
import { BrandsTable } from "layouts/itemPageContainer/userItem/brandsTable";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { BoUser } from "models/BO/BoUser";
import { SUNotificationsSettings } from "layouts/itemPageContainer/components/notifications";
import { setCurrentDialog, setCurrentUser, useMaterialUIController } from "context";
import { AddNewBrand } from "./addNewBrand";
import { DialogType } from "components/alove/AloveDialog";
import { PermissionType } from "models/BO/BoRole";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;

function ViewSystemUser() {
  const pageNumber = "2.6";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole } = controller;
  const isManager = currentRole.hasAccess(pageNumber, PermissionType.Manager);
  const [canEdit, setCanEdit] = useState(currentRole.hasAccess(pageNumber, PermissionType.Edit));
  const [snackbar, setSnackbar] = useState({
    status: false,
    icon: "error",
    color: "error",
    title: "",
  });
  const [inputErrors, setInputErrors] = useState({
    email: false,
    phone: false,
    first_name: false,
    last_name: false,
    password: false,
  });

  const navigate = useNavigate();

  const [userData, setUserData] = React.useState(new BoUser());

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });
  let { id } = useParams();
  let location = useLocation();
  let isMe = location.pathname.includes("me");

  useEffect(() => {
    if (id && id != "new") {
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (isMe && currentUser) {
      id = currentUser.id.toString();
      setCanEdit(true);
      getProfile();
    }
  }, [currentUser]);

  const getProfile = async () => {
    const user = await getSystemUserByIDDO("dev", id || "me") as any
    user.firstName = user.first_name || user.firstName;
    user.lastName = user.last_name || user.lastName;

    setUserData(Object.assign(new BoUser(), user));
  };

  const updateAttribute = (key, value) => {
    userData[key] = value;
    setUserData(Object.assign(new BoUser(), userData));
  };

  const saveProfile = () => {
    inputErrors.first_name = !userData.firstName;
    inputErrors.last_name = !userData.lastName;
    inputErrors.email = inputErrors.email || !userData.email;
    inputErrors.phone = inputErrors.phone || !userData.phone;
    if (id === "new") {
      inputErrors.password = !userData.password;
      
    }
    if (Object.values(inputErrors).some((el) => el)) {
      setSnackbar({
        status: true,
        color: "error",
        icon: "error",
        title: "Please fix the errors",
      });
      return;
    }
    if (id === "new") {
      userData.brands = [currentBrand.id];
      userData.role = { [currentBrand.id]: { roles: [
        (currentUser as any).roles[currentBrand.id].roles[0],
      ] } };
    }
    // setUserData({ ...userData });
    const data:any = { ...userData };
    data.first_name = data.firstName;
    data.last_name = data.lastName;
    delete data.firstName;
    delete data.lastName;
    (id === "new" ? postNewUserDO("dev", data) : updateUserDO("dev", userData)).then(
      (res: any) => {
        console.log(res);
        if (res == "OK") {
          
          setSnackbar({
            status: true,
            color: "success",
            icon: "success",
            title: "Profile updated successfully",
          });
          if (id === "new") {
            navigate(-1);
          }
          if (isMe) {
            setCurrentUser(dispatch, { ...currentUser, ...userData });
            localStorage.setItem("BOCurrentUser", JSON.stringify( { ...currentUser, ...userData }));
          }
        } else {
          setSnackbar({
            status: true,
            color: "error",
            icon: "error",
            title: "Error updating profile",
          });
        }
      }
    );
  };

  const setStatus = async (status) => {
    userData.isActive = status == "Active";
    setUserData(Object.assign(new BoUser(), userData));
    saveProfile();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={userData.firstName} />
      <ItemPageContainer
        navItems={[
          { label: "Profile & Status" },
          { label: "Personal details", ref: "personal" },
          { label: "Brands & Roles", ref: "brnads" },
          { label: "Notifications", ref: "notifications" },
        ]}
        actions={{
          pageNumber,
          itemName: `System User`,
          itemNumber: `User ID: ${userData.id || ""}`,
          actionsTitle: "User Actions",
          actions: [
            {
              role: PermissionType.Edit,
              title: "Save",
              onClick: saveProfile,
              variant: "contained",
            },
          ],
        }}
        itemStatus={{
          title: "User Status",
          item: userData,
          isManager,
          changeStatus: setStatus,
        }}
        itemHeader={
          <SMEHeader
            formData={userData}
            indications={[
              {
                title: "Created:",
                value: formatDate(userData.created),
              },
              {
                title: "Last Login:",
                value: formatDate(userData.lastActive),
              },
            ]}
            canChange={canEdit}
            saveProfile={saveProfile}
          />
        }
        deleteItem={{
          contetnText: "Are you sure you want to delete this user?",
          buttonText: "Delete User",
          onDelete: () => deleteEndUserDO(id).then(() => navigate(-1)),
        }}
      >
        <CardItem title="Personal details" defaultOpen id="personal">
          <SMEBasic
          csHidden={true}
            formData={{...userData}}
            updateAttribute={updateAttribute}
            setFormData={(data) => setUserData({ ...userData, ...data })}
            isAdmin={isManager}
            inputErrors={inputErrors}
            canEdit={canEdit}
            isNew={id === "new"}
          />
        </CardItem>
        {id !== "new" && (
          <CardItem
            title="Brands & Roles"
            id="brands"
            addButton={currentBrand.isAlove}
            addNewContact={() => setCurrentDialog(dispatch, DialogType.AddBrandToUser)}
          >
            <BrandsTable user={userData} canEdit={canEdit && !isMe} />
          </CardItem>
        )}
      </ItemPageContainer>
      <MDSnackbar
        color={snackbar?.color}
        icon={snackbar?.icon}
        title={snackbar?.title}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
      <AddNewBrand user={userData} onAdd={getProfile} />
    </DashboardLayout>
  );
}

export default ViewSystemUser;
