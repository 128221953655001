
export enum NotificationType {
  Push = 1,
  Email = 2,
  SMS = 3,
}
export enum NotificationCategory {
  Introducions = 1,
  ChatMessages = 2,
  System = 3,
}

export interface NotificationSetting {
  channel_id: NotificationType;
  category_id: NotificationCategory;
  state: boolean;
}
export class EndUser {
  public id?: string;

  public firstName?: string;

  public lastName?: string;

  public dob?: string;

  public email?: string;

  public phoneNumber?: string;

  public username!: string;

  public identityId?: string;

  public avatarImageKey?: string;

  public fcmToken?: string;

  public fcmTokenUpdateTime?: string;

  public isEnabled?: boolean;

  public created: string;

  public lastLogin?: string;

  public avatar?: string;

  public birthdate: string;

  get age(): string {
    const date = this.dob || this.birthdate;
    if (!date) return "-1";
    const timediff = Math.abs(Date.now() - new Date(date).getTime());
    return Math.floor(timediff / (1000 * 3600 * 24 * 365)).toString();

  }

  get fullName(): string {
    return this.firstName + (this.lastName ? ` ${this.lastName}` : "");
  }

  get phone(): string {
    return this.phoneNumber || "";
  }
}
