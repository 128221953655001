// [{"content": [{"attr": "interests", "value": ["1722157565019499"], "condition": "in"}], "operand": "AND"}]

export enum PreValidationOperand {
    AND = "AND",
    OR = "OR"
}

export enum PreValidationCondition {
    Contains = "contains",
    Equals = "equals",
    NotEquals = "notEquals",
    NotIn = "notIn",
    Bigger = "bigger",
    Smaller = "smaller",
    DateBigger = "dateBigger",
    DateSmaller = "dateSmaller",
    Between = "between",
    StrContains = "strContains"

}
export class PreValidation {
    content: PreValidationContent[];
    operand: PreValidationOperand;

}

export class PreValidationContent {
    attr: string;
    condition: PreValidationCondition;
    value: string[];

}