import React, { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import PositionImageViewModal from './viewImageModal';

import { useNavigate, useParams } from "react-router-dom";
import { StatsPanel } from "components/alove/StatsPanel";
import ItemPageContainer from "layouts/itemPageContainer";
import {
  getSinglMMStats,
  getSMEProfile,
  getMMForm,
  updateMM,
  mmAction,
} from "utils/matchmakerUtils";

import { MatchmakerDetails } from "./components/matchmakerDetails";
import CardItem from "components/alove/CardItem";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { SMEBasic } from "layouts/itemPageContainer/components/basic";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";
import { Matchmaker, MMStatuses } from "models/BO/Matchmaker";
import { DialogType } from "components/alove/AloveDialog";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";
import { SnoozeDialog } from "./components/snoozeDialog";
import { tr } from "utils/globalsUtils";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import {
  NotifcationSetting,
  SUNotificationsSettings,
} from "layouts/itemPageContainer/components/notifications";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

let newStatus;

function MMProfileAndSettings() {
  const pageNumber = "13.2";
  const adminPageNumber = "7.5";
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const isAdmin = currentRole.hasAccess(adminPageNumber, PermissionType.Edit);

  const navigate = useNavigate();

  const [stats, setStats] = useState({});

  const [loader, setLoader] = useState(true);

  const [inputLists, setInputLists] = useState({
    ageRange: [],
    singlePlan: [],
    singleType: [],
    experience: [],
  });

  const [formData, setFormData] = React.useState({
    id: "",
    avatar: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    short_description: "",
    status: 1,
    population: {},
    busy_until: "",
    attributes: {
      declineReason: "",
      title: "",
      notification: {
        Email: true,
        SMS: true,
        WA: true,
      } as NotifcationSetting,
      communicationDirections: "",
      specialIntstructions: "",
    },
  });

  const [inputErrors, setInputErrors] = React.useState({
    firstName: false,
    lastName: false,
    title: false,
    email: false,
    phone: false,
    preferdMethod: false,
    communicationDirections: false,
    short_description: false,
  });

  let { id } = useParams();
  id ||= "me";

  useEffect(() => {
    getProfile();
    getInputLists();

    if (isAdmin) {
      getSinglMMStats(id).then((res) => {
        setStats(res);
      });
    }
  }, []);

  const getProfile = async () => {
    const profile = await getSMEProfile(id);

    setFormData({
      avatar: profile?.user?.avatar,
      firstName: profile?.user.first_name,
      lastName: profile?.user.last_name,
      email: profile?.user.email,
      phone: profile?.user?.phone,
      short_description: profile?.short_description,
      population: profile?.population,
      attributes: profile?.attributes,
      status: profile?.status,
      id: profile.userId,
      busy_until: profile?.busy_until,
    });

    setLoader(false);
  };

  const getInputLists = async () => {
    const lists = await getMMForm();
    setInputLists(lists);
  };

  const updateAttribute = (key, value) => {
    const keys = key.split(".");
    let current = formData;
    for (let i = 0; i < keys.length; i++) {
      if (i === keys.length - 1) {
        current[keys[i]] = value;
      } else {
        current[keys[i]] ||= {};
        current = current[keys[i]];
      }
    }

    setFormData({ ...formData });
  };
  const saveProfile = () => {
    if (Object.values(inputErrors).some((el) => el)) {
      addAlert(dispatch, "pleaseFixErrors", true);
      return;
    }
    setFormData({ ...formData });
    return updateMM(id, {
      ...formData,
      first_name: formData.firstName,
      last_name: formData.lastName,
    }).then((res) => {
      console.log(res);
      if (res?.message) {
        addAlert(dispatch, res.message);
      } else {
        addAlert(dispatch, "errorSavingProfile", true);
      }
    });
  };

  const updateMMStatus = async (statusReason: string, days?: number) => {
    const oldStatus = formData.status;
    formData.status = newStatus;
    formData.attributes ||= {} as any;
    formData.attributes.declineReason = statusReason;
    if (days) {
      formData.busy_until = new Date(Date.now() + days!).toISOString();
    }
    setFormData({ ...formData });

    if (oldStatus == MMStatuses.pending) {
      console.log("newStatus", newStatus);
      await mmAction(id, MMStatuses.available ? "approve" : "declined", statusReason);
    } else {
      await saveProfile();
    }
  };
  const setStatus = async (status: MMStatuses) => {
    newStatus = status;
    if (status == MMStatuses.available) {
      return updateMMStatus("");
    }
    if (status == MMStatuses.notAvailable) {
      setCurrentDialog(dispatch, DialogType.SnoozeSME);
    } else {
      setCurrentDialog(dispatch, DialogType.DeactivateEntitiy);
    }
  };

  const onDelete = async () => {
    if (currentRole.hasAccess(adminPageNumber, PermissionType.Manager)) {
      await mmAction(id, "deleted", "");
    } else if (currentRole.hasAccess(adminPageNumber, PermissionType.Delete)) {
      newStatus = MMStatuses.deleted;
      await updateMMStatus("Deleted");
    } else {
      newStatus = MMStatuses.deletionRequested;
      await updateMMStatus("Deletion Requested");
    }
    if (isAdmin) {
      navigate(-1);
    } else {
      navigate("/sign-in");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={formData.firstName} />
      <ItemPageContainer
        loader={loader}
        navItems={[
          { label: "profileAndStatus" },
          { label: "profileStatistics" },
          { label: "personalDetails", ref: "personal" },
          { label: "SMEDetails", ref: "sme-details" },
          { label: "Notifications", ref: "notifications" },
        ]}
        actions={{
          itemName: formData.firstName,
          itemNumber: `Profile ID: ${id}`,
          actionsTitle: "profileActions",
          pageNumber: isAdmin ? adminPageNumber : pageNumber,
          actions: [
            formData.status == MMStatuses.available
              ? {
                  title: "snoozeProfile",
                  onClick: () => setStatus(MMStatuses.notAvailable),
                  role: PermissionType.Edit,
                  color: "error",
                }
              : {
                  title: "activateProfile",
                  onClick: () => setStatus(MMStatuses.available),
                  role: PermissionType.Edit,
                  color: "primary",
                },
            {
              title: "Save Changes",
              onClick: saveProfile,
              variant: "contained",
              role: PermissionType.Edit,
            },
          ],
        }}
        itemStatus={{
          title: "profileStatus",
          changeStatus: setStatus,
          isManager: currentRole.hasAccess(adminPageNumber, PermissionType.Manager),
          item: Object.assign(new Matchmaker(), formData),
        }}
        itemHeader={<SMEHeader formData={formData} saveProfile={saveProfile} canChange={true} />}
        deleteItem={
          currentRole.hasAccess(adminPageNumber, PermissionType.Delete)
            ? {
                contetnText: "deleteMatchmakerContent",
                buttonText: "deleteMatchmaker",
                onDelete: onDelete,
              }
            : {
                contetnText: "deleteMatchmakerContentRequest",
                buttonText: "requestProfileDeletion",
                onDelete: onDelete,
              }
        }
      >
        {isAdmin && (
          <StatsPanel
            mb={0}
            title="profileStats"
            stats={stats}
            fields={[
              {
                name: "totalIntros",
                title: "SMEtotalIntros",
                color: "primary",
              },
              {
                name: "introPerMonth",
                title: "SMEintroPerMonth",
              },
              {
                name: "declineByMonth",
                title: "SMEdeclineByMonth",
              },
              {
                name: "warning",
                title: "SMEwarning",
                color: "error",
              },
            ]}
          />
        )}
        <CardItem title="personalDetails" defaultOpen id="personal">
          <SMEBasic
            formData={formData}
            updateAttribute={updateAttribute}
            setFormData={setFormData}
            inputErrors={inputErrors}
            isAdmin={isAdmin}
            canEdit
            withTitle
          />
        </CardItem>

        <CardItem title="SMEDetails" id="sme-details">
          <MatchmakerDetails
            formData={formData}
            updateAttribute={updateAttribute}
            inputLists={inputLists}
            setFormData={setFormData}
          />
        </CardItem>

        <CardItem title="Notifications" id="notifications">
          <SUNotificationsSettings
            notification={formData.attributes.notification || {
              Email: true,SMS: true,WA: true,
            }}
            setNotification={(notification: NotifcationSetting) =>
              updateAttribute("attributes.notification", notification)
            }
          />
        </CardItem>
      </ItemPageContainer>

      <DeactivateDialog
        title="changeStatusProfileDialogTitle"
        content="changeStatusProfileDialogContent"
        onSubmit={(reason) => updateMMStatus(reason)}
        reasonLabel="changeStatusProfileReason"
        confirm="updateMatchmaker"
      />

      <SnoozeDialog mmId={id} handleConfirm={(days) => updateMMStatus("", days)} />
    </DashboardLayout>
  );
}

export default MMProfileAndSettings;
