import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Delete, DeleteOutline } from "@mui/icons-material";
import { deleteRoleDO } from 'utils/rolesUtils';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function (props) {
    const { row, snackbar, setSnackbar, deleteContact } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const handleClickOpen = (e) => {
        setModalOpen(true);
        e.stopPropagation();
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}>
                <DeleteOutline />
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 800, width: 800, height: 500, maxHeight: 500, position: 'absolute', top: '12vh', left: '35vw' }}
            >
                <DialogTitle sx={{ m: 1, p: 2 }} id="brand-contact-delete">
                    Are you sure you want to delete this contact?
                </DialogTitle>
                <DialogContent>
                    <MDTypography>
                        {row?.role_name}
                    </MDTypography>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6}>
                            <MDButton
                                variant="outlined"
                                color="info"
                                fullWidth
                                size="small"
                                onClick={handleClose}
                            >
                                Cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                fullWidth
                                onClick={() => deleteContact(row?.id)}
                            >
                                Delete
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}