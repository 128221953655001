import { snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import { BoUser, Statusable } from "../BO/BoUser";
import { EndUser } from "../profile/EndUser";
import { Profile, ProfileAsset } from "../profile/Profile";

export enum IntroductionStatus {
    RAW = 1,
    INITIATOR_APPROVED = 2,
    INITIATOR_DECLINED = 3,
    INITIATOR_IGNORED = 4,
    MATCHED = 5,
    RESPONDER_DECLINED = 6,
    RESPONDER_IGNORED = 7,
    TERMINATED = 8,
    MM_AVAILABLE = 9,
    MM_TAKING_ON = 10,
    MM_CONNECTED = 11,
    MM_RECORDED = 12,
    DATING = 13,
    ENGAGED = 14,
    ON_HOLD = 15,
    ENDED = 16,
    PENDING_APPROVAL = 17,
    ADMIN_REJECTED = 18,
    AVAILABILITY_CHECK = 19

  }
  export interface IntroductionStatusReason {
    reason: string;
    declinedProfileId: string;
    dateNumber: number;
    initiatorStatus: string;
    responderStatus: string;
    noResponse: string;
    adminStatusReason: string;
    terminationReason: string;

  }

  export class Introduction implements Statusable {
    introductionId: string;
    introductionUid: string;
    initiatorProfileId: string;
    initiatorMatchScore: number;
    responderProfileId: string;
    responderMatchScore: number;
    statusId: IntroductionStatus;
    created: string;
    updated: string;
    lastEventUid: string;
    statusReasonObj: IntroductionStatusReason;
    mustRespondUntil: string;
    lastReminderAt: string;
    matchmakerId: string;
    brandId: number;
    initiatorUser: EndUser;
    responderUser: EndUser;
    initiatorImage: ProfileAsset;
    responderImage: ProfileAsset;
    initiatorProfile: Profile;
    responderProfile: Profile;
    initiatorResume: string;
    responderResume: string;
    boUser: BoUser;
    mmLogs: any[];
    nextMatchmaker: BoUser;

    get statusLabel() {
      return IntroductionStatus[this.statusId];
    }


    get status() {
      return this.statusId;
    }
    set status(value: IntroductionStatus) {
      this.statusId = value;
    }
    get statuses() {
      return Object.keys(IntroductionStatus)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => ({
        key,
        value: snakeCaseToCapitalizedWords(IntroductionStatus[key]).replace("Mm", "MM"),
      }));
    }
  
    get statusColor() {
      return "primary";
    }

    get statusReason() :string {
      return this.statusReasonObj?.adminStatusReason || this.statusReasonObj?.reason || this.statusReasonObj?.terminationReason || "";
        }

    set statusReason(value:any) {
      this.statusReasonObj = value;
    }

    get mmBoUser() {
      return this.boUser;
    }


  };
