import { CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import colors from "assets/theme/base/colors";
import { T } from "components/alove/Translator";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { Introduction, IntroductionStatus } from "models/introduction/Introduction";
import React, { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { declineIntroduction, STATUSES, updateIntroStatus, updateMM } from "utils/matchmakerUtils";
import UpdateStatusDialog, { DialogProps } from "./updateStatusDialog";
import RingsSvg from "assets/images/icons/rings.svg";

import X from "assets/images/icons/x.png";
import untie from "assets/images/icons/untie.png";
import snooze from "assets/images/icons/snooze.png";
import engage from "assets/images/icons/engage.png";
import dismiss from "assets/images/icons/dismiss.png";
import { setCurrentDialog, useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";
import { DialogType } from "components/alove/AloveDialog";
import { SnoozeDialog } from "layouts/smeProfiles/profileAndSettings/components/snoozeDialog";
import { MMStatuses } from "models/BO/Matchmaker";
import { getSetting, SettingName } from "utils/settingsUtils";

const MDTypography = require("components/MDTypography").default;
const MDButton = require("components/MDButton").default;

export async function doAction(
  shRequest: Introduction,
  setDialog: Function,
  refresh: (v?) => void,
  navigate: NavigateFunction,
  status: string | IntroductionStatus,
  extra?: any
) {
  switch (status) {
    case "decline":
      setDialog({
        dialog: "updateStatus" + shRequest.status,
        red: true,
        img: X,
        onConfirm: (ex) =>
          declineIntroduction(shRequest.introductionId, ex.declineReason).then(() => navigate(-1)),
      });
      break;
    case "unmatch":
      setDialog({
        dialog: "unmatch",
        red: true,
        img: untie,
        onConfirm: () =>
          updateIntroStatus(shRequest.introductionId, STATUSES.ENDED, extra).then(() =>
            navigate(-1)
          ),
      });
      break;
    case "snooze":
      setDialog({
        dialog: "snooze",
        red: true,
        img: snooze,
        onConfirm: () => {
          setDialog({
            dialog: "pickTime",
            onConfirm: (time) =>
              updateIntroStatus(shRequest.introductionId, STATUSES.BUSY, {
                ...extra,
                ...time,
              }).then(() => navigate(-1)),
          });
          return true;
        },
      });
      break;
    case "markEngaged":
      setDialog({
        dialog: "markEngaged",
        img: engage,
        onConfirm: () =>
          updateIntroStatus(shRequest.introductionId, STATUSES.ENGAGED).then(() => navigate(-1)),
      });
      break;
    case "dismis":
      setDialog({
        dialog: "dismis",
        img: dismiss,
        onConfirm: () =>
          updateIntroStatus(shRequest.introductionId, shRequest.status, {
            initiatorStatus: "",
            responderStatus: "",
          }).then(refresh),
      });
      break;
    case "reassing":
      console.log("reassign");
      setDialog({
        dialog: "reassing",
        isAdmin: true,
        onConfirm: (extra) =>
          declineIntroduction(shRequest.introductionId, extra.declineReason, extra.mmId).then(
            refresh
          ),
      });
      break;
    case IntroductionStatus.ON_HOLD:
      await updateIntroStatus(shRequest.introductionId, STATUSES.CONTACTED, {
        initiatorStatus: "",
        responderStatus: "",
      });
      break;

    case IntroductionStatus.MATCHED:
      await updateIntroStatus(shRequest.introductionId, STATUSES.AVAILABILE);
      break;
    case IntroductionStatus.MM_AVAILABLE:
      await updateIntroStatus(shRequest.introductionId, STATUSES.TAKING_ON);
      break;
    case IntroductionStatus.MM_TAKING_ON:
      await updateIntroStatus(shRequest.introductionId, STATUSES.CONTACTED);
      break;
    case IntroductionStatus.MM_CONNECTED:
      setDialog({
        dialog: "recordAnswers",
        onConfirm: (extra) => {
          if (extra.initiatorStatus == "interested" && extra.responderStatus == "interested") {
            return updateIntroStatus(shRequest.introductionId, STATUSES.ANSWERS_RECORDED).then(
              refresh
            );
          } else if (extra.initiatorStatus == "unmatch" || extra.responderStatus == "unmatch") {
            doAction(shRequest, setDialog, refresh, navigate, "unmatch", extra);
            return true;
          } else {
            setDialog({
              dialog: "chooseOption",
              onConfirm: (type) => {
                type == "unmatch"
                  ? doAction(shRequest, setDialog, refresh, navigate, "unmatch", extra)
                  : setDialog({
                      dialog: "pickTime",
                      onConfirm: (time) =>
                        updateIntroStatus(shRequest.introductionId, STATUSES.BUSY, {
                          ...extra,
                          ...time,
                        }).then(() => navigate(-1)),
                    });
                return true;
              },
            });
            return true;
          }
        },
      });
      break;

    case IntroductionStatus.MM_RECORDED:
    case IntroductionStatus.DATING:
      setDialog({
        dialog: "recordDate",
        img: engage,
        onConfirm: (extra) =>
          updateIntroStatus(shRequest.introductionId, STATUSES.DATING, extra).then(refresh),
      });
  }
  await refresh();
}

export const OptionsMenu = ({
  shRequest,
  refresh,
}: {
  shRequest: Introduction;
  refresh: (v?) => void;
}) => {
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { mobileView } = controller;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState({ dialog: "" } as DialogProps);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key?) => {
    setAnchorEl(null);
    console.log("key", key);
    if (key) doAction(shRequest, setDialog, refresh, navigate, key);
  };

  return (
    <React.Fragment>
      <Grid item xs={mobileView ? 4 : 0}>
      <MDButton
        style={{ width: "100%", height: mobileView ?"64px":null }}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        color="dark"
      >
        <T>holdUnmatch</T>
      </MDButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {shRequest.status != IntroductionStatus.ON_HOLD && (
          <MenuItem onClick={() => handleClose("snooze")}>
            <T>putShidduchOnHold</T>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleClose("unmatch")}>
          <T>endShidduch</T>
        </MenuItem>
      </Menu>
      </Grid>
      <UpdateStatusDialog shRequest={shRequest} dialog={dialog} />
    </React.Fragment>
  );
};

const TimeLeft = ({ shRequest,mobileView }: { shRequest: Introduction,mobileView:boolean }) => {
  let left = Math.floor(
    (new Date(shRequest.mustRespondUntil).getTime() - new Date().getTime()) / (1000 * 60 * 60)
  );
  let overall = Math.floor(
    (new Date(shRequest.mustRespondUntil).getTime() - new Date(shRequest.updated).getTime()) /
      (1000 * 60 * 60)
  );
  let unit = "hours";
  if (overall > 24) {
    left = Math.floor(left / 24);
    overall = Math.floor(overall / 24);
    unit = "days";
  }
  return (
    <Grid container gap={1} alignItems="left" flexDirection={"column"} item xs={mobileView ? 4 : true}>
      <MDTypography variant="body2" color={left > 0 ? "text" : "error"}>
        {left > 0 ? `${left} ${unit} left` : "Overdue"}
      </MDTypography>
      <Grid style={{ width: "100%", height: "25px", border: `1px solid ${colors.grey[400]}`, maxWidth: "150px"}}>
        <div
          style={{
            width: `${Math.min((1 - left / overall) * 100, 100)}%`,
            height: "100%",
            backgroundColor: colors.error.main,
          }}
        />
      </Grid>
    </Grid>
  );
};

const action = (request: Introduction) => {
  let message: any = "",
    color = "error",
    user;

  if (request.statusReasonObj?.initiatorStatus == "busy") {
    message = "pending-hold";
    user = request.initiatorUser?.firstName;
  } else if (request.statusReasonObj?.initiatorStatus == "unmatch") {
    message = "pending-unmatch";
    user = request.initiatorUser?.firstName;
  } else if (request.statusReasonObj?.responderStatus == "busy") {
    message = "pending-hold";
    user = request.responderUser?.firstName;
  } else if (request.statusReasonObj?.responderStatus == "unmatch") {
    message = "pending-unmatch";
    user = request.responderUser?.firstName;
  } else if (request.status == IntroductionStatus.ON_HOLD) {
    message = "hold-status";
  } else {
    message = <T>actionRequired{request.status}</T>;
    color = "text";
  }
  return (
    <MDTypography variant="h4" fontWeight="medium" color={color}>
      <T
        params={{
          user,
        }}
      >
        {message}
      </T>
    </MDTypography>
  );
};

export const RequiredAction = ({
  shRequest,
  refresh,
  openReassign,
  setOpenReassign,
}: {
  shRequest: Introduction;
  refresh: (v?) => void;
  openReassign: boolean;
  setOpenReassign: Function;
}) => {
  const [controller, dispath] = useMaterialUIController();
  const { currentRole, mobileView } = controller;

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [dialog, setDialog] = React.useState({ dialog: "" } as DialogProps);

  useEffect(() => {
    console.log("openReassign", openReassign);
    if (openReassign) {
      doAction(shRequest, setDialog, refresh, navigate, "reassing");
      setOpenReassign(false);
    }
  }, [openReassign]);

  const loadingButton = (title) =>
    loading ? <CircularProgress size={20} color="inherit" /> : <T>{title}</T>;

  //   if (loading) return;
  //   setLoading(true);

  const _doAction = async (status: string | IntroductionStatus, extra?: any) => {
    if (!shRequest.boUser && currentRole.hasAccess("7.2", PermissionType.Manager)) {
      status = "reassing";
    }
    if (loading) return;
    setLoading(true);
    await doAction(shRequest, setDialog, refresh, navigate, status, extra);
    setLoading(false);
  };

  return (
    <Grid container spacing={1} alignItems="end">
      {!mobileView && <Grid item>{action(shRequest)}</Grid>}
      {!mobileView && <VerticalDivider />}
      {![IntroductionStatus.DATING, IntroductionStatus.MM_RECORDED].includes(shRequest.status) &&
        !getSetting(SettingName.SystemShutdown, false) && <TimeLeft shRequest={shRequest} mobileView={mobileView} />}
      {shRequest.status < IntroductionStatus.MM_TAKING_ON && (
        <Grid item xs={mobileView ? 4 : 0}>
          <MDButton
            style={{ width: mobileView ? "100%" : null, height: mobileView ?"64px":null  }}
            color="error"
            onClick={() =>
              shRequest.status == IntroductionStatus.MATCHED
                ? setCurrentDialog(dispath, DialogType.SnoozeSME)
                : _doAction("decline")
            }
          >
            {loadingButton("declineButton" + shRequest.status)}
          </MDButton>
        </Grid>
      )}

      {[IntroductionStatus.DATING, IntroductionStatus.MM_RECORDED].includes(shRequest.status) && (
        <Grid item xs={mobileView ? 4 : 0}>
          <MDButton
            style={{ width: mobileView ? "100%" : null, gap: "10px",  height: mobileView ?"64px":null }}
            color="engaged"
            onClick={() => _doAction("markEngaged")}
          >
            <T>markEngaged</T>
            <img src={RingsSvg} height="20px" />
          </MDButton>
        </Grid>
      )}

      <Grid item xs={mobileView ? 4 : 0}>
        <MDButton
          color="primary"
          onClick={() => _doAction(shRequest.status)}
          style={{ width: mobileView ? "100%" : null ,  height: mobileView ?"64px":null }}
        >
          {loadingButton("actionButton" + shRequest.status)}
        </MDButton>
      </Grid>
      {shRequest.status >= IntroductionStatus.MM_CONNECTED && (
        <OptionsMenu shRequest={shRequest} refresh={refresh} />
      )}
      <UpdateStatusDialog shRequest={shRequest} dialog={dialog} />

      <SnoozeDialog
        mmId={shRequest.matchmakerId}
        handleConfirm={(days) => {
          updateMM(shRequest.matchmakerId, {
            busy_until: new Date(Date.now() + days!).toISOString(),
            status: MMStatuses.notAvailable,
          });

          return declineIntroduction(shRequest.introductionId, "Not avialable").then(() =>
            navigate(-1)
          );
        }}
      />
    </Grid>
  );
};
