import { Dialog } from "@mui/material";
import React from "react";
import { avatarUrl } from "utils/globalsUtils";
import { STATUSES } from "utils/matchmakerUtils";

export const ImageDialog = ({ url,size }: { url: string; size?: string }) => {
  
  const [showImageDialog, setShowImageDialog] = React.useState("");


  return (
    <React.Fragment>
      <img
        src={url}
        onClick={() => setShowImageDialog(url)}

        style={{
          fit: "cover",
          crop: "fill",
          objectFit: "cover",
          width: size || "30px",
          height: size || "30px",
        } as any}
      />
      <Dialog
        open={!!showImageDialog}
        onClose={() => setShowImageDialog("")}
        fullWidth
        maxWidth="md"
      >
        <img
          src={url}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Dialog>
    </React.Fragment>
  );
};
