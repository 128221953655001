import React, { useState, useEffect } from "react";
import {
  TextField,
  Autocomplete,
  Checkbox,
  Chip,
  Link,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CopyTranslation from "./copyTranslationModal";
import {
  getTranslationsByLetterDO,
  getTranslationsByInputDO,
  updateTranslationValuesDO,
  deployTranslationsDO,
  getTranslationsByPositionsDO,
  updateLangValueDO
} from "utils/translateUtils";
import { postLogDO, getLogForDeployDO, updateStatusDO } from "utils/logsUtils";
import { postNewAdminTaskDO } from "utils/adminUtils";
import { useMaterialUIController, setCurrentUser } from "context";
import { getAvailablePositionsDO, publishLangsDO } from "utils/translateUtils";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BO/BoRole";
import { getContents } from "utils/contentsUtils";
import { Content } from "models/content/Content";
import { networkGlobals } from "utils/networkUtils";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let letterFromSession = window.sessionStorage.getItem("translation_letter");

function LangTranslations() {

  const langCode: any = useParams()?.langCode;

  const pageNumber = "3.1";

  const columns: TableColumnProps<Content>[] = [
    {
      headerName: "Syntax",
      field: "entityId",
      flex: 2,
      minWidth: 100,
    },
    {
      headerName: "English text",
      field: "txt",
      flex: 4,
      minWidth: 200,
    },
    {
      headerName: "Translation (Double click to edit)",
      field: `${langCode}`,
      flex: 6,
      minWidth: 180,
      editableCB: () => currentRole.hasAccess(pageNumber, PermissionType.Edit),
      renderCell: (params) => params?.row?.[langCode]? params?.row?.[langCode]: (<React.Fragment> <span style={{color:'#c6c6c6'}}>{ params?.row?.txt }</span></React.Fragment>)
    },
    {
      headerName: "Q",
      field: "question",
      width: 70,
      hidden: (params) => !currentRole.hasAccess(pageNumber, PermissionType.ViewAllOptions),
      renderCell: (params) => (
        params.row.question?.id &&
        <Link href={`#/questions/${params.row.question?.id}`} color="prinary">
          Q{params.row.question?.id}
        </Link>
      )
    },
    {
      headerName: "A",
      field: "answer",
      width: 70,
      hidden: (params) => !currentRole.hasAccess(pageNumber, PermissionType.ViewAllOptions),
      valueFormatter: (answer: any) => answer ? `A${answer.id}` : '',
    },
    {
      headerName: "Screen",
      field: "position",
      width: 100,
      renderCell: (params) => showImage(params)
      // currentRole.hasAccess(pageNumber, PermissionType.Manager)
      //   ? positionsDD((v) => updatePosition(params.id, v), params.row)
      //   : renderPostions(params.row),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 2,
      minWidth: 150,
      renderCell: (p) => statusRender(p),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      minWidth: 50,
      renderCell: (params) => rowActions(params.row),
    },
  ];

  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole } = controller;
  const [access, setAccess] = useState(
    Object.keys(currentRole).length !== 0
      ? currentRole?.controls?.find((el) => el?.page === pageNumber)?.access
      : JSON.parse(localStorage.getItem("BORoles") || "{}")?.controls?.find((el) => el?.page === pageNumber)
        ?.access
  );

  const [translations, setTranslations] = useState([] as any[]);
  const [newTranslations, setNewTranslations] = useState([] as any[]);
  const [search, setSearch] = useState("");
  // const [currentPositionForSearch, setCurrentPositionForSearch] = useState();
  const [positions, setPositions] = useState([] as any[]);
  const [host, setHost] = useState(networkGlobals.host);
  const [snackbar, setSnackbar] = useState({
    status: false,
    color: "success",
    icon: "check",
    title: "success",
    text: "",
  });
  const [loader, setLoader] = useState(false);
  const [currentLetter, setCurrentLetter] = useState("");
  const [choosenPosition, setChoosenPosition] = useState();
  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [changesLog, setChangesLog] = useState([] as any[]);
  const [excludeList, setExcludeList] = useState([] as any[]);
  const [refresh, setRefresh] = useState(false);
  const [changes, setChanges] = useState([] as any[]);
  const [canPublish, setCanPublish] = useState(false);

  const preExcludeList = [1263, 1264, 1265];

  useEffect(() => {
    if (currentUser) {
      getPosions();
    }
  }, [currentUser]);

  useEffect(() => {
    if (choosenPosition !== undefined) {
      setSearch("");
      setTranslations([]);
      setNewTranslations([]);
      const positionRes = positions?.find((pos) => pos?.position_name === choosenPosition);
      console.log("positionRes: ", positionRes);
      getTranslationsByPositionsDO(host, "en", positionRes?.id).then((res) => {
        setTranslations(res);
        setNewTranslations(res);
      });
    }
  }, [choosenPosition]);

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const addChildren = (pos, positions, sorted, level) => {
    pos.level = level;
    sorted.push(pos);
    const children = positions.filter((x) => parseInt(x.parent_id) === pos.id);
    children.forEach((child, index) => addChildren(child, positions, sorted, level + 1));
  };

  const getPosions = async () => {
    const positions = await getAvailablePositionsDO(host, "content", currentBrand?.id);
    const sorted = [];
    positions
      .filter((x) => !x.parent_id)
      .forEach((pos, index) => addChildren(pos, positions, sorted, 0));
    setPositions(sorted);
  };

  const getTranslations = async (_host, filters, page) => {
    
    setHost(_host);

    if (!filters["Letter"] && !filters["Search"] && !filters["Status"] && !filters["Screen"] && !filters["Translated"]) {
      return []
    }

    const trs = await getContents({
      page: page,
      letter: filters["Letter"],
      search: filters["Search"],
      status: filters["Status"],
      position: filters["Screen"]?.id,
      translated: filters['Translated'],
      langCode: langCode
    })

    setTranslations(trs);
    setNewTranslations(trs);

    if (trs.find((x) => x.status === 1)) {
      setCanPublish(true);
    }
    return trs;
  };

  const getTranslationsByLetter = async (letter, page, host) => {

    letterFromSession = letter;
    window.sessionStorage.setItem("translation_letter", letter);

    setLoader(true);
    setTranslations([]);
    setNewTranslations([]);
    setCurrentLetter(letter);
    console.log("excludeList: ", excludeList);
    const translations = await getTranslationsByLetterDO(
      host,
      "en",
      letter,
      page,
      currentBrand?.id,
      excludeList
    );
    translations?.sort((a, b) =>
      a.txt.toLowerCase() > b.txt.toLowerCase()
        ? 1
        : b.txt.toLowerCase() > a.txt.toLowerCase()
          ? -1
          : 0
    );
    setTranslations(translations);
    setNewTranslations(translations);
    setLoader(false);
    translations.forEach((item) => (item.id = item.entity_id));
    return translations;
  };

  const getTranslationsByInput = async (letter, page) => {
    setLoader(true);
    setTranslations([]);
    setNewTranslations([]);
    setCurrentLetter(letter);
    const translations = await getTranslationsByInputDO(host, "en", letter, currentBrand?.id, page);
    translations?.sort((a, b) =>
      a.txt.toLowerCase() > b.txt.toLowerCase()
        ? 1
        : b.txt.toLowerCase() > a.txt.toLowerCase()
          ? -1
          : 0
    );
    setTranslations(translations);
    setNewTranslations(translations);
    setLoader(false);
    translations.forEach((item) => (item.id = item.entity_id));
    return translations;
  };

  const buildPositions = (positionsValue) => {
    let returnedPositions = [] as any[];
    if (positionsValue?.length && positionsValue[0] !== null) {
      for (let position of positionsValue) {
        const pos = positions?.find((p) => p?.id == position);
        if (pos !== undefined) {
          returnedPositions.push({ position_name: pos?.position_name });
        }
      }
      // console.log('returnedPositions: ', returnedPositions);
      return returnedPositions;
    }
  };

  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const statusRender = (params) => {
    let color = "";
    let text = "";
    switch (params.row.status) {
      case 0:
        color = "error";
        text = "Saving...";
        break;
      case 1:
        color = "warning";
        text = "Pending Publish";
        break;
      case 2:
        color = "secondary";
        text = "Pending Approval";
        break;
      case 3:
        color = "success";
        text = "Published";
        break;
      case 4:
        color = "primary";
        text = "New";
      default:
    }
    return <MDTypography variant="body2">{text}</MDTypography>
  };

  const renderPostions = (s) => {
    return positions
      ?.filter((x) => s.position.includes(x.id))
      .map((x) => positionTooltip(x, <Chip label={x.position_name} size="small" sx={{ mr: 1 }} />));
  };

  const updatePosition = (id, value) => {
    const index = newTranslations.findIndex((x) => x.entityId === id);
    let positionIndexes = [] as any[];
    for (let val of value) {
      if (val?.id) {
        positionIndexes.push(val?.id);
      } else {
        positionIndexes.push(positions[index]?.id);
      }
    }
    const foundIndex = changes.findIndex((x) => x.key === newTranslations[index].entity_id);
    let newChanges = changes;
    if (foundIndex === -1) {
      newChanges.push({
        key: newTranslations[index].entity_id,
        old: newTranslations[index].txt,
        new: newTranslations[index].pre_validated,
        position: positionIndexes,
      });
    } else {
      newChanges[foundIndex].position = positionIndexes;
    }
    setChanges(newChanges);
  };

  const processRowUpdate = async (newRow, oldRow) => {
    const updateStatus = await updateLangValueDO(host, langCode, newRow?.entityId, newRow?.[langCode], currentBrand?.id);
    console.log('updateStatus: ', updateStatus);
    setSnackbar({
      ...snackbar,
      status: true,
      color: updateStatus === 200 ? "success" : "error",
      icon: updateStatus === 200 ? "check" : "error",
      title: updateStatus === 200 ? "Translation was updated" : "Something was wrong",
    });
  };

  const updateValue = (id, val) => {
    let newChanges = changes;
    const newArray = newTranslations.map((item, i) => {
      if (item.entity_id === id) {
        const foundIndex = changes.findIndex((x) => x.key === item.entity_id);
        if (foundIndex === -1) {
          newChanges.push({
            key: `${item.entity_id}`,
            old: `${item.txt}`,
            new: val,
            position: item.position,
          });
        } else {
          newChanges[foundIndex].new = val;
        }
        return { ...item, pre_validated: val };
      } else {
        return item;
      }
    });
    setChanges(newChanges);
    setNewTranslations(newArray);
    saveUpdatedValue();
  };

  const saveUpdatedValue = async () => {
    console.log("changes: ", changes);
    // setSaveLoader(true)
    const updateStatus = await updateTranslationValuesDO(host, changes, currentBrand?.id);
    if (updateStatus === 201) {
      await postLogDO(
        "dev",
        host,
        "cahnges in translations",
        "translations",
        changes,
        currentUser?.id ? currentUser?.id : 0
      );
      await setSnackbar({
        ...snackbar,
        status: true,
        color: "success",
        icon: "check",
        title: "Content was updated",
      });
      //   await getprviousChanges();
    }
    setSaveLoader(false);
    setCanPublish(true);
    setChanges([]);
  };

  const publish = async () => {
    const publishRes = await publishLangsDO(host, currentBrand?.id, currentBrand?.name, langCode);
    setSnackbar({
      ...snackbar,
      status: true,
      color: publishRes === 200 ? "success" : "error",
      icon: publishRes === 200 ? "check" : "error",
      title: publishRes === 200 ? "Translation was updated" : "Something was wrong",
    });
  };

  const switchersMennager = async () => {
    let sessionUser;
    if (!currentUser?.role) {
      console.log("aaaa");

      sessionUser = JSON.parse(localStorage.getItem("BOCurrentUser") || "{}");
      setCurrentUser(dispatch, JSON.parse(localStorage.getItem("BOCurrentUser") || "{}"));
    }
    console.log("sessionUser: ", sessionUser);
    if (!currentRole.hasAccess(pageNumber, PermissionType.Manager)) {
      setHost("staging");
      setExcludeList(preExcludeList);
      let buildPositions = [...positions];

      if (buildPositions?.length === 0) {
        buildPositions = await getAvailablePositionsDO(networkGlobals.host, "content", currentBrand?.id);
      }

      buildPositions = buildPositions.filter(
        (x) =>
          x.position_name !== "Question" &&
          x.position_name !== "Questionnaire Name" &&
          x.position_name !== "Answer"
      );

      const sorted = [];
      buildPositions
        .filter((x) => !x.parent_id)
        .forEach((pos, index) => addChildren(pos, buildPositions, sorted, 0));

      setPositions(sorted);
    }
  };

  const CustomWidthTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const rowActions = (s) => [
    <CopyTranslation
      translation={s}
      curentUser={currentUser}
      currentBrand={currentBrand}
      setSnackbar={setSnackbar}
      snackbar={snackbar}
    />,
    // <IconButton size="small" disableRipple>
    //   <DeleteTranslationModal
    //     host={host}
    //     translation={s}
    //     currentBrand={currentBrand}
    //     setSnackbar={setSnackbar}
    //     snackbar={snackbar}
    //     getTranslationsByLetter={getTranslationsByLetter}
    //   />
    // </IconButton>,
  ];

  const positionTooltip = (position, child) => {
    return (
      <CustomWidthTooltip
        placement="left-start"
        title={
          <a href={position.img} target="_blank" onClick={(e) => e.stopPropagation()}>
            <img src={position.img} height={500} width="auto" style={{ minWidth: 250 }} />
          </a>
        }
      >
        {child}
      </CustomWidthTooltip>
    );
  };

  const showImage = (param): JSX.Element => {
    // console.log('param: ', param);
    return (
      <></>
    )
  }

  const positionsDD = (onChange, s?): JSX.Element => (

    <Autocomplete
      sx={{ minWidth: 150, mt: s ? 1 : 0 }}
      isOptionEqualToValue={(positions, value) => positions?.position_name === value?.position_name}
      multiple={!!s}
      defaultValue={buildPositions(s?.position)}
      options={positions}
      limitTags={1}
      // onClick={() => {
      // }}
      disableCloseOnSelect
      onChange={(e, v) => onChange(v)}
      getOptionLabel={(positions) => positions?.position_name}
      getOptionDisabled={(option) => option.level !== 2}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={option.position_name}
            style={{ marginLeft: option.level ? 20 : 0, fontWeight: 900 - option.level * 400 }}
          >
            {positionTooltip(option, <img src={option.img} height={25} width="auto" />)}

            {option.level == 2 && (
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
            )}
            {option.position_name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // variant="standard"
          size={s ? "small" : "medium"}
          label="Screen"
          placeholder="More..."
        />
      )}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TablePage
        refresh={refresh}
        title= {`${langCode.toUpperCase()} Content translations`}
        table={{
          columns,
          loadNext: getTranslations,
          processRowUpdate: processRowUpdate,
          pagination: true,
        }}
        actions={{
          title: `${langCode.toUpperCase()} translation actions`,
          page: pageNumber,
          filters: [
            <Autocomplete
              sx={{ width: 150 }}
              defaultValue={networkGlobals.host}
              // onChange={(event, newValue) => {
              //     setHost(newValue);
              // }}
              // disabled
              options={['dev', 'staging', 'prod']}
              renderInput={(params) => <TextField {...params} label="Host" />}
            />,
          ],
          saveActions: [
            {
              label: "Publish",
              variant: "contained",
              // disabled:true,
              role: PermissionType.Publish,
              onClick: publish,
            },
            // {
            //   label: "Ask to publish",
            //   role: PermissionType.AskToPublish,
            //   onClick: askToPublish,
            //   disabled: !canPublish,
            //   variant: "contained",
            // },
          ],
        }}
        filters={[
          {
            label: "Search",
            type: "search",
            placeholder: "Search...",
          },
          {
            label: "Translated",
            options: [
              { key: 1, label: "Yes" },
              { key: 2, label: "No" }
            ],
          },
          {
            label: "Screen",
            renderFilter: positionsDD,
          },
          {
            label: "Status",
            options: [
              { key: 1, label: "Pending Publish" },
              { key: 2, label: "Pending Approval" },
              { key: 4, label: "New" },
            ],
          },
          {
            label: "Letter",
            options: letters.map((l) => ({
              key: l,
              label: l,
            })),
            defaultValue: letterFromSession
              ? { key: letterFromSession, label: letterFromSession }
              : null,
          },
        ]}
      />

      <MDSnackbar
        color={snackbar?.color ? snackbar?.color : "success"}
        icon={snackbar?.icon ? snackbar?.icon : "check"}
        title={snackbar?.title ? snackbar?.title : "success"}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}

export default LangTranslations;
