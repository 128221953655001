import * as React from 'react';
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CircularProgress from '@mui/material/CircularProgress';
import DeActivQuesion from "./deActiveQuesionModal";


export default function Buttons(props) {
    const { updateQuestion, saveNewQuestion, questionID, saveLoader, question, questionForEdit, getQuestion, showAnswers, currentUser } = { ...props };
    const [copyToBrand, setCopyToBrand] = React.useState('');

    const handleCopyToBrand = (event) => {
        setCopyToBrand(event.target.value);
    };

    return (
        <Grid item xs={12} xl={8.8} ml={3}>
            <MDBox pl={0} pb={0} pt={0}>
                <MDBox lineHeight={0}>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={12} xl={3} sx={{ textAlign: "left" }}>
                            {questionID ? (
                                <DeActivQuesion questionForEdit={questionForEdit} getQuestion={getQuestion} />
                            ) : null}
                        </Grid>
                        <Grid item xs={12} xl={2} sx={{ textAlign: "center" }}>
                            {currentUser === 15 ? (
                                <MDButton mr={1} ml={1} pr={1} pl={1} size={'small'} onClick={() => showAnswers()} color="info">show answers</MDButton>
                            ) : null}
                            {/* {!questionID ? (
                                <FormControl fullWidth>
                                    <InputLabel id="CopyToBrand">Copy to brand</InputLabel>
                                    <Select
                                        labelId="CopyToBrandLabel"
                                        id="CopyToBrandSelect"
                                        value={copyToBrand}
                                        label="Copy to brand"
                                        onChange={handleCopyToBrand}
                                    >
                                        <MenuItem value={'alove'}>Alove</MenuItem>
                                        <MenuItem value={'zuug'}>Zuug</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : null} */}
                        </Grid>
                        <Grid item xs={12} xl={1} ></Grid>
                        <Grid item xs={12} xl={1} sx={{ textAlign: "right" }}>
                            {questionID ?
                                saveLoader ? (
                                    <CircularProgress color="info" size={25} thickness={5} />
                                ) : (
                                    <MDButton mr={1} ml={1} pr={1} pl={1} size={'small'} onClick={() => updateQuestion()} color="info">update</MDButton>
                                )
                                : saveLoader ?( <CircularProgress color="info" size={25} thickness={5} />):(
                                    <MDButton mr={1} ml={1} pr={1} pl={1} size={'small'} onClick={() => saveNewQuestion()} color="info">Save</MDButton>
                                )}
                        </Grid>
                        <Grid item xs={12} xl={2} sx={{ textAlign: "right" }}>
                            <MDButton mr={1} ml={1} pr={1} pl={1} size={'small'} disabled variant="outlined" color="dark">Preview</MDButton>
                        </Grid>
                        <Grid item xs={12} xl={3} sx={{ textAlign: "right" }}>
                            <MDButton mr={1} ml={1} pr={1} pl={1} size={'small'} variant="outlined" color="dark">Publish for Approval</MDButton>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </Grid>
    )
}