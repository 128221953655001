import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getShidduchRequests, shouldColorBackground } from "utils/matchmakerUtils";
import { useNavigate } from "react-router-dom";
import { Box, List, ListItem } from "@mui/material";
import { columns } from "./components/requestItem";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { getMMList } from "utils/matchmakerUtils";
import { getAdminStats } from "utils/matchmakerUtils";
import { TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BO/BoRole";
import { snakeCaseToCapitalizedWords, tr } from "utils/globalsUtils";
import { IntroductionStatus } from "models/introduction/Introduction";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

let navigate;

const action = (request) => {
  if (shouldColorBackground(request)) {
    request.hide = true;
    return;
  }
  navigate(`/shidduch-requests/${request.introductionId}`, { state: { request } });
};

function ShidduchRequests() {
  const pageNumber = "7.1";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentRole, currentBrand,mobileView } = controller;
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);
  navigate = useNavigate();

  const [mmList, setMmList] = useState([] as any[]);
  const [stats, setAdminStats] = useState({
    groupByMM: 0,
    introTotal: 0,
    monthlyAvg: 0,
    warning: 0,
    groupByStatus: {},
  });

  useEffect(() => {
    if (!isAdmin) return;
    getAdminStats().then((s) => {
      if (!s?.introTotal) return;
      s.groupByStatus["warning"] = s.warning;
      s.groupByStatus["all"] = s.introTotal;
      s.groupByMM = (
        Object.values(s.groupByMM as any[]).reduce((a, b) => a + b, 0) /
        Math.max(Object.values(s.groupByMM || {}).length, 1)
      ).toFixed(2);
      setAdminStats(s);
    });

  }, [currentBrand]);

  const loadMM = async (v) => {

    getMMList({
      user:v
    }).then((list) => {
      setMmList(list.filter((x) => x));
    });
  }

  const getShRequests = async (host, filters, page) => {
    let allRequests = await getShidduchRequests(
      page++,
      filters["status"] || "",
      filters["date"]?.["before"] || "",
      filters["date"]?.["after"] || "",
      filters["matchmaker"] || "",
      filters["shidduchId"] || ""
    );
    allRequests?.sort((a, b) => (a.status == IntroductionStatus.MATCHED ? -1 : 1));
    allRequests?.forEach((r) => (r.id = r.introductionId));
    return allRequests;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          "& .urgent": {
            color: "#F4357A",
          },
          "& .hidden": {
            color: "transparent !important",
          },
          "& .hidden::selection": {
            color: "transparent !important",
          },
        }}
      >
        <TablePage
          title="requestsList"
          table={{
            columns: columns(isAdmin, action),
            loadNext: getShRequests,
            pagination: true,
            lineClick: (row) => action(row.row),
            rowCount: stats.introTotal,
          }}
          filters={[
            {
              label: "shidduchId",
              type: "search",
            },
            {
              label: "status",
              featured: mobileView,
              options: Object.values(STATUSES)
                .filter((x) => !isNaN(Number(x)))
                .map((key) => ({
                  key,
                  label: `${snakeCaseToCapitalizedWords(STATUSES[key])} ${
                    isAdmin ? "(" + (stats.groupByStatus[key] || 0) + ")" :""
                  }`,
                })),
            },

            {
              label: "matchmaker",
              hidden: !isAdmin,
              onInputChange: (e) => e.target.value?.length > 2 ? loadMM(e.target.value) : null,
              options: mmList.map((x) => ({
                key: x.id,
                label: x.first_name + " " + x.last_name,
              })),
            },
            {
              label: "date",
              type: "date",
            },
          ]}
          stats={
            isAdmin
              ? {
                  title: "requestsStats",
                  fields: [
                    {
                      name: "introTotal",
                      title: "totalRequest",
                      color: "primary",
                      filterLabel: "status",
                      filterValue: "",
                    },
                    {
                      name: "monthlyAvg",
                      title: "monthlyAvg",
                    },
                    {
                      name: "groupByMM",
                      title: "matchmakerAvg",
                    },
                    {
                      name: "warning",
                      title: "warning",
                      color: "error",
                      filterLabel: "status",
                      filterValue: "warning",
                    },
                  ],
                  stats,
                }
              : undefined
          }
        />
      </Box>
    </DashboardLayout>
  );
}

export default ShidduchRequests;
