import { capitalize } from "@mui/material";
import { Statusable } from "models/BO/BoUser";

export enum PredictorRelationLevel {
  Low = 0,
  Mid = 1,
  High = 2,
}

export enum Predictor {
  anxietyDimension = 0,
  avoidanceDimension = 1,
  intriguing = 2,
  emotionalStability = 3,
  ambition = 4,
  agreeableness = 5,
}

export class PredictorRelation {
  id: string;
  predictor1: Predictor ;
  level1: PredictorRelationLevel;
  predictor2: Predictor;
  level2: PredictorRelationLevel;
  grade: number;
}
