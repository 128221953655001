import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { postRoleDO, getRoleDO } from 'utils/rolesUtils';
import { getSystemUsersNamesDO } from 'utils/systemUsersUtils';
import { Card, Grid, Autocomplete, Divider, TextField, CircularProgress, IconButton } from "@mui/material";
import { saveNewQuestionnaire } from './../../../utils/questionsUtils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddQuestionnaireModal(props) {
    const { snackbar, setSnackbar, currentBrand, currentUser, getAndPrepareTableData } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [data, setData] = useState({
        name: '',
        description: '',
        version: 1,
        batch_type_id: '',
    })

    const [error, setError] = useState({
        name: false,
        description: false,
        version: false,
    })

    const [batchTypeOptions] = useState([
        { id: 1, name: 'OnBoarding' },
        { id: 2, name: 'Questionnaire' },
        { id: 3, name: 'BreakTheIce' },
        { id: 4, name: 'LoraChat' },
        { id: 5, name: 'LoraChatCollapsable' }
    ]);

    const [batchType, setBatchType] = useState({ id: 1, name: 'OnBoarding' });

    const handleClickOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const addQuestionnaire = async () => {
        console.log('data: ', data);
        setError({ ...data, name: false, description: false, version: false });

        if (!data?.name && !data?.description && !data?.version) {
            setError({ ...data, name: true, description: true, version: true });
        } else if (!data?.name && !data?.description) {
            setError({ ...data, name: true, description: true });
        } else if (!data?.name) {
            setError({ ...data, name: true });
        }

        if (data?.name && data?.description && data?.version) {
            console.log('valid');
            const saveStatus = await saveNewQuestionnaire('dev', data, currentBrand, currentUser, batchType?.id);
            console.log('saveStatus: ', saveStatus);
            if (saveStatus === 201) {
                handleClose();
                getAndPrepareTableData();
                setSnackbar({ ...snackbar, status: true, title: 'Questionniare was saved', color: 'success', icon: 'check' });
                setData({...data, name:'', description:''})
            }
        } else {
            // Display an error message or handle validation feedback
            console.error('Please fill in all required fields and select at least one user.');
            setSnackbar({ ...snackbar, status: true, title: 'something happened on save questionniare', color: 'error', icon: 'check' });
        }
    }

    return (
        <React.Fragment>
            <MDButton mr={1} pr={1} color="secondary" onClick={handleClickOpen}>
                Questionnaire
            </MDButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Questionnaire
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >

                    <MDInput
                        label="Display name"
                        placeholder="Display name"
                        value={data?.name}
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        // fullWidth
                        onChange={(e) => setData({ ...data, name: e?.target?.value })}
                        error={error?.name}
                    />

                    <MDInput
                        label="Description"
                        value={data?.description}
                        placeholder="Description"
                        onChange={(e) => setData({ ...data, description: e?.target?.value })}
                        fullWidth
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        error={error?.description}
                    />
                    <MDInput
                        label="Version"
                        value={data?.version}
                        placeholder="Version"
                        size="small"
                        // rows="3"
                        onChange={(e) => setData({ ...data, version: e?.target?.value })}
                        fullWidth
                        sx={{ width: 300, mb: 2 }}
                        error={error?.version}
                    />

                    <Autocomplete
                        disableClearable
                        value={batchType}
                        onChange={(event, newValue) => {
                            setBatchType(newValue);
                        }}
                        size='small'
                        // options={['mobile_app', 'brands_assets', 'brands_colors', 'algorithm', 'payments', 'app_features']}
                        isOptionEqualToValue={(positions, value) => positions.name === value.name}
                        getOptionLabel={(positions) => positions.name}
                        options={batchTypeOptions}
                        renderInput={(params) => <TextField {...params} label="Type" />}
                    />
                </DialogContent>
                <DialogActions><Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            // color={'info'}
                            fullWidth
                            size="small"
                            onClick={() => handleClose()}
                        >
                            cancel
                        </MDButton>
                        {/* <MDTypography variant="button" color="text" fontWeight="regular">
                                Pending
                            </MDTypography> */}
                    </Grid>
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            color={'info'}
                            fullWidth
                            size="small"
                            onClick={() => addQuestionnaire()}
                            disabled={data?.name && data?.description && data?.version ? false : true}
                        >
                            Add
                        </MDButton>
                    </Grid>
                </Grid>
                    {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment >
    );
}