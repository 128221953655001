import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid } from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { updateRoleDo } from 'utils/rolesUtils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function EditRoleModal(props) {
    const { row, snackbar, setSnackbar, getRoles } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [role, setRole] = useState({
        name: null,
        description: null
    });
    const [errors, setErrors] = useState({
        name: false,
        description: false
    });

    useEffect(() => {
        // getRole(row);
        setRole({ ...role, name: row?.row?.role, description: row?.row?.description });
    }, [modalOpen])

    const handleClickOpen = (e) => {
        if (Number.isInteger(row?.id)) {
            setModalOpen(true);
            e.stopPropagation();
        }
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const updateRoleHandle = async () => {
        let errors = {};
        errors.name = role.name === '' ? true : false;
        errors.description = role.description === '' ? true : false;
        setErrors({ ...errors });

        if (!errors.name && !errors.description) {
            const status = await updateRoleDo('dev', role, row?.id);
            if (status === 200) {
                await setSnackbar({ ...snackbar, status: true, color: 'success', icon: 'check', title: 'Role was updated' });
                handleClose();
                getRoles();
            }
        } else {
            // Display an error message or handle validation feedback
            console.error('Please fill in all required fields and select at least one user.');
        }
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}>
                < EditIcon style={{ color: 'black' }}  />
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="edit-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="edit-dialog-title">
                    Edit role - {row?.row?.role}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[700],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >

                    <MDInput
                        placeholder="Role name"
                        value={role?.name}
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        // fullWidth
                        onChange={(e) => setRole({ ...role, name: e.target.value })}
                        error={errors?.name}
                    />

                    <MDInput
                        value={role?.description}
                        placeholder="Description"
                        rows="3"
                        onChange={(e) => setRole({ ...role, description: e.target.value })}
                        multiline
                        fullWidth
                        sx={{ width: 300, mb: 2 }}
                        error={errors?.description}
                    />

                </DialogContent>
                <DialogActions sx={{ margin: 1 }}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={5.9} xl={5.9}>
                            <MDButton
                                variant="outlined"
                                color={'info'}
                                size="small"
                                fullWidth
                                onClick={handleClose}
                            >
                                cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                color={'info'}
                                size='small'
                                fullWidth
                                onClick={updateRoleHandle}
                            >
                                Update
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}