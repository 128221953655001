import { Autocomplete, Card, Grid } from "@mui/material";
import CardItem from "components/alove/CardItem";
import { Comm } from "models/content/Comm";
import { snakeCaseToCapitalizedWords } from "utils/globalsUtils";

const MDInput = require("components/MDInput/index.js").default;

export const PushForm = ({ comm, setComm }: { comm: Comm; setComm: any }) => {
  return (
    <Grid container flexDirection="row" justifyContent="start" alignItems="center" gap={2}>
      <MDInput
            label="Title"
            value={comm.push?.title || ""}
            fullWidth
            multiline
            onChange={(e) => setComm({ ...comm, push: { ...comm.push, title: e.target.value } })}
          />
      <MDInput
            label="Body"
            value={comm.push?.body || ""}
            fullWidth
            multiline
            onChange={(e) => setComm({ ...comm, push: { ...comm.push, body: e.target.value } })}
          />
    </Grid>
  );
};
