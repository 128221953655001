import { Autocomplete, Card, Divider, Grid } from "@mui/material";
import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode";
import { Comm, CommEvent, CommEventsParams, PushTemplate } from "models/content/Comm";
import React, { useEffect, useState } from "react";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { ActionSetting } from "layouts/screens/screenView/components/action";
import { appPages, ScreenModel } from "models/content/Screen";
const MDInput = require("components/MDInput/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const PushWorkflow = ({
  comm,
  setComm,
  screens,
}: {
  comm: Comm;
  setComm: any;
  screens: ScreenModel[];
}) => {
  return (
    <Grid container flexDirection="row" alignItems="center" spacing={1}>
      <Grid item xs={2}>
        <MDTypography variant="body2">App live behavior</MDTypography>
      </Grid>
      <Grid item xs={5}>
        <Autocomplete
          fullWidth
          options={Object.values(PushTemplate)}
          getOptionLabel={(option: PushTemplate) => camelCaseToCapitalizedWords(option)}
          value={(comm.push?.template as PushTemplate) || PushTemplate.None}
          onChange={(e, value) => setComm({ ...comm, push: { ...comm.push, template: value } })}
          renderInput={(params) => <MDInput {...params} label="While app live" />}
        />
      </Grid>
      <Grid item xs={5}>
        {comm.push?.template === PushTemplate.AppScreen && (
          <Autocomplete
            value={comm.push?.popup || ""}
            fullWidth
            freeSolo
            getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
            onChange={(e, o) => setComm({ ...comm, push: { ...comm.push, popup: o } })}
            options={appPages}
            renderInput={(params) => <MDInput {...params} label="Action URL" />}
          />
        )}
        {comm.push?.template == PushTemplate.Popup && (
          <Autocomplete
            value={screens.find((s) => s.id == comm.push?.popup) || null}
            fullWidth
            getOptionLabel={(option: ScreenModel) => option.name}
            onChange={(e, o) => setComm({ ...comm, push: { ...comm.push, popup: o?.id } })}
            options={screens}
            renderInput={(params) => <MDInput {...params} label="Popup Screen" />}
          />
        )}
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid item xs={2}>
        <MDTypography variant="body2">Action Urls</MDTypography>
      </Grid>
      <Grid item xs={10}>
        <ActionSetting
          action={comm.push || {}}
          setAction={(a) => setComm({ ...comm, push: a })}
          screens={screens}
        />
      </Grid>
    </Grid>
  );
};
