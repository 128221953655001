import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { postRoleDO } from 'utils/rolesUtils';
import { getSystemUsersNamesDO } from 'utils/systemUsersUtils';
import { Card, Grid, Autocomplete, Divider, TextField, CircularProgress, IconButton } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AsigUsersToRoleModal() {
    const [modalOpen, setModalOpen] = useState(false);
    const [options, setOptions] = useState([]);
    // const [roleName, setRoleName] = useState('');
    // const [roleNameError, setRoleNameError] = useState(false)
    // const [description, setDescription] = useState('');
    // const [descriptionError, setDescriptionError] = useState(false);
    // const [users, setUsers] = useState('');
    // const [open, setOpen] = useState(false);

    // const loading = open && options.length === 0;

    // React.useEffect(() => {
    //     let active = true;

    //     const fetchRolesData = async () => {
    //         try {
    //             const rolesResult = await getSystemUsersNamesDO('dev');
    //             if (active) {
    //                 setOptions(rolesResult);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching roles data:', error);
    //         }
    //     };

    //     if (open && options.length === 0) {
    //         fetchRolesData();
    //     }

    //     return () => {
    //         active = false;
    //     };
    // }, [open, options]);

    // Reset options when Autocomplete is closed
    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleClickOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const createNewRoleHandle = async () => {
       

    }

    return (
        <React.Fragment>
            <Tooltip title="Asign Users">
                <IconButton>
                    < GroupAddIcon
                        onClick={handleClickOpen}
                    />
                </IconButton>
            </Tooltip>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Asign users
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >

                    
                </DialogContent>
                <DialogActions><Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            // color={'info'}
                            fullWidth
                            onClick={() => handleClose()}
                        >
                            cancel
                        </MDButton>
                        {/* <MDTypography variant="button" color="text" fontWeight="regular">
                                Pending
                            </MDTypography> */}
                    </Grid>
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            color={'info'}
                            fullWidth
                            onClick={() => createNewRoleHandle()}
                        >
                            Asign users
                        </MDButton>
                    </Grid>
                </Grid>
                    {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
