import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AddNewPosition from "./addNewPositionModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditPosition from "./editPosition";
import DeleteDialog from "./deletePosition";
import { getPositionsDO } from "utils/translateUtils";
import { uploadImageDO } from "utils/positionsUtils";
import { postLogDO } from "utils/logsUtils";
import { useMaterialUIController } from "context";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { TablePage } from "components/alove/TablePage";
import { Permission, PermissionType } from "models/BO/BoRole";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

let positions = [];
function Positions() {
  const pageNumber = "3.5";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole } = controller;
  const [where, setPlace] = useState("Content");
  const [snackbar, setSnackbar] = useState({
    status: false,
    color: "success",
    icon: "check",
    title: "Posotion was saved",
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // positions = []
  }, []);

  const columns = [
    {
      field: "position_name",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "preview",
      headerName: "Preview",
      width: 200,
      renderCell: (params) => previewImage(params.row),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      align: "center",
      renderCell: (params) => rowActionsElement(params.row),
    },
  ];

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInput = async (e, position) => {
    const base64Image = await getBase64(e?.target?.files[0]);
    const uploadRes = await uploadImageDO(base64Image, position, e.target.files[0]?.type);
    if (uploadRes === 201) {
      getPosiions();
      setSnackbar({
        ...snackbar,
        status: false,
        icon: "check",
        title: "image was uploaded",
        color: "success",
      });
    }
  };

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const getPosiions = async (host, filter, page) => {
    positions = await getPositionsDO(host, where?.toLowerCase());
    return positions;
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const rowActionsElement = (p) => (
    <Grid container gap={3} alignItems="center" sx={{ height: "100%" }}>
      {currentRole.hasAccess(pageNumber, PermissionType.Edit) && (
        <EditPosition
          position={p}
          getPosiions={getPosiions}
          setSnackbar={setSnackbar}
          snackbar={snackbar}
        />
      )}
      {currentRole.hasAccess(pageNumber, PermissionType.Delete) && (
        <DeleteDialog
          position={p}
          getPosiions={getPosiions}
          setSnackbar={setSnackbar}
          snackbar={snackbar}
          currentUser={currentUser}
        />
      )}

      <MDButton
        component="label"
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        onChange={(e) => handleFileInput(e, p)}
      >
        <VisuallyHiddenInput type="file" />
      </MDButton>
    </Grid>
  );

  const previewImage = (p) => {
    return (
      <CustomWidthTooltip
        placement="right-start"
        title={<img src={p.img} height={250} width="auto" />}
      >
        <img src={p.img} height={50} />
      </CustomWidthTooltip>
    );
  };

  const getTreeDataPath = (data) => {
    let path = [];
    let current = data;
    while (current) {
      path.push(current.position_name);
      current = positions.find((p) => p.id == current.parent_id);
    }
    return path.reverse();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TablePage
        title="Positions"
        table={{
          columns,
          loadNext: getPosiions,
          getTreeDataPath,
        }}
        actions={{
          title: "Positions Actions",
          page: pageNumber,
          addActions: [
            {
              role: PermissionType.AddNew,
              element: (
                <AddNewPosition
                  setSnackbar={setSnackbar}
                  snackbar={snackbar}
                  getPosiions={getPosiions}
                />
              ),
            },
          ],
        }}
      />

      <MDSnackbar
        color={snackbar?.color}
        icon={snackbar?.icon}
        title={snackbar?.title}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}

export default Positions;
