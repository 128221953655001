import { Card, Grid, Autocomplete } from "@mui/material";
import { Comm, CommEvent, CommEventsParams, EmailTemplate } from "models/content/Comm";
import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode";
import { useState, useEffect } from "react";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { camelCaseToCapitalizedWords, snakeCaseToCapitalizedWords } from "utils/globalsUtils";

const MDInput = require("components/MDInput/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const CommHeader = ({ comm, setComm }: { comm: Comm; setComm: any }) => {
  const [categories, setCategories] = useState<GeneralCode[]>([]);
  useEffect(() => {
    getGeneralCodes(GeneralCodeType.EmailCategory).then((res) => {
      if (res) {
        setCategories(res);
      }
    });
  }, []);
  return (
    <Card style={{height: "125px" }}>
      <Grid
        container
        flexDirection="row"
        justifyContent="start"
        alignItems="center"
        px={4}
        columnSpacing={3}
        style={{ height: "100%" }}
      >
        <Grid item xs={4}>
          <Autocomplete
            value={comm.event || CommEvent.None}
            fullWidth
            disableClearable
            getOptionLabel={(option: string) => camelCaseToCapitalizedWords(option)}
            onChange={(e, o) => setComm({ ...comm, event: o })}
            options={Object.values(CommEvent).filter((key) => isNaN(Number(key)))}
            renderInput={(params) => <MDInput {...params} label="Trigger Event" />}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            value={comm.param || []}
            fullWidth
            multiple
            disableClearable
            getOptionLabel={(option: string) =>
              (CommEventsParams[comm.event || ""] || []).find((p) => p.value == option)?.label || ""
            }
            onChange={(e, o) => setComm({ ...comm, param: o })}
            options={(CommEventsParams[comm.event || ""] || []).map((p) => p.value)}
            renderInput={(params) => <MDInput {...params} label="Param" />}
          />
        </Grid>

        
        <Grid item xs={4}>
          <Autocomplete
            fullWidth
            options={categories}
            getOptionLabel={(option: GeneralCode) => option.name}
            value={categories.find((c) => c.id === comm.categoryId) || null}
            onChange={(e, value) => setComm({ ...comm, categoryId: value?.id })}
            renderInput={(params) => <MDInput {...params} label="Category" />}
          />

          </Grid>
      </Grid>
    </Card>
  );
};
