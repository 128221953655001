import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ItemPageContainer from "layouts/itemPageContainer";
import { getBrandByNmeDO, getContactByBrandDO } from "utils/brandsUtils";
import { getSystemUsersBYBrandDO, getEndUsersBYBrandDO } from "utils/systemUsersUtils";
import CardItem from "components/alove/CardItem";
import { formatDate } from "utils/globalsUtils";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { BoUser } from "models/BO/BoUser";
import { SystemUsersTable } from "layouts/itemPageContainer/userItem/systemUsersTable";
import { ContactsTable } from "layouts/itemPageContainer/userItem/contactsTable";
import { updateContactDO, postNewRoleDO, updateBrandStatusDO, getCountTickets } from "utils/brandsUtils";
import { useMaterialUIController } from "context";
import { Brand } from "models/BO/Brand";
import { PermissionType } from "models/BO/BoRole";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;

const host: string = 'dev';

function ViewBrand() {

    const pageNumber = "1.1";

    const [controller, dispatch] = useMaterialUIController();
    const { currentRole } = controller;
    const [snackbar, setSnackbar] = useState({
        status: false,
        icon: "error",
        color: "error",
        title: "",
    });
    const [inputErrors, setInputErrors] = useState({});
    const [brand, setBrand] = useState({} as Brand);
    const [systemUsers, setUsers] = useState([]);
    const [endUsers, setEndUsers] = useState([]);
    const [contacts, setContacts] = useState<{ id: number, first_name: string; last_name: string; email: string; phone: string; position: string; brand_id: number; }[]>([]);

    const [loader, setLoader] = useState(true);
    const [saveLoader, setSaveLoader] = useState(false);

    const [userData, setUserData] = useState(new BoUser());
    const [brandTickets, setBrandTickets] = useState(0);

    const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });
    let { brandName } = useParams();

    useEffect(() => {
        getData();
    }, []);

    const addNewContact = async () => {
        const newContact = {
            function: 'Function',
            first_name: 'First name',
            last_name: 'Last name',
            email: '',
            phone: '',
            position: '',
            brand: brand?.id,
        };

        const postRes = await postNewRoleDO('dev', newContact);
        getData();
    }

    const getData = async () => {
        let currentBrand: any = await getBrandByNmeDO(host, brandName);

        setBrand(Object.assign(new Brand(), currentBrand[0]));

        const endUsers: any = await getEndUsersBYBrandDO('prod', currentBrand[0]?.id);
        setEndUsers(endUsers[0]?.count ? endUsers[0]?.count : 0);

        const systemUsers: any = await getSystemUsersBYBrandDO('dev', currentBrand[0]?.id);
        setUsers(systemUsers);
        setContacts(await getContactByBrandDO(host, currentBrand[0]?.id) as any);

        const openTickets: any = await getCountTickets(host, currentBrand[0]?.id);
        setBrandTickets(openTickets[0]?.tickets);
    }

    const changeBrandStatus = async (status: any) => {
        const newStatus = status === 'Active' ? 1 : 0;
        const updateStatus = await updateBrandStatusDO('dev', newStatus, brand?.id);
        console.log('updateStatus: ', updateStatus);
        getData();
        setSnackbar({
            status: true,
            color: updateStatus === 200 ? "success" : "error",
            icon: updateStatus === 200 ? "check" : "error",
            title: updateStatus === 200 ? "Contact was updated" : "Something was wrong",
        });
    };

    const update = () => { };

    const setStatus = (status, action, reason) => { };

    return (
        <DashboardLayout>
            <DashboardNavbar pageTitle={userData.first_name} />
            {/* <DeactivateDialog
                title="deactivateDialogTitle"
                content="deactivateDialogContent"
                onSubmit={() => { }}
                reasonLabel="deactivateReason"
                confirm="Deactivate User"
            /> */}
            <ItemPageContainer
                brandLogoIMG={brand?.avatar}
                navItems={[
                    { label: "Contacts" },
                    { label: "System users", ref: "personal" },
                ]}
                actions={{
                    itemName: brandName ? brandName : '',
                    itemNumber: `Brand #: ${brand?.id ? brand?.id : 0}`,
                    actionsTitle: "Brand Actions",
                    actions: [
                    ],
                }}
                itemStatus={{
                    title: "Brand Status",
                    changeStatus: changeBrandStatus,
                    item: brand,
                    isManager: currentRole.hasAccess(pageNumber, PermissionType.Manager),
                }}
                itemHeader={
                    <SMEHeader
                        title="Brand Details"
                        showImage={false}
                        formData={userData}
                        indications={[
                            {
                                title: "Name:",
                                value: `${brandName}`
                            },
                            {
                                title: "Created:",
                                value: formatDate(brand[0]?.created),
                            },
                            {
                                title: "Last Update:",
                                value: formatDate(brand[0]?.updated),
                            },
                            {
                                title: "End Users:",
                                value: `${endUsers}`,
                            },
                            {
                                title: "Pending Content:",
                                value: 0,
                                color: '#F4357A'
                            },
                            {
                                title: "Open Tickets:",
                                value: brandTickets,
                                color: '#F4357A'
                            }
                        ]}
                        saveProfile={update}
                    />
                }
            >
                <CardItem title="Contacts" defaultOpen id="contacts" addButton={true} addNewContact={addNewContact}>
                    <ContactsTable contacts={contacts} setSnackbar={setSnackbar} snackbar={snackbar} getData={getData} />
                </CardItem>

                <CardItem defaultOpen title={brandName + " System Users"} id="brandSystemUsers">
                    <SystemUsersTable user={systemUsers} />
                </CardItem>
                {/* <CardItem title="Notifications" id="notifications">
                    <NotificationsSettings />
                </CardItem> */}
            </ItemPageContainer>

            <MDSnackbar
                color={snackbar?.color}
                icon={snackbar?.icon}
                title={snackbar?.title}
                content=""
                dateTime=""
                open={snackbar.status}
                onClose={closeSnackBar}
                close={closeSnackBar}
            />
        </DashboardLayout>
    );
}

export default ViewBrand;
