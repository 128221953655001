import { DataGrid, GridColDef, } from "@mui/x-data-grid";
import { formatDateTime, formatDate } from "utils/globalsUtils";
import { useNavigate } from "react-router-dom";

export const SystemUsersTable = ({ user }) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      headerName: "First Name",
      field: "name",
      width: 150
      // flex: 1,
    },
    {
      headerName: "Last Name",
      field: "lastname",
      width: 150
    },
    {
      headerName: "Last Active",
      field: "lastactive",
      width: 150,
      renderCell: (params) => formatDate(params?.formattedValue),
    },
    // {
    //   headerName: "Role(s)",
    //   field: "role_names",
    //   flex: 1,
    // },
    {
      headerName: "Active",
      field: "is_active",
      width: 150,
      renderCell: (params) => params?.row?.is_active? 'Active': 'Inactive'
      ,
    },
    {
      headerName: "Phone",
      field: "phone",
      width: 150
    },
    {
      headerName: "Email",
      field: "email",
      width: 190
    },
  ];

  return <DataGrid
    rows={user}
    columns={columns}
    hideFooter
    onRowClick={(row) => navigate(`/systemUsers/${row?.id}`)}
  />;
};
