import { Grid, TextField } from "@mui/material";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { addAlert, useMaterialUIController } from "context";
import { create } from "domain";
import { useState } from "react";
import { createNewIntroduction } from "utils/introductionUtils";

export const AddIntoIntroductionDialog = ({refresh}): JSX.Element => {
  const [controller,dispatch] = useMaterialUIController();
  const { currentUser, currentRole } = controller;
  const [form, setForm] = useState({
    initiatorProfileId: "",
    responderProfileId: "",
  });

  const handleSubmit = async () => {
    createNewIntroduction(form).then(() => {
        setForm({
            initiatorProfileId: "",
            responderProfileId: "",
        });
        refresh();
        addAlert(dispatch,"Introduction added successfully");
        });
  };

  return (
    <AloveDialog
      dialogType={DialogType.AddIntroduction}
      dialogTitle="Add Introduction"
      handleConfirm={handleSubmit}
      dialogConfirm="Add"
      valid={!!form.initiatorProfileId && !!form.responderProfileId}
    >
      <Grid container gap={2}>
        <TextField
          label="Initiator Profile ID"
          variant="outlined"
          fullWidth
          value={form.initiatorProfileId}
          onChange={(e) => setForm({ ...form, initiatorProfileId: e.target.value })}
        />
        <TextField
          label="Responder Profile ID"
          variant="outlined"
          fullWidth
          value={form.responderProfileId}
          onChange={(e) => setForm({ ...form, responderProfileId: e.target.value })}
        />
      </Grid>
    </AloveDialog>
  );
};
