import React, { useState, useEffect } from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { postLogDO } from 'utils/logsUtils';
import { addNewTranslationDO } from 'utils/translateUtils';
import { Grid, Autocomplete, TextField, IconButton } from "@mui/material";
import { postNewAdminTaskDO } from 'utils/adminUtils';
import { useMaterialUIController } from "context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddNewTrasnlation(props) {

    const [controller, dispatch] = useMaterialUIController();
    const { currentBrand } = controller;
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setRoleName] = useState('');
    const [nameError, setRoleNameError] = useState(false)
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [currentPositions, setCurrentPositions] = useState([]);

    const { positions, setSnackbar, snackbar, getTranslationsByLetter, currentUser, host, access } = props;
    
    const handleClickOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setRoleName('');
        setDescription('');
        setModalOpen(false);
    };

    const addNewTranslationHandle = async () => {
        if (!name) {
            setRoleNameError(true);
        } else {
            setRoleNameError(false);
        }

        if (!description) {
            setDescriptionError(true);
        } else {
            setDescriptionError(false);
        }

        if (name && description) {

            let positionIndexes = [];
            for (let position of currentPositions) {
                positionIndexes.push(position?.id)
            }
            const status = await addNewTranslationDO(name, description, positionIndexes, currentUser?.id ? currentUser?.id : 0, host, currentBrand?.id);
            const logData = [{
                type: 'new translition',
                name,
                description,
                positions: positionIndexes
            }];
            if (status === 201) {
                // await postLogDO(host, 'new translition value', 'translations', logData, currentUser?.id ? currentUser?.id : 0);
                console.log('-=-=-=-after=-=-=-=-');
                setRoleName('');
                setDescription('');
                setModalOpen(false);
                setSnackbar({ ...snackbar, status: true, title:'New translation' });
                getTranslationsByLetter(name[0],0,host)
            }
            handleClose();
        } else {
            // Display an error message or handle validation feedback
            console.error('Please fill in all required fields and select at least one user.');
        }
    }

    return (
        <React.Fragment>
            <MDButton
                variant="contained"
                color='secondary'
                onClick={handleClickOpen}
            >
                Text
            </MDButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add new
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >

                    <MDInput
                        placeholder="Syntax"
                        value={name}
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        // fullWidth
                        onChange={(e) => setRoleName(e.target.value)}
                        error={nameError}
                    />

                    <MDInput
                        value={description}
                        placeholder="Text"
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        error={descriptionError}
                    />

                    <Autocomplete
                        size="small"
                        isOptionEqualToValue={(positions, value) => positions.position_name === value.position_name}
                        multiple
                        // id="checkboxes-tags-demo"
                        // defaultValue={[{position_name:"Loras chat"}]}
                        options={positions}
                        disableCloseOnSelect
                        onChange={(e, value) => setCurrentPositions(value)}
                        getOptionLabel={(positions) => positions.position_name}
                        renderOption={(props, option, { selected }) =>
                        (
                            <li {...props} key={option.position_name}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                {option.position_name}
                            </li>
                        )
                        }
                        renderInput={(params) => (
                            <TextField {...params}
                                label="Positions" />
                        )}
                    />
                </DialogContent>
                <DialogActions><Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            // color={'info'}
                            fullWidth
                            onClick={() => handleClose()}
                        >
                            cancel
                        </MDButton>
                        {/* <MDTypography variant="button" color="text" fontWeight="regular">
                                Pending
                            </MDTypography> */}
                    </Grid>
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            color={'info'}
                            fullWidth
                            onClick={() => addNewTranslationHandle()}
                        >
                            Create new
                        </MDButton>
                    </Grid>
                </Grid>
                    {/* <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button> */}
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
