import { Grid, Menu } from "@mui/material";
import { IconButton } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { useMaterialUIController } from "context";
import React from "react";

export const MobileDotMenu = ({ children }) => {
  const [controller] = useMaterialUIController();
  const { mobileView } = controller;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return mobileView ? (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <GridMoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid item container flexDirection="column" gap={2}>
        {children}
        </Grid>
      </Menu>
    </div>
  ) : (
    <Grid
      item
      container
      flexDirection="row"
      gap={1}
      sm={true}
      justifyContent="flex-end"
      alignItems="center"
    >
        {children}
    </Grid>
  );
};
