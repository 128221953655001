import React, { useState, useEffect } from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Tooltip from '@mui/material/Tooltip';
import { postLogDO } from 'utils/logsUtils';
import { deleteTeanslationDO } from 'utils/translateUtils';
import { Grid, Autocomplete, TextField, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useMaterialUIController } from 'context';
import { PermissionType } from 'models/BO/BoRole';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function DeleteTranslationModal(props) {
    const { host, translation, currentBrand, setSnackbar, snackbar, getTranslationsByLetter, currentUser } = props;

    const [controller, dispatch] = useMaterialUIController();
    const { currentRole } = controller;


    const [modalOpen, setModalOpen] = useState(false);

    const handleClickOpen = () => {
        setModalOpen(true);

        /**remove current brand --> you can not copy to same brand */
        // prepareBrand();
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const deleteTranslationHandle = async () => {
        const deleteRes = await deleteTeanslationDO(host, translation, currentBrand?.id);
        console.log('access from delete: ', access);
        console.log('deleteRes: ', deleteRes);
        setModalOpen(false);
        setSnackbar({ ...snackbar, status: true, title:'New translation' });
        getTranslationsByLetter(translation?.entity_id[0],0,host);
    }

    return (
        <React.Fragment>
            <IconButton size="small" disableRipple onClick={handleClickOpen} disabled={!currentRole.hasAccess("3.1", PermissionType.Delete)}>
                <Tooltip title={'Delete translation'}>
                    <DeleteIcon />
                </Tooltip>
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="delete-translation-dialog">
                    Delete translation
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >

                    <MDTypography variant="h5" fontWeight="medium">
                        {translation?.entity_id}
                    </MDTypography>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                fullWidth
                                onClick={() => handleClose()}
                            >
                                cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                color={'info'}
                                fullWidth
                                onClick={() => deleteTranslationHandle()}
                            >
                                Delete
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
