import { Grid, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import { ImageDialog } from "components/alove/ImageDialog";
import { formatDateTime } from "utils/globalsUtils";
import { getCustomerSupportImage } from "utils/networkUtils";

export const Message = ({ message, identityId }) => {
    const isFromCustomerSupport = message.sender === "customerSupport";
    

    return (<Grid container>
        <Grid item sm={6} style={{ display: "flex", justifyContent: "center" ,alignItems: "center"}}>
            <Typography variant="body2" pr={1}>{formatDateTime(message.createdAt)}</Typography>
            <Typography variant="body2" pr={1}>{isFromCustomerSupport ? "Customer Support:" : "User:"}</Typography>
            {isFromCustomerSupport &&
                <Grid item xs={true} />}
                {message.type == "TEXT" &&
            <Typography variant="body2" style={{
                color: isFromCustomerSupport ? "white" : "black",
                backgroundColor: isFromCustomerSupport ? colors.primary.main : colors.lightGrey.main,
                padding: "5px", borderRadius: "5px", margin: "5px"
            }}>
                {message.content}
            </Typography>}
            {identityId && message.type == "PHOTO" &&
            <ImageDialog url={getCustomerSupportImage(message.assetLink,identityId)} size="150px" />}
            {identityId && message.type == "VIDEO" &&
            <video width="150" height="150" controls>
                <source src={getCustomerSupportImage(message.assetLink,identityId)} type="video/mp4" />
            </video>}

            {!isFromCustomerSupport &&
                <Grid item xs={true} />}
        </Grid>
    </Grid>)
}