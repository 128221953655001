import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import MDButton from "components/MDButton";
import { postLogDO } from 'utils/logsUtils';
import { deletePositionDO } from 'utils/positionsUtils';

export default function DeleteDialog(props) {

    const { position, setSnackbar, snackbar, getPosiions, currentUser } = props;
    const [modalOpen, setModalOpen] = React.useState(false);

    const handleClickOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleDelete = async () => {
        const deleteStatus = await deletePositionDO('dev', position.position_name, props?.position?.id, currentUser?.id ? currentUser?.id : 0);
        if (deleteStatus === 201) {
            const logData = [{
                type: 'delete position',
                name: position.position_name
            }];
            // await postLogDO('dev', 'delete position value', 'positions', logData, currentUser?.id ? currentUser?.id : 0);
            setModalOpen(false);
            setSnackbar({ ...snackbar, status: false, icon: 'check', title: 'position was delete', color: 'success' })
            getPosiions();
        }
    }


    return (
        <React.Fragment>
            <IconButton size="small" disableRipple onClick={handleClickOpen}>
                <Tooltip title={'Delete'}>
                    <DeleteIcon />
                </Tooltip>
            </IconButton>
            <Dialog
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Wanna delete?
                </DialogTitle>
                <DialogActions>
                    <MDButton
                        fullWidth
                        variant="outlined"
                        size="small"
                        color={'info'}
                        onClick={handleClose}
                    >
                        Cancel
                    </MDButton>
                    <MDButton
                        fullWidth
                        variant="outlined"
                        size="small"
                        color={'info'}
                        onClick={handleDelete}
                    >
                        Delete
                    </MDButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
