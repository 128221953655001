import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import MDTypography from "components/MDTypography";
import { addNewPositionDO } from 'utils/positionsUtils';
import CircularProgress from '@mui/material/CircularProgress';
import { postLogDO } from 'utils/logsUtils';
import { Grid, IconButton } from "@mui/material";
import { useMaterialUIController } from "context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddNewPosition(props) {
    const [controller, dispatch] = useMaterialUIController();
    const { currentUser } = controller;
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [place, setPlace] = useState('Content');
    const [loader, setLoader] = useState(false);
    const [errorMessageStatus, setErrorMessageStatus] = useState(false)

    const { setSnackbar, snackbar, getPosiions } = props;
    const globalHost = 'dev';

    const handleClickOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
        setErrorMessageStatus(false);
        setName('');
    };

    const handleAddNewPosition = async () => {
        setLoader(true);
        if (!name) {
            setNameError(true);
        } else {
            setNameError(false);
        }

        if (name) {
            const status = await addNewPositionDO(globalHost, name, currentUser?.id ? currentUser?.id : 0, place.toLowerCase());
            if (status === 201) {
                const logData = [{
                    type: 'new position',
                    name
                }];
                await postLogDO('dev', globalHost, 'new position value', 'positions', logData, currentUser?.id ? currentUser?.id : 0);
                setName('');
                setLoader(false);
                setModalOpen(false);
                getPosiions();
                setSnackbar({ ...snackbar, status: false, icon:'check', title:'new position was added', color:'success' })
            }
            else{
                setErrorMessageStatus(true);
                setLoader(false);
            }
        } else {
            // Display an error message or handle validation feedback
            console.error('Please fill in all required fields and select at least one user.');
        }
    }

    return (
        <React.Fragment>
            <MDButton
                // variant="outlined"
                color='secondary'
                onClick={handleClickOpen}
            >
                Position
            </MDButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add new
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <MDInput
                        placeholder="Position name"
                        value={name}
                        size="small"
                        sx={{ width: 300, mb: 2 }}
                        onChange={(e) => setName(e.target.value)}
                        error={nameError}
                    />
                    <Autocomplete
                        disableClearable
                        defaultValue={place}
                        onChange={(event, newValue) => {
                            setPlace(newValue);
                        }}
                        size='small'
                        options={['Content', 'Settings']}
                        renderInput={(params) => <TextField {...params} label="Place" />}
                    />
                    {errorMessageStatus ? (
                        <MDTypography variant="button" fontWeight="medium" sx={{color:"red"}}>
                            This value is dublicate
                        </MDTypography>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={2} xl={2}></Grid>
                        <Grid item xs={4} xl={4}>
                            <MDButton
                                variant="gradient"
                                size="small"
                                fullWidth
                                onClick={() => handleClose()}
                            >
                                cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={4} xl={4}>
                            {!loader ? (
                                <MDButton
                                    variant="gradient"
                                    color={'info'}
                                    size="small"
                                    fullWidth
                                    onClick={() => handleAddNewPosition()}
                                >
                                    Add
                                </MDButton>
                            ) : (<CircularProgress color='info' size={20} />)}
                        </Grid>
                        <Grid item xs={2} xl={2}></Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}