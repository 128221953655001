import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';

import selectData from "./../data/data";

export default function QuestionSettings(props) {
    const { question, setQuestion, questionTypes, factor, responseTypes, questionID, questionForEdit, inputErrors,questionsCategory } = { ...props };
    return (
        <MDBox pt={0} pb={0}>
            <Card>
                <MDBox p={3} lineHeight={1}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} xl={2.4}>
                            <Autocomplete
                                disableClearable
                                disabled={questionID ? true : false}
                                value={ question?.questionType}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(positions, value) => positions?.name === value?.name}
                                onChange={(event, newValue) => {
                                    setQuestion({ ...question, questionType: newValue });
                                }}
                                size="small"
                                // id="controllable-states-demo"
                                // options={selectData.questionType}
                                options={questionTypes}
                                renderInput={(params) => <TextField {...params} label="Question type"
                                error={inputErrors?.questionTypeError} />}
                            />
                        </Grid>
                        {/* {question.questionType?.name === 'Predictor' ? (
                            <>
                                <Grid item xs={12} xl={2.4}>
                                    <Autocomplete
                                        disableClearable
                                        // defaultValue='Factor'
                                        size="small"
                                        getOptionLabel={(option) => option?.name}
                                        options={factor}
                                        renderInput={(params) => <TextField {...params} label="Factor" />}
                                    />
                                </Grid>
                                <Grid item xs={12} xl={2.4}>
                                    <Autocomplete
                                        disableClearable
                                        // defaultValue='Measured Predictor'
                                        size="small"
                                        options={selectData.measuredPredictor}
                                        getOptionLabel={(option) => option?.name}
                                        renderInput={(params) => <TextField {...params} label="Measured Predictor" />}
                                    />
                                </Grid>
                            </>
                        ) : null } */}
                    
                     { !question?.attribute && 
                         <Grid item xs={12} xl={2.4}>
                             <Autocomplete
                                 disableClearable
                                 value={question?.category || {}}
                                 isOptionEqualToValue={(positions, value) => positions?.id == value?.id}
                                 getOptionLabel={(option) => option?.name}
                                 size="small"
                                 onChange={(event, newValue) => {
                                     setQuestion({ ...question, category: newValue });
                                 }}
                                 options={questionsCategory?.filter((category) => category.question_type_id == question?.questionType?.id)}
                                 renderInput={(params) => <TextField {...params} label="Category"
                                 error={inputErrors?.questionCategoryError}
                                  />}
                             />
                         </Grid>}

                        <Grid item xs={12} xl={2.4}>
                            <Autocomplete
                                disableClearable
                                value={question?.layout}
                                isOptionEqualToValue={(positions, value) => positions.name === value.name}
                                size="small"
                                // disableCloseOnSelect
                                disabled={questionID ? true : false}
                                getOptionLabel={(option) => option?.name}
                                options={selectData.layout}
                                onChange={(event, newValue) => {
                                    setQuestion({ ...question, layout: newValue });
                                }}
                                renderInput={(params) => <TextField {...params} label="Layout" />}
                            />
                        </Grid>

                        <Grid item xs={12} xl={3.4}>
                            <Autocomplete
                                disableClearable
                                value={question?.responseType}
                                size="small"
                                options={responseTypes}
                                disabled={questionID ? true : false}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                onChange={(event, newValue) => {
                                    setQuestion({ ...question, responseType: newValue });
                                }}
                                renderInput={(params) => <TextField {...params} label="Response type" 
                                error={inputErrors?.responseTypeError}/>}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        </MDBox>
    )
} 