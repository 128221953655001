import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import AddNewSystemUser from "layouts/systemUsers/addNewSystemUser";
import AddNewQuestion from "layouts/questionnaire/question";
import QuestionnaireList from "layouts/questionnaire/questionnaireList";
import QuestionList from "layouts/questionnaire/questionList";
import BatchList from "layouts/questionnaire/batchList";
import AppSettings from "layouts/appSettings";
import MainDashboard from "layouts/dashboard";
import AdminTasks from "layouts/adminTasks";
import Logs from "layouts/logs";
import ViewRoles from "layouts/roles/viewRoles";
import Translations from "layouts/translations";
import Positions from "layouts/positions";
import DBCopy from "layouts/dbCopy";
import SignInIllustration from "layouts/signIn";
import ShidduchRequests from "layouts/matchmakers/shidduchRequests";
import ShidduchRequestView from "layouts/matchmakers/shidduchRequestView";
import MMProfileAndSettings from "layouts/smeProfiles/profileAndSettings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EmptyPage from "layouts/Empty";
import RolePermissions from "layouts/roles/rolePermissions";
import CustomerSupportTickets from 'layouts/customerSupport/tickets';
import CustomerSupportSettings from 'layouts/customerSupport/settings';
import CustomerSupportViewTicket from 'layouts/customerSupport/viewTicket';
import LanguageSettings from 'layouts/translations/translationsList';
import LangTranslations from 'layouts/translations/LangTranslations';
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";


// Otis Admin PRO React components
// @mui icons
import Icon from "@mui/material/Icon";
import { layouts } from "chart.js";
// Images
import md5 from "md5";
import { DynamicFeed, Layers, NoteAdd, NoteAlt, Notes } from "@mui/icons-material";
import SMEProfiles from "layouts/smeProfiles/profiles";
import ViewSystemUsersList from "layouts/systemUsers/viewUsers";
import ViewSystemUser from "layouts/systemUsers/viewSystemUser";
import ViewEndUsersList from "layouts/endUsers/endUsers";
import ViewEndUser from "layouts/endUsers/viewEndUser";
import ViewBrandsList from "layouts/brands";
import ViewBrand from "layouts/brands/viewBrand";

import RolePermissions2 from "layouts/roles/rolePermissions2";
import { ErrorPage } from "layouts/error/errorPage";
import { AttributesList } from "layouts/questionnaire/attributesList";
import ViewEndUserIntroductions from "layouts/endUsers/endUserIntroductions";
import IntroductionsScreen from "layouts/introductions/Introductions";
import ViewIntroduction from "layouts/introductions/viewIntroduction";
import { ScreensList } from "layouts/screens/screensList";
import { ScreensView } from "layouts/screens/screenView";
import { PredictorRelationsList } from "layouts/predictorRelations/predictorRelationsList";
import { CommsList } from "layouts/comms/commsList";
import { CommsView } from "layouts/comms/viewComm";
import ReportPage from "layouts/reports";
import { SystemSettings } from "layouts/appSettings/system";
import EndUsersMatchScores from "layouts/endUsers/endUserMatchScores";
import { AppFeature } from "utils/settingsUtils";


const MDAvatar = require("components/MDAvatar").default;
const redirect = () => {
  console.log("I'm in function of redirect");
};

const d = new Date();
const today = md5(
  d.getFullYear() +
  "-" +
  ("0" + (d.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + d.getDate()).slice(-2) +
  "T" +
  ("0" + d.getHours()).slice(-2) +
  ":" +
  ("0" + d.getMinutes()).slice(-2)
);

export type MyRoute = {
  type?: string;
  name?: string;
  key?: string;
  icon?: any;
  route?: string;
  feature?: AppFeature;
  component?: any;
  accessNumber?: number;
  collapse?: MyRoute[];
  hide?: boolean;
  href?: string;
  onclick?: any;
  function?: any;
  noCollapse?: boolean;
};

const routes:MyRoute[] = [
  {
    type: "collapse",
    name: "User",
    key: "brooklyn-alice",
    accessNumber: 13,
    // hide:true,
    icon: (
      <MDAvatar
        src={
          "https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
        }
        alt="User"
        size="sm"
      />
    ),
    collapse: [
      // {
      //   name: "My Profile",
      //   key: "my-profile",
      //   route: "/pages/profile/profile-overview",
      //   component: <ProfileOverview />,
      //   accessNumber: -1
      // },
      {
        name: "My Profile",
        key: "my-profile",
        route: "/systemUsers/me",
        component: <ViewSystemUser />,
        accessNumber: 13.1,
      },
      {
        name: "Profile & Settings",
        key: "profileAndSettings",
        icon: <Icon fontSize="medium">se</Icon>,
        route: "/myProfile",
        component: <MMProfileAndSettings />,
        accessNumber: 13.2,
      },
      // {
      //   name: "Settings",
      //   key: "profile-settings",
      //   route: "/pages/account/settings",
      //   component: <Settings />,
      //   accessNumber: -1
      // },
      {
        name: "Logout",
        key: "logout",
        route: "/sign-in",
        // onclick: console.log('aaaaa'),
        // function: redirect(),
        component: <SignInIllustration />,
        accessNumber: 0,
      },
    ],
  },
  { type: "divider", key: "divider-0" },

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    hide: true,
    accessNumber: 0,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        component: <MainDashboard />,
        accessNumber: 0,
      },
    ],
  },
  {
    type: "collapse",
    name: "Brands",
    key: "brands",
    icon: <Layers />,
    accessNumber: 1,
    collapse: [
      {
        name: "Brands",
        key: "list",
        route: "/brands",
        component: <ViewBrandsList />,
        accessNumber: 1.1
      },
      {
        name: "View Brand",
        key: "viewBrand",
        route: "/brands/:brandName",
        component: <ViewBrand />,
        hide: true,
        accessNumber: 1.2
      },
    ],
  },

  {
    type: "collapse",
    name: "Users",
    key: "Users_dashboard",
    icon: <Icon fontSize="medium">groups</Icon>,
    accessNumber: 2,
    collapse: [
      {
        name: "End users",
        key: "endUsers",
        route: "/endUsers",
        component: <ViewEndUsersList />,
        accessNumber: 2.1
      },
      {
        name: "View End user",
        key: "viewEndUsers",
        route: "/endUsers/:id",
        hide: true,
        component: <ViewEndUser />,
        accessNumber: 2.7
      },
      {
        name: "View User Interactions Statistics",
        key: "viewEndUserIntroductions",
        route: "/endUsers/:id/introductions",
        hide: true,
        component: <ViewEndUserIntroductions />,
        accessNumber: 2.8
      },
      {
        name: "View User Match Scores",
        key: "viewEndUserMatchScores",
        route: "/endUsers/:id/matchScores",
        hide: true,
        component: <EndUsersMatchScores />,
        accessNumber: 2.7

      },
      {
        name: "Introductions",
        key: "introductions",
        route: "/introductions",
        component: <IntroductionsScreen />,
        accessNumber: 2.9
      },
      {
        name: "View Introduction",
        key: "viewIntroduction",
        route: "/introductions/:id",
        component: <ViewIntroduction />,
        hide: true,
        accessNumber: 2.11
      },
      {
        name: "Groups",
        key: "groups",
        route: "/groups",
        component: <EmptyPage />,
        accessNumber: 2.2
      },
      {
        name: "System users",
        key: "system_users",
        route: "/systemUsers",
        component: <ViewSystemUsersList />, 
        accessNumber: 2.3
      },

      {
        name: "View System user",
        key: "single_system_users",
        hide: true,
        route: "/systemUsers/:id",
        component: <ViewSystemUser />, 
        accessNumber: 2.6
      },
      {
        name: "Roles",
        key: "roles_permissions",
        route: "/roles",
        component: <ViewRoles />,
        accessNumber: 2.5
      },
      {
        name: "View Role (Permissions)",
        key: "permissions",
        route: "/roles/:id",
        component: <RolePermissions2 />, 
        accessNumber: 2.4,
        hide:true
      },
      // {
      //   name: "Permissions",
      //   key: "permissions",
      //   route: "/roles_old",
      //   hide: true,
      //   component: <RolePermissions />,
      //   // accessNumber: 4.1
      // },
    ],
  },

  {
    type: "collapse",
    name: "Content",
    key: "content",
    icon: <NoteAlt />,
    accessNumber: 3,
    collapse: [
      {
        name: "App content",
        key: "app_content",
        route: "/content",
        component: <Translations />,
        accessNumber: 3.1
      },
  {
        name: "Communications",
        key: "communications",
        route: "/communications",
        component: <CommsList />,
        accessNumber: 3.2
      },
      {
        name: "View Communication",
        key: "communication",
        route: "/communications/:id",
        accessNumber: 3.2,
        component: <CommsView />,
        hide: true,
      },
      {
        name: "Translations list",
        key: "translations_list",
        route: "/translations_list",
        component: <LanguageSettings />,
        accessNumber: 3.3
      },
      {
        name: "Info Screens",
        key: "info_screens",
        route: "/screens",
        component: <ScreensList />,
        accessNumber: 3.4
      },
      {
        name: "View Info Screen",
        key: "info_screen",
        route: "/screens/:id",
        hide: true,
        component: <ScreensView />,
        accessNumber: 3.6
      },
      {
        name: "Positions",
        key: "positions",
        route: "/settings/positions",
        component: <Positions />,
        accessNumber: 3.5
      },
      {
        name: "Lang Translations",
        key: "lang_translations",
        route: "/translations_list/:langCode",
        component: <LangTranslations />,
        accessNumber: 3.7,
        hide:true
      },
    ],
  },

  {
    type: "collapse",
    name: "Questionnaires",
    key: "Questionnaires",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 4,
    collapse: [
      {
        name: "Questionnaires",
        key: "questionnaires",
        route: "/questionnaires",
        component: <QuestionnaireList />,
        accessNumber: 4.2
      },
      {
        name: "View Questionnaire",
        key: "questionList",
        // route: "/questionnaire/questionList",
        route: "/questionnaires/:bname",
        component: <QuestionList />,
        hide: true,
        accessNumber: 4.4
      },
      {
        name: "View Question From Questionnaire",
        key: "questionByQuestionnaire",
        route: "/questionnaires/:bname/:questionID",
        // route: "/applications/data-tables",
        component: <AddNewQuestion />,
        hide: true,
        accessNumber: 4.1
      },
      {
        name: "Questions List",
        key: "questions",
        route: "/questions",
        component: <BatchList />,
        accessNumber: 4.3
      },
      {
        name: "View Question",
        key: "question",
        hide: true,
        route: "/questions/:questionID",
        component: <AddNewQuestion />,
        accessNumber: 4.1
      },
      {
        name: "Attributes List",
        key: "attributes",
        route: "/attributes",
        component: <AttributesList />,
        accessNumber: 4.5
      },
      {
        name: "Predictors",
        key: "predictors",
        route: "/predictors",
        component: <PredictorRelationsList />,
        accessNumber: 4.6
      }
    ],
  },
  {
    type: "collapse",
    name: "Lora",
    key: "lora",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 5,
    collapse: [
      {
        name: "Screen flow mgmt",
        key: "screen_flow_mgmt",
        route: "/screen_flow_mgmt",
        component: <EmptyPage />,
        accessNumber: 5.1
      },
      {
        name: "Content / order",
        key: "content_order",
        route: "/content_order",
        component: <EmptyPage />,
        accessNumber: 5.2
      },
      {
        name: "Settings / algorithm",
        key: "Settings_algorithm",
        route: "/settings_algorithm",
        component: <EmptyPage />,
        accessNumber: 5.3
      },
    ],
  },
  {
    type: "collapse",
    name: "Support Center",
    key: "support_enter",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 6,
    collapse: [
      {
        name: "Customer Support Tickets",
        key: "customer_support_tickets",
        route: "/customerSupportTickets",
        component: <CustomerSupportTickets />,
        accessNumber: 6.1
      },
      {
        name: "Customer Support Settings",
        key: "customer_support_settings",
        route: "/customerSupportSettings",
        component: <CustomerSupportSettings />,
        accessNumber: 6.2
      },
      {
        name: "Customer Support Ticket View",
        key: "customer_support_ticket_view",
        route: "/customerSupportTickets/:ticketID",
        component: <CustomerSupportViewTicket />,
        accessNumber: 6.3,
        hide:true
      },
      
    ],
  },
  {
    type: "collapse",
    name: "SME Marketplace",
    key: "SME_marketplace",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 7,
    collapse: [
      {
        name: "Shidduch Requests",
        key: "shidduchRequests",
        route: "/shidduch-requests",
        component: <ShidduchRequests />,
        accessNumber: 7.1,
      },
      {
        name: "Shidduch Request View",
        key: "shidduchRequestView",
        route: "/shidduch-requests/:id",
        component: <ShidduchRequestView />,
        accessNumber: 7.2,
        hide: true,
      },
      {
        name: "SME Profiles",
        key: "smeProfiles",
        route: "/smeProfiles",
        component: <SMEProfiles />,
        accessNumber: 7.4,
      },
      {
        name: "View SME Profile",
        key: "profileAndSettings",
        icon: <Icon fontSize="medium">se</Icon>,
        route: "/smeProfiles/:id",
        component: <MMProfileAndSettings />,
        hide: true,
        accessNumber: 7.5,
      },
    ],
  },

  {
    type: "collapse",
    name: "Content Center",
    key: "content_center",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 8,
    collapse: [
      {
        name: "List",
        key: "list",
        route: "/list",
        component: <EmptyPage />,
        accessNumber: 8.1
      },
    ],
  },

  {
    type: "collapse",
    name: "Settings",
    key: "settings_new",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 9,
    collapse: [
      {
        name: "General",
        key: "general",
        route: "/appSettings",
        component: <AppSettings />,
        accessNumber: 9.1
      },
      {
        name: "System",
        key: "system",
        route: "/appSettings/system",
        component: <SystemSettings />,
        accessNumber: 9.13
      },
      {
        name: "White labeling",
        key: "White_labeling",
        route: "/white_labeling",
        component: <EmptyPage />,
        accessNumber: 9.2
      },
      {
        name: "Notifications",
        key: "Notifications",
        route: "/notifications",
        component: <EmptyPage />,
        accessNumber: 9.3
      },
      {
        name: "Localization",
        key: "Localization",
        route: "/localization",
        component: <EmptyPage />,
        accessNumber: 9.4
      },
      {
        name: "Triggers",
        key: "Triggers",
        route: "/triggers",
        component: <EmptyPage />,
        accessNumber: 9.5
      },

      {
        name: "Mobile",
        key: "Mobile",
        route: "/mobile",
        component: <EmptyPage />,
        accessNumber: 9.6
      },
      {
        name: "Web management",
        key: "Web management",
        route: "/web_management",
        component: <EmptyPage />,
        accessNumber: 9.7
      },
      {
        name: "DB copy",
        key: "dbCoppy",
        route: "/settings/dbCopy",
        component: <DBCopy />,
        accessNumber: 9.8
      },
      {
        name: "Admin Task",
        key: "adminTask",
        route: "/admin/adminTask",
        component: <AdminTasks />,
        accessNumber: 9.9
      },
      {
        name: "View Logs",
        key: "logs",
        route: "/admin/logs",
        component: <Logs />,
        accessNumber: 9.11
      },
      {
        type: "collapse",
        name: "Link to old Back Office",
        key: "changelog",
        href: `http://a-dmin.ai/backOffice/?id=${today}${(Math.random() + 1)
          .toString(16)
          .substring(9)}`,
        // icon: <Icon fontSize="medium">receipt_long</Icon>,
        noCollapse: true,
        accessNumber: 9.12,
      },
    ]
  },

  {
    type: "collapse",
    name: "Reports",
    key: "Reports",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 10,
    collapse: [
      {
        name: "Users",
        key: "users-report",
        route: "/reports/users",
        component: <ReportPage type="users" />,
        accessNumber: 10.1
      },
      {
        name: "Questions",
        key: "questions-report",
        route: "/reports/questions",
        component: <ReportPage type="questions" />,
        accessNumber: 10.1
      },
      {
        name: "Chats",
        key: "chats-report",
        route: "/reports/chats",
        feature: AppFeature.chat,
        component: <ReportPage type="chats" />,
        accessNumber: 10.1
      },
      {
        name: "Communications",
        key: "communications-report",
        route: "/reports/communications",
        component: <ReportPage type="communications" />,
        accessNumber: 10.1
      },
      {
        name: "Interactions",
        key: "interactions-report",
        route: "/reports/interactions",
        component: <ReportPage  type="interactions" />,
        accessNumber: 10.1
      },
      {
        name: "Customer Support",
        key: "customer-support-report",
        route: "/reports/customerSupport",
        component: <ReportPage type="customer_support" />,
        accessNumber: 10.1
      }
    ],
  },

  {
    type: "collapse",
    name: "Marketing",
    key: "marketing",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 11,
    collapse: [
      {
        name: "Empty2",
        key: "empty2",
        route: "/empty2",
        component: <EmptyPage />,
        accessNumber: 11.1
      },
    ],
  },

  {
    type: "collapse",
    name: "Premium settings",
    key: "premium_settings",
    icon: (
      <Icon fontSize="medium">
        <SettingsApplicationsIcon />
      </Icon>
    ),
    accessNumber: 12,
    collapse: [
      {
        name: "Top level",
        key: "Top_level",
        route: "/top_level",
        component: <EmptyPage />,
        accessNumber: 12.1
      },
      {
        name: "Brand level",
        key: "Brand_level",
        route: "/brand_level",
        component: <EmptyPage />,
        accessNumber: 12.2
      },
    ],
  },

  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          // {
          //   name: "Basic",
          //   key: "basic",
          //   route: "/authentication/sign-in/basic",
          //   component: <SignInBasic />,
          // },
          // {
          //   name: "Cover",
          //   key: "cover",
          //   route: "/authentication/sign-in/cover",
          //   component: <SignInCover />,
          //   accessLevel: 0
          // },
          {
            name: "Illustration",
            key: "illustration",
            route: "/sign-in",
            component: <SignInIllustration />,
          },
          {
            name: "Error",
            key: "error",
            route: "/error",
            component: <ErrorPage />,
          }
        ],
      },
      // ro
    ],
  },
];

export default routes;
