import { Grid } from "@mui/material";

import React from "react";
import { getStatistics } from "utils/enduserUtils";
import { setCurrentDialog, useMaterialUIController } from "context";
import { DialogType } from "components/alove/AloveDialog";
import {
  HeaderIndication,
  HeaderIndicationProps,
} from "layouts/itemPageContainer/components/headerIndication";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { useNavigate } from "react-router-dom";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { PermissionType } from "models/BO/BoRole";

const MDTypography = require("components/MDTypography/index.js").default;

export const IntroStats = ({ profileId, profileName }: { profileId: string, profileName: string }) => {
  const [stats, setStats] = React.useState([] as HeaderIndicationProps[]);
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const  navigate = useNavigate();
  React.useEffect(() => {
    if (!profileId) return;
    getStatistics(profileId).then((res) => {
      setStats(Object.keys(res).map((k) => ({ title: k, value: res[k] })));
    });
  }, [profileId]);

  const format = (title) => {
    let t = camelCaseToCapitalizedWords(title)
    if (t.split(" ").length > 1) {
      t = t.split(" ").map((word,i) => i ? word : word.slice(0,3) + ".").join(" ")
    }
    return t.replace("Me", profileName)
  }
  return (
    <Grid
      container
      display="flex"
      justifyContent="start"
      gap={1}
      flexDirection="row"
    >
      {stats
        .map((indication: HeaderIndicationProps, index) => [
          <HeaderIndication
            title={format(indication.title)}
            value={indication.value}
            index={index}
            key={indication.title}
            onClick={ currentRole.hasAccess("2.7", PermissionType.Manager) ? () => {
              navigate(`introductions?filter=${indication.title}`);
            } : undefined}
          />,
          index != stats.length - 1 && <VerticalDivider height="40px" key={index} />,
        ])
        .flat()}
    </Grid>
  );
};
