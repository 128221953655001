import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { copyRoleDO } from 'utils/rolesUtils';
import { getBrandsDO } from 'utils/brandsUtils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CopyRoleModal(props) {
    const { row, snackbar, setSnackbar, currentUser } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [brands, setBrands] = useState([]);
    const [copyTO, setCopyTO] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getBrands();
    }, []);

    const getBrands = async () => {
        try {
            const brands = await getBrandsDO('dev');
            const index = brands.findIndex((brand) => brand?.id === 100);
            if (index !== -1) brands.splice(index, 1);
            setBrands(brands);
        } catch (error) {
            console.error("Error fetching brands: ", error);
        } finally {
            setLoading(false);
        }
    };

    const handleClickOpen = (e) => {
        setModalOpen(true);
        e.stopPropagation();
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleCheckboxChange = (brandId) => {
        setCopyTO((prev) =>
            prev.includes(brandId)
                ? prev.filter((id) => id !== brandId)
                : [...prev, brandId]
        );
    };

    const copyHandle = async () => {
        try {
            const copyStatus = await copyRoleDO('dev', copyTO, row.id, currentUser?.id);
            if (copyStatus === 201) {
                handleClose();
                setSnackbar({ ...snackbar, status: true, title: 'Role was copied successfully', color: 'success' });
            }
        } catch (error) {
            console.error("Error copying role: ", error);
            setSnackbar({ ...snackbar, status: true, title: 'Error copying role', color: 'error' });
        }
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}>
                <ContentCopyIcon />
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{
                    maxWidth: 400,
                    width: 400,
                    height: 500,
                    maxHeight: 500,
                    position: 'absolute',
                    top: '10vh',
                    left: '40vw'
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Copy {row?.role}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <MDTypography>Loading...</MDTypography>
                    ) : (
                        <Grid container spacing={3} alignItems="center">
                            {brands?.map((brand) => (
                                <React.Fragment key={brand.id}>
                                    <Grid item xs={3} xl={3}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={copyTO.includes(brand.id)}
                                            onChange={() => handleCheckboxChange(brand.id)}
                                        />
                                    </Grid>
                                    <Grid item xs={9} xl={9}>
                                        <MDTypography>
                                            {brand.name}
                                        </MDTypography>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="outlined"
                                size="small"
                                color="info"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={6} xl={6}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                fullWidth
                                disabled={copyTO.length === 0}
                                onClick={copyHandle}
                            >
                                Copy
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
