import React, { useState, useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import MDSnackbar from "components/MDSnackbar";
import CircularProgress from '@mui/material/CircularProgress';
import { postLogDO } from 'utils/logsUtils';
import { useMaterialUIController } from "context";
import { dbCopyDO } from 'utils/dbCopy';

function DBCopy() {

    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, auth, currentUser } = controller;
    const host = 'dev';

    const [table, setTable] = useState('translations')
    const [source, setSource] = useState('dev');
    const [target, setTarget] = useState('staging');
    const [successSB, setSuccessSB] = useState(false);
    const [loader, setLoader] = useState(false)

    const closeSuccessSB = () => setSuccessSB(false);

    console.log('currentUser from appsettings: ', currentUser?.role[0]);

    const copyDB = async () => {
        setLoader(true);
        console.log('incopy db');
        if (source !== target) {
            console.log('in copy');
            const copyResp = await dbCopyDO(table, source, target);
            console.log('copyResp: ', copyResp.status);
            if (copyResp.status === 200) {
                setLoader(false);
            }
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} xl={12}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    DB Copy
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox pt={1} pl={3} pr={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={3} xl={3}>
                                <Autocomplete
                                    disableClearable
                                    defaultValue={table}
                                    onChange={(event, newValue) => {
                                        setTable(newValue);
                                    }}
                                    size='small'
                                    options={['translations']}
                                    renderInput={(params) => <TextField {...params} label="Table" />}
                                />
                            </Grid>
                            <Grid item xs={3} xl={3}>
                                <Autocomplete
                                    disableClearable
                                    defaultValue={source}
                                    onChange={(event, newValue) => {
                                        setSource(newValue);
                                    }}
                                    size='small'
                                    options={['dev', 'staging', 'prod']}
                                    renderInput={(params) => <TextField {...params} label="Source" />}
                                />
                            </Grid>
                            <Grid item xs={3} xl={3}>
                                <Autocomplete
                                    disableClearable
                                    defaultValue={target}
                                    onChange={(event, newValue) => {
                                        setTarget(newValue);
                                    }}
                                    size='small'
                                    options={['dev', 'staging', 'prod']}
                                    renderInput={(params) => <TextField {...params} label="Target" />}
                                />
                            </Grid>
                            <Grid item xs={0.5} xl={0.5}></Grid>
                            <Grid item xs={2} xl={2}>

                                {loader ? (<CircularProgress color="success" size={25} thickness={5} />) : (
                                    <MDButton
                                        fullWidth
                                        //  variant="outlined"
                                        size="small"
                                        color={'success'}
                                        onClick={() => copyDB()}
                                    >
                                        copy
                                    </MDButton>
                                )}
                            </Grid>
                            <Grid item xs={0.5} xl={0.5}></Grid>

                        </Grid>
                    </MDBox>
                    <MDBox pt={1} pl={3} pr={3} lineHeight={1}>
                    </MDBox>
                </Card>
            </MDBox >

            <MDSnackbar
                color="success"
                icon="check"
                title="settings was saved"
                content=""
                dateTime=""
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite
            />
        </DashboardLayout >
    );
}

export default DBCopy;