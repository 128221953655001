import { Alert, Grid } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { addAlert, removeAlert, useMaterialUIController } from "context";
import React, { useEffect } from "react";
import { uniqueBy } from "utils/globalsUtils";
import { networkGlobals } from "utils/networkUtils";
import { T } from "./Translator";

export class AlertModel {
  error?: boolean = true;
  content: string;
}

export const ALoveAlert = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { currentAlerts } = controller;
  const [alerts, setAlerts] = React.useState([] as AlertModel[]);

  useEffect(() => {
    networkGlobals.errorCallbacks.push((error) => {
      addAlert(dispatch, "Error From Server: " + error.message + (error.config?.url ? "; Url: " + error.config?.url : ""), true);
    });
  }, []);

  useEffect(() => {
    if (currentAlerts.length) {
      setAlerts(uniqueBy(currentAlerts, "content"));
      setTimeout(() => {
        removeAlert(dispatch, currentAlerts[0]);
        setAlerts(alerts.slice(1));
      }, 10000);
    }
  }, [currentAlerts]);

  return <Grid container direction="column" gap={1} m={alerts.length ? 1 : 0} item xs={12}>
  {alerts.map((alert, index) => (
    <Alert key={index} severity={alert.error ? "error" : "success"}  variant="filled" style={{  position: 'sticky', 
      top: "1%", zIndex: 1000 }}>

      <T>{alert.content}</T>
    </Alert>
  ))}
  </Grid>
};
