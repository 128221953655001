import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Divider,
  IconButton,
  TextField,
  CircularProgress,
  Stack,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useParams, useNavigate } from "react-router-dom";
import ItemPageContainer from "layouts/itemPageContainer";
import CardItem from "components/alove/CardItem";
import { formatDate } from "utils/globalsUtils";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { addAlert, useMaterialUIController } from "context";
import { Brand } from "models/BO/Brand";
import { PermissionType } from "models/BO/BoRole";
import { Profile } from "models/profile/Profile";
import { PackageDetails } from "layouts/endUsers/viewEndUser/components/package";
import { getByUserId } from "utils/enduserUtils";
import {
  getTicketDO,
  getCustomerSupportStatusesDO,
  updateticketStatusDO,
  getUsersWithBrandsDO,
  getSLASettingsDO,
  getUserIDDO,
  addResponse,
  getTicketResponses,
} from "utils/customerSupportUtils";
import { avatarUrl, formatDateTime } from "utils/globalsUtils";
import { EndUser } from "models/profile/EndUser";
import { getAllUSersDO } from "utils/customerSupportUtils";
import ReAssignModal from "./components/reAssignModal";
import { makeStyles, Modal, Backdrop, Fade } from "@material-ui/core";
import SendIcon from "@mui/icons-material/Send";
import { networkGlobals } from "utils/networkUtils";
import { AppFeature, hasFeature } from "utils/settingsUtils";
import { Message } from "./components/message";
import { ReporterDetails } from "./components/reporterDetails";
import { Ticket } from "models/customer-supports/Ticket";
import { stat } from "fs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

let host: string = "dev";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
}));

function CustomerSupportViewTicket() {
  const pageNumber = "6.3";
  const navigate = useNavigate();
  const { ticketID } = useParams();
  const now = new Date();

  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;

  const [snackbar, setSnackbar] = useState({
    status: false,
    icon: "error",
    color: "error",
    title: "",
  });
  const [brand, setBrand] = useState({} as Brand);
  const [profileData, setProfileData] = useState({} as Profile);
  const [ticket, setTicket] = useState({} as any);
  const [logs, setLogs] = useState([] as any);
  const [loader, setLoader] = useState(false);
  const [newLog, setNewLog] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [userData, setUserData] = useState(new EndUser());
  const [reporteeData, setReporteeData] = useState(new EndUser());
  const [statuses, setStatuses] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({ key: "", value: "" });
  const [reAssignModalStatus, setReAssignModalStatus] = useState(false);
  const [brandUsers, setBrandUsers] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("false");
  const [currentSLA, setCurrentSLA] = useState("");
  const [hasPackage, setHaspackage] = useState(false);

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  useEffect(() => {
    getData();
    getTickeLogs();
    if (hasFeature(AppFeature.premium)) {
      setHaspackage(true);
    }
  }, []);

  const buildEmailLink = () => {
    return `mailto:${userData?.email}?subject=Re: ${ticket?.topic_name} Ticket #${ticketID}&from=support@zuug.app`;
  };

  const getData = async () => {
    host = networkGlobals.host;

    const ticketData: Ticket = await getTicketDO(host, ticketID);

    setTicket(ticketData);

    let brands: any = [];
    if (ticketData?.brandId === 100) {
      brands.push(100);
    } else {
      brands.push(ticketData?.brandId);
    }

    const users: any = await getUsersWithBrandsDO("dev", brands);
    setBrandUsers(users);

    const allUsers: any = await getAllUSersDO("dev");
    setAllUsers(allUsers);

    const statuses: any = await getCustomerSupportStatusesDO(host);
    const newStatuses: any = rebuildStatuses(statuses);
    setStatuses(newStatuses);
    setCurrentStatus(statuses.find((s) => s.key === ticketData?.status));

    if (ticketData?.profile_id) {
      const reporterID: any = await getUserIDDO(host, ticketData?.profile_id);

      const { user, profile, profileAssets } = await getByUserId(reporterID);

      user.avatar = avatarUrl(ticketData?.profile_id, profileAssets?.[0]?.uri);
      setUserData(Object.assign(new EndUser(), user));
      setProfileData(Object.assign(new Profile(), profile));
    }
    if (ticketData?.reportee && ticketData.topic.id === 5) {
      // const reporteeID: any = await getUserIDDO(host, ticketData?.reportee);
      const { user, profile, profileAssets } = await getByUserId( ticketData?.reportee);
      user.avatar = avatarUrl(ticketData?.reportee, profileAssets?.[0]?.uri);
      setReporteeData(Object.assign(new EndUser(), user));
    }

    const SLASettings: any = await getSLASettingsDO(host, ticketData.brandId);
    const sla: any = SLASettings.find((sla) => sla.topic_id === ticketData.topic.id);

    const createdDate = new Date(ticketData.created);
    const slaDeadline = new Date(createdDate.getTime() + sla.sla_first * 60 * 60 * 1000);
    const adjustedNow = new Date(now.getTime() + 3 * 60 * 60 * 1000);
    const timeDiff = slaDeadline.getTime() - adjustedNow.getTime();
    const totalMinutesLeft = timeDiff / (1000 * 60);
    const hours: any = Math.floor(totalMinutesLeft / 60);
    const minutes: any = Math.floor(Math.abs(totalMinutesLeft % 60));
    setCurrentSLA(`${hours} h ${minutes} m`);
  };

  const rebuildStatuses = (statuses: any) => {
    statuses.map((s) => {
      s.key = s?.id;
      s.value = s?.label;
    });
    return statuses;
  };

  const getTickeLogs = async () => {
    setLogs(await getTicketResponses(ticketID!));
  };

  const changeBrandStatus = async (status) => {
    const updateStatus = await updateticketStatusDO(
      {
        status,
        userId: userData?.id,
      },
      ticketID
    );
    getData();
    addAlert(dispatch, "Ticket status was updated");
    getTickeLogs();
  };

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const sendLog = async (e) => {
    if ((e?._reactName === "onClick" || e?.code === "Enter") && newLog.length > 0) {
      e.preventDefault();
      setLoader(true);
      await addResponse(newLog, userData!.id!, ticketID!);
      setNewLog("");
      setLoader(false);
      getTickeLogs();
    }
  };

  const saveChanges = () => {
    setReAssignModalStatus(true);
  };

  const update = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (value) => {
    setImage(value);
    setOpen(true);
  };

  const test = (e: any) => {
    console.log("e after enter: ", e?.code);
  };
  return (
    <DashboardLayout>
      <ReAssignModal
        reAssignModalStatus={reAssignModalStatus}
        setReAssignModalStatus={setReAssignModalStatus}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        allUsers={allUsers}
        ticket={ticket}
        host={host}
        brandUsers={brandUsers}
        ticketID={ticketID}
        getData={getData}
      />
      <DashboardNavbar pageTitle={`Support Ticket #${ticketID}`} />
      <ItemPageContainer
        brandLogoIMG={ticket?.avatar}
        navItems={[
          { label: "Ticket details" },
          { label: "Reporter - Reportee", ref: "reporter" },
          { label: "Package details", ref: "package" },
          { label: "Ticket content", ref: "ticketContent" },
          { label: "Ticket conversation", ref: "ticketActivityLog" },
          { label: "Reporter Details", ref: "details" },
        ]}
        actions={{
          itemName: `${ticket?.topic_name} Ticket`,
          itemNumber: `Ticket #: ${ticketID}`,
          actionsTitle: "Ticket actions",
          actions: [
            {
              title: "Re assign",
              onClick: saveChanges,
              variant: "outlined",
              // role: PermissionType.Edit
            },
          ],
        }}
        itemStatus={{
          title: "Ticket Status",
          changeStatus: changeBrandStatus,
          item: brand,
          isManager: currentRole.hasAccess(pageNumber, PermissionType.Edit),
          alternativeStatuses: statuses,
          alternativeCurrentStatus: ticket?.status,
          // alternativeCurrentStatus: currentStatus
        }}
        itemHeader={
          <SMEHeader
            title="Ticket details"
            showImage={false}
            formData={userData}
            indications={[
              {
                title: "Created:",
                value: formatDate(ticket?.created),
              },
              {
                title: "Assigned to:",
                value: ticket?.assigned_to?.map((p: any, i) => {
                  const user: any = allUsers?.find((u: any) => u.id === p);
                  return user?.first_name + " " + user?.last_name + ", ";
                }),
                fixedSize: 200,
              },
              {
                title: "Updated",
                value: formatDate(ticket?.last_update),
              },
              {
                title: "Topic",
                value: ticket?.topic_name,
              },
              {
                title: "Sub-Topic",
                value: ticket?.sub_topic_name,
              },
              {
                title: "SLA",
                value: currentSLA,
              },
            ]}
            saveProfile={update}
          />
        }
      >
        <Card style={{ width: "100%" }}>
          <Grid
            flexDirection="row"
            container
            alignItems={"end"}
            justifyContent="center"
            sx={{ px: 4 }}
          >
            <Grid
              item
              xs={5.8}
              lg={5.8}
              sx={{ my: 2 }}
              flexWrap="nowrap"
              flexDirection="row"
              container
              gap={3}
            >
              <Stack direction="row">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <img
                    src={userData?.avatar}
                    style={{
                      height: "84px",
                      width: "84px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Badge>
              </Stack>
              <Grid
                container
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignContent="center"
              >
                <MDTypography variant="h5" fontWeight="medium">
                  Reporter:
                </MDTypography>
                <MDTypography variant="title" color="inherit" noWrap>
                  &nbsp;
                </MDTypography>
                {userData?.firstName ? (
                  <MDTypography
                    variant="h5"
                    fontWeight="light"
                    onClick={() => navigate(`/endUsers/${userData?.id}`)}
                    sx={{ color: "#49AAEF", cursor: "pointer" }}
                  >
                    {`${userData?.firstName} ${userData?.lastName ? userData?.lastName : ""}`}
                  </MDTypography>
                ) : null}
              </Grid>

            </Grid>
            <Grid
              item
              xs={0.2}
              lg={0.2}
              sx={{ my: 2 }}
              // flexWrap="nowrap"
              // flexDirection="row"
              // container
              // gap={3}
            >

              <Divider orientation="vertical" sx={{ bgcolor: "black" }} flexItem />
            </Grid>

            <Grid
              item
              xs={5.8}
              lg={5.8}
              sx={{ my: 2 }}
              flexWrap="nowrap"
              flexDirection="row"
              container
              gap={3}
            >
              {ticket.topic === 5 ? (
                <>
                  <Stack direction="row">
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                      <img
                        src={reporteeData?.avatar}
                        style={{
                          height: "84px",
                          width: "84px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </Badge>
                  </Stack>
                  <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                    alignContent="center"
                  >
                    <MDTypography variant="h5" fontWeight="medium">
                      Reportee:
                    </MDTypography>
                    <MDTypography variant="title" color="inherit" noWrap>
                      &nbsp;
                    </MDTypography>
                    {userData?.firstName ? (
                      <MDTypography
                        variant="h5"
                        fontWeight="light"
                        onClick={() => navigate(`/endUsers/${reporteeData?.id}`)}
                        sx={{ color: "#49AAEF", cursor: "pointer" }}
                      >
                        {`${reporteeData?.firstName} ${
                          reporteeData?.lastName ? reporteeData?.lastName : ""
                        }`}
                      </MDTypography>
                    ) : null}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Card>
        {hasPackage && (
          <CardItem title="Package details" id="package">
            <PackageDetails profile={profileData} />
          </CardItem>
        )}

        <CardItem title="Ticket content" defaultOpen id="ticketContent">
          <Grid item xs={9.3} lg={9.3} sx={{ display: "flex" }}>
            <Grid container flexDirection="row" alignItems="flex-start" gap={1}>
              <Grid item xs={9.3} lg={9.3} sx={{ display: "flex" }}>
                <MDTypography variant="body2">
                  On {formatDateTime(ticket?.created)} user has written:
                </MDTypography>
              </Grid>
              <Grid item xs={9.3} lg={9.3} sx={{ display: "flex" }}>
                <MDTypography variant="body2">{ticket?.content}</MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </CardItem>
        <CardItem title="Ticket Conversation" defaultOpen id="ticketActivityLog">
          <Grid container flexDirection="row" alignItems="flex-start" gap={1}>
            <Grid item xs={12} lg={12}>
              {logs
                .filter((l) => l.type != "CS_TICKET" && l.type != "CS_TICKET_CLOSED")
                .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                .map((l, i) => (
                  <Message message={l} key={i} identityId={userData?.identityId} />
                ))}
              <Divider />
            </Grid>
            {!!logs?.length ? (
              <Grid item xs={6} lg={6}>
                <TextField
                  id="standard-name"
                  // label="Name"
                  variant="outlined"
                  sx={{ maxWidth: 350, width: "90vw" }}
                  placeholder="Add response"
                  disabled={
                    !currentRole.hasAccess(pageNumber, PermissionType.Edit) || ticket?.status == 5
                  }
                  value={newLog}
                  onChange={(e) => setNewLog(e.target.value)}
                  onKeyDown={(e) => sendLog(e)}
                  InputProps={{
                    endAdornment: (
                      <IconButton color="secondary" aria-label="add an alarm">
                        {loader ? <CircularProgress size={20} /> : <SendIcon onClick={sendLog} />}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            ) : (
              <a href={buildEmailLink()} style={{ textDecoration: "none" }} target="_blank">
                <MDButton variant="contained" color="secondary">
                  Respond
                </MDButton>
              </a>
            )}
          </Grid>
        </CardItem>
        <CardItem title="Reporter Device" id="details">
        <ReporterDetails ticket={ticket} />
        </CardItem>
      </ItemPageContainer>

      <MDSnackbar
        color={snackbar?.color}
        icon={snackbar?.icon}
        title={snackbar?.title}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} timeout={500}>
          <img src={image} alt="asd" style={{ maxHeight: "90%", maxWidth: "90%" }} />
        </Fade>
      </Modal>
    </DashboardLayout>
  );
}

export default CustomerSupportViewTicket;
