import { useMaterialUIController } from "context";
import a_love from "assets/images/brands/a-love.png";
import mujual from "assets/images/brands/mujual.png";
import zuug from "assets/images/brands/zuug.png";
import MDBox from "components/MDBox";

export const BrandLogo = (props) => {
  const { allowedBrans, brand, brandLogoIMG, size } = props;
  const [controller, dispatch] = useMaterialUIController();
  const { currentBrand } = controller;

  const imgLink = brandLogoIMG ? brandLogoIMG : currentBrand?.name === "a-love" ? a_love : currentBrand?.name === "mujual" ? mujual : zuug;
  return (
    <MDBox
      component="img"
      src={
        imgLink
      }
      alt="Brand logo"
      height={size || "43px"}
      width={size || "43px"}
      style={{ objectFit: "contain" }}
    />
  );
};
