import { Grid, Slider, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { T } from "components/alove/Translator";
import { fileLink } from "utils/networkUtils";

const MDTypography = require("components/MDTypography").default;


export const ResponseCell = (params: GridRenderCellParams) => {
    const row = params.row;
    const value = row[params.field]
    if ([4, 5].includes(row.responseType) && value) {
      return (
        <Grid container flexDirection="row" >
          <Grid item xs={2} alignSelf="center">
            <Tooltip title={ <T>{row.responseSettings?.txt_min || row.rangeMin || "0"}</T>}>
            <MDTypography style={{ fontSize: "12px"}} textAlign="center">
            {row.rangeMin || "0" }
            </MDTypography>
              
            </Tooltip>
          </Grid>
          <Grid item xs={true}>
            <Slider
              value={value.split(",").map(Number)}
              max={Number(row.rangeMax)}
              valueLabelDisplay="on"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
          <Grid item xs={2} alignSelf="center">
            <Tooltip title={ <T>{row.responseSettings?.txt_max || row.rangeMax || "0"}</T>}>
            <MDTypography style={{ fontSize: "12px"}} textAlign="center">
            {row.rangeMax}
            </MDTypography>
              
            </Tooltip>
           
          </Grid>
        </Grid>
      );
    }
    if (value?.startsWith("http")) {
      return <a href={fileLink(value)} target="blank">{row.bioName}</a>;
    }
    return  <Tooltip title={ <p dangerouslySetInnerHTML={{ __html: value}} ></p>}>

    <p dangerouslySetInnerHTML={{ __html: value}} ></p>
    </Tooltip>;
  };