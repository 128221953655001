import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { postLogDO } from 'utils/logsUtils';
import { addNewTranslationDO } from 'utils/translateUtils';
import { Grid, Autocomplete, TextField, IconButton } from "@mui/material";
import { postNewAdminTaskDO } from 'utils/adminUtils';
import { useMaterialUIController } from "context";
import {addNewTranslationLangDO} from 'utils/translateUtils';
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddNewLanguage(props) {

    const [controller, dispatch] = useMaterialUIController();
    const { } = controller;
    const {newLangs, snackbar, setSnackbar, host, refresh, setRefresh  } = props;
    const navigate = useNavigate();
    
    const [modalOpen, setModalOpen] = useState(false);
    const [newLang, setNewLang] = useState();

    const handleClickOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const addNewlang  = async () => {
        console.log('newLang: ', newLang);
        const addRes = await addNewTranslationLangDO(host, newLang);
        console.log('addRes: ', addRes);
        if(addRes === 201){
            setTimeout(() => {
                handleClose();   
                // setRefresh(!refresh)   
                navigate(0);
            },500)
        }
    }

    return (
        <React.Fragment>
            <MDButton
                variant="contained"
                color='secondary'
                onClick={handleClickOpen}
            >
                Add new
            </MDButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add language
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <Autocomplete
                        sx={{ minWidth: 300 }}
                        options={newLangs}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.code  == value.code}
                        onChange={(e, o) => setNewLang(o)}
                        getOptionLabel={(o) => ( o.name)}
                        renderInput={(params) => <MDInput {...params} label='Language' />}
                    />
                </DialogContent>
                <DialogActions><Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            fullWidth
                            onClick={() => handleClose()}
                        >
                            cancel
                        </MDButton>
                    </Grid>
                    <Grid item xs={6} xl={6}>
                        <MDButton
                            variant="gradient"
                            color={'info'}
                            fullWidth
                            onClick={() => addNewlang()}
                        >
                            Add new
                        </MDButton>
                    </Grid>
                </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
