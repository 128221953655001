import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { T } from "components/alove/Translator";
import {
  Autocomplete,
  Avatar,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { getMMList } from "utils/matchmakerUtils";
import { Matchmaker, MMStatuses } from "models/BO/Matchmaker";
import { tr } from "utils/globalsUtils";
import { Introduction } from "models/introduction/Introduction";

const MDTypography = require("components/MDTypography").default;
const MDButton = require("components/MDButton").default;
const MDInput = require("components/MDInput").default;

export interface DialogProps {
  dialog: string;
  red?: boolean;
  img?: string;
  isAdmin?: boolean;
  onConfirm?: (extra: any) => Promise<boolean>;
}

const Answers = (props) => {
  const { onChange, title } = props;
  return (
    <Grid
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      gap="10px"
    >
      <MDTypography variant="h6">
        <T>{title}</T>
      </MDTypography>
      <FormControl>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
          <FormControlLabel
            value="interested"
            control={<Radio />}
            label={tr("interestedRadio")}
            onChange={onChange}
          />
          <FormControlLabel value="busy" control={<Radio />} label={tr("busyRadio")} onChange={onChange} />
          <FormControlLabel
            value="unmatch"
            control={<Radio />}
            label={tr("unmatchRadio")}
            onChange={onChange}
          />
          <FormControlLabel
            value="ignore"
            control={<Radio />}
            label={tr("ignoreRadio")}
            onChange={onChange}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default function UpdateStatusDialog({shRequest, dialog} : {shRequest: Introduction, dialog: DialogProps}) {

  const body = () => {
    switch (dialog.dialog) {
      case "pickTime":
        const DAY = 24 * 60 * 60 * 1000;
        validator = () => extra.holdUntil;
        return (
          <Grid
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="10px"
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="option1"
                  control={<Radio />}
                  label="1 day"
                  onChange={() => setExtra({ holdUntil: DAY })}
                />
                <FormControlLabel
                  value="option2"
                  control={<Radio />}
                  label="3 days"
                  onChange={() => setExtra({ holdUntil: 3 * DAY })}
                />
                <FormControlLabel
                  value="option3"
                  control={<Radio />}
                  label="1 week"
                  onChange={() => setExtra({ holdUntil: 7 * DAY })}
                />
                <FormControlLabel
                  value="option4"
                  control={<Radio />}
                  label="1 month"
                  onChange={() => setExtra({ holdUntil: 30 * DAY })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        );
      case "updateStatus9":
        validator = () => extra.declineReason;
        return (
          <Grid
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="10px"
          >
            <MDTypography variant="h6"><T>reasonDecline</T></MDTypography>
            <FormControl>
              <MDInput
                onChange={(e) => setExtra({ declineReason: e.target.value })}
                multiline
                rows={3}
                style={{ width: "300px" }}
                placeholder={tr("reasonDeclineHint")}
              />
            </FormControl>
          </Grid>
        );
      case "snooze":
      case "unmatch":
        validator = () => extra.checked;
        return (
          <Grid>
            <Checkbox onChange={() => setExtra({ checked: !extra.checked })} />
            <T>concatedBothSided</T>
          </Grid>
        );
      case "recordAnswers":
        validator = () => extra.initiatorStatus && extra.responderStatus;
        return (
          <Grid display="flex" flexDirection="row" justifyContent="space-evenly">
            <Answers
              onChange={(e) =>
                setExtra({
                  initiatorStatus: e.target.value,
                  responderStatus: extra.responderStatus,
                })
              }
              title={shRequest.initiatorUser.firstName + "'s Answer"}
            />
            <Answers
              onChange={(e) =>
                setExtra({
                  responderStatus: e.target.value,
                  initiatorStatus: extra.initiatorStatus,
                })
              }
              title={shRequest.responderUser.firstName + "'s Answer"}
            />
          </Grid>
        );
      case "recordDate":
        const oldDate = shRequest.statusReasonObj?.dateNumber || 0;
        validator = () => extra.dateNumber > oldDate;
        extra.dateNumber = Math.max(oldDate + 1, extra.dateNumber || 0);
        return (
          <Grid display="flex" flexDirection="column" sx={{ mx: 5 }} alignItems="center" gap={2}>
            <Grid display="flex" flexDirection="row" alignItems="center">
              <MDTypography>
                <T>dateNumber</T>
              </MDTypography>
              <MDTypography
                color="primary"
                sx={{ px: 2 }}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => setExtra({ ...extra, dateNumber: extra.dateNumber - 1 })}
              >
                -
              </MDTypography>
              {extra.dateNumber}

              <MDTypography
                color="primary"
                sx={{ px: 2 }}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => setExtra({ ...extra, dateNumber: extra.dateNumber + 1 })}
              >
                +
              </MDTypography>
            </Grid>
            <T>addDateComment</T>
            <MDInput
              onChange={(e) => setExtra({ ...extra, dateComment: e.target.value })}
              multiline
              rows={5}
              style={{ width: "300px" }}
              fullWidth
            />
          </Grid>
        );
      case "reassing":
        const nextMM = shRequest.nextMatchmaker
        return (
          <Grid display="flex" flexDirection="column" sx={{ mx: 5 }} alignItems="center" gap={2}>
            <Card>
              <MDTypography variant="h6">Next Matchmaker:</MDTypography>
              <MDTypography variant="h3">
                {nextMM?.first_name} {nextMM?.last_name}
              </MDTypography>
            </Card>

            <Autocomplete
              id="combo-box-demo"
              options={mmList}
              renderOption={(p, o) => (<ListItem {...p}>
              <ListItemAvatar>
                <Avatar src={o.avatar}/>
              </ListItemAvatar>
              <ListItemText
                secondary={o.first_name + " " + o.last_name}
              />
            </ListItem>)}
              getOptionLabel={(option) => option.first_name + " " + option.last_name}
              style={{ maxWidth: "300px", width: "60vw" }}
              onChange={(e, newValue) => setExtra({ ...extra, mmId: newValue?.id })}
              renderInput={(params) => <MDInput {...params} label="Matchmaker" />}
              noOptionsText={tr("typeToSearch")}
              
              onInputChange={(e, newValue) => {
                getMMList({
                  statusFilter: MMStatuses.available,
                  user: newValue,
                }).then((res) => {
                  setMmList(res);
                })
              }}
            />
          </Grid>
        );

      default:
        return;
    }
  };
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [extra, setExtra] = React.useState({} as any);
  const [mmList, setMmList] = React.useState([] as any[]);
  let validator = () => true;

  const handleClose = () => {
    setOpen(false);
    dialog.dialog = "";
    setExtra({});
  };

  const handleConfirm = async () => {
    console.log(loading);
    if (loading) return;
    setLoading(true);
    const changed = await dialog.onConfirm?.(extra)

    if (!changed) {
      setOpen(false);
      dialog.dialog = "";
    }
    setLoading(false);
    setExtra({});
  };

  React.useEffect(() => {
    if (dialog.dialog != "") {
      setOpen(true);
    }
    if (dialog.isAdmin && mmList?.length == 0) {
      getMMList({
        statusFilter: MMStatuses.available,
      }).then((res) => {
        setMmList(res);
      });
    }
  }, [dialog.dialog]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "40px",
            width: "90%",
            maxWidth: "400px"

          },
        }}
      >
        <DialogTitle
          color="error"
          style={{ display: dialog.img ? "flex" : "none", justifyContent: "center" }}
        >
          <img src={dialog.img} height="42px" />
        </DialogTitle>
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
        >
          <T>{dialog.dialog}Title</T>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
            <T>{dialog.dialog}Body</T>
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          {body()}
        </DialogContent>
        <Grid
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          alignItems="center"
          gap="10px"
          sx={{ p: 2 }}
        >
          <MDButton
            onClick={handleConfirm}
            circular
            variant="contained"
            color={dialog.red ? "error" : "primary"}
            style={{ width: "250px" }}
            disabled={!validator()}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <T>{dialog.dialog}Confirm</T>
            )}
          </MDButton>
          {dialog.dialog === "chooseOption" ? (
            <MDButton
              onClick={() => handleConfirm()}
              circular
              variant="contained"
              color={dialog.red ? "error" : "primary"}
              style={{ width: "250px" }}
              disabled={!validator()}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : <T>unmatchButton</T>}
            </MDButton>
          ) : (
            <MDButton
              onClick={handleClose}
              variant="outlined"
              circular
              color="dark"
              style={{ width: "250px" }}
            >
              <T>Cancel</T>
            </MDButton>
          )}
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
