export enum GeneralCodeType {
  ScreenCategory = "screenCategory",
  EmailCategory = "emailCategory",
}

export class GeneralCode {
  id: number;
  name: string;
  type: GeneralCodeType;
}
