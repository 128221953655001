import { createContext, useContext, useReducer } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { BoRole, Permission } from "models/BO/BoRole";
import { BoUser } from "models/BO/BoUser";
import { Brand } from "models/BO/Brand";
import { networkGlobals } from "utils/networkUtils";
import { AlertModel } from "components/alove/AloveAlert";
import { changeFavicon } from "utils/globalsUtils";
import { loadSettings } from "utils/settingsUtils";
// The Soft UI Dashboard PRO Material main context
const MaterialUI = createContext([] as any);
// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "AUTH": {
      return { ...state, auth: action.value };
    }
    case "CURRENT_USER": {
      return { ...state, currentUser: action.value };
    }
    case "AUTH_TOKEN": {
      return { ...state, authToken: action.value };
    }
    case "CURRENT_BRAND": {
      return { ...state, currentBrand: action.value };
    }
    case "CURRENT_ROLE": {
      return { ...state, currentRole: action.value };
    }
    case "TRANSLATIONS": {
      return { ...state, translations: action.value };
    }
    case "CURRENT_HOST": {
      return { ...state, currentHost: action.value };
    }
    case "CURRENT_DIALOG": {
      return { ...state, currentDialog: action.value };
    }
    case "CURRENT_ALERTS": {
      return { ...state, currentAlerts: [...state.currentAlerts, action.value] };
    }
    case "REMOVE_ALERT": {
      return { ...state, currentAlerts: state.currentAlerts.slice(1) };
    }
    case "MOBILE_VIEW": {
      return { ...state, mobileView: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export type controller = {
  miniSidenav: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  sidenavColor: string;
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  direction: string;
  layout: string;
  darkMode: boolean;
  auth: boolean;
  translations: any;
  currentUser: BoUser;
  currentBrand: Brand;
  currentRole: Permission;
  currentHost: string;
  currentDialog: string;
  currentAlerts: AlertModel[];
  mobileView: boolean;
};

function defaultHostBydomain() {
  const currentHost = window.localStorage.getItem("currentHost");
  if (currentHost) {
    console.log("currentHost", currentHost);
    return currentHost;
  }

  const domain = window.location.hostname;
  switch (domain) {
    case "zuug.a-dmin.ai":
      return "prod";
    default:
      return "prod";
  }
}
function MaterialUIControllerProvider({ children }): {} {
  const currentBrand = Object.assign(
    new Brand(),
    JSON.parse(localStorage.getItem("currentBrand") || "{}")
  );
  let currentUser;
  if (localStorage.getItem("BOCurrentUser")) {
    currentUser = Object.assign(new BoUser(), JSON.parse(localStorage.getItem("BOCurrentUser")!));
  }
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    auth: false,
    translations: {},
    // authToken:null,
    currentUser: currentUser,
    currentBrand,
    currentRole: Object.assign(
      new Permission(),
      JSON.parse(localStorage.getItem("BORoles" + currentBrand?.id) || "{}")
    ),
    currentHost: defaultHostBydomain(),
    currentDialog: "",
    currentAlerts: [],
    mobileView:  window.innerWidth < 992
  };

  console.log("initialState", initialState);
  const [controller, dispatch] = useReducer(reducer, initialState);
  const contextValue = [controller, dispatch];

  return <MaterialUI.Provider value={contextValue}>{children}</MaterialUI.Provider>;
}

function useMaterialUIController(): [controller, any] {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }
  return context;
}

MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setAuth = (dispatch, value) => dispatch({ type: "AUTH", value });
const setCurrentUser = (dispatch, value) => dispatch({ type: "CURRENT_USER", value });
const setAuthToken = (dispatch, value) => dispatch({ type: "AUTH_TOKEN", value });
const setCurrentBrand = (dispatch, value: Brand) => {
  if (value) {
    localStorage.setItem("currentBrand", JSON.stringify(value));
    networkGlobals.brand = Object.assign(new Brand(), value);
    changeFavicon(value?.name);
    loadSettings().then((loded) => {
      if (loded) {
        
      setCurrentBrand(dispatch, value);
      }
    })
  }
  dispatch({ type: "CURRENT_BRAND", value: Object.assign(new Brand(), value) });
};
const setTranslations = (dispatch, value) => dispatch({ type: "TRANSLATIONS", value });
const setCurrentRole = (dispatch, value: Permission) =>
  dispatch({ type: "CURRENT_ROLE", value: Object.assign(new Permission(), value) });
const setCurrentHost = (dispatch, value) => {
  if (value) {
    localStorage.setItem("currentHost", value);
  }
  networkGlobals.host = value;
  loadSettings();
  dispatch({ type: "CURRENT_HOST", value });
};
const setCurrentDialog = (dispatch, value) => dispatch({ type: "CURRENT_DIALOG", value });

const addAlert = (dispatch, content: string, error?: boolean) =>
  dispatch({ type: "CURRENT_ALERTS", value: { content, error } as AlertModel });

const removeAlert = (dispatch, value) => dispatch({ type: "REMOVE_ALERT", value });

const setMobileView = (dispatch, value) => dispatch({ type: "MOBILE_VIEW", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setAuth,
  setCurrentUser,
  setAuthToken,
  setCurrentBrand,
  setCurrentRole,
  setTranslations,
  setCurrentHost,
  setCurrentDialog,
  addAlert,
  removeAlert,
  setMobileView,
};
