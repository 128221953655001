import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { T } from "../../../components/alove/Translator";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { setCurrentDialog, useMaterialUIController } from "context";

const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const MDInput = require("components/MDInput/index.js").default;

export interface DeactivateDialogProps {
  title: string;
  content: string;
  reasonLabel?: string;
  confirm: string;
  onSubmit: (reason: string) => any;
}

export default function DeactivateDialog(props: DeactivateDialogProps) {
  const danger = props;
  const [reason, setReason] = React.useState("");
  const [controller, dispatch] = useMaterialUIController();

  return (
    <React.Fragment>
      <AloveDialog
        dialogTitle={danger.title}
        handleConfirm={async () => { 
          await danger.onSubmit(reason)
          setReason('')
        }}
        dialogType={DialogType.DeactivateEntitiy}
        dialogBody={danger.content}
        valid={reason.length > 0}
        dialogConfirm={danger.confirm}
        error
      >
        {danger.reasonLabel ?
        <MDInput
          variant="outlined"
          label={<T>{danger.reasonLabel}</T>}
          onChange={(e) => setReason(e.target.value)}
        /> : undefined}
      </AloveDialog>
    </React.Fragment>
  );
}
