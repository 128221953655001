const { useMaterialUIController } = require("context");
export const T = (props) => {

    const [controller] = useMaterialUIController();
    const { currentBrand, translations } = controller;
  
    const { children, params={} } = props;
    const key = children?.join ? children.join('') : children;
    return typeof(key) == 'string' ? (translations[currentBrand?.name]?.[key] || key)
               .replace(/\@[a-zA-Z0-9]+/g, (a)=>params[a.substring(1)]) : children;
};