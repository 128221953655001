import { Button, capitalize, Card, Grid, Typography } from "@mui/material";
import CardItem from "components/alove/CardItem";
import ItemPageContainer from "layouts/itemPageContainer";
import { Comm, CommBody, emailTemplateLabels } from "models/content/Comm";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createCommDO, deleteCommDO, getComm, saveCommDO } from "utils/commUtils";
import { useMaterialUIController } from "context";
import { EmailContainer } from "./emails/emailContainer";
import { PushContainer } from "./push/pushContainer";
import { PermissionType } from "models/BO/BoRole";
import { ScreenModel } from "models/content/Screen";
import { getScreens } from "utils/screenUtils";
import { ContentStatus } from "models/content/Content";
import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { CommHeader } from "./components/commHeader";
import { SMSContainer } from "./sms/smsContainer";
import { WAContainer } from "./wa/waContainer";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export const CommsView = () => {
  const pageNumber = "3.6";
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const { id } = useParams();
  const [channel, setChannel] = useState("email");
  const [comm, setComm] = useState({} as Comm);

  useEffect(() => {
    if (id && !id.startsWith("new")) {
      getComm(id).then((comm) => setCommObject(comm));
    }
  }, [id]);

  const [screens, setScreens] = useState([] as ScreenModel[]);

  useEffect(() => {
    getScreens().then((screens) => setScreens(screens));
  }, []);



  const setCommObject = (newComm) => {
    const comm = Object.assign(new Comm(), { ...newComm });
    if (comm.email) {
      comm.email = Object.assign(new CommBody(), { ...newComm.email });
    }
    if (comm.push) {
      comm.push = Object.assign(new CommBody(), { ...newComm.push });
    }
    if (comm.sms) {
      comm.sms = Object.assign(new CommBody(), { ...newComm.sms });
    }
    if (comm.wa) {
      comm.wa = Object.assign(new CommBody(), { ...newComm.wa });
    }
    setComm(comm);
  };

  const saveComm = async () => {
    emailTemplateLabels[comm.email?.template || ""]?.forEach((label) => {
      comm.email![label] ||= "";
    })


    return id?.startsWith("new") ? createCommDO(comm).then((comm) => navigate(-1)) : saveCommDO(comm);
  }

  const deleteComm = async () => deleteCommDO(comm).then(() => navigate(-1));

  const channelElement = (channel, child) => {
    if (comm[channel]) {
      return child;
    }
    return (
      <CardItem title="Channel is not active" id="comm">
        <Grid container>
            <MDButton
              onClick={() => {
                comm[channel] = new CommBody();
                comm[channel].statusId = ContentStatus.draft;
                setCommObject(comm);
              }}
              variant="contained"
              color="primary"
            >
              Add {channel} communication
            </MDButton>
          </Grid> 
        
      </CardItem>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={"Edit communication"} />
      <ItemPageContainer
        navItems={[
          { label: "Email", onClick: () => setChannel("email") },
          { label: "Push", onClick: () => setChannel("push") },
          { label: "SMS", onClick: () => setChannel("sms") },
          { label: "WhatsApp", onClick: () => setChannel("wa") },
        ]}
        actions={{
          actionsTitle: comm.name,
          itemName: `Communication ID`,
          itemNumber: `#${comm.id}`,
          actions: [
            {
              title: "Save",
              onClick: saveComm,
              variant: "contained",
            },
          ],
        }}
        itemHeader={<CommHeader comm={comm} setComm={setCommObject} />}
        itemStatus={comm[channel] && {
          title: capitalize(channel) + " status",
          item: comm[channel],
          isManager: true,
          changeStatus: async (newStatus) => {
            comm[channel].statusId = newStatus;
            setCommObject({ ...comm });
            await saveComm();
          },
        }}
        deleteItem={{
          buttonText: "Delete Email",
          contetnText: "Are you sure you want to delete this email?",
          hidden: !currentRole.hasAccess(pageNumber, PermissionType.Delete),
          onDelete: deleteComm,
        }}
      >
        {channel === "email"
          ? channelElement(
              "email",
              <EmailContainer
                comm={comm}
                setCommObject={setCommObject}
                saveComm={saveComm}
                screens={screens}
              />
            )
          : channel === "push"
          ? channelElement(
              "push",
              <PushContainer
                comm={comm}
                setCommObject={setCommObject}
                saveComm={saveComm}
                screens={screens}
              />
            )
          : channel === "sms" ?
          channelElement("sms", <SMSContainer comm={comm} setComm={setCommObject} screens={screens} />):
          channelElement("wa", <WAContainer comm={comm} setComm={setCommObject} screens={screens} />)}

      </ItemPageContainer>
    </DashboardLayout>
  );
};
