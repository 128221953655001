/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Admin PRO React Base Styles
import colors from "assets/theme/base/colors";
import boxShadow from "../functions/boxShadow";

const { info, dark } = colors;

export default {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  "*::-webkit-scrollbar": {
    width: "7px",
  },
  "*::-webkit-scrollbar-track": {
    background: "linear-gradient(to left, transparent 2px, white 5px)",
    margin: "10px",
    borderRadius: "5px 15px 15px 5px",
    marginTop: "80px",
  },
  "*::-webkit-scrollbar-thumb": {
    background: "linear-gradient(to left, transparent 2px, black 5px)",
    borderRadius: "5px 15px 15px 5px",
  },
};
