import CardItem from "components/alove/CardItem";
import { ActionSetting } from "layouts/screens/screenView/components/action";
import React, { useEffect, useState } from "react";

import { Comm } from "models/content/Comm";
import { ScreenModel } from "models/content/Screen";
import { getWATemplates, saveWATemplate, submitWATemplate } from "utils/commUtils";
import { TwilioTemplate } from "models/content/TwilioTemplate";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Autocomplete, Grid, Typography } from "@mui/material";
import { setCurrentDialog, useMaterialUIController } from "context";
import { DialogType } from "components/alove/AloveDialog";
import { WAAddNewTemplate } from "./waAddNewTemplate";
import { WATemplatesList } from "../../commsList/waTemplatesList";
import { snakeCaseToCapitalizedWords } from "utils/globalsUtils";

const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;
export const WAContainer = ({
  comm,
  setComm,
  screens,
}: {
  comm: Comm;
  setComm: any;
  screens: ScreenModel[];
}) => {
  const [controller, dispatch] = useMaterialUIController();

  const [templates, setTemplates] = useState<TwilioTemplate[]>([]);
  useEffect(() => {
    getWATemplates().then(setTemplates);
  }, []);

  return (
    <React.Fragment>
      <CardItem title="Choose WhatsApp Template" id="comm">
        <Grid container spacing={2}>
        <Grid item xs={7}>
          <Autocomplete
            options={templates.filter((t) => t.approvalRequests?.status === "approved")}
            getOptionLabel={(option) => snakeCaseToCapitalizedWords(option.friendlyName)}
            value={templates.find((t) => t.sid === comm.wa?.template) || null}
            onChange={(_, value) => setComm({ ...comm, wa: { ...comm.wa, template: value?.sid } })}
            renderInput={(params) => <MDInput {...params} label="Template" rows={3} />}
          />
        </Grid>
        
        </Grid>
        <Grid item sx={{ my: 2 }}>
          <MDTypography variant="body2" sx={{ mb: 2 }} style={{ whiteSpace: "pre-line" }}>
            {templates.find((t) => t.sid === comm.wa?.template)?.body}
          </MDTypography>
        </Grid>
      </CardItem>
      {/* <CardItem title="wa Link" id="wa" halfWidth>
        <ActionSetting
          action={comm.wa || {}}
          setAction={(a) => setComm({ ...comm, wa: a })}
          screens={screens}
        />
      </CardItem> */}

      
    </React.Fragment>
  );
};
