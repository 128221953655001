/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Admin PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Otis Admin PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { dark } = colors;
const { size, fontWeightBold } = typography;

export default {
  styleOverrides: {
    root: {
      "& .MuiOutlinedInput-root": {
        padding: "4.5px!important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        // borderWidth: "2px!important"
        border: "1px solid #7A7A86",
      },
      "& .MuiInputBase-sizeSmall":{
        padding: "6px !important",
      }
    },
  },
};
