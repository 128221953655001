import { capitalize } from "@mui/material";
import { BoUser, Statusable } from "../BO/BoUser";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";

export class Matchmaker implements Statusable {
  id: number;
  short_description: string;
  population: {
    singleAges: string[];
    singlePlans: string[];
    singleTypes: string[];
  };
  experience: string;
  location: {
    latitude: number;
    longitude: number;
  };
  attributes: {
    title: string;
    experience: string;
    preferContact: string[];
    special_intstructions: string;
    communicationDirection: string;
    declineReason: string;
  };
  created: string;
  updated: string;
  busy_until: string;
  userId: number;
  brandId: number;
  user: BoUser;
  status: number;

  get statusLabel(): string {
    return camelCaseToCapitalizedWords(MMStatuses[this.status]);
  }

  get statusColor(): string {
    return getStatusColor(this.status);
  }

  get statuses(): { key: string; value: string }[] {
    return Object.keys(MMStatuses)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => ({
        key: key,
        value: camelCaseToCapitalizedWords(MMStatuses[key]),
      }));
  }

  get statusReason(): string {
    return this.attributes?.declineReason;
  }
}

export const getStatusColor = (status: number): string => {
  switch (status) {
    case MMStatuses.available:
      return "primary";
    case MMStatuses.pending:
      return "secondary";
    case MMStatuses.notAvailable:
    case MMStatuses.blocked:
    case MMStatuses.declined:
    case MMStatuses.deleted:
    case MMStatuses.deletionRequested:
      return "error";
    default:
      return "";
  }
};

export enum MMStatuses {
  available = 1,
  pending = 2,
  notAvailable = 3,
  blocked = 4,
  declined = 5,
  deleted = 6,
  deletionRequested = 7,
}
