import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { postRoleTypeDO } from 'utils/rolesUtils';
import { Grid, Dialog, DialogTitle, DialogActions, IconButton, DialogContent, CircularProgress } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AddNewRoleType(props) {
    const { snackbar, setSnackbar, currentUser, getData } = props
    const [modalOpen, setModalOpen] = useState(false);
    const [roleTypeName, setRoleTypName] = useState('');
    const [roleNameError, setRoleNameError] = useState(false)
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleClickOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setRoleTypName('');
        setDescription('');
        setModalOpen(false);
    };

    const createNewRoleHandle = async () => {
        console.log('roleTypeName: ', roleTypeName);

        if (!roleTypeName) {
            setRoleNameError(true);
        } else {
            setRoleNameError(false);
        }
        const data = {
            name: roleTypeName,
            added_by: currentUser?.id
        }

        if (roleTypeName) {
            setLoader(true);
            const status = await postRoleTypeDO('dev', data);
            if (status === 201) {
                handleClose();
                getData();
                setLoader(false);
                setSnackbar({ ...snackbar, status: true, title: 'New role type was added successfully', color: 'success' });
            }
        } else {
            // Display an error message or handle validation feedback
            setLoader(false);
            console.error('Please fill in all required fields and select at least one user.');
        }
    }

    return (
        <React.Fragment>
            <MDButton
                variant="contained"
                color='secondary'
                onClick={handleClickOpen}
            >
                Type
            </MDButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 400, width: 400, height: 500, maxHeight: 500, position: 'absolute', top: '10vh', left: '40vw' }}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add new role type
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon color='#000000' />
                </IconButton>
                <DialogContent >

                    <MDInput
                        placeholder="Role type name"
                        value={roleTypeName}
                        size="small"
                        sx={{ mb: 2 }}
                        fullWidth
                        onChange={(e) => setRoleTypName(e.target.value)}
                        error={roleNameError}
                    />

                    <MDInput
                        value={description}
                        placeholder="Description"
                        rows="3"
                        disabled
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        fullWidth
                        // sx={{ width: 300, mb: 2 }}
                        error={descriptionError}
                    />

                    {/* <Autocomplete
                        // id="asynchronous-demo"
                        multiple
                        size="small"
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        onChange={(e, value) => setUsers(value)}
                        isOptionEqualToValue={(option, value) => option.first_name === value.first_name}
                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                        options={options}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="New Users"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    /> */}
                </DialogContent>
                <DialogActions sx={{ margin: 1 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={5.9} xl={5.9}>
                            <MDButton
                                variant="outlined"
                                color={'info'}
                                fullWidth
                                size="small"
                                onClick={() => handleClose()}
                            >
                                cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={6} xl={6}>
                            {loader ? (<CircularProgress color="success" size={25} thickness={5} />) : (
                                <MDButton
                                    variant="gradient"
                                    color={'info'}
                                    size="small"
                                    fullWidth
                                    onClick={() => createNewRoleHandle()}
                                >
                                    Create
                                </MDButton>
                            )}
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}