import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HIDDEN_ATTRIBUTES } from "utils/matchmakerUtils";
import { groupBy, snakeCaseToCamelCase, uniqueBy } from "utils/globalsUtils";
import { getAttrArray, loadAttributes } from "utils/attributesUtil";
import { visibiltyOptions } from "utils/attributesUtil";
import { Attribute, AttrResponseType } from "models/profile/Attribute";
import { Introduction } from "models/introduction/Introduction";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";
import CardItem from "components/alove/CardItem";
import { getQuestionDO, questionnarieListDo } from "utils/questionsUtils";
import colors from "assets/theme/base/colors";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getProfileResponses } from "utils/enduserUtils";
import { ProfileResponse } from "models/profile/ProfileResponse";

const MDTypography = require("components/MDTypography").default;

const headerCell = (value) => (
  <Grid
    xs={6}
    item
    display="flex"
    justifyContent="space-between"
    flexDirection="column"
    alignItems="center"
    gap={2}
  >
    <div></div>
    <MDTypography style={{ fontSize: "16px" }} fontWeight="bold" textAlign="center" lineHeight="1">
      {value}
    </MDTypography>

    <div
      style={{
        height: "3px",
        width: "30%",
        borderRadius: "7px",
        marginBottom: "25px",
      }}
    />
  </Grid>
);

export const ShidduchAttributesTable = ({ request }: { request: Introduction }) => {
  const [attributes, setAttributes] = useState([] as Attribute[]);
  const [cellHover, setCellHover] = useState("");
  const [folded, setFolded] = useState(true);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    loadAttributes().then((attrs) => {
      setAttributes(attrs);
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const initiatorName = request.initiatorUser?.firstName;
  const responderName = request.responderUser?.firstName;

  const cell = (value: string, match: boolean | null, align: string, key?: string) => {
    let color;
    if (match !== null) {
      color = match ? "#49EFBD" : "#F4357A";
      if (cellHover != key) {
        color += "3b";
      }
    }
    return (
      <Grid
        container
        sx={{ p: 1 }}
        bgcolor={color}
        borderRadius={1}
        item
        flexDirection={align == "right" ? "row-reverse" : "row"}
        wrap="nowrap"
        justifyContent="center"
        alignItems={"center"}
        onMouseEnter={() => setCellHover(key || "")}
        gap={2}
      >
        <MDTypography
          style={{ fontSize: "12px", whiteSpace: "pre-line" }}
          lineHeight="1"
          textAlign={align}
        >
          <p dangerouslySetInnerHTML={{ __html: value }}></p>
        </MDTypography>
        {align != "center" && (
          <Grid
            item
            xs={true}
            alignItems={"center"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <Divider
              style={{
                color: "black",
                borderStyle: "dotted",
                borderWidth: "2px",
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const line = (row) => (
    <Grid
      display="flex"
      flexDirection="row"
      gap={2}
      bgcolor={cellHover.startsWith(row.bioName) ? "#00000010" : ""}
    >
      {cell(row.bioName, null, "left")}
      {cell(row.initiatorBio, row.initiatorMatch, "center", row.bioName + "initiator")}
      {cell(row.responderPrefernce, row.initiatorMatch, "center", row.bioName + "initiator")}
      <Grid item bgcolor="grey" style={{ width: "5px", height: "50px" }} alignSelf="center"></Grid>
      {cell(row.responderBio, row.responderMatch, "center", row.bioName + "responder")}
      {cell(row.initiatorPrefernce, row.responderMatch, "center", row.bioName + "responder")}
      {cell(row.bioName, null, "right")}
    </Grid>
  );

  return (
    <Grid>
      {!isLandscape ? (
        <MDTypography
          style={{ fontSize: "16px" }}
          fontWeight="bold"
          textAlign="center"
          lineHeight="1"
        >
          To see the full table, please rotate your device
        </MDTypography>
      ) : (
        <Grid
          justifyContent="space-evenly"
          display="flex"
          flexDirection="column"
          sx={{ width: "100%" }}
          style={{ zIndex: 2, position: "relative" }}
        >
          <Grid display="flex" justifyContent="center" flexDirection="row" gap={1}>
            {headerCell("")}
            {headerCell(initiatorName + "'s bio")}
            {headerCell(responderName + "'s Pref")}
            {headerCell(responderName + "'s bio")}
            {headerCell(initiatorName + "'s Pref")}
            {headerCell("")}
          </Grid>

          <Grid display="flex" flexDirection="column" gap={2} sx={{ width: "100%" }}>
            {getAttrArray(attributes, request, true)
              .slice(0, folded ? 7 : Infinity)
              .map(line)}
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", mt: 2 }}
            style={{ cursor: "pointer" }}
            onClick={() => setFolded(!folded)}
          >
            <MDTypography
              style={{ fontSize: "16px" }}
              fontWeight="bold"
              textAlign="center"
              lineHeight="1"
            >
              {folded ? "See All" : "Hide"}
            </MDTypography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
