import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import MDInput from "components/MDInput";
import { Autocomplete, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { T } from "components/alove/Translator";
import { visibiltyOptions } from "utils/attributesUtil";
import { useMaterialUIController } from "context";

const categories = {
  100:[
    {
      label: "Expectations",
      value: "expectationsSettings",
    },
    {
      label: "Looks",
      value: "looksSettings",
    },
    {
      label: "Basic Title Profile",
      value: "basicTitleProfileProfileSettings",
    },
    {
      label: "Family",
      value: "familySettings",
    },
    {
      label: "Lifestyle",
      value: "lifestyleSettings",
    },
    {
      label: "Identity",
      value: "identitySettings",
    },
    {
      label: "Personal Details",
      value: "personalDetailsSettings",
    },
    {
      label: "Background",
      value: "backgroundSettings",
    }],
  101: [
    {
      label: "Expectations",
      value: "expectationsSettings",
    },
    {
      label: "Looks",
      value: "looksSettings",
    },
    {
      label: "Basic Title Profile",
      value: "basicTitleProfileProfileSettings",
    },
    {
      label: "Family",
      value: "familySettings",
    },
    {
      label: "Lifestyle",
      value: "lifestyleSettings",
    },
    {
      label: "Identity",
      value: "identitySettings",
    },
    {
      label: "Personal Details",
      value: "personalDetailsSettings",
    },
    {
      label: "Background",
      value: "backgroundSettings",
    }
  ],
  102: [
    {
      label: "Personal Details",
      value: "personalDetailsSettings",
    },
    {
      label: "Plan",
      value: "planSettings",
    },
    {
      label: "Personal Hashkafa",
      value: "personalHashkafaSettings",
    },
    {
      label: "Family",
      value: "familySettings",
    },
    {
      label: "Family Hashkafa",
      value: "familyHashkafaSettings",
    },
    {
      label: "Education",
      value: "educationSettings",
    },
    {
      label: "Employment",
      value: "employmentSettings",
    },
    {
      label: "Additional Info",
      value: "additionalInfoSettings",
    },
  ],
};

export default function AttributeConf(props) {
  const { attribute, setAttribute, question, setQuestion } = { ...props };
  const [controller] = useMaterialUIController();
  const {currentBrand} = controller;

  return (
    <MDBox p={2} lineHeight={1}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} xl={12}>
          <MDTypography variant="h6" fontWeight="medium">
            Profile Attribute
          </MDTypography>
        </Grid>
        <Grid item xs={12} xl={9}>
          <MDInput
            label="Title"
            placeholder="title"
            size="small"
            fullWidth
            value={question.questionTexts["attribute.txt_id"]}
            onChange={(e) => {
              question.questionTexts["attribute.txt_id"] = e.target.value;
              setQuestion({ ...question });
              setAttribute({
                ...attribute,
                name: e.target.value.replace(/ /g, "_").toLowerCase().substring(0, 20),
              });
            }}
          />
        </Grid>
        <Grid item xs={12} xl={3}>
          <Switch
            checked={attribute.user_editable}
            onChange={(e) => {
              setAttribute({ ...attribute, user_editable: e.target.checked });
            }}
          />
          <MDTypography variant="body2">User editable</MDTypography>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Autocomplete
            size="small"
            disableClearable
            options={["bio", "pref"]}
            value={attribute.attribute_group}
            renderInput={(params) => <TextField {...params} label="Viewed on screen" />}
            onChange={(e, o) => {
              setAttribute({ ...attribute, attribute_group: o });
            }}
          />
        </Grid>
        <Grid item xs={12} xl={3}>
          <Autocomplete
            size="small"
            disableClearable
            value={
              Object.values(visibiltyOptions).find((o) => o.value === attribute.default_policy) ||
              visibiltyOptions.PUBLIC
            }
            getOptionLabel={(option) => option.label}
            options={Object.values(visibiltyOptions)}
            renderInput={(params) => <TextField {...params} label="Visibility" />}
            onChange={(e, o) => setAttribute({ ...attribute, default_policy: o.value })}
          />
        </Grid>
        <Grid item xs={12} xl={3}>
          <Switch
            checked={attribute.user_toggleable}
            onChange={(e) => setAttribute({ ...attribute, user_toggleable: e.target.checked })}
          />
          <MDTypography variant="body2">Privacy editable</MDTypography>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Switch
            label="Sensitive attribute"
            checked={attribute.is_sensitive}
            onChange={(e) => setAttribute({ ...attribute, is_sensitive: e.target.checked })}
          />
          <MDTypography variant="body2">Sensitive attribute</MDTypography>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Autocomplete
            size="small"
            disableClearable
            value={categories[currentBrand.id].find((o) => o.value === attribute.attribute_category)}
            options={categories[currentBrand.id]}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Category" />}
            onChange={(e, v) => setAttribute({ ...attribute, attribute_category: v.value })}
          />
        </Grid>
        <Grid item xs={12} xl={3}>
          <MDInput
            label="Ordinal"
            size="small"
            fullWidth
            value={attribute.ordinal}
            type="number"
            onChange={(e) => setAttribute({ ...attribute, ordinal: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} xl={3}>
          <Switch
            checked={attribute.is_featured}
            onChange={(e) => setAttribute({ ...attribute, is_featured: e.target.checked })}
          />
          <MDTypography variant="body2">Featured</MDTypography>
        </Grid>
        <Grid item xs={12} xl={9}>
          <MDInput
            label="Extended Info"
            size="small"
            fullWidth
            value={question.questionTexts["attribute.extended_info_txt_id"]}
            onChange={(e) => {
              question.questionTexts["attribute.extended_info_txt_id"] = e.target.value;
              setQuestion({ ...question });
            }}
          />
        </Grid>{" "}
      </Grid>
    </MDBox>
  );
}
