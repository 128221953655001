import CardItem from "./CardItem";
import { Autocomplete, Card, Grid } from "@mui/material";
import { T } from "./Translator";
import { VerticalDivider } from "./VerticalDivider";
import { useMaterialUIController } from "context";
import { Permission, PermissionType } from "models/BO/BoRole";
import { MobileDotMenu } from "./MobileDotMenu";

const MDTypography = require("components/MDTypography/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export interface ActionsRowProps {
  title?: string;
  saveActions?: Action[];
  addActions?: Action[];
  filters?: any;
  page?: string;
  hostChanged?: (event: any, value: any) => void;
}

interface Action {
  label?: string;
  onClick?: () => void;
  color?: string;
  variant?: "contained" | "outlined";
  disabled?: boolean;
  role?: PermissionType;
  element?: JSX.Element;
}

export const ActionsRow = (props: ActionsRowProps): JSX.Element => {
  const { title, saveActions, addActions, filters } = props;
  const [controller] = useMaterialUIController();
  const { currentUser, currentRole } = controller;

  const renderAction = (action: Action) => {
    if (action?.label) {
      return (
        <MDButton
          key={action.label}
          onClick={action.onClick}
          color={action.color || "primary"}
          variant={action.variant || "outlined"}
          disabled={action.disabled}
        >
          <T>{action.label}</T>
        </MDButton>
      );
    } else {
      return action.element;
    }
  };

  const sholdShow = (action: Action) => {
    if (action?.role && props.page) {
      return currentRole.hasAccess(props.page, action.role);
    }
    return true;
  };

  const renderedAddActions = addActions?.filter(sholdShow).map(renderAction);
  const renderedSaveActions = saveActions?.filter(sholdShow).map(renderAction);

  return (
    <>
      {filters}
      {!!renderedAddActions?.length && !!filters?.length && <VerticalDivider />}
      {!!renderedAddActions?.length && <MDTypography variant="body2">Add New:</MDTypography>}
      {renderedAddActions}
      {!!renderedAddActions?.length && !!renderedSaveActions?.length && <VerticalDivider />}
      {renderedSaveActions}
    </>
  );
};

export const ActionsCard = (props: ActionsRowProps): JSX.Element => {
  const { title } = props;
  return (
    <Card sx={{ px: 4, mb: 1 }}>
      <Grid container alignItems="center" gap={1} justifyContent={"space-between"}>
        <Grid item sx={{ my: 4 }}>
          <MDTypography variant="h5" >
            <T>{title || "Actions"}</T>
          </MDTypography>
        </Grid>
        <MobileDotMenu>
          <ActionsRow {...props} />
        </MobileDotMenu>
      </Grid>
    </Card>
  );
};
