import { Grid } from "@mui/material";
import CardItem from "components/alove/CardItem";
import { Comm } from "models/content/Comm";
import React from "react";
import { PushForm } from "./pushForm";
import PushPreview from "./pushPreview";
import { ScreenModel } from "models/content/Screen";
import { PushWorkflow } from "./workflow";

export const PushContainer = ({
  comm,
  setCommObject,
  saveComm,
  screens,
}: {
  comm: Comm;
  setCommObject: any;
  saveComm: any;
  screens: ScreenModel[];
}) => {
  return (
    <React.Fragment>
      <Grid container flexDirection="column" gap={1} style={{ width: "49.5%" }}>
        <CardItem title="Edit push" id="comm">
          <PushForm comm={comm} setComm={setCommObject} />
        </CardItem>
        <CardItem title="Workflow" id="workflow">
          <PushWorkflow comm={comm} setComm={setCommObject} screens={screens} />
        </CardItem>
      </Grid>
      <CardItem title="Push preview" id="settings" halfWidth>
        <PushPreview comm={comm} />
      </CardItem>
    </React.Fragment>
  );
};
