import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteRoleDO } from 'utils/rolesUtils';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function DeleteRole(props) {
    const { row, snackbar, setSnackbar, getRoles } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const handleClickOpen = (e) => {
        setModalOpen(true);
        e.stopPropagation();
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const deleteRoleHandle = async () => {
        const deleteRes = await deleteRoleDO('dev', row.id);
        if (deleteRes === 200) {
            handleClose();
            setSnackbar({ ...snackbar, status: true, title: 'Role deleted successfully', color: 'success' });
            getRoles();
        } else {
            setSnackbar({ ...snackbar, status: true, title: 'Failed to delete role', color: 'error' });
        }
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}>
                <DeleteOutlineIcon />
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ maxWidth: 800, width: 800, height: 500, maxHeight: 500, position: 'absolute', top: '12vh', left: '35vw' }}
            >
                {/* <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton> */}
                <DialogTitle sx={{ m: 1, p: 2 }} id="customized-dialog-title">
                    Are you sure you want to delete this role?

                </DialogTitle>
                <DialogContent>
                    <MDTypography>
                        {row?.role_name}
                    </MDTypography>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6}>
                            <MDButton
                                variant="outlined"
                                color="info"
                                fullWidth
                                onClick={handleClose}
                            >
                                Cancel
                            </MDButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                fullWidth
                                onClick={deleteRoleHandle}
                            >
                                Delete
                            </MDButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}