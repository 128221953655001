export default {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        // 'actions': false,
        // "isFilled": false,
      },
    },
  },


  labels: [
    { id: 99, name: "acceess" },
    { id: 100, name: "edit" },
    { id: 101, name: "add_new" },
    { id: 102, name: "delete" },
    { id: 103, name: "duplicate" },
    { id: 104, name: "save" },
    { id: 105, name: "ask_to_publish" },
    { id: 106, name: "publish" },
    { id: 107, name: "host" },
    { id: 108, name: "lang" },
    { id: 109, name: "manage" },
  ],
};
