export class BoRole {
  id: string;
  role_name: string;
  permissions: Permission;
  description: string;
  created: Date;
  type: string;
  created_by: number;
  brand_id: number;
  is_manager: boolean;
}

export class Permission {
  pages: number[];
  controls: Control[];

  hasAccess(page: string|undefined, control: PermissionType): boolean {
    // if (location.hostname === "localhost") {
    //   return true;
    // }
    const pageId = this.pages?.find((p) => p.toString() == page);
    if (!pageId) {
      return false;
    }
    const controlId = this.controls.filter(x=>x).find(
      (c) => c.page == page && c.access.includes(control)
    );
    return !!controlId;
  }
}

export class Control {
  page: string;
  access: PermissionType[];
}

export enum PermissionType {
  Access = 99,
  Edit = 100,
  AddNew = 101,
  Delete = 102,
  Duplicate = 103,
  AskToPublish = 105,
  Publish = 106,
  Host = 107,
  Lang = 108,
  ViewAllOptions = 109,

  
  Manager = 200
}
