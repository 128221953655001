import { Grid } from "@mui/material";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { DropdownButton } from "components/alove/DropDownButton";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import { TwilioTemplate } from "models/content/TwilioTemplate";
import { useEffect, useState } from "react";
import { saveWATemplate } from "utils/commUtils";

const MDInput = require("components/MDInput/index.js").default;
export const WAAddNewTemplate = ({ refresh }) => {
  const [controller, dispatch] = useMaterialUIController();
  const [template, setTemplate] = useState({ friendlyName: "", body: "", action: "" });

  return (
    <AloveDialog
      dialogTitle="Add New WhatsApp Template"
      dialogType={DialogType.AddWATemplate}
      handleConfirm={() =>
        saveWATemplate(template)
          .then(() => addAlert(dispatch, "Template saved successfully"))
          .then(() => refresh())
      }
      handleDismis={() => setTemplate({ friendlyName: "", body: "", action: "" })}
      valid={!!template.friendlyName && !!template.body && !!template.action}
      width="600px"
      dialogConfirm="Save"
    >
      <MDInput
        onChange={(e) =>
          setTemplate({ ...template, friendlyName: e.target.value.replace(/\s/g, "_").toLocaleLowerCase() })
        }
        label="Template Name"
      />
      <MDInput
        label="Template Body"
        onChange={(e) => setTemplate({ ...template, body: e.target.value })}
        multiline
        rows={3}
      />
      <Grid item>
        <DropdownButton
          label="Choose Action"
          onClick={(v) => setTemplate({ ...template, action: v.value })}
          options={[
            {
              value: "openApp",
              label: "Open ZUUG App",
            },
            {
              value: "boOffice",
              label: "Open MM platform",
            },
          ]}
        />
      </Grid>
    </AloveDialog>
  );
};
