import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, Chip, Divider, Icon, List } from "@mui/material";

import { GridColDef } from "@mui/x-data-grid";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { getUsersStatsDO, getSystemUsersDO } from "utils/systemUsersUtils";
import {
  getByEmailPhone,
  getPendingProfiles,
  getEndusersList,
  getProfileStats,
  getProfileMatchScores,
  getByUserId,
} from "utils/enduserUtils";
import { EndUser } from "models/profile/EndUser";
import { StatsPanel } from "components/alove/StatsPanel";
import { TwoSizeChip } from "components/alove/Chips";
import { PermissionType } from "models/BO/BoRole";
import { brandLogo, getBrandsDO } from "utils/brandsUtils";
import { Brand } from "models/BO/Brand";
import { Profile, ProfileAsset, ProfileStatus } from "models/profile/Profile";
import {
  formatDateTime,
  formatDate,
  avatarUrl,
  tr,
  formtHeight,
  formatAge,
} from "utils/globalsUtils";
import { MatchScore, MatchScoreDB } from "models/introduction/matchScore";
import { findBioFromPref, loadAttributes } from "utils/attributesUtil";
import { Attribute } from "models/profile/Attribute";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
const { useMaterialUIController } = require("context");
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
const MDAvatar = require("components/MDAvatar/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

function EndUsersMatchScores() {
  const pageNumber = "2.7";
  const [controller, dispatch] = useMaterialUIController();

  const [attributes, setAttributes] = useState([] as Attribute[]);
  const [profile, setProfile] = useState<{
    user: EndUser;
    profile: Profile;
    profileAssets: ProfileAsset[];
  }>();

  useEffect(() => {
    loadAttributes().then((attrs) => {
      setAttributes(attrs);
    });
  }, []);

  const { currentBrand, currentHost, currentRole } = controller;
  const navigate = useNavigate();

  const partnerBioCell = (params) => {
    const bioAttr = findBioFromPref(attributes, params.row.dealBreakerReason);
    const p =
      params.row.isDealBreaker === MatchScoreDB.Responder ? profile?.profile : params.row.profile;
    const values = bioAttr?.map((attr) => p.attributesValues[attr.attr_name]?.[0]);
    if (params.row.dealBreakerReason === "heightPref") {
      return formtHeight(values[0]);
    }
    if (params.row.dealBreakerReason === "prefAge") {
      return formatAge(values[0]);
    }
    return tr(values?.join(", ") || "");
  };

  const renderPrefCell = (params) => {
    const p =
      params.row.isDealBreaker === MatchScoreDB.Initiator ? profile?.profile : params.row.profile;

    if (params.row.dealBreakerReason === "heightPref") {
      return (
        formtHeight(p?.attributesValues[params.row.dealBreakerReason]?.[0]) +
        " - " +
        formtHeight(p?.attributesValues[params.row.dealBreakerReason]?.[1])
      );
    }

    return (
      p?.attributesValues[params.row.dealBreakerReason]?.map((v) => tr(v?.toString())).join(", ") ||
      ""
    );
  };

  let { id } = useParams();

  useEffect(() => {
    getByUserId(id!).then((data) => {
      setProfile({
        user: Object.assign(new EndUser(), data.user),
        profile: Object.assign(new Profile(), data.profile),
        profileAssets: data.profileAssets,
      });
    });
  }, [id]);

  const getProfileMS = async (env, filter, page) => {
    return getProfileMatchScores(id!, filter,page);
  };

  const columns: TableColumnProps<MatchScore>[] = [
    {
      headerName: "",
      field: "initiatorUserAvatar",
      width: 50,
      renderCell: (params) => (
        <Grid container alignItems="center" height="100%">
          <Avatar src={avatarUrl(params.row.profile.profileId, params.row.profileImage?.uri)} />
        </Grid>
      ),
    },
    {
      headerName: "Potential",
      field: "initiatorUser",
      width: 100,
      renderCell: (params) => params.row.user?.firstName,
    },
    {
      field: "initiatorBioScore",
      headerName: "Initiator Bio Score",
      width: 50,
    },
    {
      field: "responderBioScore",
      headerName: "Responder Bio Score",
      width: 50,
    },
    {
      field: "predictorScore",
      headerName: "Predictor Score",
      width: 50,
    },
    {
      field: "dealBreakerReason",
      headerName: "Deal Breaker Field",
      renderCell: (params) =>
        attributes.find((a) => a.attr_name === params.row.dealBreakerReason)?.editing_question_txt,
      flex: 1,
    },
    {
      field: "dealBreakerValue",
      headerName: "DB Value",
      renderCell: partnerBioCell,
      flex: 1,
    },
    {
      field: "dealBreakerPref",
      headerName: "DB Pref",
      renderCell: renderPrefCell,
      flex: 2,
    },
    {
      field: "isDealBreaker",
      headerName: "Deal Breaker",
      renderCell: (params) => MatchScoreDB[params.row.isDealBreaker],
      width: 100,
    },
    {
      field: "comare",
      headerName: "Compare",
      width: 150,
      renderCell: (params) => (
        <MDButton
          small
          variant="text"
          color="primary"
          onClick={() =>
            navigate(
              `/introductions/compare?initiatorProfileId=${params.row.initiatorProfileId}&responderProfileId=${params.row.responderProfileId}`
            )
          }
        >
          Compare
        </MDButton>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="End Users" />
      <Grid container gap={1}>
        <Grid item xs={12}>
          <SMEHeader
            formData={profile?.user}
            indications={[
              {
                title: "Gender:",
                value: profile?.profile?.attributesValues["gender"]?.[0],
              },
              {
                title: "Age:",
                value: profile?.user?.age,
              },
              {
                title: "Height:",
                value: formtHeight(profile?.profile?.attributesValues["height"]?.[0]),
              },
              {
                title: "Build:",
                value: profile?.profile?.attributesValues["bodyType"]?.[0],
              }
              

              
            ]}
            saveProfile={() => {}}
          />
        </Grid>
        <TablePage
          title={"Potential Matches"}
          table={{
            columns,
            loadNext: getProfileMS,
            getRowId: (row) => row.responderProfileId,
          }}
          filters={[
            {
                label: "Attribute",
                type: "select",
                options: attributes
                    .filter((a) => a.attr_group == 'pref')
                    .map((a) => ({ key: a.attr_name, label: a.editing_question_txt })),
            },
            {
                label: "hideDealBreakers",
                type: "switch"
            }
          ]}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default EndUsersMatchScores;
