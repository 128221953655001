import { Grid } from "@mui/material";
import { BrandLogo } from "components/alove/BrandLogo";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams, useSearchParams } from "react-router-dom";

const MDTypography = require("components/MDTypography").default;
const MDButton = require("components/MDButton").default;

export const ErrorPage = (props) => {
    const { error, resetError } = props
  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Error" />
      <Grid container justifyContent="center" alignItems="center" flexDirection="column" gap={2}>
        <BrandLogo />
        <MDTypography variant="h1" fontWeight="bold" color="error">
            An error occured
        </MDTypography>
        <MDTypography variant="h2" fontWeight="bold" color="error">
            {error.toString()}
        </MDTypography>
        <MDTypography variant="body2" xs={4}>
            {error.stack}
        </MDTypography>
        <MDButton onClick={resetError} variant="contained" color="primary">
            Go back
        </MDButton>
      </Grid>
    </DashboardLayout>
  );
};
