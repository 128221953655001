import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";
import { Avatar } from "@mui/material";
import { setCurrentDialog, useMaterialUIController } from "context";
import { TablePage } from "components/alove/TablePage";
import { getIntroductions } from "utils/introductionUtils";
import { avatarUrl, formatDate, snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Introduction, IntroductionStatus } from "models/introduction/Introduction";
import MDBox from "components/MDBox";
import { EndUser } from "models/profile/EndUser";
import { AddIntoIntroductionDialog } from "./components/addIntroductionDialog";
import { DialogType } from "components/alove/AloveDialog";
import { useState } from "react";
import { PermissionType } from "models/BO/BoRole";
import { RemoveRedEye } from "@mui/icons-material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

let navigate;

function IntroductionsScreen() {
  const pageNumber = "2.9";
  const [controller, dispatch] = useMaterialUIController();
  const [refresh, setRefresh] = useState(false);
  navigate = useNavigate();

  const getShRequests = async (host, filters, page) => {
    let allRequests = await getIntroductions(
      page++,
      filters["status"] || "",
      filters["date"]?.["before"] || "",
      filters["date"]?.["after"] || "",
      filters["matchmaker"] || "",
      filters["shidduchId"] || "",
      refresh
    );
    allRequests?.forEach((r) => (r.id = r.introductionId));
    return allRequests?.map((r) => Object.assign(new Introduction(), r)).filter((r: Introduction) => {
      return r.initiatorProfile.isInternal == !!filters["showInternal"];
    });
  };

  const name = (user: EndUser) => {
    return (
      <div
        onClick={() => navigate(`/endUsers/${user?.id}/introductions`)}
        style={{ cursor: "pointer" }}
      >
        {user?.firstName + " " + (user?.lastName || "")}
      </div>
    );
  };

  const columns: GridColDef<Introduction>[] = [
    {
      headerName: "Intro ID",
      field: "introductionId",
    },
    {
      headerName: "Created",
      field: "created",
      renderCell: (params) => formatDate(params.row.created),
    },
    {
      headerName: "Due",
      field: "mustRespondUntil",
      renderCell: (params) => formatDate(params.row.mustRespondUntil),
    },
    {
      headerName: "",
      field: "initiatorUserAvatar",
      width: 50,
      renderCell: (params) => (
        <Grid container alignItems="center" height="100%">
          <Avatar src={avatarUrl(params.row.initiatorProfileId, params.row.initiatorImage?.uri)} />
        </Grid>
      ),
    },
    {
      headerName: "Initiator",
      field: "initiatorUser",
      flex: 1,
      renderCell: (params) => name(params.row.initiatorUser),
    },
    {
      field: "initiatorMatchScore",
      valueFormatter: (v) => Math.round(v) + "%",
      headerName: "% Match",
      width:10
    },
    {
      field: 'initiatorInspected',
      renderCell: (params) => <Tooltip title="Inspected by initiator"><RemoveRedEye className={params.value ? "" : "hidden"} /></Tooltip>,
      headerName: "Inspected",
      width:10
    },
    {
      headerName: "",
      field: "responderUserAvatar",
      width: 50,
      renderCell: (params) => (
        <Grid container alignItems="center" height="100%">
          <Avatar src={avatarUrl(params.row.responderProfileId, params.row.responderImage?.uri)} />
        </Grid>
      ),
    },
    {
      headerName: "Responder",
      field: "responderUser",
      flex: 1,
      renderCell: (params) => name(params.row.responderUser),
    },
    {
      field: "responderMatchScore",
      valueFormatter: (v) => Math.round(v) + "%",
      headerName: "% Match",
      width:10
    },
    {
      field: 'responderInspected',
      renderCell: (params) => <Tooltip title="Inspected by responder"><RemoveRedEye className={params.value ? "" : "hidden"} /></Tooltip>,
      headerName: "Inspected",
      width:10
    },
    {
      headerName: "Status",
      field: "statusId",
      flex: 1,
      valueFormatter: (v, p) =>
        snakeCaseToCapitalizedWords(IntroductionStatus[v])
          .replace("Initiator", p.initiatorUser?.firstName || "")
          .replace("Responder", p.responderUser?.firstName || ""),
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MDButton>View</MDButton>}
          label="Edit"
          onClick={() => navigate(`/introductions/${params.id}`)}
        />,
      ],
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          "& .urgent": {
            color: "#F4357A",
          },
          "& .hidden": {
            color: "transparent !important",
          },
        }}
      >
        <TablePage
          title="Introductions List"
          actions={{
            page: pageNumber,
            addActions: [
              {
                label: "Introduction",
                onClick: () => setCurrentDialog(dispatch, DialogType.AddIntroduction),
                variant: "contained",
                role: PermissionType.AddNew
              },
            ],
          }}
          table={{
            columns,
            loadNext: getShRequests,
            pagination: true,
            lineClick: (row) => navigate(`/introductions/${row.id}`),
          }}
          refresh={refresh}
          filters={[
            {
              label: "shidduchId",
              type: "search",
            },
            {
              label: "status",
              multiple: true,
              options: Object.keys(IntroductionStatus)
                .filter((key) => isNaN(Number(key)))
                .map((key) => ({
                  key: IntroductionStatus[key],
                  label: snakeCaseToCapitalizedWords(key),
                })),
            },
            {
              label: "date",
              type: "date",
            },
            
            {
              label: "showInternal",
              type: "switch",
            }
          ]}
        />
      </Box>
      <AddIntoIntroductionDialog refresh={() => setRefresh(!refresh)} />
    </DashboardLayout>
  );
}

export default IntroductionsScreen;
