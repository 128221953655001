import colors from "assets/theme/base/colors";
import { Statusable } from "./BoUser";

export class Brand implements Statusable {
    id: number;
    name: string;
    contact_email: string;
    user_pool_id: string;
    folder: string;
    userpool_client_id: string;
    avatar: string;
    created: string;
    updated: string;
    is_active: boolean;
    roles: any[];
    

    get isAlove () {
        return this.name === 'a-love';
    }

    get status(): string {
        return this.is_active ? "Active" : "Inactive";
    }

    get statusLabel(): string {
        return this.is_active ? "Active" : "Inactive";
    }

    get statusColor(): string {
        return this.is_active ? 'success' : 'error';
    }

    get statuses(): { key: string; value: string }[] {
        return [
            { key: "Active", value: "Active" },
            { key: "Inactive", value: "Inactive" }
        ];
    }

    get statusReason(): string {
        return ""
    }

}