import { Comm, PushTemplate } from "models/content/Comm";
import { useEffect, useState } from "react";
import { defaultAvatarUrl } from "utils/globalsUtils";
import iphone from "assets/images/alove/iphone.png";
import iphoneBackgeound from "assets/images/alove/iphone-background.jpg";
import mujualScreenshot from "assets/images/alove/mujual-screenshot.png";
import { Grid } from "@mui/material";
import { BrandLogo } from "components/alove/BrandLogo";
import { useMaterialUIController } from "context";

const MDTypography = require("components/MDTypography/index.js").default;


export default function PushPreview({ comm }: { comm: Comm }) {
  const [controller, dispatch] = useMaterialUIController();
  const { currentBrand } = controller;

  const addParams = (text?: string) => {
    const fields = {
      ...comm.email,
      INITIATOR_NAME: "John Doe",
      RESPONDER_NAME: "Jane Doe",
      PROFILE_NAME: "John Doe",
      "@PARTNER_NAME": "Jane Doe",
      "@CALL_TYPE": "voice",
      M_LINK: defaultAvatarUrl("m"),
      W_LINK: defaultAvatarUrl("w"),
    };
    Object.keys(fields).forEach((key) => {
      text = text?.replace(key, fields[key]);
    });
    return text;
  };

  return (
    <Grid container gap={2} flexDirection="column">
      <MDTypography variant="body2">While app is closed:</MDTypography>
      <div
        style={{
          backgroundImage: `url(${iphone})`,
          backgroundSize: "cover",
          width: "349px",
          height: "300px",
          padding: "80px 20px 0px 22px",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${iphoneBackgeound})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",

            paddingTop: "5px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "95%",
              padding: "10px",
              marginLeft: "5px",
              borderRadius: "10px",
            }}
          >
            <Grid container alignItems="center" gap={1}>
              <Grid item>
                <BrandLogo size="20px" />
              </Grid>
              <MDTypography variant="body2">{currentBrand?.name} • 1m</MDTypography>
            </Grid>
            <MDTypography variant="h6">{addParams(comm.push?.title)}</MDTypography>
            <MDTypography variant="body2">{addParams(comm.push?.body)}</MDTypography>
          </div>
        </div>
      </div>
      <MDTypography variant="body2">
        While app is open:
      </MDTypography>
      <div
        style={{
          backgroundImage: `url(${iphone})`,
          backgroundSize: "cover",
          width: "349px",
          height: "300px",
          padding: "80px 20px 0px 22px",
        }}
      >
        <Grid
          style={{
            backgroundImage: `url(${mujualScreenshot})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",

            paddingTop: "50px",
          }}
          container
          flexDirection={comm.push?.template == PushTemplate.InnerMessage ? "column" : "column-reverse"}
        >
          {comm.push?.template == PushTemplate.InnerMessage && (
            <Grid
              style={{
                backgroundColor: "white",
                width: "95%",
                padding: "10px",
                marginLeft: "5px",
                borderRadius: "10px",
                boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
              }}
            >
              <MDTypography variant="body2" style={{ color: "#5418EA" }}>
                {addParams(comm.push?.body)}
              </MDTypography>
            </Grid>
          )}

          {comm.push?.template == PushTemplate.Popup && (
            <Grid
              style={{
                backgroundColor: "white",
                width: "85%",
                height: "50%",
                padding: "10px",
                marginLeft: "20px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
              }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <MDTypography variant="title" style={{ color: "#5418EA" }}>
                Popup
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  );
}
