import React, { useState, useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import imgURL from "assets/images/brands/alove_product-admin-1.png";

function EmptyPage() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <Grid
                            container
                            spacing={3}
                            justify-content="center"
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: '40vh' }}
                        >
                            <Grid item xs={12} xl={12} justify-content="center" alignItems="center">
                                <img
                                    src={imgURL}
                                    style={{
                                        width: '500px',
                                        fit: "cover",
                                        borderRadius: "50%"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}
export default EmptyPage;