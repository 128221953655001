import { Comm, EmailTemplate } from "models/content/Comm";
import { useEffect, useState } from "react";
import { defaultAvatarUrl } from "utils/globalsUtils";

import { getEmailTemplate } from "utils/networkUtils";

export default function EmailPreview({ comm }: { comm: Comm }) {
  const [mjmlContent, setMjmlContent] = useState("");
  const [template, setTemplate] = useState();
  useEffect(() => {
    if (!comm.email?.template) return;
    getEmailTemplate(comm.email?.template as EmailTemplate).then((res) => {
      setTemplate(res.data);
    });
  }, [comm.email?.template]);

  useEffect(() => {
    if (!template) return;
    let mjmlHtml = template as string;

    const fields = {
      ...comm.email,
      INITIATOR_NAME: "John Doe",
      RESPONDER_NAME: "Jane Doe",
      PROFILE_NAME: "John Doe",
      "@PARTNER_NAME": "Jane Doe",
      M_LINK: defaultAvatarUrl("m"),
      W_LINK: defaultAvatarUrl("w"),
    };
    Object.keys(fields)
      .filter((k) => !fields[k] || isNaN(Number(fields[k])))
      .forEach((key) => {
        mjmlHtml = mjmlHtml?.replace(key, fields[key]?.replace(/(?:\r\n|\r|\n)/g, "<br>") || "");
      });

    setMjmlContent(mjmlHtml);
  }, [comm, template]);
  return mjmlContent ? (
    <div style={{ letterSpacing: 0 }} dangerouslySetInnerHTML={{ __html: mjmlContent }}></div>
  ) : (
    <div>loading</div>
  );
}
