import { Grid } from "@mui/material";
import { ScreenModel } from "models/content/Screen";
import { useState } from "react";
import { useEffect } from "react";

const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export const Slides = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  return (
    <Grid
      container
      spacing={2}
      style={{
        backgroundColor: "#f0f0f0",
        borderRadius: "5px",
        padding: "5px 5px",
        marginTop: "5px",
        marginLeft: "0px",
      }}
    >
      {screen.slides.map((text, index) => {
        return (
          <Grid item xs={12} key={index} container justifyContent="space-between">
            <Grid item xs={8}>
              <MDInput
                label="Text"
                value={text}
                fullWidth
                multiline
                onChange={(e: any) => {
                  screen.extra[index] = e.target.value;
                  setScreen({ ...screen, extra: screen.extra });
                }}
              />
            </Grid>
            {index === 0 ? null : (
              <Grid item xs={3}>
                <MDButton
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setScreen({
                      ...screen,
                      extra: screen.extra.filter((_, i) => i !== index),
                    });
                  }}
                >
                  Remove
                </MDButton>
              </Grid>
            )}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <MDButton
          variant="contained"
          color="primary"
          onClick={() => {
            setScreen({ ...screen, extra: [...screen.extra, ""] });
          }}
        >
          Add Text
        </MDButton>
      </Grid>
    </Grid>
  );
};
