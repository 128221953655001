import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { ticketsColumns } from "./components/requestItem";
import MDButton from "components/MDButton";
import {
    Autocomplete,
    Avatar,
    CircularProgress,
    ListItemAvatar,
    ListItemText,
    TextField,
    Box, List, ListItem, Grid, Card
} from "@mui/material";
import { T } from "components/alove/Translator";
import { useMaterialUIController } from "context";
import { getAdminStats } from "utils/matchmakerUtils";
import { TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BO/BoRole";
import { getBrandsDO } from "utils/brandsUtils";
import { batchListByBrandDO } from 'utils/questionsUtils';

let navigate;
const host = 'dev';
const pageNumber = "4.3"

const action = (request, refresh) => { };

function CustomerSupportTickets() {
    navigate = useNavigate();
    const [controller, dispatch] = useMaterialUIController();
    const { currentUser, currentRole } = controller;
    const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);

    const [brands, setBrands] = useState([]);
    const [realBrands, setRealBrands] = useState([
        { id: 100, key: 'a-love', label: 'a-love' },
        { id: 101, key: 'mujual', label: 'mujual' },
        { id: 102, key: 'zuug', label: 'ZUUG' }
    ]);
    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setBrands(await getBrandsDO(host));
    }

    const getTableData = async (host, filters, page) => {
        console.log('filters: ', filters);
        console.log('page: ', page);
        
        
        if (filters.hasOwnProperty('brand') && filters.brand) {
            const brand = brands.find(b => b.folder === filters.brand);
            if (brand) {
                filters.brand = brand.id;
            }
        }

        if (filters.hasOwnProperty('question') && filters.question) {
                filters.question = question;
        }

        const tableRes = await batchListByBrandDO(host, filters.brand);
        console.log('tableRes: ', tableRes);

        return tableRes;
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box
                sx={{
                    '& .urgent': {
                        color: '#F4357A'
                    },
                    '& .hidden': {
                        color: 'transparent !important'
                    }
                }}
            >
                <TablePage
                    title="Quetions List"
                    table={{
                        columns: ticketsColumns(isAdmin, action, allUsers),
                        loadNext: getTableData,
                        pagination: true,
                        // lineClick: (row) => navigate(`/customerSupportTickets/${Number(row?.id)}`)

                    }}
                    filters={[
                        {
                            label: "question",
                            type: "search",
                        },
                        {
                            label: "hosts",
                            hidden: isAdmin ? false : null,
                            options: [
                                { id: 'dev', label: 'dev' },
                                { id: 'staging', label: 'staging' },
                                { id: 'prod', label: 'prod' }
                            ],
                            defaultValue: { id: 'staging', label: 'staging' },
                            disableClearable: true
                        },
                        {
                            label: "brand",
                            hidden: isAdmin ? false : null,
                            // options:brands
                            options: [
                                { id: 100, key: 'a-love', label: 'a-love' },
                                { id: 101, key: 'mujual', label: 'mujual' },
                                { id: 102, key: 'zuug', label: 'ZUUG' }
                            ],
                            defaultValue:  { id: 100, key: 'a-love', label: 'a-love' },
                            disableClearable: true
                        }
                    ]}
                />
            </Box>
        </DashboardLayout>
    );
}
export default CustomerSupportTickets;