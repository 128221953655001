import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Slider from "@mui/material/Slider";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Switch from "@mui/material/Switch";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MDDatePicker from "components/MDDatePicker";
import DeleteResponseModal from "./deleteResponseModal";
import Divider from "@mui/material/Divider";
import Globals from "./../data/data";
import { toCapitalizedWords } from "utils/globalsUtils";
import { RangeScalResponse } from "./rangeScaleResponse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RootRef } from "@material-ui/core";
import { List, ListItem } from "@mui/material";

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';

export default function Response(props) {
  const {
    question,
    setQuestion,
    responseSwitcher,
    setResponseSwitcher,
    singleChoiceDropdownValues,
    updateResponseSingleChoiceDropdownValues,
    addMoreResponseSingleChoiceDropdownValues,
    removeResponseSingleChoiceDropdownValues,
    questionResponseForEdit,
    questionID,
    batchQuestionList,
    localQuestionsWithAnswers,
    addAutoResponse,
    removeAutoResponse,
  } = { ...props };

  console.log("batchQuestionList; ", batchQuestionList);
  console.log("localQuestionsWithAnswers; ", localQuestionsWithAnswers);
  // console.log('responsesText: ', responsesText);
  console.log("questionResponseForEdit: ", questionResponseForEdit);

  const [selectedQuestion, setSelectedQuestion] = React.useState([]);
  const bIsFreeText = question.responseType?.name == "Free Text";

  const onDragEnd = (result) => {
    let responses = Array.from(singleChoiceDropdownValues);
    console.log("beforeresponses: ", responses);
    const sourceResponse = singleChoiceDropdownValues[result.source.index];
    responses = responses.filter((response) => response.id !== sourceResponse.id);
    responses.splice(result.destination.index, 0, sourceResponse);
    responses.forEach((response, index) => {
      response.ordinal = index;
      updateResponseSingleChoiceDropdownValues(response.id, { ordinal: index });
    })

    console.log("afterresponses: ", responses);

  }


  return (
    <MDBox pt={0} pb={3}>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} xl={3}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  Response
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} xl={8}></Grid>
            {["Single Choice", "Single Choice Dropdown", "Multiple Choice"].includes(
              question?.responseType?.name
            ) ? (
              <Grid item xs={1} xl={1} sx={{ textAlign: "center" }}>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Core
                  </MDTypography>
                </MDBox>
              </Grid>
            ) : null}
          </Grid>
          {["Scale", "Range"].includes(question.responseType?.name) && (
            <RangeScalResponse {...props} />
          )}
          {["Free Text"].includes(question.responseType?.name) ? (
            <Grid container spacing={3} alignItems="center" mb={2}>
              <Grid item xs={4} xl={4}>
                <Autocomplete
                  defaultValue="text"
                  getOptionLabel={(option) => toCapitalizedWords(option)}
                  value={question?.response_settings?.inputType}
                  onChange={(event, newValue) => {
                    question.response_settings ||= {};
                    question.response_settings.inputType = newValue;
                    setQuestion({ ...question });
                  }}
                  size="small"
                  // id="controllable-states-demo"
                  options={[
                    "text",
                    "multiline",
                    "number",
                    "phone",
                    "datetime",
                    "emailAddress",
                    "url",
                    "visiblePassword",
                    "name",
                    "address",
                    "none",
                  ]}
                  renderInput={(params) => <TextField {...params} label="Input type" />}
                />
              </Grid>
              <Grid item xs={4} xl={4}>
                <MDInput
                  placeholder="Place holder"
                  value={question?.questionTexts["vis_settings.placeholder"]}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    question.questionTexts["vis_settings.placeholder"] = e?.target?.value;
                    setQuestion({ ...question });
                  }}
                />
              </Grid>
              <Grid item xs={4} xl={4}>
                <MDInput
                  placeholder="Max characters"
                  value={question?.response_settings?.maxLength}
                  size="small"
                  fullWidth
                  type="number"
                  onChange={(e) => {
                    question.response_settings ||= {};
                    question.response_settings.maxLength = e?.target?.value;
                    setQuestion({ ...question });
                  }}
                />
              </Grid>
            </Grid>
          ) : null}

          {["Date Picker"].includes(question.responseType?.name) ? (
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={4} xl={4}></Grid>

              <Grid item xs={4} xl={4}>
                <MDDatePicker
                  input={{ placeholder: "Select max date" }}
                  onChange={(value) => console.log("value: ", value)}
                  // value='2024-02-02'
                />
              </Grid>
              <Grid item xs={4} xl={4}></Grid>
            </Grid>
          ) : null}

          {["Location"].includes(question.responseType?.name) ? (
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={4} xl={4}></Grid>

              <Grid item xs={4} xl={4}>
                <MDInput
                  placeholder="Input location"
                  // value={input.response}
                  size="small"
                  fullWidth
                  // onChange={(e) => {
                  //     updateResponseSingleChoiceDropdownValues(input?.id, { response: e?.target?.value })
                  // }}
                />
              </Grid>
              <Grid item xs={4} xl={4}></Grid>
            </Grid>
          ) : null}

          {/* {question.responseType?.name === 'Single Choice Dropdown' ? ( */}
          {[1, 2, 3, 8].includes(question?.responseType?.id) ? (
            <MDBox>
              {bIsFreeText && (
                <MDTypography variant="h6" fontWeight="medium">
                  Free text suggestions
                </MDTypography>
              )}
              <Grid container spacing={3} alignItems="center" pt={2}>
                {[3, 8].includes(question?.responseType?.id) && [
                  <Grid item xl={2}>
                    <MDInput
                      label={"Min selections"}
                      size="small"
                      fullWidth
                      type="number"
                      value={question?.response_settings?.min_selections}
                      onChange={(e) => {
                        question.response_settings ||= {};
                        question.response_settings.min_selections = e?.target?.value;
                        setQuestion({ ...question });
                      }}
                    />
                  </Grid>,
                  <Grid item xl={2}>
                    <MDInput
                      label={"Max selections"}
                      size="small"
                      fullWidth
                      type="number"
                      value={question?.response_settings?.max_selections}
                      onChange={(e) => {
                        question.response_settings ||= {};
                        question.response_settings.max_selections = e?.target?.value;
                        setQuestion({ ...question });
                      }}
                    />
                  </Grid>,
                ]}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => {
                      return (
                        <RootRef rootRef={provided.innerRef}>
                          <List sx={{ width: "100%" }}>
                            {singleChoiceDropdownValues.map((response, index) => {
                              const items = question?.automaticlAnswers.filter(
                                (item) => item?.answer_id == response?.id
                              );
                              console.log("batchQuestionList: ", batchQuestionList);
                              return (
                                <Draggable key={response.id} draggableId={response.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div 
                                    
                                    ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Grid item xs={12} xl={12}>
                                          {/* <Grid item xs={mainLength} xl={mainLength}> */}
                                          <Grid container spacing={3} alignItems="center">
                                            <Grid item xs={6.5} xl={4}>
                                              <Tooltip
                                                title="Attention: You're About to Change a Key Text
Before saving, ensure that your change won't negatively affect other questions or sections of the app. 
Only proceed when you're confident it won't cause unintended consequences."
                                              >
                                                <MDInput
                                                  placeholder={
                                                    bIsFreeText ? "Suggestion" : "Response"
                                                  }
                                                  value={response?.response}
                                                  size="small"
                                                  fullWidth
                                                  onChange={(e) => {
                                                    updateResponseSingleChoiceDropdownValues(
                                                      response?.id,
                                                      {
                                                        response: e?.target?.value,
                                                      }
                                                    );
                                                  }}
                                                />
                                              </Tooltip>
                                            </Grid>

                                            <Grid item xs={3} xl={2}>
                                              {!bIsFreeText && (
                                                <Autocomplete
                                                  disableClearable
                                                  getOptionLabel={(option) => option?.name}
                                                  value={
                                                    response?.values
                                                      ? response?.values
                                                      : { id: 0, name: "Default" }
                                                  }
                                                  onChange={(event, newValue) => {
                                                    updateResponseSingleChoiceDropdownValues(
                                                      response?.id,
                                                      {
                                                        values: newValue,
                                                      }
                                                    )
                                                  }}
                                                  isOptionEqualToValue={(positions, value) =>
                                                    positions.name === value.name
                                                  }
                                                  size="small"
                                                  // id="controllable-states-demo"
                                                  options={Globals.responseBehavior}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label="Response behavior"
                                                    />
                                                  )}
                                                />
                                              )}
                                            </Grid>
                                            <Grid item xs={2} xl={1}>
                                              {response.canDelete ? (
                                                <Tooltip title="Delete">
                                                  <DeleteResponseModal
                                                    removeResponseSingleChoiceDropdownValues={
                                                      removeResponseSingleChoiceDropdownValues
                                                    }
                                                    response={response}
                                                  />
                                                </Tooltip>
                                              ) : null}
                                            </Grid>
                                            {!bIsFreeText && (
                                              <Grid item xs={2} xl={2}>
                                                {/* is core */}
                                                <Tooltip title="Is core">
                                                  <Switch
                                                    checked={response.isCore}
                                                    onChange={() =>
                                                      updateResponseSingleChoiceDropdownValues(
                                                        response?.id,
                                                        {
                                                          isCore: !response?.isCore,
                                                        }
                                                      )
                                                    }
                                                  />
                                                </Tooltip>
                                              </Grid>
                                            )}
                                            {!bIsFreeText && (
                                              <Grid
                                                container
                                                spacing={3}
                                                alignItems="center"
                                                sx={{ mt: 0.1 }}
                                                xl={1}
                                              >
                                                <IconButton
                                                  size="small"
                                                  onClick={() =>
                                                    addAutoResponse(questionID, response?.id)
                                                  }
                                                >
                                                  <AddCircleIcon />
                                                </IconButton>
                                                <MDTypography
                                                  variant="caption"
                                                  fontWeight="bold"
                                                  color="text"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    addAutoResponse(questionID, response?.id)
                                                  }
                                                >
                                                  Add auto response
                                                </MDTypography>
                                              </Grid>
                                            )}
                                            {!bIsFreeText && (
                                              <Grid
                                                container
                                                spacing={3}
                                                alignItems="center"
                                                sx={{ mt: 0.1 }}
                                              >
                                                {items?.map((item) => {
                                                  console.log("item of auto reponse: ", item);
                                                  const question = localQuestionsWithAnswers.find(
                                                    (q) => q.question_id?.[0] == item?.relay_to_question_id
                                                  );
                                                  return (
                                                    <>
                                                      <Grid item xs={1} xl={1}></Grid>
                                                      <Grid
                                                        item
                                                        xs={5}
                                                        xl={5}
                                                        container
                                                        justifyContent="center"
                                                      >
                                                        <Autocomplete
                                                          fullWidth
                                                          size="small"
                                                          freeSolo
                                                          id="free-solo-2-demo"
                                                          disableClearable
                                                          value={question}
                                                          options={localQuestionsWithAnswers}
                                                          getOptionLabel={(option) => option?.text}
                                                          onChange={(e, value) => {
                                                            item.relay_to_question_id = value?.question_id?.[0];
                                                            setSelectedQuestion(value);
                                                          }}
                                                          renderInput={(params) => (
                                                            <TextField
                                                              {...params}
                                                              label="Question"
                                                              InputProps={{
                                                                ...params.InputProps,
                                                                type: "search",
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                      </Grid>

                                                      <Grid
                                                        item
                                                        xs={4}
                                                        xl={4}
                                                        container
                                                        justifyContent="center"
                                                      >
                                                        {/* answer: {item?.answer_id} */}
                                                        <Autocomplete
                                                          fullWidth
                                                          size="small"
                                                          freeSolo
                                                          id="free-solo-2-demo"
                                                          disableClearable
                                                          options={question?.answers_text || []}
                                                          value={
                                                            question?.answers_text[
                                                              question?.answer_id?.findIndex(
                                                                (t) =>
                                                                  t === item?.relay_to_reponse_id
                                                              )
                                                            ]
                                                          }
                                                          onChange={(e, value) => {
                                                            item.relay_to_reponse_id =
                                                              question.answer_id[
                                                                question?.answers_text?.findIndex(
                                                                  (t) => t === value
                                                                )
                                                              ];
                                                          }}
                                                          // options={localQuestionsWithAnswers.map((option) => option.text)}
                                                          // options={[]}
                                                          renderInput={(params) => (
                                                            <TextField
                                                              {...params}
                                                              label="Answer"
                                                              InputProps={{
                                                                ...params.InputProps,
                                                                type: "search",
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={1}
                                                        xl={1}
                                                        container
                                                        justifyContent="center"
                                                      >
                                                        <Tooltip title="Remove">
                                                          <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                              removeAutoResponse(item?.id)
                                                            }
                                                          >
                                                            <RemoveCircleIcon />
                                                            {/* <MDTypography variant="caption" fontWeight="bold" color="text" >
                                                                                Remove scale mark
                                                                            </MDTypography> */}
                                                          </IconButton>
                                                        </Tooltip>
                                                      </Grid>
                                                      <Grid item xs={1} xl={1}></Grid>
                                                    </>
                                                  );
                                                })}
                                              </Grid>
                                            )}
                                          </Grid>
                                          <Divider sx={{ ml: 1, mr: 1, backgroundColor: "#27b" }} />
                                        </Grid>
                                      </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          </List>
                        </RootRef>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
                <Grid item xs={1} xl={1}>
                  <Tooltip title="Add response">
                    <IconButton onClick={() => addMoreResponseSingleChoiceDropdownValues()}>
                      <AddCircleIcon />
                      <MDTypography variant="caption" fontWeight="bold" color="text">
                        Add {bIsFreeText ? "suggestion" : "response"}
                      </MDTypography>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </MDBox>
          ) : null}

          {/** relay to question */}
          {/* {batchQuestionList?.length > 0 ? (
                        <Grid container spacing={3} mt={1} alignItems="center" >
                            <Grid item xs={3} xl={3} >
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Relay to questions
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={9} xl={9} >
                                <Autocomplete
                                    size="small"
                                    isOptionEqualToValue={(positions, value) => positions.name === value.name}
                                    multiple
                                    value={question?.relayToQuestion}
                                    // defaultValue={buildPositions(s?.position)}
                                    options={batchQuestionList}
                                    disableCloseOnSelect
                                    onChange={(e, value) => setQuestion({ ...question, relayToQuestion: value })}
                                    // onChange={(e, value) => updatePosition(index, value)}
                                    getOptionLabel={(positions) => positions.name}
                                    renderOption={(props, option, { selected }) =>
                                    (
                                        <li {...props} key={option.name} sx={{ height: 20 }}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            // variant="standard"
                                            label=""
                                            placeholder="More..."
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    ) : null} */}

          <Grid container spacing={3} alignItems="center">
            {/* {question.responseType === 'Single Choice Dropdown' ? (
                            <>b</>
                        ) : null} */}

            {question.responseType === "Scale" || question.responseType === "Range" ? (
              <Grid item xs={4} xl={4}>
                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch
                      checked={responseSwitcher.linear}
                      onChange={() =>
                        setResponseSwitcher({
                          ...responseSwitcher,
                          linear: !responseSwitcher.linear,
                        })
                      }
                    />
                  </MDBox>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Linear
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch
                      checked={responseSwitcher.displaySelectedValue}
                      onChange={() =>
                        setResponseSwitcher({
                          ...responseSwitcher,
                          displaySelectedValue: !responseSwitcher.displaySelectedValue,
                        })
                      }
                    />
                  </MDBox>
                  <MDBox ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Display Selected Value
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch
                      checked={responseSwitcher.displayMinMaxValues}
                      onChange={() =>
                        setResponseSwitcher({
                          ...responseSwitcher,
                          displayMinMaxValues: !responseSwitcher.displayMinMaxValues,
                        })
                      }
                    />
                  </MDBox>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Display Min/Max Values
                    </MDTypography>
                  </MDBox>
                </MDBox>

                {question.responseType !== "Range" ? (
                  <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                    <MDBox mt={0.5}>
                      <Switch
                        checked={responseSwitcher.reversedItem}
                        onChange={() =>
                          setResponseSwitcher({
                            ...responseSwitcher,
                            reversedItem: !responseSwitcher.reversedItem,
                          })
                        }
                      />
                    </MDBox>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        Reversed Item
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ) : null}
              </Grid>
            ) : null}

            {question.questionType?.name === "Bio" || question.questionType?.name === "Pref" ? (
              <>
                <Grid item xs={4} xl={4}>
                  {question.questionType?.name === "Pref" ? (
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDBox mt={0.5}>
                        <Switch
                          checked={responseSwitcher.openToAll}
                          onChange={() =>
                            setResponseSwitcher({
                              ...responseSwitcher,
                              openToAll: !responseSwitcher.reversedItem,
                            })
                          }
                        />
                      </MDBox>

                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          Include: Open to all
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  ) : (
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDBox mt={0.5}>
                        <Switch
                          checked={responseSwitcher.preferNotSay}
                          onChange={() =>
                            setResponseSwitcher({
                              ...responseSwitcher,
                              preferNotSay: !responseSwitcher.reversedItem,
                            })
                          }
                        />
                      </MDBox>

                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          Include: I'd rather not say
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  )}

                  {/* <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                                        <MDBox mt={0.5}>
                                            <Switch checked={responseSwitcher.iDontMind} onChange={() => setResponseSwitcher({ ...responseSwitcher, iDontMind: !responseSwitcher.iDontMind })} />
                                        </MDBox>
                                        <MDBox width="80%" ml={0.5}>
                                            <MDTypography variant="button" fontWeight="regular" color="text">
                                                Open to all
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox> */}
                </Grid>
                <Grid item xs={4} xl={4}></Grid>
              </>
            ) : null}
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}
