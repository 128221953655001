import { Card, Grid } from "@mui/material";
import CardItem from "components/alove/CardItem";
import ItemPageContainer from "layouts/itemPageContainer";
import { Comm } from "models/content/Comm";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createCommDO, deleteCommDO, getComm, saveCommDO } from "utils/commUtils";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import EmailPreview from "./preview";
import { EmailForm } from "./emailForm";
import { ScreenModel } from "models/content/Screen";
import { getScreens } from "utils/screenUtils";
import { ItemStatus } from "layouts/itemPageContainer/components/itemStatus";
import { GeneralCode } from "models/content/GeneralCode";
import { EmailWorkflow } from "./workflow";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const EmailContainer = ({
  comm,
  setCommObject,
  saveComm,
  screens
}: {
  comm: Comm;
  setCommObject: any;
  saveComm: any;
  screens: ScreenModel[];
}) => {
  const pageNumber = "3.6";
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;

  return (
    <React.Fragment>
     
      <Grid container flexDirection="column" gap={1} style={{ width: "49.5%" }}>
        <CardItem title="Edit email" id="comm">
          <EmailForm comm={comm} setComm={setCommObject} />
        </CardItem>
        <CardItem title="Email settings" id="workflow">
          <EmailWorkflow comm={comm} setComm={setCommObject} screens={screens} />
        </CardItem>
      </Grid>
      <CardItem title="Email preview" id="settings" halfWidth>
        <EmailPreview comm={comm} />
      </CardItem>
    </React.Fragment>
  );
};
