import { Autocomplete, Card, Grid } from "@mui/material";
import CardItem from "components/alove/CardItem";
import { Comm, EmailTemplate, emailTemplateLabels } from "models/content/Comm";
import { snakeCaseToCapitalizedWords } from "utils/globalsUtils";

const MDInput = require("components/MDInput/index.js").default;

export const EmailForm = ({ comm, setComm }: { comm: Comm; setComm: any }) => {
  return (
    <Grid container flexDirection="row" justifyContent="start" alignItems="center" gap={2}>
      <MDInput
        label="Title"
        value={comm.email?.title || ""}
        fullWidth
        multiline
        onChange={(e) => setComm({ ...comm, email: { ...comm.email, title: e.target.value } })}
      />
      {emailTemplateLabels[comm.email?.template || ""]?.map((label) => (
        <MDInput
          fullWidth
          label={snakeCaseToCapitalizedWords(label)}
          value={comm.email?.[label] || ""}
          multiline
          onChange={(e: any) =>
            setComm({ ...comm, email: { ...comm.email, [label]: e.target.value } })
          }
        />
      ))}
    </Grid>
  );
};
