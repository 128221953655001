import { makeRequest, networkGlobals } from "./networkUtils";

/** credentionals for auth */
export const authDO = async (email, password, host) => {
  return makeRequest("auth/", { method: "POST", body: { email: email.toLowerCase(), password, host}, withoutError:true });
};

export const checkAuthDO = async () => {
  return makeRequest("auth/checkAuth", { method: "GET" });
};


