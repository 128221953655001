import axios from "axios";
import { getLambadaSubdomain, makeRequest, networkGlobals } from "./networkUtils";
/** get list of questions type */

export const getQuestionsTypeDO = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${networkGlobals.serverHost}/api/questions/questionsType?host=${networkGlobals.host}`)
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get profile:", error);
        reject(error);
      });
  });
};

export const getQuestionsFactorDO = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${networkGlobals.serverHost}/api/questions/questionsFactor?host=${networkGlobals.host}`)
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get profile:", error);
        reject(error);
      });
  });
};

export const getQuestionsDO = async (host, brandID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${networkGlobals.serverHost}/api/questions/questions?host=${host}&brandID=${brandID}`)
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get getQuestionsDO:", error);
        reject(error);
      });
  });
};

export const questionsListWithAnswersDO = async (batchID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${networkGlobals.serverHost}/api/questions/questionsListWithAnswers?host=${
          networkGlobals.host
        }&brandID=${networkGlobals.brand.id}&batchID=${batchID || ""}`
      )
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get getQuestionsDO:", error);
        reject(error);
      });
  });
};

export const getResponseTypeDO = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${networkGlobals.serverHost}/api/questions/responseTypes?host=${networkGlobals.host}`)
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get getResponseTypes:", error);
        reject(error);
      });
  });
};

export const getQuestionsCategoryDO = async (host, brandID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${networkGlobals.serverHost}/api/questions/questionsCategory?host=${
          host || networkGlobals.host
        }&brandID=${brandID || networkGlobals.brand.id}`
      )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        console.error("error from get getQuestionsCategoryDO:", error);
        reject(error);
      });
  });
};

export const getAttributesDO = async (host) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${networkGlobals.serverHost}/api/questions/attributes?host=${host}`)
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get getQuestionsCategoryDO:", error);
        reject(error);
      });
  });
};

export const getBatchQuestionDO = async (host, questionID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${networkGlobals.serverHost}/api/questions/batchQuestion?host=${host}&questionID=${questionID}`
      )
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get getQuestionsCategoryDO:", error);
        reject(error);
      });
  });
};

export let questionnaires = {};

export const questionnarieListDo = async (host, brand) => {
  const res = await makeRequest("questionnaires");
  questionnaires = res?.reduce((acc, curr) => {
    acc[curr.name] = curr.id;
    return acc;
  }, {});
  return res;
  // return new Promise((resolve, reject) => {
  //     // const url = `${networkGlobals.serverHost}/api/questions/questionnarieList?host=${host}&brand=${brand}`;
  //     // console.log('url: ', url);
  //     axios.get(`${networkGlobals.serverHost}/api/questions/questionnarieList?host=${host || networkGlobals.host}&brand=${brand || networkGlobals.brand.id}`)
  //         .then(res => {
  //             questionnaires = res?.data?.rows.reduce((acc, curr) => {
  //                 acc[curr.name] = curr.id
  //                 return acc;
  //             }, {})
  //             resolve(res?.data?.rows);
  //         })
  //         .catch(error => {
  //             console.error('error from get questionnarieListDO:', error)
  //             reject(error);
  //         })
  // })
};

export const updateQuestionsOrderDO = async (batchId, questions) => {
  return makeRequest(`questionnaires/${batchId}/order`, { method: "PUT", body: questions });
  // return new Promise((resolve, reject) => {
  //     axios.put(`${networkGlobals.serverHost}/api/questions/questionsOrder`, { host, questions })
  //         .then(res => {
  //             console.log('res from update question order: ', res);
  //             resolve(res.status);
  //         })
  //         .catch(error => {
  //             console.error('error from update question order:', error)
  //             reject(error);
  //         })
  // })
};

export const questionsListDO = async (batchID) => {
  return makeRequest(`questionnaires/${batchID}/questions`);
  // return new Promise((resolve, reject) => {
  //     axios.get(`${networkGlobals.serverHost}/api/questions/questionsList?host=${networkGlobals.host}&brandID=${networkGlobals.brand.id}&batchID=${batchID || ""}`)
  //         .then(res => {
  //             resolve(res?.data?.rows);
  //         })
  //         .catch(error => {
  //             console.error('error from get questionListDO:', error)
  //             reject(error);
  //         })
  // })
};

export const getQuestionDO = async (questionID, batchID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${networkGlobals.serverHost}/api/questions/?host=${networkGlobals.host}&questionID=${questionID}&batchID=${batchID}`
      )
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get questionDO:", error);
        reject(error);
      });
  });
};

export const saveNewQuestionDO = async (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${networkGlobals.serverHost}/api/questions/`, {
        host: networkGlobals.host,
        data,
        brandID: networkGlobals.brand.id,
      })
      .then((res) => {
        console.log("res from save new question: ", res);
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from post positions:", error);
        console.log("error from res: ", error);
        resolve(error);
        // reject(error);
      });
  });
};

export const saveTextQuestionDO = async (
  host,
  data,
  questionnarieID,
  brandID,
  userID,
  lastOrdinal
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${networkGlobals.serverHost}/api/questions/textQuestion`, {
        host,
        data,
        questionnarieID,
        brandID,
        userID,
        lastOrdinal,
      })
      .then((res) => {
        console.log("res from save new question: ", res);
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from post positions:", error?.response?.status);
        resolve(error?.response?.status);
        // reject(error);
      });
  });
};

export const updateTextQuestionDO = async (host, data, brandID, userID) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${networkGlobals.serverHost}/api/questions/textQuestion`, {
        host,
        data,
        brandID,
        userID,
      })
      .then((res) => {
        console.log("res from update text question: ", res);
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from update question:", error);
        reject(error);
      });
  });
};

export const updateQuestionStatusDO = async (host, quesionID, status) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${networkGlobals.serverHost}/api/questions/questionStatus`, { host, quesionID, status })
      .then((res) => {
        console.log("res from update text question: ", res);
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from update question:", error);
        reject(error);
      });
  });
};

export const saveNewQuestionnaire = async (host, data, brandID, userID, batchType) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${networkGlobals.serverHost}/api/questions/questionaire`, {
        host,
        data,
        brandID,
        userID,
        batchType,
      })
      .then((res) => {
        console.log("res from save new questionnaire: ", res);
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from post new questionnaire:", error);
        reject(error);
      });
  });
};

export const updateQuestionarieDO = async (name, description, version, host, id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${networkGlobals.serverHost}/api/questions/updateQuestionarie`, {
        name,
        description,
        version,
        host,
        id,
      })
      .then((res) => {
        console.log("res from save new question: ", res);
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from post positions:", error);
        reject(error);
      });
  });
};

export const responsesByQuestionIDDO = async (questionID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${networkGlobals.serverHost}/api/questions/responsesByQuestionID?host=${networkGlobals.host}&questionID=${questionID}`
      )
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get getResponseTypes:", error);
        reject(error);
      });
  });
};

export const getAutomaticAnswersDO = async (questionID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${networkGlobals.serverHost}/api/questions/automaticAnswers?host=${networkGlobals.host}&questionID=${questionID}`
      )
      .then((res) => {
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get getResponseTypes:", error);
        reject(error);
      });
  });
};

export const updateQuestionDO = async (quesionID, data) => {
  console.log("data: ", data);
  return new Promise((resolve, reject) => {
    axios
      .put(`${networkGlobals.serverHost}/api/questions/question`, {
        host: networkGlobals.host,
        quesionID,
        data,
        brandID: networkGlobals.brand.id,
      })
      .then((res) => {
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from post positions:", error);
        reject(error);
      });
  });
};

export const deactivateQuesionDO = async (host, id, value) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${networkGlobals.serverHost}/api/questions/deactivateQuesion`, { host, id, value })
      .then((res) => {
        resolve(res.status);
      })
      .catch((error) => {
        console.error("error from post positions:", error);
        reject(error);
      });
  });
};

export const publishQuestionnaireDO = async (host, brand, questionID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://${getLambadaSubdomain()}.algoaitech.io/utils/deploySettings/questionnaire/${brand.name.toLowerCase()}/${
          brand.id
        }?batchId=${questionID}`
      )
      .then((res) => {
        resolve(res?.status);
      })
      .catch((error) => {
        console.error("error from get getResponseTypes:", error);
        resolve(error);
        // reject(error);
      });
  });
};

export const batchListByBrandDO = async (host, brandID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${networkGlobals.serverHost}/api/questions/batchListByBrand?host=${host}&brandID=${brandID}`
      )
      .then((res) => {
        console.log("res: ", res);
        resolve(res?.data?.rows);
      })
      .catch((error) => {
        console.error("error from get question:", error);
        resolve(error);
        // reject(error);
      });
  });
};
