import { Alert, Snackbar, Tooltip } from "@mui/material";
import { ReactComponent as ResumeSvg } from "assets/images/icons/resume-link.svg";
import { ReactComponent as ResumePaper } from "assets/images/icons/resume_paper.svg";
import React from "react";
import { T } from "./Translator";
export const ResumeIcon = (props) => {
  const { resume, enabled, big } = props;
  const hasResume = !!resume && resume != "[]";
  const [open, setOpen] = React.useState(false);
  function openResume(e) {
    e.stopPropagation();
    if (hasResume) {
      if (enabled) {
        window.open(resume);
      } else {
        setOpen(true);
      }
    }
  }
  return (
    <React.Fragment>
      <Tooltip title={hasResume ? <T>resumeTooltip</T> : <T>noResumeTooltip</T>}>
        {big ? <ResumePaper
          onClick={openResume}
          style={{
            width: "100%",
            height: "100%",
            opacity: hasResume ? 1 : 0.2,
            cursor: hasResume ? "pointer" : "default",
          }}></ResumePaper> : <ResumeSvg
          onClick={openResume}
          style={{
            width: "40px",
            height: "40px",
            opacity: hasResume ? 1 : 0.2,
            cursor: hasResume ? "pointer" : "default",
            padding: "10px",
          }}
        /> }
      </Tooltip>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message="Confirm this request before viewing resume"
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          Confirm this request before viewing resume!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
