import { Grid, Switch } from "@mui/material";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { NotificationCategory, NotificationSetting, NotificationType } from "models/profile/EndUser";
import { Profile } from "models/profile/Profile";
import React, { useEffect } from "react";
import { getNotifcationsSettings, updateNotificationSetting } from "utils/enduserUtils";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
const MDTypography = require("components/MDTypography/index.js").default;

export const EUNotificationsSettings = ({ profileId = "" }) => {
  const [notifications, setNotifications] = React.useState([] as NotificationSetting[]);
  useEffect(() => {
    if (!profileId) return;
    getNotifcationsSettings(profileId).then((res) => {
      setNotifications(res);
    });
  }, [profileId]);

  const updateSetting = (channel_id: number, category_id: number, state: boolean) => {
    const notification = notifications.find((n) => n.channel_id == channel_id && n.category_id == category_id);
    if (!notification) {
      notifications.push({ channel_id, category_id, state });
    } else {
      notification!.state = state;
    }
    setNotifications([...notifications]);
    updateNotificationSetting(profileId, channel_id, category_id, state);
  }

  return (
    <Grid container gap={2} alignItems="center" flexDirection="column">
       <Grid container  flexDirection="row" >
          {[1, 2, 3].map((j) => (
            <Grid
              item
              xs
              container
              alignItems="center"
              flexDirection="row"
              justifyContent={"space-evenly"}
              key={j}
            >
              <MDTypography variant="h6">
                {camelCaseToCapitalizedWords(NotificationCategory[j])}
              </MDTypography>
            </Grid>
          ))}
        </Grid>
      {[1, 2].map((i) => (
        <Grid container gap={5} alignItems="center" flexDirection="row" key={i}>
          {[1, 2, 3].map((j) => (
            <Grid
              item
              xs
              container
              alignItems="center"
              flexDirection="row"
              justifyContent={"space-between"}
              key={j}
            >
              <Switch
                disabled={i == 2}
                checked={notifications?.find((n) => n.channel_id == i && n.category_id == j)?.state || false}
                onChange={(e) => updateSetting(i, j, e.target.checked)}
              />
              <MDTypography variant="body2">
                {NotificationType[i]}
              </MDTypography>
              <VerticalDivider key={i + j} />
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};
