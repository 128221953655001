import { Grid } from "@mui/material";
import { T } from "components/alove/Translator";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { Profile } from "models/profile/Profile";
import React from "react";
import { formatDate } from "utils/globalsUtils";

import MToken from "assets/images/icons/m-token.svg";
import Box from "assets/images/icons/box.svg";

const MDTypography = require("components/MDTypography/index.js").default;

export const PackageDetails = ({ profile }: { profile: Profile }) => {
  return (
    <Grid container flexDirection="row" alignItems="center" gap={1}>
      <img src={Box} />
      <MDTypography variant="body2">
        <T
          params={{
            purchased: formatDate(profile.purchasedAt),
          }}
        >
          packageTitle{profile.plan}
        </T>
      </MDTypography>
      <VerticalDivider />
      <img src={MToken} />
      <MDTypography variant="body2">
        {profile.tokens} <T>tokensLeft</T>
      </MDTypography>
    </Grid>
  );
};
