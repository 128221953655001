import { useState, } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { TablePage } from "components/alove/TablePage";
import { getBrandsDO, countUserForBrandDO, getCountTickets } from "utils/brandsUtils";
// import { hasFeature } from "utils/settingsUtils";
import { StatsPanel } from "components/alove/StatsPanel";
// import { TwoSizeChip } from "components/alove/Chips";
import { formatDateTime, formatDate } from "utils/globalsUtils";
const { useMaterialUIController } = require("context");
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDInput = require("components/MDInput/index.js").default;

function ViewBrandsList() {
  const navigate = useNavigate();
  
  const [controller, dispatch] = useMaterialUIController();
  const { currentBrand, currentUser,  } = controller;
  const [brands, setBrands] = useState(false);
  const [stats, setStats] = useState({
    brands: 0,
    systemUsers: 32,
    totalUsers: 0,
    deactivatedProfiles: 0,
  });
  const [showPending, setShowPending] = useState(false);
  const isAdmin = currentUser?.role?.includes(1);
  const columns: GridColDef<any>[] = [
    {
      headerName: "Brand",
      field: "name",
      flex: 1,
      // minWidth: 170,
      // renderCell: (params) => console.log(params),
    },
    {
      headerName: "Created",
      field: "created",
      flex: 1,
      // minWidth: 170,
      renderCell: (params) => formatDate(params.row.created),
    },
    {
      headerName: "Last Update",
      field: "lastUpdate",
      flex: 1,
      // minWidth: 170,
      renderCell: (params) => formatDate(params.row.updated),
    },

    { headerName: "End Users", field: "endUsers", flex: 1, align: "center" },
    { headerName: "Pending Content", field: "pendingContent", flex: 1 },
    { headerName: "Open Tickets", field: "openTickets", flex: 1,  align: "center"},
    {
      headerName: "SLA fulfillment",
      field: "SLAfulfillment",
      flex: 1,
      // minWidth: 170,
      renderCell: (params) => formatDateTime(params.row.lastLogin),
    }
  ];

  const getBrands = async (host: string, filters: any, page: number) => {
    const realBrands: any = await getBrandsDO(host);
    const usersData: any = await countUserForBrandDO('prod');
    const openTickets: any = await getCountTickets(host, 'all');
    
    let totalEndUsers = 0;
    
    realBrands.forEach(entity => {
      let countData = usersData?.find(count => count.brand_id === entity.id);
      
      if (countData) {
        entity.endUsers = countData.count;
        totalEndUsers += Number(countData.count);
      }
  
      let ticketData = openTickets?.find(ticket => ticket.brand === entity.id);
      
      if (ticketData) {
        entity.openTickets = ticketData.tickets;
      } else {
        entity.openTickets = 0;
      }
    });
    
    setBrands(realBrands);
    setStats({ ...stats, brands: realBrands.length, totalUsers: totalEndUsers });
    return realBrands;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Brands" />
      <Grid container gap={1}>
        <TablePage
          title='Brands'
          table={{
            columns,
            loadNext: getBrands,
            lineClick: (row) => navigate(`/brands/${row?.row?.name}`),
            pagination: isAdmin,
            // rowCount: stats.totalProfiles,
          }}
          filters={[]}
          stats={{
            title:"Brands Statistics",
            fields:[
              {
                title: "Brands",
                name: "brands",
                color: "primary",
              },
              {
                title: "System Users",
                name: "systemUsers",
              },
              {
                title: "Total users",
                name: "totalUsers",
              }
            ],
            stats:stats}
          }
        />
      </Grid>
    </DashboardLayout>
  );
}

export default ViewBrandsList;
