import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ItemPageContainer from "layouts/itemPageContainer";
import { formatDate } from "utils/globalsUtils";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import { Profile, ProfileStatus } from "models/profile/Profile";
import {
  updateStatusIntroduction,
  getIntroductionDO,
  getAttachmentMatchScore,
  swapIntroductionDO,
  compareIntroduction,
  createNewIntroduction,
} from "utils/introductionUtils";
import { Introduction, IntroductionStatus } from "models/introduction/Introduction";
import CardItem from "components/alove/CardItem";
import { AttributesTable } from "./components/attributesTable";
import { ProfileCards } from "./components/profileCard";
import { EndUser } from "models/profile/EndUser";
import { PermissionType } from "models/BO/BoRole";
import { IntroductionLogs } from "./components/introductionLogs";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";
import { DialogType } from "components/alove/AloveDialog";
import { swap } from "formik";
import { create } from "domain";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
let newStatus = ProfileStatus.Active;

function ViewIntroduction() {
  const pageNumber = "2.8";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole, currentHost } = controller;
  const navigate = useNavigate();
  const [introduction, setIntroduction] = useState({} as Introduction);
  const [loader, setLoader] = useState(true);
  const [attachmentResult, setAttachmentResult] = useState(-1);
  let { id } = useParams();
  let [queryParameters ] = useSearchParams();
  const initiatorProfileId = queryParameters.get("initiatorProfileId");
  const responderProfileId = queryParameters.get("responderProfileId");

  useEffect(() => {
    setLoader(true);
    getIntroduction();
  }, [id]);

  const getIntroduction = async () => {
    setLoader(true);

    const res = await (id == "compare" ? compareIntroduction(initiatorProfileId, responderProfileId) : getIntroductionDO(id));
     
    setLoader(false);
    if (res) {
      const intro = Object.assign(new Introduction(), res);
      intro.initiatorUser = Object.assign(new EndUser(), res.initiatorUser);
      intro.responderUser = Object.assign(new EndUser(), res.responderUser);
      intro.initiatorProfile = Object.assign(new Profile(), intro.initiatorProfile);
      intro.responderProfile = Object.assign(new Profile(), res.responderProfile);
      setIntroduction(intro);


      getAttachmentMatchScore(intro).then((res) => setAttachmentResult(res));
    }
  };

  const swapIntroduction = async () => {
    swapIntroductionDO(id)
        .then(() => getIntroduction())
        .then(() => addAlert(dispatch, "Introduction Swapped"));
    
  }

  const createIntroduction = async () => {
    createNewIntroduction({initiatorProfileId: initiatorProfileId!, responderProfileId: responderProfileId!})
    .then((res) => navigate(`/introductions/${res.introduction.introductionId}`))
    .then(() => addAlert(dispatch, "Introduction Created"));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ItemPageContainer
        loader={loader}
        navItems={[
          { label: "Introduction Details" },
          { label: "Parties Details", ref: "parties" },
          { label: "Logs", ref: "logs" },
        ]}
        actions={{
          itemName: `Introduction`,
          itemNumber: `Introduction ID: ${id}`,
          actionsTitle: "View Introduction",
          actions:
            introduction.status == IntroductionStatus.PENDING_APPROVAL
              ? [
                  {
                    hidden: !currentRole.hasAccess(pageNumber, PermissionType.Edit),
                    onClick: () =>
                      updateStatusIntroduction(id, IntroductionStatus.AVAILABILITY_CHECK)
                        .then(() => getIntroduction())
                        .then(() => addAlert(dispatch, "Introduction Approved")),
                    title: "Approve",
                    variant: "contained",
                  },
                  {
                    hidden: !currentRole.hasAccess(pageNumber, PermissionType.Edit),
                    onClick: () => setCurrentDialog(dispatch, DialogType.DeactivateEntitiy),
                    title: "Decline",
                    variant: "outlined",
                    color: "error",
                  },
                  {
                    hidden: !currentRole.hasAccess(pageNumber, PermissionType.Edit),
                    onClick: swapIntroduction,
                    title: "Swap Direction",
                    variant: "outlined"
                  }
                ]
              : id == "compare" ? [
                {
                  title: "Create This Now",
                  onClick: createIntroduction,
                  variant: "contained",
                }
              ] : 
              []
        }}
        itemStatus={id == "compare"  ? undefined :{
          title: "User Status",
          item: introduction,
          isManager: currentRole.hasAccess(pageNumber, PermissionType.Edit),
          changeStatus: (status) => 
            updateStatusIntroduction(id, status)
              .then(() => getIntroduction())
              .then(() => addAlert(dispatch, "Status Updated")),
        }}
        itemHeader={
          <SMEHeader
            title="Introduction Details"
            formData={introduction}
            showImage={false}
            indications={[
              {
                title: "Created:",
                value: formatDate(introduction.created),
              },
              {
                title: "Last Updated:",
                value: formatDate(introduction.updated),
              },
              {
                title: "Predictors Score:",
                value: attachmentResult,
              },
              {
                hidden: ![IntroductionStatus.RAW, IntroductionStatus.INITIATOR_APPROVED].includes(
                  introduction.status
                ),
                title: "SLA:",
                value: formatDate(introduction.mustRespondUntil),
              },
            ]}
          />
        }
      >
        <CardItem title="Parties Details" id="parties">
          <ProfileCards shRequest={introduction} isAdmin />
        </CardItem>
        {id != "compare" && (
          
        <CardItem title="Logs" id="logs">
          <IntroductionLogs id={id} />
        </CardItem>
        )}
      </ItemPageContainer>
      <DeactivateDialog
        title="intoRejectionReason"
        content="intoRejectionReasonContent"
        onSubmit={(reason) =>
          updateStatusIntroduction(id, IntroductionStatus.ADMIN_REJECTED,reason).then(() => navigate(-1))
        }
        reasonLabel="intoRejectionReasonLabel"
        confirm="Reject"
      />
    </DashboardLayout>
  );
}

export default ViewIntroduction;
