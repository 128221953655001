export default {

    measuredPredictor: [
        { id: 17, name: 'Anxiety dimension' },
        { id: 18, name: 'Avoidance dimension' }
    ],

    layout: [
        { id: 1, name: 'In chat' },
        { id: 2, name: 'Full screen' }
    ],

    language: [
        { id: 'en', name: 'EN' }
    ],

    pairWith: [
        'Pair with'
    ],

    responseBehavior : [
        { id: 0, name: 'Default' },
        { id: 1, name: 'Open to all' },
        { id: 2, name: 'Act as "I\'d rather not to say' },
        { id: 3, name: 'Nothing' }
    ]
}