import { Collapse, Grid, Icon } from "@mui/material";
import { TableColumnProps } from "./TablePage";
import { useState } from "react";
import { T } from "./Translator";

const MDTypography = require("components/MDTypography/index.js").default;

export interface MobileListItemProps {
  item: any;
  onRowOpen?: () => void;
  currentRowId?: string;
  columns: TableColumnProps<any>[];
}

export const MobileListItem = (props: MobileListItemProps): JSX.Element => {
  if (!props.columns.find(p=>p.featured)) {
    props.columns[0].featured = true;
  }
  return (
    <Grid container flexDirection="column" gap={1}>
      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        
        style={{ height: "70px" }}
      >
        {props.columns
          .filter((column) => column.featured)
          .map((column) => (
            <Grid
              item
              key={column.field}
              style={{ alignItems: "center", whiteSpace: "pre-line" }}
              xs={3}
            >
              <MDTypography variant="body2">
                {column.renderCell?.({ row: props.item } as any) ||
                  (column.valueFormatter as any)?.(props.item[column.field], props.item) ||
                  props.item[column.field]?.toString()}
              </MDTypography>
            </Grid>
          ))
          .concat(
            <Grid
              item
              xs={1}
              onClick={(e) => {
                props.onRowOpen?.();
                e.stopPropagation();
                e.preventDefault();
              }}
              style={{height: "70px"}}
            >
              <Icon sx={{ fontSize: "24px !important", lineHeight: "70px",height: "340px" }}>
                {props.currentRowId == props.item.id ? "expand_less" : "expand_more"}
              </Icon>
            </Grid>
          )}
      </Grid>
      {props.columns
        .filter((column) => !column.hidden?.(column))
        .filter((column) => !column.mobileHidden)
        .filter((column) => !column.featured)
        .map((column) => (
          <Grid item container key={column.field} justifyContent="space-between">
            <MDTypography variant="body2" style={{ fontWeight: "bold" }}>
              <T>{column.headerName}</T>
            </MDTypography>
            <MDTypography variant="body2">
              {column.renderCell?.({ row: props.item } as any) ||
                (column.valueFormatter as any)?.(props.item[column.field], props.item) ||
                props.item[column.field]?.toString()}
            </MDTypography>
          </Grid>
        ))}
    </Grid>
  );
};
