import { Clear } from "@mui/icons-material";
import { Grid, IconButton, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { uploadImage } from "utils/networkUtils";

const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;


const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: "100%",
  });

export const ImageUploader = ({ initImage, onUploaded }: { initImage: string; onUploaded: (image: string) => void }) => {

    const [image, setImage] = useState(initImage);

    useEffect(() => {
        setImage(initImage);
    }, [initImage]);
  

  const pickImage = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result as string);
      uploadImage(file).then((res) => onUploaded(res.Location));
    };
    reader.readAsDataURL(file);
  };

    return (<Grid 
    container
    alignItems={"center"}
    justifyContent={"space-between"}
    style={{ 
      border: "1px solid black",
      borderRadius: "5px",
      padding: "5px 15px",
    }}>
      {image ?
      <img
        style={{
          width: "30%",
          height: "100px",
          objectFit: "cover",
        }}
        src={image}
        alt="screen"
      /> : <MDTypography variant="body2">Upload Image</MDTypography>}
    
    {image &&
    <IconButton onClick={() => onUploaded("")}>
        <Clear />
      </IconButton>
}
      <MDButton component="label" variant="outlined" color="primary">
        <VisuallyHiddenInput type="file" onChange={pickImage} accept="image/*" />
        Browse
      </MDButton>
  </Grid>)
}