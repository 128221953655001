/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

const MDBox = require("components/MDBox").default;
const MDTypography = require("components/MDTypography").default;
const MDButton = require("components/MDButton").default;
const MDBadgeDot = require("components/MDBadgeDot").default;
const PieChart = require("examples/Charts/PieChart").default;

// Otis Admin PRO React contexts
import { useMaterialUIController } from "context";
import { PieChartReport } from "models/BO/Report";
import { useEffect, useState } from "react";
import { colorsArray } from "assets/theme/base/colors";
import { T } from "components/alove/Translator";
import { DropdownButton } from "components/alove/DropDownButton";
import { capitalize } from "@mui/material";
import { snakeCaseToCamelCase, snakeCaseToCapitalizedWords } from "utils/globalsUtils";



const pieVariants = ["gender", "ethnicity","state","user_type"]

function PieChartContainer({ pieData,setPie }: { pieData: PieChartReport,setPie: any }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pie, _setPie] = useState("gender");
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">{pieData?.title}</MDTypography>
        
        <DropdownButton
                label="Gender"
                options={pieVariants.map((key) => ({ value: key, label: snakeCaseToCapitalizedWords(key) }))}
                onClick={(option) => {
                  _setPie(option.value);
                  setPie(option.value);
                }}
              />
      </MDBox>
      <MDBox mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <PieChart chart={pieData} height="12.5rem" />
          </Grid>
          <Grid item xs={5} height="12.5rem" style={{ overflow: "scroll" }}>
            <MDBox pr={1}>
              {pieData.labels.map((label, index) => (
                <MDBox mb={1}>
                  <MDBadgeDot
                    color={pieData.datasets.backgroundColors?.[index]}
                    size="sm"
                    badgeContent={<T>{label}</T>}
                  />
                </MDBox>
              ))}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {pieData.note}
          </MDTypography>
        </MDBox>
        {/* <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
          <MDButton color={darkMode ? "white" : "light"}>read more</MDButton>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default PieChartContainer;
