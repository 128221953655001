import React, { useEffect } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";

function MainDashboard() {

    const [controller, dispatch] = useMaterialUIController();
    const {
        auth,
        currentUser,
    } = controller;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} xl={12}>
                                <MDTypography variant="h5" fontWeight="medium">
                                    Dashboard 
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h6" fontWeight="medium">
                            Hello {currentUser?.first_name} {currentUser?.last_name}.
                        </MDTypography>
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default MainDashboard;