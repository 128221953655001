import { GridColDef } from "@mui/x-data-grid";
import { AddGeneralCodeDialog } from "components/alove/AddGeneralCodeDialog";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { TwoSizeChip } from "components/alove/Chips";
import { TablePage } from "components/alove/TablePage";
import { setCurrentDialog, useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";
import { Predictor, PredictorRelation, PredictorRelationLevel } from "models/batch/PredictorRelation";
import { useNavigate } from "react-router-dom";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { getPredictorRelations, publishPredictorRelations } from "utils/predictorRelationUtils";
import { AddRelationDialog } from "./components/addRelationDialog";
import React, { useEffect } from "react";
import { getQuestionsCategoryDO } from "utils/questionsUtils";
import { networkGlobals } from "utils/networkUtils";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const PredictorRelationsList = () => {
  const [controller, dispatch] = useMaterialUIController();
  const [refresh, setRefresh] = React.useState(false);
  const columns: GridColDef<PredictorRelation>[] = [{
    field: "id",
  },{
    field: "predictor1",
    headerName: "Predictor 1",
    valueGetter: (v) => camelCaseToCapitalizedWords(Predictor[v]),
    flex: 1,
  },{
    field: "level1",
    headerName: "Level 1",
    valueGetter: (v) => camelCaseToCapitalizedWords(PredictorRelationLevel[v]),
    flex: 1,
  },{
    field: "predictor2",
    headerName: "Predictor 2",
    valueGetter: (v) => camelCaseToCapitalizedWords(Predictor[v]),
    flex: 1,
  },{
    field: "level2",
    headerName: "Level 2",
    valueGetter: (v) => camelCaseToCapitalizedWords(PredictorRelationLevel[v]),
    flex: 1,
  },{
    field: "grade",
    headerName: "Grade",
  }];


  const loadPredictorRelations = () => getPredictorRelations();

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Predictors" />
      <TablePage
        actions={{
          title: "Predictors actions",
          addActions: [
            {
              label: "Relation",
              onClick: () => setCurrentDialog(dispatch, DialogType.AddPredictorRelation),
              variant: "contained",
              color: "secondary",
            }
          ],
        }}
        refresh={refresh}
        table={{
          loadNext: loadPredictorRelations,
          columns,
          groupingColDef: { width: 300 },
        }}
        title="Predictors"
      />
      <AddRelationDialog onRefresh={() => setRefresh(!refresh)} />
    </DashboardLayout>
  );
};
