import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MDTypography from "components/MDTypography";
// import { deactivateQuesionDO } from 'utils/questionsUtils';

export default function DeleteREsponseModal(props) {

    const { removeResponseSingleChoiceDropdownValues, response } = { ...props };

    const [open, setOpen] = React.useState(false);

    const handleRemoveAnswer = () => {
        removeResponseSingleChoiceDropdownValues(response?.id)
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}>
                <RemoveCircleIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Want more details?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {/* fontWeight="bold" */}
                        <MDTypography variant="h7" color="text" >
                            The text you're about to <MDTypography fontWeight="bold" variant="h7" color="text">update/delete </MDTypography>is linked to a key in the application,
                            which might be used in multiple places throughout the app.
                            Changing this text will update it across all instances where the key is referenced. For example,
                            if you change <MDTypography fontWeight="bold" variant="h7" color="text">"{response?.response}"</MDTypography>
                            which is a response option for <MDTypography fontWeight="bold" variant="h7" color="text">"How would you describe your body type?"</MDTypography>,
                            to <MDTypography fontWeight="bold" variant="h7" color="text">"Hourglass"</MDTypography> it will also modify the same key's text in the equivalent response for the question
                            <MDTypography fontWeight="bold" variant="h7" color="text">"What body type would you like in a partner?"</MDTypography>, since responses to both of these questions use the same keys.
                            This might be what you're aiming for, but be aware that the key could also be used in other parts of the app.
                        </MDTypography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} xl={6} sx={{ textAlign: "center" }}>
                            <Button onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} xl={6} sx={{ textAlign: "center" }}>
                            <Button onClick={() => handleRemoveAnswer()} autoFocus>Proceed</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}